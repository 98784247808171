import { object, string } from "yup";
import { ASIGNACION_ESTADOS } from "../../constants/contexts";

export const FormikInitialValues = {
	_id: "",
	fuente: "",
	situacion_laboral: "",
	titulo: "",
	institucion: "",
	exp_general: "",
	exp_especifica: "",
	evaluacion: 0,
	estado: ASIGNACION_ESTADOS.PENDIENTE,
}

export const FormikValidationSchema = object().shape({
	fuente: string()
		.nullable()
		.min(2, ({ min }) => `La fuente debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La fuente debe tener a lo más ${max} caracteres.`)
		.optional(),
	situacion_laboral: string()
		.nullable()
		.min(3, ({ min }) => `La situación laboral y disponibilidad debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La situación laboral y disponibilidad debe tener a lo más ${max} caracteres.`)
		.optional(),
	titulo: string()
		.nullable()
		.min(3, ({ min }) => `El título debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El título debe tener a lo más ${max} caracteres.`)
		.optional(),
	institucion: string()
		.nullable()
		.min(3, ({ min }) => `La institución debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La institución debe tener a lo más ${max} caracteres.`)
		.optional(),
	exp_general: string()
		.nullable()
		.min(3, ({ min }) => `La experiencia general debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La experiencia general deb tener a lo más ${max} caracteres.`)
		.optional(),
	exp_especifica: string()
		.nullable()
		.min(3, ({ min }) => `La experiencia específica debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La experiencia específica debe tener a lo más ${max} caracteres.`)
		.optional(),
	estado: string()
		.required("El estado es requerido."),
});