import { object, array } from "yup";
import * as Permissions from "../../../../constants/permissions";

export const FormikInitialValues = {
	persona: null,
	permisos: [],
}

export const FormikValidationSchema = object().shape({
	persona: object()
		.nullable()
		.required("La persona es requerida."),
	permisos: array()
		.of(object())
		.min(1, ({ min }) => `Se requiere al menos ${min} permiso.`)
		.required("Los permisos son requeridos."),
});

export const PERMISOS = Object.values(Permissions);