import React from "react";
import { Box } from "@material-ui/core";

export default function TabPanelComponent(props) {
	const {
		children,
		value,
		index,
	} = props;

	if (value === index) {
		return (
			<Box p={2}>
				{children}
			</Box>
		);
	} else {
		return null;
	}
}