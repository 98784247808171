import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import MaterialTable from "../../../../components/materialTable";
import TooltipCustom from "../../../../components/tooltip_custom";
import TabSelector from "../../../../components/tab_selector";
import DialogAddLicitacion from "./dialogAddLicitacion";
import DialogUpdateLicitacion from "./dialogUpdateLicitacion";
import DialogDeleteLicitacion from "./dialogDeleteLicitacion";
import { FormatearFecha, NombreAbreviado } from "../../../../services/utilities/formatUtils";
import { CheckPermiso } from "../../../../services/utilities/common";
import { LICITACION_PROYECTO_ESTADOS } from "../../../../constants/contexts";
import { DETALLE_LICITACION } from "../../../../constants/routes";
import * as Permissions from "../../../../constants/permissions";
import { MainContext } from "../../../../App";

export default function Page(props) {
	const {
		licitaciones,
		is_loading,
		gerencia,
		mutate_licitaciones,
	} = props;

	const [TabGerencia, SetTabGerencia] = useState(0);
	const [TabEstado, SetTabEstado] = useState(0);
	const [IsOpen, SetIsOpen] = useState({ tipo: "", data: null });
	const MainCTX = useContext(MainContext);
	const history = useHistory();

	let columns = [
		{
			title: "Código",
			field: "codigo",
			width: 150,
		}, {
			title: "Nombre",
			field: "nombre",
			width: 300,
		}, {
			title: "Vacantes abiertas",
			field: "cant_vacantes_abiertas",
			width: 50,
		}, {
			title: "Vacantes cerradas",
			field: "cant_vacantes_cerradas",
			width: 50,
		}, {
			title: "Responsable",
			field: "_responsable_ref.nombre",
			render: (row) => <TooltipCustom tooltip={row._responsable_ref.contacto.email}>{NombreAbreviado(row._responsable_ref)}</TooltipCustom>,
			width: 100,
		}, {
			title: "Gerencia",
			field: "_gerencia_ref.sigla",
			width: 50,
		}, {
			title: "Fecha creación",
			field: "fecha_creacion",
			render: (row) => FormatearFecha(row.fecha_creacion),
			width: 100,
		}, {
			title: "Mandante",
			field: "mandante",
			width: 100,
		}
	];

	let actions = [
		{
			icon: "login",
			tooltip: "Entrar",
			onClick: (event, row) => history.push(DETALLE_LICITACION.replace(":licitacion_id", row._id)),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_DETALLE),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_DETALLE),
		}, {
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_EDITAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_EDITAR),
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_ELIMINAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_ELIMINAR),
		}, {
			icon: "add",
			tooltip: "Nueva Licitación",
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: null }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_AGREGAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_AGREGAR),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de cerrar el POPUP.
	 */
	const handleClose = () => {
		SetIsOpen({});
	}

	/**
	 * Método encargado de retornar las licitaciones, según gerencia seleccionada.
	 * @returns Colección de licitaciones filtradas.
	 */
	const LicitacionesFilter = () => {
		if (!licitaciones) {
			return [];
		}
		let licitacionesFilter = Array.from(licitaciones);
		if (TabGerencia === 0) {
			//Está seleccionada la pestaña con la gerencia del usuario.
			return licitacionesFilter.filter(p => p._gerencia_ref.sigla === gerencia && p.estado === Object.values(LICITACION_PROYECTO_ESTADOS)[TabEstado]);
		} else {
			//Está seleccionada la pestaña "todas las gerencias".
			return licitacionesFilter.filter(p => p.estado === Object.values(LICITACION_PROYECTO_ESTADOS)[TabEstado]);
		}
	}

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{/* PESTAÑA CON GERENCIAS */}
					<TabSelector
						tab_list={[gerencia, "Todas las búsquedas"]}
						tab_index={TabGerencia}
						handle_change={(event, index) => SetTabGerencia(index)}
					/>
				</Grid>
				<Grid item xs={12}>
					{/* PESTAÑA CON ESTADOS */}
					<TabSelector
						tab_list={Object.values(LICITACION_PROYECTO_ESTADOS).map(t => t.replace(/_/g, " "))}
						tab_index={TabEstado}
						handle_change={(event, index) => SetTabEstado(index)}
					/>
				</Grid>
				<Grid item xs={12}>
					{/* LISTADO DE LICITACIONES */}
					<MaterialTable
						title="Licitaciones"
						is_loading={is_loading}
						data={LicitacionesFilter()}
						columns={columns}
						actions={actions}
					/>
				</Grid>
			</Grid>

			{/* DIALOG AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAddLicitacion
					is_open={Boolean(IsOpen)}
					handle_close={handleClose}
					mutate_licitaciones={mutate_licitaciones}
				/>
			)}

			{/* DIALOG ACTUALIZACION */}
			{IsOpen.tipo === "update" && (
				<DialogUpdateLicitacion
					is_open={Boolean(IsOpen)}
					data={IsOpen.data}
					handle_close={handleClose}
					mutate_licitaciones={mutate_licitaciones}
				/>
			)}

			{/* DIALOG ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDeleteLicitacion
					is_open={Boolean(IsOpen)}
					data={IsOpen.data}
					handle_close={handleClose}
					mutate_licitaciones={mutate_licitaciones}
				/>
			)}
		</Fragment>
	);
}