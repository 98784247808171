import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Ruta en la API para cartas de oferta templates.
 */
const RUTA_TEMPLATE = "cartas-oferta-template";

/**
 * Ruta en la API para cartas de oferta generadas.
 */
const RUTA_GENERADAS = "cartas-oferta-generadas";

/**
 * Requests de cartas de oferta templates.
 */
export const TemplateRequest = {
	/**
	 * Método encargado de agregar una nueva cartas de oferta template.
	 * @param {*} cartaOfertaTemplate Datos de la carta de oferta template.
	 * @returns Response.
	 */
	Agregar: async function (cartaOfertaTemplate) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_TEMPLATE}`;
			let response = await Axios.post(url, cartaOfertaTemplate);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una cartas de oferta template.
	 * @param {*} cartaOfertaTemplate Datos actualizados de la carta de oferta template.
	 * @returns Response.
	 */
	Actualizar: async function (cartaOfertaTemplate) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_TEMPLATE}/${cartaOfertaTemplate._id}`;
			let response = await Axios.put(url, cartaOfertaTemplate);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una cartas de oferta template.
	 * @param {*} cartaOfertaTemplateID ID de la carta de oferta template.
	 * @returns Response.
	 */
	Eliminar: async function (cartaOfertaTemplateID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_TEMPLATE}/${cartaOfertaTemplateID}`;
			let response = await Axios.delete(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la colección de cartas de oferta template.
	 * @returns Colección de cartas de oferta template.
	 */
	Obtener: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_TEMPLATE}`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una carta de oferta template, por su ID.
	 * @param {*} cartaOfertaTemplateID ID de la carta de oferta template.
	 * @returns Response.
	 */
	ObtenerPorID: async function (cartaOfertaTemplateID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_TEMPLATE}/${cartaOfertaTemplateID}`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Requests de cartas de oferta generadas.
 */
export const GeneradasRequest = {
	/**
	 * Método encargado de agregar una nueva cartas de oferta generada.
	 * @param {*} cartaOfertaGenerada Datos de la carta de oferta generada.
	 * @returns Response.
	 */
	Agregar: async function (cartaOfertaGenerada) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}`;
			let response = await Axios.post(url, cartaOfertaGenerada);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una cartas de oferta generada.
	 * @param {*} cartaOfertaGenerada Datos actualizados de la carta de oferta generada.
	 * @returns Response.
	 */
	Actualizar: async function (cartaOfertaGenerada) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/${cartaOfertaGenerada._id}`;
			let response = await Axios.put(url, cartaOfertaGenerada);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una cartas de oferta generada.
	 * @param {*} cartaOfertaGeneradaID ID de la carta de oferta generada.
	 * @returns Response.
	 */
	Eliminar: async function (cartaOfertaGeneradaID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/${cartaOfertaGeneradaID}`;
			let response = await Axios.delete(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la colección de cartas de oferta generadas.
	 * @returns Colección de cartas de oferta generadas.
	 */
	Obtener: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una carta de oferta generada, por su ID.
	 * @param {*} cartaOfertaGeneradaID ID de la carta de oferta generada.
	 * @returns Response.
	 */
	ObtenerPorID: async function (cartaOfertaGeneradaID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/${cartaOfertaGeneradaID}`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una carta de oferta generada, por ID de asignación.
	 * @param {*} asignacionID ID de la asignación.
	 * @returns Datos de la carta de oferta generada.
	 */
	ObtenerPorAsignacionID: async function (asignacionID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/carta_oferta_url/_usuario_ref.nombre/_usuario_ref.apellido_paterno/_usuario_ref.apellido_materno/_postulante_ref.nombre/_postulante_ref.apellido_paterno/_postulante_ref.apellido_materno/_carta_oferta_template_ref.nombre/_carta_oferta_template_ref.link_template/fecha_creacion?_licitacion_proyecto_cargo_asignacion_ref_eq=${asignacionID}`;
			let response = await Axios.get(url);
			if (!response.data || !response.data.data || Array.from(response.data.data).length === 0) {
				return null;
			}
			return Array.from(response.data.data)[0];
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las cartas de oferta generadas, por ID de reclutador .
	 * @param {*} reclutadorID ID del reclutador.
	 * @returns Datos de cartas de oferta generadas por un reclutador.
	 */
	ObtenerPorReclutadorID: async function (reclutadorID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/carta_oferta_url/_postulante_ref.nombre/_postulante_ref.apellido_paterno/_postulante_ref.apellido_materno/_licitacion_proyecto_cargo_asignacion_ref.licitacion_proyecto_codigo/_licitacion_proyecto_cargo_asignacion_ref.cargo_codigo/_carta_oferta_template_ref.nombre/_carta_oferta_template_ref.link_template/fecha_creacion?_usuario_ref_eq=${reclutadorID}`;
			let response = await Axios.get(url);
			return Array.from(response.data.data);
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener el KPI de las cartas de oferta.
	 * @returns Datos de KPI de las cartas de oferta.
	 */
	ObtenerKPI: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA_GENERADAS}/kpi`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
}