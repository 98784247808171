import React, { Fragment, useContext } from "react";
import { Backdrop, Box, Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import Formulario from "./formulario";
import LinearProgressComponent from "../../../components/linear_progress";
import PostulanteDocumentos from "../../../components/postulante_documentos";
import { PostulanteEditarContext } from "./index";

export default function Page(props) {
	const {
		show_backdrop,
	} = props;

	const {
		formik,
		porcentaje,
		porcentajes,
	} = useContext(PostulanteEditarContext);

	const classes = useStyles();

	return (
		<Fragment>
			{/* COMPONENTE DE CARGA */}
			<Backdrop open={show_backdrop} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography align="center" variant="h5">Cargando datos...</Typography>
			</Backdrop>

			{/* FORMULARIO Y DOCUMENTOS */}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<LinearProgressComponent porcentaje={porcentaje} detalle={porcentajes} />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Formulario />
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<PostulanteDocumentos
						curriculum_vitae={formik && formik.values && formik.values.curriculum_vitae}
						adjuntos={formik && formik.values && formik.values.adjuntos}
					/>
				</Grid>
				<Grid item xs={12}>
					<br />
					<Box display="flex" justifyContent="center">
						<Button onClick={formik.handleSubmit} disabled={!formik.isValid || formik.isSubmitting} variant="contained" color="primary">Guardar</Button>
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 100,
		color: "#fff",
	},
}));