import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import DialogTitleComponent from "../../../../components/dialog_title";
import { TemplateRequest } from "../../../../services/request/reclutamiento/cartas_oferta";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../../../App";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_cartas_oferta,
	} = props;

	const MainCTX = useContext(MainContext);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				values["_id"] = data._id;
				await TemplateRequest.Actualizar(values);
				MainCTX.ShowSnackbar("Carta de oferta actualizada exitosamente.");
			} catch (error) {
				MainCTX.ShowSnackbar("Error al intentar actualizar la carta de oferta.", error);
			} finally {
				helper.resetForm();
				mutate_cartas_oferta();
				handle_close();
			}
		}
	});

	useEffect(() => {
		if (data) {
			formik.setValues({
				nombre: data.nombre,
				link_template: data.link_template,
			});
		}
	}, [data]);

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Actualizar carta de oferta</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria de la carta de oferta y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="link_template"
							label="Link template"
							value={formik.values.link_template}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.link_template}
							error={Boolean(formik.errors.link_template)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}