import Axios from "axios";
import { EMAIL_API_URL } from "../../constants/urls";

/**
 * Método encargado de enviar un Email.
 * @param {*} to Destinatario (String) o colección de destinatarios (Array).
 * @param {*} cc Colección de copiados (Array).
 * @param {*} bcc Colección de copiados ocultos (Array).
 * @param {*} subject Asunto.
 * @param {*} text Contenido texto.
 * @returns Response del email enviado.
 */
export async function SendText(to, cc, bcc, subject, text) {
	try {
		let response = await Axios.post(EMAIL_API_URL, { to, cc, bcc, subject, text });
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

/**
 * Método encargado de enviar un Email.
 * @param {*} to Destinatario (String) o colección de destinatarios (Array).
 * @param {*} cc Colección de copiados (Array).
 * @param {*} bcc Colección de copiados ocultos (Array).
 * @param {*} subject Asunto.
 * @param {*} html Contenido HTML.
 * @returns Response del email enviado.
 */
export async function SendHTML(to, cc = [], bcc = [], subject, html) {
	try {
		let response = await Axios.post(EMAIL_API_URL, { to, cc, bcc, subject, html });
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

/**
 * Método encargado de abrir una pestaña para mandar email desde Gmail.
 * @param {*} to Email del destinatario, es requerido.
 * @param {*} cc Email para copiar, es opcional.
 * @param {*} bcc Email para copia oculta, es opcional.
 * @param {*} subject Asunto del email.
 * @param {*} body Contenido del email.
 * @link https://stackoverflow.com/a/8852679 Referencia.
 */
export function SendGmail(to, cc = null, bcc = null, subject, body) {
	let url = `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}`;
	if (cc) {
		url = `${url}&cc=${cc}`;
	}
	if (bcc) {
		url = `${url}&bcc=${bcc}`;
	}
	window.open(url, "_blank");
}