import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import CargoInfo from "./_cargo_info";
import CargoPostulantes from "./_cargo_postulantes";
import TabSelector from "../tab_selector";
import { AdjuntoRequest, CargoRequest, AsignacionRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import { CARPETA_LICITACIONES, HOME } from "../../constants/routes";
import { TabToEstado } from "./utils";
import { MainContext } from "../../App";
import { ASIGNACION_ESTADOS } from "../../constants/contexts";
import { EstadoAsignacion } from "../../services/utilities/formatUtils";

export default function CargoDetalle(props) {
	const [Cargo, SetCargo] = useState(null);
	const [Adjuntos, SetAdjuntos] = useState([]);
	const [TabIndex, SetTabIndex] = useState(0);
	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const { licitacion_id, proyecto_id, cargo_id } = useParams();
	const LicitacionProyectoID = licitacion_id || proyecto_id;
	const AsignadosSWR = useSWR(`asignados_licitacion_${TabIndex}`, (key) => AsignacionRequest.Obtener({ _licitacion_proyecto_ref: LicitacionProyectoID, _cargo_ref: cargo_id }, "/_postulante_ref"));
	const history = useHistory();

	useEffect(() => {
		if (!cargo_id) {
			if (LicitacionProyectoID) {
				history.replace(CARPETA_LICITACIONES);
			} else {
				history.replace(HOME);
			}
		} else {
			let promises = [
				CargoRequest.ObtenerPorID(LicitacionProyectoID, cargo_id),
				AdjuntoRequest.Obtener(LicitacionProyectoID, cargo_id),
			];
			Promise.all(promises)
				.then(responses => {
					let cargo = responses[0];
					SetCargo(cargo);
					let adjuntos = responses[1];
					SetAdjuntos(adjuntos);
				})
				.catch(error => {
					ShowSnackbar("Error al intentar obtener los datos del cargo y adjuntos.", error);
					history.replace(HOME);
				});
		}
	}, [ShowSnackbar, history, LicitacionProyectoID, cargo_id]);

	/**
	 * Método encargado de cambiar las pestañas de estado de las asignaciones.
	 * @param {*} event Evento.
	 * @param {*} tabIndex Nuevo número de pestaña seleccionada.
	 */
	const handleTabChange = (event, tabIndex) => {
		SetTabIndex(tabIndex);
		//Se actualizan los datos de asignados.
		AsignadosSWR.mutate();
	}

	/**
	 * Método encargado de cambiar el estado LIKE/DISLIKE/SIN ESTADO de un asignado.
	 * @param {*} asignacionID ID de la asignación.
	 * @param {*} like_dislike Datos de LIKE/DISLIKE.
	 */
	const handleLikeDislike = async (asignacionID, like_dislike) => {
		try {
			let actualizacion = {
				_id: asignacionID,
				like_dislike: {
					usuario_nombre: usuarioSesion.nombre,
					usuario_email: usuarioSesion.email,
					usuario_gerencia: usuarioSesion.gerencia.sigla,
					estado: false,
				}
			}
			if (like_dislike && like_dislike.estado) {
				//Si el estado es TRUE.
				await AsignacionRequest.Actualizar(LicitacionProyectoID, cargo_id, actualizacion);
				ShowSnackbar("DISLIKE agregado exitosamente");
			} else if (like_dislike && !like_dislike.estado) {
				//Si el estado es FALSE.
				actualizacion.like_dislike = null;
				await AsignacionRequest.Actualizar(LicitacionProyectoID, cargo_id, actualizacion);
				ShowSnackbar("Estado quitado exitosamente");
			} else {
				//Si no hay estado de LIKE/DISLIKE.
				actualizacion.like_dislike.estado = true;
				await AsignacionRequest.Actualizar(LicitacionProyectoID, cargo_id, actualizacion);
				ShowSnackbar("LIKE agregado exitosamente");
			}
			//Se actualizan los datos de los asignados.
			AsignadosSWR.mutate();
		} catch (error) {
			ShowSnackbar("Error la intentar cambiar el estado.", error);
		}
	}

	/**
	 * Método encargado de retornar el listado de estados para las pestanas.
	 * El listado cambia dependiendo si es Licitacion o Proyecto.
	 * @returns Listado de estados.
	 */
	const ListadoEstados = () => {
		const tipo = Cargo._licitacion_proyecto_ref.tipo;
		const estados = Object.values(ASIGNACION_ESTADOS).map(e => EstadoAsignacion(e, tipo));
		return ["Todos los estados", ...estados];
	}

	return Cargo && (
		<Grid container spacing={2}>
			{/* DATOS DEL CARGO */}
			<Grid item xs={12}>
				<CargoInfo
					cargo={Cargo}
					adjuntos={Adjuntos}
				/>
			</Grid>

			{/* PESTAÑAS CON ESTADOS */}
			<Grid item xs={12}>
				<TabSelector
					tab_list={ListadoEstados()}
					tab_index={TabIndex}
					handle_change={handleTabChange}
				/>
			</Grid>

			{/* LISTADO DE ASIGNADOS */}
			<Grid item xs={12}>
				<CargoPostulantes
					titulo={TabIndex === 2 ? "Short-List Reclutador" : undefined}
					cargo={Cargo}
					renta_max={Cargo.renta_liquida_max}
					postulantes={TabIndex === 0 ? AsignadosSWR.data?.filter(a => a._postulante_ref) : AsignadosSWR.data?.filter(a => a._postulante_ref && a.estado === TabToEstado(TabIndex))}
					runs={AsignadosSWR.data?.filter(a => a._postulante_ref).map(a => a._postulante_ref.run)}
					is_loading={AsignadosSWR.isValidating}
					handle_like_dislike={handleLikeDislike}
					mutate_postulantes={AsignadosSWR.mutate}
				/>
			</Grid>

			{/* LISTADO DE ASIGNADOS CON LIKE EN SHORT-LIST */}
			<Grid item xs={12}>
				{TabIndex === 2 && (
					<CargoPostulantes
						titulo={"Preselección Cliente Interno"}
						cargo={Cargo}
						renta_max={Cargo.renta_liquida_max}
						postulantes={AsignadosSWR.data?.filter(a => a._postulante_ref && a.like_dislike && a.like_dislike.estado === true).map((p, index) => ({ ...p, key: index }))}
						runs={AsignadosSWR.data?.filter(a => a._postulante_ref).map(a => a._postulante_ref.run)}
						is_loading={AsignadosSWR.isValidating}
						handle_like_dislike={handleLikeDislike}
						mutate_postulantes={AsignadosSWR.mutate}
					/>
				)}
			</Grid>
		</Grid>
	);
}