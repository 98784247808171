import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Grid, Typography, Paper } from "@material-ui/core";
import StackedColumnChartChart from "../components/stackedColumnChart";
import * as RegistrosKPIRequests from "../../../services/request/reclutamiento/registro_kpi";
import * as CurriculumsRequests from "../../../services/request/reclutamiento/curriculums";
import { CargoRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { GeneradasRequest } from "../../../services/request/reclutamiento/cartas_oferta";
import { AsignacionRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { GERENCIAS_VISIBLES } from "../../../constants/contexts";
import { MainContext } from "../../../App";

export default function Trafico(props) {
	const [SessionsKPI, SetSessionsKPI] = useState([]);
	const [AsignadosKPI, SetAsignadosKPI] = useState([]);
	const [CVsKPI, SetCVsKPI] = useState([]);
	const [CargosKPI, SetCargosKPI] = useState([]);
	const [CartasKPI, SetCartasKPI] = useState([]);
	const [BusquedasKPI, SetBusquedasKPI] = useState([]);
	const Meses = Array.from({ length: 6 }, (_, i) => Moment().add(-i, "month").utc()).reverse();
	const SesionSWR = useSWR("session-kpi", (key) => RegistrosKPIRequests.ObtenerSesionKPI(), { revalidateOnFocus: false });
	const AsignadosSWR = useSWR("asignados-kpi", (key) => AsignacionRequest.ObtenerKPI(), { revalidateOnFocus: false });
	const CVsCargadosSWR = useSWR("cvs-kpi", (key) => CurriculumsRequests.ObtenerKPI(), { revalidateOnFocus: false });
	const CargosSWR = useSWR("cargos-kpi", (key) => CargoRequest.ObtenerKPI(), { revalidateOnFocus: false });
	const CartasSWR = useSWR("cartas-kpi", (key) => GeneradasRequest.ObtenerKPI(), { revalidateOnFocus: false });
	const BusquedasSWR = useSWR("busquedas-kpi", (key) => RegistrosKPIRequests.ObtenerBusquedaKPI(), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	/**
	 * Método encargado de formatear los datos de KPI.
	 * @param {*} registrosKPI Datos de KPI.
	 * @returns Datos formateados.
	 */
	let DataMap = (registrosKPI) => {
		let dataKPI = GERENCIAS_VISIBLES.map(gerencia => ({
			name: gerencia.sigla,
			data: Meses.map(m => Array.from(registrosKPI || []).find(d => d.gerencia === gerencia.id && m.isSame(Moment(d.fecha), "month"))?.total || 0),
		}));
		return dataKPI;
	}

	useEffect(() => {
		let dataSessions = DataMap(SesionSWR.data);
		SetSessionsKPI(dataSessions);

		let dataAsignados = DataMap(AsignadosSWR.data);
		SetAsignadosKPI(dataAsignados);

		let dataCVs = DataMap(CVsCargadosSWR.data);
		SetCVsKPI(dataCVs);

		let dataCargos = DataMap(CargosSWR.data);
		SetCargosKPI(dataCargos);

		let dataCartas = DataMap(CartasSWR.data);
		SetCartasKPI(dataCartas);

		let dataBusquedas = DataMap(BusquedasSWR.data);
		SetBusquedasKPI(dataBusquedas);
	}, [SesionSWR.data, AsignadosSWR.data, CVsCargadosSWR.data, CargosSWR.data, CartasSWR.data, BusquedasSWR.data]);

	useEffect(() => {
		if (SesionSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los registros KPI de sesión.", SesionSWR.error);
		}
		if (AsignadosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener las asignaciones.", AsignadosSWR.error);
		}
		if (CVsCargadosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los CVs.", CVsCargadosSWR.error);
		}
		if (CargosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los vacantes.", CargosSWR.error);
		}
		if (CartasSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener las cartas de oferta.", CartasSWR.error);
		}
		if (BusquedasSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los registros KPI de búsquedas.", BusquedasSWR.error);
		}
	}, [MainCTX, SesionSWR.error, AsignadosSWR.error, CVsCargadosSWR.error, CargosSWR.error, CartasSWR.error, BusquedasSWR.error]);

	return (
		<Fragment>
			<Typography variant="h4">KPI Trafico</Typography>
			<Grid container spacing={2} style={{ padding: 10 }}>
				{/* GRÁFICO DE SESIONES */}
				<Grid item xs={12}>
					{GraficoComponent("Sesiones totales", SessionsKPI, Meses)}
				</Grid>
				{/* GRÁFICO DE ASIGNACIONES */}
				<Grid item xs={12}>
					{GraficoComponent("Cantidad de asignados", AsignadosKPI, Meses)}
				</Grid>
				{/* GRÁFICO DE CVS CARGADOS */}
				<Grid item xs={12}>
					{GraficoComponent("CVs cargados", CVsKPI, Meses)}
				</Grid>
				{/* GRÁFICO DE CARGOS */}
				<Grid item xs={12}>
					{GraficoComponent("Vacantes totales", CargosKPI, Meses)}
				</Grid>
				<Grid item xs={12}>
					{GraficoComponent("Cartas de oferta totales", CartasKPI, Meses)}
				</Grid>
				<Grid item xs={12}>
					{GraficoComponent("Búsquedas totales", BusquedasKPI, Meses)}
				</Grid>
			</Grid>
		</Fragment>
	);
}

/**
 * Método encargado de generar un componente gráfico.
 * @param {*} titulo Título del indicador.
 * @param {*} datos Datos del gráfico.
 * @param {*} meses Meses del gráfico.
 * @returns Component.
 */
function GraficoComponent(titulo, datos, meses) {
	return (
		<Paper elevation={10} style={{ borderRadius: 25, paddingBottom: 20 }}>
			<Typography variant="h6" align="center">{titulo}</Typography>
			<StackedColumnChartChart
				data={datos}
				meses={meses}
			/>
		</Paper>
	);
}