import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const LicitacionesSWR = useSWR("licitaciones", (key) => LicitacionProyectoRequets.ObtenerLicitaciones(), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (LicitacionesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de las licitaciones.", LicitacionesSWR.error);
		}
	}, [MainCTX, LicitacionesSWR.error]);

	return (
		<Page
			licitaciones={LicitacionesSWR.data}
			is_loading={LicitacionesSWR.isValidating}
			gerencia={MainCTX.usuarioSesion.gerencia.sigla}
			mutate_licitaciones={LicitacionesSWR.mutate}
		/>
	);
}