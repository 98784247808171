import React, { useState } from "react";
import { Box, FormControlLabel, Switch, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

const filter = createFilterOptions();

/**
 * Componente para buscar datos y filtrar por palabras claves.
 * @param {*} keywords Colección de palabras clave.
 * @param {*} checked FLAG para buscar con todas las palabras claves.
 * @param {*} handle_keywords Método encargado de incluir nuevas palabras claves.
 * @param {*} handle_checked Método encargado de cambiar el FLAG.
 * @returns Componente para búsqueda avanzada.
 */
export default function FiltroKeywordsComponent(props) {
	const {
		keywords,
		checked,
		handle_keywords,
		handle_checked,
	} = props;

	const [options, setOptions] = useState([]);

	/**
	 * Método encargado de verificar si el botón de buscar está habilitado o no.
	 * @returns Valor de habilitado o deshabilitado.
	 */
	const isDisabled = () => {
		if (!keywords || Array.from(keywords).length === 0) {
			return true;
		}
		return false;
	}

	return (
		<div style={{ width: '100%' }}>
			<Box display="flex" flexDirection="row" p={1} style={{ marginRight: 50 }}>
				{/* COLECCIÓN DE PALABRAS CLAVES */}
				<Box flexGrow={1}>
					<Autocomplete
						value={keywords}
						onChange={(event, value) => {
							let newValues = [];

							//Por cada valor ingresado como palabra clave.
							value.forEach((valor, index) => {
								if (typeof valor === "string") {
									//Si es de tipo texto.
									newValues.push({ value: valor, is_new: false });
								}
								if (typeof valor === "object") {
									//Si es de tipo objeto.
									newValues.push({ ...valor, is_new: false });
								}
							});

							handle_keywords(newValues);
							//Se combierten en texto, para buscar repetidos.
							let values = [...options, ...newValues].map(v => JSON.stringify(v));
							//Se filtran los valores repetidos.
							setOptions(Array.from(new Set(values)).map(v => JSON.parse(v)));
						}}
						filterOptions={(options, params) => {
							options = options.filter(o => !keywords.find(v => v.value === o.value));
							const filtered = filter(options, params);
							// Suggest the creation of a new value
							if (params.inputValue !== '') {
								filtered.push({
									value: params.inputValue,
									is_new: true,
								});
							}
							return filtered;
						}}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						options={options}
						getOptionLabel={(option) => option.value}
						renderOption={(option) => option.is_new ? `Incluir "${option.value}"` : option.value}
						ChipProps={{ color: "primary" }}
						freeSolo
						multiple
						fullWidth
						style={{ minWidth: 640 }}
						size="small"
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Ingrese palabras clave (para mostrar el listado completo presione buscar)"
								variant="outlined"
								fullWidth
								{...params}
							/>
						)}
					/>
				</Box>

				{/* FLAG */}
				<Box display="flex" alignItems="center">
					<Tooltip title="¿Deberá cumplir todos los criterios?">
						<FormControlLabel
							label="¿Todos?"
							labelPlacement="start"
							control={<Switch value={checked} onChange={(event, checked) => handle_checked(checked)} disabled={isDisabled()} color="primary" />}
						/>
					</Tooltip>
				</Box>
			</Box>
		</div>
	);
}