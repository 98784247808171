import { number, object, string } from "yup";

export const FormikInitialValues = {
	nombre: "",
	exp_general: 0,
	exp_especifica: 0,
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre del título debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre del título debe tener a lo más ${max} caracteres.`)
		.required("El nombre del título es requerido."),
	exp_general: number()
		.min(0, ({ min }) => `La experiencia general debe ser mayor o igual a ${min}.`)
		.max(99, ({ max }) => `La experiencia general debe ser menor a ${max + 1}.`)
		.required("La  de vacantes es requerida."),
	exp_especifica: number()
		.min(0, ({ min }) => `La experiencia específica debe ser mayor o igual a ${min}.`)
		.max(99, ({ max }) => `La experiencia específica debe ser menor a ${max + 1}.`)
		.required("La experiencia específica es requerida."),
});