import React from "react";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Fab, Grid, Tooltip, Typography } from "@material-ui/core";
import { ArrowBack, ExpandMore } from "@material-ui/icons";
import TooltipCustom from "../../components/tooltip_custom";
import { FormatearFecha, NombreAbreviado, NombreCompleto } from "../../services/utilities/formatUtils";
import { PreviousRoute } from "../../services/utilities/common";

/**
 * Método encargado de retornar un componente con los detalles de la licitación o proyecto
 * @param {*} licitacion_proyecto Datos de la licitación o proyecto.
 * @param {*} tipo Tipo licitación o proyecto.
 * @returns Componente.
 */
export default function LicitacionProyectoDetalle(props) {
	const {
		licitacion_proyecto,
		tipo,
	} = props;

	const history = useHistory();

	/**
	 * Método encargado de volver a la vista anterior.
	 */
	const handleVolver = () => {
		//Se obtiene la ruta anterior.
		let previousRoute = PreviousRoute(history);
		history.push(previousRoute);
	}

	return (
		<Accordion style={{ borderRadius: 25, backgroundColor: "#731f1f" }}>
			<AccordionSummary expandIcon={<ExpandMore style={{ color: "white" }} />}>
				<div style={{ width: "100%" }}>
					<Box display="flex">
						{/* BOTÓN VOLVER */}
						<Tooltip title={`Volver a ${tipo}`}>
							<Fab onClick={handleVolver} color="default" size="small">
								<ArrowBack />
							</Fab>
						</Tooltip>
						{/* DATOS DE LA LICITACIÓN/PROYECTO */}
						<Box display={"flex"} p={1} flexGrow={1}>
							{Titulo("Código:")}
							<Typography style={{ color: "white" }}>
								{`[${licitacion_proyecto.codigo}] ${licitacion_proyecto.nombre}`}
							</Typography>
						</Box>
						<Box display={"flex"} p={1} pr={2}>
							{Titulo("Gerencia:")}
							<Typography style={{ color: "white" }}>
								{`[${licitacion_proyecto._gerencia_ref.sigla}] ${licitacion_proyecto._gerencia_ref.nombre}`}
							</Typography>
						</Box>
						<Box display={"flex"} p={1} pl={2}>
							{Titulo("Responsable:")}
							<TooltipCustom tooltip={licitacion_proyecto._responsable_ref.contacto.email} custom_color={true}>
								{NombreCompleto(licitacion_proyecto._responsable_ref)}
							</TooltipCustom>
						</Box>
					</Box>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<Grid item xs={3}>
						<Box display={"flex"}>
							{Titulo("Reclutador:")}
							<TooltipCustom tooltip={licitacion_proyecto._reclutador_ref.contacto.email} custom_color={true}>
								{NombreAbreviado(licitacion_proyecto._reclutador_ref)}
							</TooltipCustom>
						</Box>
					</Grid>
					{/* <Grid item xs={2}>
						{TituloContenido("Estado", licitacion_proyecto.estado)}
					</Grid> */}
					<Grid item xs={3}>
						{TituloContenido("Fecha creación", FormatearFecha(licitacion_proyecto.fecha_creacion))}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Mandante", licitacion_proyecto.mandante)}
					</Grid>
					<Grid item xs={12}>
						{TituloContenido("Descripción", licitacion_proyecto.descripcion)}
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * Método encargado de retornar un texto con título de sección.
 * @param {*} titulo Texto principal.
 * @returns Component.
 */
function Titulo(titulo) {
	return (
		<Typography style={{ color: "white", fontWeight: "bold", marginRight: 5 }}>
			{titulo}
		</Typography>
	);
}

/**
 * Método encargado de retornar un texto con título de sección y contenido.
 * @param {*} titulo Texto principal.
 * @param {*} contenido Texto adicional.
 * @returns Component.
 */
function TituloContenido(titulo, contenido) {
	return (
		<Box display={"flex"}>
			{Titulo(`${titulo}:`)}
			<Typography style={{ color: "white" }}>
				{contenido}
			</Typography>
		</Box>
	);
}