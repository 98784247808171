import React, { createContext, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Page from "./page";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { Agregar } from "../../../services/request/reclutamiento/curriculums";
import { uploadFileToCloudStorage } from "../../../services/utilities/files";
import { HOME } from "../../../constants/routes";
import { MainContext } from "../../../App";

export const DejarCurriculumContext = createContext({
	formik: null,
});

export default function Index(props) {
	const [Tags, SetTags] = useState([]);
	const [Options, SetOptions] = useState([]);
	const { usuarioSesion } = useContext(MainContext);
	const notistack = useSnackbar();
	const history = useHistory();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Subiendo los documentos...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Se asigna el reclutador que lo registra.
				formik.values._reclutador_ref = usuarioSesion.ref;
				formik.values._gerencia_ref = usuarioSesion.gerencia._id;
				//Colección de los textos de los Tags.
				let tags = Tags.map(t => t.value);
				//Se asignan los tags al CV.
				formik.values.tags = tags;

				let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");

				//Si se incluye un Curriculum Vitae.
				if (values.curriculum_vitae) {
					let uploadedCurriculum = await uploadFileToCloudStorage(values.curriculum_vitae, "curriculums-test", `curriculum_vitae_${ahora}`);
					values.curriculum_vitae = uploadedCurriculum;
				}

				//Si se incluye certificado de enseñanza media.
				if (values.ensenanza_media) {
					let uploadedEnsenanzaMedia = await uploadFileToCloudStorage(values.ensenanza_media, "curriculums-test", `ensenanza_media_${ahora}`);
					values.ensenanza_media = uploadedEnsenanzaMedia;
				}

				//Si se incluye hoja de vida del conductor.
				if (values.hoja_vida_conductor) {
					let uploadedHojaVidaConductor = await uploadFileToCloudStorage(values.hoja_vida_conductor, "curriculums-test", `hoja_vida_conductor_${ahora}`);
					values.hoja_vida_conductor = uploadedHojaVidaConductor;
				}

				//Si se incluyen títulos u otros estudios.
				if (values.titulos && values.titulos.length > 0) {
					let uploads = values.titulos.map((titulo, index) => uploadFileToCloudStorage(titulo, "curriculums-test", `titulo_${index + 1}_${ahora}`));
					let titulos = await Promise.all(uploads);
					values.titulos = titulos;
				}

				//Se guarda el CV en la base de datos.
				await Agregar(values);

				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Documentos subidos exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Error al intentar subir los documentos.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				helper.resetForm();
				history.push(HOME);
			}
		},
	});

	/**
	 * Método encargado de asignar u obtener el State de Tags.
	 * @param {*} tags Colección de tags asignados.
	 */
	const tagState = (tags = undefined) => {
		if (tags) {
			SetTags(tags);
		} else {
			return Tags;
		}
	}

	/**
	 * Método encargado de asignar u obtener el State de Options.
	 * @param {*} tags Colección de opciones ingresadas.
	 */
	const optionState = (options = undefined) => {
		if (options) {
			SetOptions(options);
		} else {
			return Options;
		}
	}

	return (
		<DejarCurriculumContext.Provider
			value={{
				formik: formik,
			}}
		>
			<Page
				handle_submit={formik.submitForm}
				is_submitting={formik.isSubmitting}
				tag_state={tagState}
				option_state={optionState}
			/>
		</DejarCurriculumContext.Provider>
	);
}