import React, { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "../materialTable";
import TooltipIfLongerThan from "../tooltip_if_longer_than";
import TituloDialog from "./_titulo_form";

/**
 * MaterialTable con el listado de títulos y Dialog.
 * @param {*} formik Datos del formulario.
 * @returns Component.
 */
export default function Titulos(props) {
	const { formik } = props;
	const [IsOpen, SetIsOpen] = useState(false);
	const notistack = useSnackbar();

	let columns = [
		{
			title: "Título",
			field: "nombre",
			render: (row) => <TooltipIfLongerThan texto={row.nombre} largo={20} />,
		}, {
			title: "EG",
			field: "exp_general",
			width: 20,
		}, {
			title: "EE",
			field: "exp_especifica",
			width: 20,
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarTitulo(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} titulo Datos del título.
	 */
	const handleEliminarTitulo = async (titulo) => {
		try {
			let titulos = Array.from(formik.values.titulos);
			//Se elimina el título.
			titulos.splice(titulo.tableData.id, 1);

			//Se actualizan los datos del cargo.
			formik.setFieldValue("titulos", titulos);

			notistack.enqueueSnackbar("Título eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el título.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE TÍTULOS */}
			{!IsOpen && (
				<MaterialTable
					title="Títulos"
					is_loading={false}
					data={formik.values.titulos}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			)}

			{/* DIALOG TÍTULO */}
			<TituloDialog
				is_open={IsOpen}
				formik={formik}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}