import React, { Fragment, useState } from "react";
import { Badge, Box, List, ListItem, ListItemText, Tooltip, Typography, Paper } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank, FindInPage, FolderShared, InsertComment, ThumbUp, ThumbUpOutlined, Mail } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Rating, Skeleton } from "@material-ui/lab";
import MaterialTable from "../../../components/materialTable";
import TooltipIfLongerThan from "../../../components/tooltip_if_longer_than";
import Filtro from "../../../components/filtro_keywords";
import DialogEvaluaciones from "./evaluaciones/dialogEvaluaciones";
import DialogEvaluacion from "./evaluaciones/dialogEvaluacion";
import DialogAsignaciones from "./asignaciones/dialogAsignaciones";
import DialogAsignacionMultiple from "./asignacion_multiple/dialogAsignacionMultiple";
import BarChart from "./components/barChart";
import { Constantes } from "./utils";
import { BanderaPorNombre } from "../../../services/request/common";
import { FormatearFecha, IconRelacionCyD, TooltipRelacionCyD, ToTitleCase } from "../../../services/utilities/formatUtils";
import DialogInfo from "./components/DialogInfo";

export default function Page(props) {
	const {
		open_state,
		postulantes_state,
		palabras_clave_state,
		checked_state,
		is_loading_state,
		origenes,
		cant_cvs,
		cant_incompletos,
		handle_close_dialog,
		handle_add_evaluacion,
		handle_update_evaluacion,
		handle_add_asignacion,
		handle_actualizar_local,
		handle_buscar,
		handle_favorito,
		handle_toggle,
		handle_ver_adjunto,
		handle_ver_detalle,
		handle_email,
		is_disabled_asignar,
	} = props;

	const [Filters, SetFilters] = useState({});
	const [Hiddens, SetHiddens] = useState({});

	/**
	 * Método encargado de actualizar los filtros de cada columna.
	 * @param {*} filtros Colección de columnas y sus filtros.
	 */
	const handleChangeFilters = (filtros) => {
		let nuevosFiltros = Array.from(filtros).map(filtro => [filtro.column.field, filtro.value]);
		let filters = Object.fromEntries(nuevosFiltros);
		SetFilters(filters);
	}

	/**
	 * Método encargado de actualizar el estado oculto de cada columna.
	 * @param {*} column Datos de la columna modificada.
	 * @param {*} hidden Estado oculto o mostrado.
	 */
	const handleChangeHiddenColumn = (column, hidden) => {
		let nuevosOcultos = { ...Hiddens }
		nuevosOcultos[column.field] = hidden;
		SetHiddens(nuevosOcultos);
	}

	/**
	 * Método encargado de mostrar el popup para la asignación múltiple.
	 */
	const handleAsignacionMultiple = () => {
		open_state({
			tipo: Constantes.ASIGNACION_MULTIPLE,
			postulantes: postulantes_state().filter(p => p.checked),
		});
	}

	let columns = [
    {
      title: "RUN",
      field: "run",
      defaultFilter: Filters["run"],
      hidden: Boolean(Hiddens["run"]),
      maxWidth: 150,
    },
    {
      title: "Nombre",
      field: "nombre_completo",
      defaultFilter: Filters["nombre_completo"],
      hidden: Boolean(Hiddens["nombre_completo"]),
    },
    {
      title: "Edad",
      field: "edad",
      defaultFilter: Filters["edad"],
      hidden: true, //Boolean(Hiddens["edad"]),  // Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279
      grouping: false,
      render: (row) => (
        <Tooltip title={row.fecha_nacimiento_formato}>
          <label>{row.edad}</label>
        </Tooltip>
      ),
      maxWidth: 80,
    },
    {
      title: "País",
      field: "nacionalidad",
      defaultFilter: Filters["nacionalidad"],
      hidden: true, //Boolean(Hiddens["nacionalidad"]),  // Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279
      render: (row) => (
        <img
          src={BanderaPorNombre(row.nacionalidad)}
          width={30}
          alt={row.nacionalidad}
        />
      ),
      maxWidth: 80,
    },
    {
      title: "Región",
      field: "ubicacion.region",
      defaultFilter: Filters["ubicacion.region"],
      hidden: Boolean(Hiddens["ubicacion.region"]),
      render: (row) =>
        row.ubicacion ? (
          <TooltipIfLongerThan texto={row.ubicacion.region} largo={20} />
        ) : null,
    },
    {
      title: "Comuna",
      field: "ubicacion.comuna",
      defaultFilter: Filters["ubicacion.comuna"],
      hidden: Boolean(Hiddens["ubicacion.comuna"]),
      render: (row) =>
        row.ubicacion ? ToTitleCase(row.ubicacion.comuna) : null,
    },
    {
      title: "Exp.",
      field: "anos_experiencia",
      type: "numeric",
      defaultFilter: Filters["anos_experiencia"],
      hidden: true, //Boolean(Hiddens["anos_experiencia"]), // Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279
      maxWidth: 80,
    },
    {
      title: "Último título",
      field: "ultimo_titulo",
      defaultFilter: Filters["ultimo_titulo"],
      hidden: Boolean(Hiddens["ultimo_titulo"]),
      minWidth: 250,
    },
    {
      title: "Fecha nacimiento",
      field: "fecha_nacimiento_formato",
      defaultFilter: Filters["fecha_nacimiento_formato"],
      hidden: CheckBoolean(Hiddens["fecha_nacimiento_formato"]),
    },
    {
      title: "Genero",
      field: "genero",
      defaultFilter: Filters["genero"],
      hidden: CheckBoolean(Hiddens["genero"]),
      render: (row) => ToTitleCase(row.genero),
    },
    {
      title: "Teléfono",
      field: "contacto.telefono",
      defaultFilter: Filters["contacto.telefono"],
      hidden: CheckBoolean(Hiddens["contacto.telefono"]),
    },
    {
      title: "Email",
      field: "contacto.email",
      defaultFilter: Filters["contacto.email"],
      hidden: CheckBoolean(Hiddens["contacto.email"]),
    },
    {
      title: "Comentarios",
      field: "comentario",
      defaultFilter: Filters["comentario"],
      hidden: true,
      searchable: true,
      removable: false,
      hiddenByColumnsButton: true,
    },
    {
      title: "estudios_arreglo",
      field: "titulos",
      defaultFilter: Filters["titulos"],
      hidden: true,
      searchable: true,
      removable: false,
      hiddenByColumnsButton: true,
    },
    {
      title: "Promedio evaluaciones",
      field: "evaluacion_promedio",
      defaultFilter: Filters["evaluacion_promedio"],
      hidden: CheckBoolean(Hiddens["evaluacion_promedio"]),
      render: (row) => EvaluacionComponent(row),
    },
    {
      title: "% Completitud",
      field: "porcentaje",
      type: "numeric",
      defaultFilter: Filters["porcentaje"],
      hidden: CheckBoolean(Hiddens["porcentaje"]),
    },
    {
      title: "Fecha CV en GdR",
      field: "fecha_cv",
      defaultFilter: Filters["fecha_cv"],
      hidden: false, //CheckBoolean(Hiddens["fecha_cv"]), // Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279
    },
    {
      title: "Tags",
      field: "detalle.tags",
      defaultFilter: Filters["detalle.tags"],
      hidden: CheckBoolean(Hiddens["detalle.tags"]),
      render: (row) => TagsComponent(row),
    },
  ];

	let actions = [
		//Boton 1 para ver el documento CV de un postulante.
		(row) => ({
			icon: () => <Badge badgeContent={Number(row.cant_visualizaciones || 0)} color="primary"><FindInPage style={{ color: row.semaforo }} /></Badge>,
			tooltip: "Ver Adjunto",
			onClick: (event, row) => handle_ver_adjunto(row),
			disabled: row.disabledVerAdjunto,
		}),
		//Boton 2 para ver el detalle de un postulante
		(row) => ({
			icon: () => <Badge badgeContent={Number(row.cant_asignaciones || 0)} color="primary"><FolderShared /></Badge>,
			tooltip: "Detalles",
			onClick: (event, row) => handle_ver_detalle(row),
			disabled: row.disabledDetalles,
		}),
		//Boton 3 para ver los comentarios de un postulante.
		(row) => ({
			icon: () => <Badge badgeContent={Number(row.cant_evaluaciones || 0) + Number(row.cant_interacciones || 0)} color="primary"><InsertComment /></Badge>,
			tooltip: "Comentario",
			onClick: (event, row) => open_state({ tipo: Constantes.EVALUACIONES, postulante: row }),
			disabled: row.disabledComentario,
		}),
		//Boton 4 para ver el estado de un postulante (Contratado por CyD, Bloqueado o no contratado).
		(row) => ({
			icon: () => IconRelacionCyD(row),
			tooltip: TooltipRelacionCyD(row),
			hidden: row.hiddenIconRelacion,
		}),
		//Boton 5 para indicar estado de favorito del postulante.
		(row) => ({
			icon: () => row.is_favorito ? <ThumbUp /> : <ThumbUpOutlined />,
			tooltip: row.is_favorito ? "Quitar favorito" : "Agregar favorito",
			onClick: (event, row) => handle_favorito(row._id),
		}),
		//Boton 6 para mandar correo (y ver estado de notificacion) solicitando CV.
		(row) => ({
			icon: () => <Mail style={{ color: row.is_notificado ? "green" : "" }} />,
			tooltip: row.is_notificado ? `Notificado el ${FormatearFecha(row.fecha_notificacion)}` : "Solicitar CV",
			onClick: (event, row) => handle_email(row),
			disabled: row.disabledMail,
			hidden: row.hiddenMail,
		}),
		//Boton 7 para seleccionar multiples postulantes.
		(row) => ({
			icon: () => row.checked ? <CheckBox /> : <CheckBoxOutlineBlank />,
			tooltip: row.checked ? "Quitar" : "Agregar",
			onClick: (event, row) => handle_toggle(row._id, !row.checked)
		}),
		//Accion 1 para mostrar significado de cada icono de las acciones.
		{
			icon: "help",
			tooltip: "Informacion",
			onClick: () => open_state({ tipo: Constantes.POPUP_INFO }),
			isFreeAction: true,
		},
		//Accion 2 para asignar multiples postulantes a la vez.
		{
			icon: () => ButtonAsignar(is_disabled_asignar),
			tooltip: "Asignación Múltiple",
			onClick: handleAsignacionMultiple,
			disabled: is_disabled_asignar,
			isFreeAction: true,
		},
		//Accion 3 para buscar postulantes.
		{
			icon: () => ButtonBuscar(),
			tooltip: "Buscar (CTRL + Enter)",
			onClick: handle_buscar,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de incluir un estilo custom a la fila, sombreando la fila si ya fue visitada.
	 * @param {*} row Datos de la fila.
	 * @returns Estilo que será aplicado.
	 */
	const rowStyle = (row) => ({
		backgroundColor: row.visitado ? "#0000000a" : "#FFF",
	})

	return (
		<Fragment>
			{/* CARGA DE GRÁFICO ORIGENES */}
			{(!origenes.data || !cant_cvs.data || origenes.isValidating || cant_cvs.isValidating) && (
				<Skeleton variant="rect" height={60} style={{ marginBottom: 10, borderRadius: 25 }} />
			)}

			{/* GRÁFICO ORIGENES */}
			{origenes.data && !origenes.isValidating && !cant_cvs.isValidating && (
				<BarChart
					serie_name="Fuentes"
					data={Object.entries(origenes.data)}
					cant_cvs={cant_cvs.data}
					cant_incompletos={cant_incompletos.data}
				/>
			)}

			{/* TABLA DE POSTULANTES */}
			<MaterialTable
				title={(
					<Box display="flex" alignItems="center">
						<Typography>Candidatos</Typography>
						<Filtro
							keywords={palabras_clave_state()}
							checked={checked_state()}
							handle_search={handle_buscar}
							handle_keywords={palabras_clave_state}
							handle_checked={checked_state}
						/>
					</Box>
				)}
				is_loading={is_loading_state()}
				data={postulantes_state()}
				columns={columns}
				actions={actions}
				searching={false}
				handle_filter_change={handleChangeFilters}
				handle_hidden_column_change={handleChangeHiddenColumn}
				row_style={rowStyle}
			/>

			{/* DIALOGO DE EVALUACIONES */}
			{open_state() && open_state().tipo === Constantes.EVALUACIONES && (
				<DialogEvaluaciones
					postulante={open_state().postulante}
					handle_add={handle_add_evaluacion}
					handle_update={handle_update_evaluacion}
					handle_close={handle_close_dialog}
				/>
			)}

			{/* DIALOGO DE EVALUACION */}
			{open_state() && (open_state().tipo === Constantes.EVALUACION_ADD || open_state().tipo === Constantes.EVALUACION_UPDATE) && (
				<DialogEvaluacion
					postulante={open_state().postulante}
					evaluacion={open_state().evaluacion}
					handle_close={handle_close_dialog}
					actualizar_postulante_local={handle_actualizar_local}
				/>
			)}

			{/* DIALOGO DE ASIGNACIONES */}
			{open_state() && open_state().tipo === Constantes.ASIGNACIONES && (
				<DialogAsignaciones
					postulante={open_state().postulante}
					handle_add={handle_add_asignacion}
					handle_close={handle_close_dialog}
				/>
			)}

			{/* DIALOGO DE ASIGNACION MULTIPLE */}
			{open_state() && open_state().tipo === Constantes.ASIGNACION_MULTIPLE && (
				<DialogAsignacionMultiple
					postulantes={open_state().postulantes}
					// handle_add={handle_add_asignacion}
					handle_close={handle_close_dialog}
				/>
			)}

			{open_state() && open_state().tipo === Constantes.POPUP_INFO && (
				<DialogInfo
					handle_close={handle_close_dialog}
				/>
			)}
		</Fragment>
	);
}

/**
 * Método encargado de retornar el botón para buscar postulantes.
 * @returns Component.
 */
function ButtonBuscar() {
	return (
		<Paper style={{ backgroundColor: "#731f1f", borderRadius: 25, paddingLeft: 10, paddingRight: 10 }}>
			<Typography style={{ color: "white" }}>Buscar</Typography>
		</Paper>
	);
}

/**
 * Método encargado de retornar el botón para asignar postulantes.
 * @param {*} isMostrable FLAG para mostrar u ocultar el botón.
 * @returns Component.
 */
function ButtonAsignar(isMostrable) {
	if (!isMostrable) {
		return (
			<Paper style={{ backgroundColor: "#263238", borderRadius: 25, paddingLeft: 10, paddingRight: 10 }}>
				<Typography style={{ color: "white" }}>Asignar</Typography>
			</Paper>
		);
	}
}

/**
 * Método encargado de retornar el componente para mostrar el promedio de evaluaciones.
 * @param {*} postulante Datos del postulante.
 * @returns Componente.
 */
function EvaluacionComponent(postulante) {
	return (
		<Rating name="valor" value={postulante.evaluacion_promedio || 0} precision={0.5} size="medium" readOnly />
	);
}

/**
 * Método encargado de retornar el componente para mostrar los Tags del postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Componente.
 */
function TagsComponent(postulante) {
	if (postulante.detalle && postulante.detalle.tags && postulante.detalle.tags.length > 0) {
		return (
			<HtmlTooltip
				title={
					<List dense component="nav">
						{postulante.detalle.tags.map((tag, index) => (
							<ListItem dense key={`list_item_${index}`}>
								<ListItemText primary={tag} />
							</ListItem>
						))}
					</List>
				}
			>
				<Typography>{`${postulante.detalle.tags[0]} ${postulante.detalle.tags.length > 1 ? `(+${postulante.detalle.tags.length - 1})` : ""}`}</Typography>
			</HtmlTooltip>
		);
	} else {
		return "Sin Tags";
	}
}

/**
 * Método encargado de verificar si una columna está oculta o no.
 * @param {*} valor Valor a verificar.
 * @returns FLAG para mostrar u ocultar columna.
 */
function CheckBoolean(valor) {
	return valor === undefined ? true : valor;
}

/**
 * Componente Tooltip Custom para TAGs.
 */
const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}))(Tooltip);