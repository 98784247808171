import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerEstadosCiviles } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: estadosCiviles,
		isValidating: loadingEstadosCiviles,
		mutate: mutateEstadosCiviles,
		error: errorEstadosCiviles,
	} = useSWR("estados_civiles_seleccionables", ObtenerEstadosCiviles, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorEstadosCiviles) {
			ShowSnackbar("Error al intentar cargar los datos de los estados civiles.", errorEstadosCiviles);
		}
	}, [ShowSnackbar, errorEstadosCiviles]);

	return (
		<Page
			estados_civiles={estadosCiviles}
			is_loading={loadingEstadosCiviles}
			mutate_estados_civiles={mutateEstadosCiviles}
		/>
	);
}