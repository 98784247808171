import React, { Fragment, useState } from "react";
import MaterialTable from "../../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import * as Permissions from "../../../../constants/permissions";
import { CheckPermiso } from "../../../../services/utilities/common";
import { Chip, Typography } from "@material-ui/core";

export default function Page(props) {
	let {
		cartas_oferta,
		permisos,
		is_loading,
		mutate_cartas_oferta,
	} = props;

	const [IsOpen, SetIsOpen] = useState({});

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Link",
			field: "link_template",
			render: (row) => <a href={row.link_template} target="_blank" rel="noreferrer">Link</a>
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			hidden: !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_CARTAS_OFERTA_EDITAR),
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			hidden: !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_CARTAS_OFERTA_ELIMINAR),
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
		}, {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			hidden: !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_CARTAS_OFERTA_AGREGAR),
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: null }),
		},
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	/**
	 * Método encargado de redireccionar al usuario a la carpeta con templastes de cartas de oferta.
	 */
	const handleClickTemplates = () => {
		const templatesURL = "https://drive.google.com/drive/folders/10-rP1SodlE6IZGJOwCFI6QAqMD9iK6QI";
		window.open(templatesURL, "_blank");
	}

	/**
	 * Método encargado de redireccionar al usuario a la carpeta con cartas de oferta generadas.
	 */
	const handleClickGeneradas = () => {
		const generadasURL = "https://drive.google.com/drive/folders/1x9SPRQPMgsQEodUxUfoULphRzDcku82d";
		window.open(generadasURL, "_blank");
	}

	return (
		<Fragment>
			{/* LISTADO DE CARTAS DE OFERTA */}
			<MaterialTable
				title={<Fragment>
					<Typography variant="inherit">Cartas de oferta</Typography>
					<Chip size="small" label="Carpeta Templates" onClick={handleClickTemplates} clickable style={{ marginLeft: 20, marginRight: 5 }} />
					<Chip size="small" label="Carpeta Generadas" onClick={handleClickGeneradas} clickable style={{ marginLeft: 5, marginRight: 20 }} />
				</Fragment>}
				is_loading={is_loading}
				data={cartas_oferta?.data}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					handle_close={handleCloseDialog}
					mutate_cartas_oferta={mutate_cartas_oferta}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_cartas_oferta={mutate_cartas_oferta}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_cartas_oferta={mutate_cartas_oferta}
				/>
			)}
		</Fragment>
	);
}