import React, { useContext } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { Box, Button, Collapse, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import { FormikInitialValues, FormikValidationSchema } from "./adjunto_utils";
import FileInput from "../../../../components/fileInput";
import { uploadFileToCloudStorage } from "../../../../services/utilities/files";
import { ActualizacionRequest, AdjuntoRequest, PostulanteRequest } from "../../../../services/request/reclutamiento/postulantes";
import { Agregar } from "../../../../services/request/reclutamiento/curriculums";

const APPLICATION_PDF_EXTENSION = "application/pdf";

export default function AdjuntoDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { postulante_id } = useParams();
	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nuevo adjunto del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Si existe un archivo adjunto.
				if (values.file) {
					notistack.enqueueSnackbar("Subiendo adjunto a la nube...", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});
					//Archivo con el nuevo nombre. https://pqina.nl/blog/rename-a-file-with-javascript/
					const renamedFile = new File([values.file], String(values.file.name).replace(/^.*\./, `${values.nombre}.`), { type: values.file.type });
					//Texto para el formato del archivo.
					let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
					//Se sube el adjunto a la nube.
					const archivo = await uploadFileToCloudStorage(renamedFile, "adjuntos-test", `adjunto_${ahora}`);
					values.adjunto = archivo;

					//Si el tipo de adjunto es PDF.
					if (values.file.type === APPLICATION_PDF_EXTENSION) {
						//Datos del curriculum.
						let curriculum = {
							_postulante_ref: postulante_id,
							_gerencia_ref: usuarioSesion.gerencia._id,
							nombre: `${formik.values.nombre} ${formik.values.apellido_paterno} ${formik.values.apellido_materno}`,
							run_postulante: formik.values.run,
							tags: ["Nuevo Adjunto"],
							curriculum_vitae: archivo,
							origen: "Nuevo Adjunto",
						}
						//Se agrega el nuevo documento para escaneo.
						await Agregar(curriculum);
					}
				}

				//Si no tiene CV asignado.
				if (!formik.values.curriculum_vitae) {
					//Se actualiza el CV asignado del postulante.
					await PostulanteRequest.Actualizar(postulante_id, { curriculum_vitae: values.adjunto });
					formik.setFieldValue("curriculum_vitae", values.adjunto);
				}

				//Se agregan adjunto y actualización del postulante.
				let responses = await Promise.all([
					AdjuntoRequest.Agregar(postulante_id, values.adjunto),
					ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref, "Nuevo adjunto"),
				]);

				//Nuevo adjunto del postulante.
				let adjunto = responses[0];
				//Nuevo registro de actualización del postulante.
				let actualizacion = responses[1];

				//Se actualizan los datos del postulante.
				formik.setFieldValue("adjuntos", [...formik.values.adjuntos, adjunto]);
				formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

				notistack.enqueueSnackbar("Adjunto del postulante agregado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar agregar el adjunto del postulante.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handleCloseDialog();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCloseDialog = () => {
		set_is_open(false);
		formikDialog.resetForm();
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formikDialog.isSubmitting ||
			!formikDialog.values.nombre ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					{/* NOMBRE DEL ADJUNTO */}
					<Grid item xs={12}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formikDialog.values.nombre}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.nombre}
							error={Boolean(formikDialog.errors.nombre)}
						/>
					</Grid>

					{/* CAMPO ADJUNTO */}
					<Grid item xs={12}>
						<FileInput
							inputName="file"
							label="adjunto"
							handleOnChange={(e) => formikDialog.setFieldValue("file", e.target.files[0])}
							meta={{ touched: formikDialog.touched.file, error: formikDialog.errors.file }}
							accept="image/png, image/jpg, image/jpeg, application/pdf"
							buttonColor="primary"
							buttonVariant="outlined"
							value={formikDialog.values.file}
							textVariant="outlined"
							size="small"
						/>
					</Grid>

					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box pr={1}>
								<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
									Agregar adjunto
								</Button>
							</Box>
							<Box pl={1}>
								<Button variant="contained" onClick={handleCloseDialog} disabled={formikDialog.isSubmitting} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}