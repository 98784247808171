import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import useSWR from "swr";
import DialogTitleComponent from "../../../../components/dialog_title";
import TabSelector from "../../../../components/tab_selector";
import { ActualizarUsuarioPlataforma } from "../../../../services/request/reclutamiento/usuariosPlataforma";
import { GetPersonas } from "../../../../services/request/usuarios/personas";
import { FormikInitialValues, FormikValidationSchema, PERMISOS } from "./utils";
import { MainContext } from "../../../../App";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_usuarios,
	} = props;

	const [TabIndex, SetTabIndex] = useState(0);
	const [Permisos, SetPermisos] = useState(PERMISOS.map(p => ({ ...p, checked: false })));
	const TiposPermisos = Array.from(new Set(PERMISOS.map(p => p.group)));
	const UsuariosSWR = useSWR("personas_usuarios_plataforma", (key) => GetPersonas({ is_gsuite_talana: true }), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (UsuariosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de las personas.", UsuariosSWR.error);
		}
	}, [MainCTX, UsuariosSWR.error]);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				values["_id"] = data._id;
				values["_usuario_ref"] = values.persona._id;
				values["permisos"] = values.permisos.filter(p => p.checked).map(p => p.value);
				await ActualizarUsuarioPlataforma(values);
				MainCTX.ShowSnackbar("Usuario actualizado exitosamente.");
			} catch (error) {
				MainCTX.ShowSnackbar("Error al intentar actualizar el usuario.", error);
			} finally {
				helper.resetForm();
				mutate_usuarios();
				handle_close();
			}
		}
	});

	useEffect(() => {
		if (data && UsuariosSWR.data) {
			for (let i = 0; i < Permisos.length; i++) {
				if (data.permisos.includes(Permisos[i].value)) {
					Permisos[i]["checked"] = true;
				}
			}
			formik.setValues({
				persona: UsuariosSWR.data ? Array.from(UsuariosSWR.data).find(p => p._id === data._usuario_ref._id) : null,
				permisos: Permisos,
			});
		}
	}, [data, UsuariosSWR.data]);

	/**
	 * Método encargado de 
	 * @param {*} checked FLAG de permiso activado o no.
	 * @param {*} permiso Datos del permiso.
	 */
	const handleToggle = (checked, permiso) => {
		let permisos = Array.from(Permisos);
		for (const p of permisos) {
			if (p.value === permiso.value) {
				p.checked = checked;
			}
		}
		SetPermisos(permisos);
		formik.setFieldValue("permisos", permisos);
	};

	/**
	 * Método encargado de activar todos los toggles de un grupo.
	 * @param {*} checked Valor del toggle.
	 * @param {*} tipoPermiso Tipo de permiso.
	 */
	const handleToggleGroup = (checked, tipoPermiso) => {
		let permisos = Array.from(Permisos);
		for (const p of permisos) {
			if (p.group === tipoPermiso) {
				p.checked = checked;
			}
		}
		SetPermisos(permisos);
		formik.setFieldValue("permisos", permisos);
		MainCTX.ShowSnackbar(`Asignados todos los permisos del grupo "${tipoPermiso}".`);
	}

	/**
	 * Método encargado de activar todos los toggles de permisos.
	 * @param {*} checked Valor del toggle.
	 */
	const handleToggleAll = (checked) => {
		let permisos = Array.from(Permisos).map(p => ({ ...p, checked }));
		SetPermisos(permisos);
		formik.setFieldValue("permisos", permisos);

		MainCTX.ShowSnackbar("Asignados todos los permisos.");
	}

	/**
	 * Metodo encargado de generar un listado unico de grupos de permisos.
	 * @returns Coleccioon de grupos de permisos, con detalle de las cantidades seleccionadps y totales.
	 */
	const TitulosTabs = () => {
		let cantPermisosGrupo = (grupo) => Permisos.filter(permiso => permiso.group === grupo).length;
		let cantPermisosAsignados = (grupo) => Permisos.filter(permiso => permiso.group === grupo && permiso.checked === true).length;
		return TiposPermisos.map(grupo => `${grupo} (${cantPermisosAsignados(grupo)} de ${cantPermisosGrupo(grupo)})`);
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20, height: "85%", marginTop: 90 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Actualizar Usuario</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria del usuario y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<Autocomplete
							options={UsuariosSWR.data || []}
							value={formik.values.persona}
							getOptionLabel={(persona) => `[${persona.contacto.email}] ${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno}`}
							onChange={(event, value) => formik.setFieldValue("persona", value)}
							loading={UsuariosSWR.isValidating}
							disabled={true}
							noOptionsText="Sin opciones"
							size="small"
							renderInput={(params) => (
								<TextField
									label="Persona"
									helperText={formik.errors.persona}
									error={Boolean(formik.errors.persona)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* BOTON PARA ASIGNAR TODOS LOS PERMISOS DE TODOS LOS GRUPOS */}
						<Button onClick={() => handleToggleAll(true)} disabled={!UsuariosSWR.data || !data} variant="contained" color="secondary">
							Asignar todo
						</Button>
					</Grid>
					<Grid item xs={12}>
						{/* PESTAÑA CON GRUPOS DE PERMISOS */}
						<TabSelector
							tab_list={TitulosTabs()}
							tab_index={TabIndex}
							handle_change={(event, index) => SetTabIndex(index)}
						/>
						{/* BOTON PARA ASIGNAR TODOS LOS PERMISOS DEL GRUPO */}
						<Box display="flex" justifyContent="flex-end" style={{ margin: 10 }}>
							<Button onClick={(event) => handleToggleGroup(true, TiposPermisos[TabIndex])} disabled={!UsuariosSWR.data || !data} variant="outlined" color="secondary" size="small">
								{`Asignar todo ${TiposPermisos[TabIndex]}`}
							</Button>
						</Box>
						{/* COLECCION DE PERMISOS */}
						<List>
							{Permisos && Permisos.filter(p => p.group === TiposPermisos[TabIndex]).map((permiso, index2) => (
								<ListItem divider dense key={`list_item_${index2}`}>
									<ListItemText primary={permiso.label} />
									<ListItemSecondaryAction>
										<Switch
											edge="end"
											onChange={(event, checked) => handleToggle(checked, permiso)}
											checked={Boolean(permiso.checked)}
											disabled={!UsuariosSWR.data || !data}
											size="small"
										/>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}