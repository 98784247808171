import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerInstituciones } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: instituciones,
		isValidating: loadingInstituciones,
		mutate: mutateInstituciones,
		error: errorInstituciones,
	} = useSWR("instituciones_seleccionables", ObtenerInstituciones, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorInstituciones) {
			ShowSnackbar("Error al intentar cargar los datos de las instituciones.", errorInstituciones);
		}
	}, [ShowSnackbar, errorInstituciones]);

	return (
		<Page
			instituciones={instituciones}
			is_loading={loadingInstituciones}
			mutate_instituciones={mutateInstituciones}
		/>
	);
}