import React, { useContext, useEffect, useState } from "react";
import Page from "./page";
import { useParams, useHistory } from "react-router-dom";
import { CARPETA_PROYECTOS } from "../../../../constants/routes";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const [Proyecto, SetProyecto] = useState(null);
	const { ShowSnackbar } = useContext(MainContext);
	const { proyecto_id } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!proyecto_id) {
			history.replace(CARPETA_PROYECTOS);
		} else {
			LicitacionProyectoRequets.ObtenerPorID(proyecto_id)
				.then(licitacion => {
					SetProyecto(licitacion);
				})
				.catch(error => {
					console.error(error);
					ShowSnackbar("Error al intentar obtener los datos del proyecto.", error);
					history.replace(CARPETA_PROYECTOS);
				});
		}
	}, [ShowSnackbar, history, proyecto_id]);

	return Proyecto && (
		<Page
			proyecto={Proyecto}
		/>
	);
}