import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { Obtener } from "../../../../services/request/reclutamiento/empresas";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: empresas,
		isValidating: loadingEmpresas,
		mutate: mutateEmpresas,
		error: errorEmpresas,
	} = useSWR("empresas_seleccionables", Obtener, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorEmpresas) {
			ShowSnackbar("Error al intentar cargar los datos de las empresas.", errorEmpresas);
		}
	}, [ShowSnackbar, errorEmpresas]);

	return (
		<Page
			empresas={empresas}
			is_loading={loadingEmpresas}
			mutate_empresas={mutateEmpresas}
		/>
	);
}