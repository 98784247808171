import React, { Fragment, useState, useContext } from "react";
import { isBrowser } from "react-device-detect";
import { Menu, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MainContext } from "../../App";
import BarraSuperior from "./components/barraSuperior";
import MenuLateral from "./components/menuLateral";
import { cerrarSesion } from "../../services/utilities/auth";
import { VISITADOS } from "../../services/utilities/local_db";

import "./style.css";
import "./../../App.css";

const menuId = "primary-menu";

export default function AppBar(props) {
	const { usuarioSesion } = useContext(MainContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(isBrowser ? false : false);
	const classes = useStyles();
	const isMenuOpen = Boolean(anchorEl);

	async function handleCerrarSession() {
		setAnchorEl(null);
		await cerrarSesion();
	}

	const GlobalCss = withStyles({
		"@global": {
			".Mui-selected": {
				borderRadius: "0 25px 25px 0",
			},
			".MuiListItem-button:hover ": {
				borderRadius: "0 25px 25px 0",
			},
		},
	})(() => null);

	const renderAppMobile = () => {
		return (
			<Fragment>
				<GlobalCss />
				<div className={classes.root}>
					{/* BARRA SUPERIOR */}
					<BarraSuperior
						open={open}
						handle_drawer_open={() => setOpen(!open)}
						usuario_session={usuarioSesion}
						set_anchor_el={setAnchorEl}
					/>

					{/* MENÚ PARA CERRAR SESIÓN */}
					<Menu
						id={menuId}
						open={isMenuOpen}
						anchorEl={anchorEl}
						onClose={() => setAnchorEl(null)}
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						transformOrigin={{ vertical: "bottom", horizontal: "right" }}
						style={{ zIndex: 3000 }}
					>
						<MenuItem onClick={VISITADOS.EliminarTodo}>Limpiar visitados</MenuItem>
						<MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
					</Menu>

					{/* MENÚ CON ACCESOS A LOS MÓDULOS */}
					<MenuLateral
						open={open}
						set_open={setOpen}
					/>
				</div>
			</Fragment >
		);
	};

	return renderAppMobile();
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
}));