import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";
import * as Contexts from "../../../constants/contexts";

/**
 * Método encargado de agregar un nuevo seleccionable.
 * @param {*} seleccionable Datos del seleccionable.
 * @returns Response.
 */
export async function Agregar(seleccionable) {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables`;
		let response = await Axios.post(url, seleccionable);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un seleccionable.
 * @param {*} seleccionable Datos actualizados del seleccionable.
 * @returns Response.
 */
export async function Actualizar(seleccionable) {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables/${seleccionable._id}`;
		let response = await Axios.put(url, seleccionable);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un seleccionable.
 * @param {*} seleccionableID ID del seleccionable.
 * @returns Response.
 */
export async function Eliminar(seleccionableID) {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables/${seleccionableID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de instituciones.
 * @returns Colección de instituciones.
 */
export async function ObtenerInstituciones() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.INSTITUCIONES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de títulos.
 * @returns Colección de títulos.
 */
export async function ObtenerTitulos() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.TITULOS}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de licencias de conducir.
 * @returns Colección de licencias de conducir.
 */
export async function ObtenerLicenciasConducir() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.LICENCIAS_CONDUCIR}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de discapacidades.
 * @returns Colección de discapacidades.
 */
export async function ObtenerDiscapacidades() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.DISCAPACIDADES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de estados civiles.
 * @returns Colección de estados civiles.
 */
export async function ObtenerEstadosCiviles() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.ESTADOS_CIVILES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de niveles de inglés.
 * @returns Colección de niveles de ingles.
 */
export async function ObtenerNivelesIngles() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.NIVELES_INGLES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de situaciones laborales.
 * @returns Colección de situaciones laborales.
 */
export async function ObtenerSituacionesLaborales() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.SITUACIONES_LABORALES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de relaciones con CyD.
 * @returns Colección de relaciones con CyD.
 */
export async function ObtenerRelacionesCyD() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.RELACIONES_CYD}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de niveles de estudio.
 * @returns Colección de niveles de estudio.
 */
export async function ObtenerNivelesEstudio() {
	try {
		let url = `${RECLUTAMIENTO}/seleccionables?contexto_eq=${Contexts.NIVELES_ESTUDIOS}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}