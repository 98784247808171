import React from "react";
import clsx from "clsx";
import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";
import { AppBar, Avatar, Box, Grid, IconButton, Toolbar, Typography, Divider, Tooltip } from "@material-ui/core";
import { Menu, HeadsetMic } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import * as Routes from "../../../constants/routes";

const LOGO_CYDOCS_COLOR = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";
const URL_MESA_AYUDA_CYD = "https://soportecyd.sd.cloud.invgate.net/";
const menuId = "primary-menu";

/**
 * Método encargado de retornar la barra superior.
 * @param {*} open TRUE: Drawer abierto | FALSE: Drawer cerrado.
 * @param {*} handle_drawer_open Método encargado de abrir el Drawer.
 * @param {*} usuario_session Datos del usuario.
 * @param {*} set_anchor_el Método encargado de setear el state anchorEl.
 * @returns Component.
 */
export default function BarraSuperior(props) {
	const {
		open,
		handle_drawer_open,
		usuario_session,
		set_anchor_el
	} = props;

	const classes = useStyles();

	return (
		<AppBar
			position="fixed"
			elevation={0}
			className={clsx(classes.appBar, { [classes.appBarShift]: open })}
			style={{ zIndex: 2000 }}
		>
			<Box boxShadow={0}>
				<Toolbar
					variant="dense"
					style={{ backgroundColor: "#FFFFFF", paddingLeft: "18px" }}
				>
					<IconButton
						aria-label="open drawer"
						onClick={handle_drawer_open}
						edge="start"
						className={clsx(classes.menuButton)}
					>
						<Menu />
					</IconButton>
					<div>
						<Link to={Routes.HOME}>
							<img
								className={classes.img_cyd}
								src={LOGO_CYDOCS_COLOR}
								alt="CyDocs"
							/>
						</Link>
					</div>
					<div style={{ paddingLeft: "10px" }}>
						<Typography noWrap style={{ color: "#9e9e9e" }}>
							Gestor de Reclutamiento
						</Typography>
					</div>
					<div className={classes.grow} />


					<div className={classes.sectionDesktop}>
						{/* ÍCONO PARA REDIRECCIONAR A MESA DE AYUDA */}
						<Tooltip title="Mesa de Ayuda CyD">
							<IconButton href={URL_MESA_AYUDA_CYD} target="_blank" rel="noreferrer" style={{ marginLeft: 25 }}>
								<HeadsetMic style={{ color: "#731f1f" }} />
							</IconButton>
						</Tooltip>

						<Divider
							orientation="vertical"
							style={{
								height: "3rem",
								marginTop: "0.5rem",
								marginRight: "1rem",
							}}
						/>
						{isBrowser && (
							<Grid container direction="column">
								<div className={classes.contenedorUser}>
									<Grid item xs>
										<Typography
											className={classes.titleNoMargin}
											variant="body2"
											gutterBottom
											color="inherit"
										>
											Bienvenido
										</Typography>
										<div style={{ whiteSpace: "nowrap" }}>
											<Box
												component="div"
												textOverflow="ellipsis"
												overflow="hidden"
											>
												<Typography
													className={classes.titleBold}
													variant="body1"
													color="inherit"
												>
													{usuario_session ? usuario_session.nombre : ""}
												</Typography>
											</Box>
										</div>
									</Grid>
								</div>
							</Grid>
						)}
						<IconButton
							edge="end"
							aria-label="Account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={(event) => set_anchor_el(event.currentTarget)}
							color="inherit"
						>
							<Avatar
								alt="Remy Sharp"
								src={usuario_session ? usuario_session.photoUrl : ""}
							/>
						</IconButton>
					</div>
				</Toolbar>
			</Box>
		</AppBar>
	);
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 3,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100%px)`,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	img_cyd: {
		height: "40px",
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	contenedorUser: {
		marginTop: "auto",
		marginBottom: "auto",
		marginLeft: "20px",
	},
	titleNoMargin: {
		margin: 0,
		textAlign: "end",
		color: "black",
	},
	titleBold: {
		color: "black",
	},
}));