import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

export default function DetalleRespuestas(props) {
	const {
		respuestas,
	} = props;

	return (
		<Fragment>
			<Typography>{`1) ${respuestas.question1}`}</Typography>
			{respuestas.answer1}
			<br />
			<br />
			<Typography>{`2) ${respuestas.question2}`}</Typography>
			{respuestas.answer2}
			<br />
			<br />
			<Typography>{`3) ${respuestas.question3}`}</Typography>
			{respuestas.answer3}
			<br />
			<br />
			<Typography>{`4) ${respuestas.question4}`}</Typography>
			{respuestas.answer4}
		</Fragment>
	);
}