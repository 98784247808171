import React, { Fragment, useContext, useState } from "react";
import Moment from "moment";
import { useSnackbar } from "notistack";
import { AccordionSummary, Collapse, IconButton, Paper, Typography } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import ExperienciaDialog from "./experiencia_dialog";
import { ActualizacionRequest, ExperienciaRequest, PostulanteRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function Experiencias(props) {
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);

	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	let columns = [
		{
			title: "Empresa",
			field: "empresa",
		}, {
			title: "Cargo",
			field: "cargo",
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarExperiencia(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} experiencia Datos de la experiencia.
	 */
	const handleEliminarExperiencia = async (experiencia) => {
		try {
			//Se elimina la experiencia y se agrega actualización.
			let responses = await Promise.all([
				ExperienciaRequest.Eliminar(experiencia._postulante_ref, experiencia._id),
				ActualizacionRequest.Agregar(experiencia._postulante_ref, usuarioSesion.ref, "Experiencia eliminada"),
			]);

			//Nueva actualización.
			let actualizacion = responses[1];
			let experiencias = Array.from(formik.values.experiencias);
			//Se elimina la experiencia.
			experiencias.splice(experiencia.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("experiencias", experiencias);
			formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

			let fechasInicio = experiencias.filter(e => e.fecha_inicio)
				.map(e => Moment(e.fecha_inicio));
			if (fechasInicio.length > 0) {
				//Si existen fechas de inicio.
				let primerFecha = Moment.min(fechasInicio);
				await PostulanteRequest.Actualizar(experiencia._postulante_ref, { inicio_vida_laboral: primerFecha });
			} else {
				//Si no existen fechas de inicio.
				await PostulanteRequest.Actualizar(experiencia._postulante_ref, { inicio_vida_laboral: null });
			}

			notistack.enqueueSnackbar("Experiencia del postulante eliminada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar la experiencia del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE EXPERIENCIAS */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Experiencias"
					is_loading={false}
					data={formik.values.experiencias}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Experiencias (${formik.values.experiencias.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG EXPERIENCIA */}
			<ExperienciaDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}