import React, { Fragment, useContext, useState } from "react";
import { AccordionSummary, Collapse, Paper, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { PostulanteEditarContext } from "../index";
import { FormatearFecha, NombreAbreviado } from "../../../../services/utilities/formatUtils";

export default function Actualizaciones(props) {
	const [Expanded, SetExpanded] = useState(false);
	const {
		formik,
	} = useContext(PostulanteEditarContext);

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	let columns = [
		{
			title: "Reclutador",
			field: "nombre_completo",
		}, {
			title: "Acción realizada",
			field: "motivo",
		}, {
			title: "Fecha",
			field: "fecha",
		}
	];

	let actions = [
		{
			tooltip: "Expandir",
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	if (formik && formik.values.actualizaciones) {
		formik.values.actualizaciones.forEach((item, index) => {
			formik.values.actualizaciones[index]["nombre_completo"] = NombreAbreviado(item._reclutador_ref);
			formik.values.actualizaciones[index]["fecha"] = FormatearFecha(item.fecha_creacion, "DD/MM/YYYY HH:mm");
		});
	}

	return (
		<Fragment>
			{/* LISTADO DE ACTUALIZACIONES */}
			<Collapse in={Expanded}>
				<MaterialTable
					title="Actualizaciones"
					is_loading={false}
					data={formik.values.actualizaciones}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
					page_size={5}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Actualizaciones (${formik.values.actualizaciones.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}
		</Fragment>
	);
}