import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import useSWR from "swr";
import Moment from "moment";
import MaterialTable from "../materialTable";
import TooltipCustom from "../tooltip_custom";
import DialogAddCargo from "./dialogAddCargo";
import DialogUpdateCargo from "./dialogUpdateCargo";
import DialogDeleteCargo from "./dialogDeleteCargo";
import { FormikInitialValues, FormikValidationSchema, TIPO_ADD, TIPO_UPDATE, TIPO_DELETE, EstadoCargo } from "./utils";
import { CargoRequest, AdjuntoRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import { FormatearFecha, MonedaToMonto, NombreAbreviado } from "../../services/utilities/formatUtils";
import { uploadFileToCloudStorage } from "../../services/utilities/files";
import { CheckPermiso } from "../../services/utilities/common";
import * as Permissions from "../../constants/permissions";
import { CARGO_LICITACION_PROYECTO } from "../../constants/routes";
import { MainContext } from "../../App";
import { TIPO_LICITACION, TIPO_PROYECTO } from "../../constants/contexts";

/**
 * Componente para mostrar una colección de cargos.
 * @param licitacion_proyecto Datos de la licitación o proyecto.
 * @param mandante Mandante de la licitación/proyecto.
 * @param tipo Tipo licitación o proyecto.
 * @returns MaterialTable con listado de cargos y Dialogs para agregar, actualizar y eliminar cargos.
 */
export default function ListadoCargos(props) {
	const {
		licitacion_proyecto,
		mandante,
		tipo,
		cargo_estado,
	} = props;

	const [IsOpen, SetIsOpen] = useState({ tipo: "", data: null });
	const history = useHistory();
	const CargosSWR = useSWR("cargos", (key) => CargoRequest.ObtenerPorLicitacionProyecto(licitacion_proyecto._id));
	const MainCTX = useContext(MainContext);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				values._licitacion_proyecto_ref = licitacion_proyecto._id;
				values._responsable_ref = licitacion_proyecto._responsable_ref._id;
				values._reclutador_ref = values.reclutador._id;
				values._gerencia_ref = licitacion_proyecto._gerencia_ref;
				values.renta_liquida_min = MonedaToMonto(values.renta_liquida_min_formato);
				values.renta_liquida_max = MonedaToMonto(values.renta_liquida_max_formato);
				values.renta_liquida_objetivo = MonedaToMonto(values.renta_liquida_objetivo_formato);

				//Si el estado cambia, se asigna la fecha.
				if (IsOpen.data.estado !== values.estado) {
					//Si ya existe una fecha de último cambio, se asigna como penultimo cambio.
					if (values.fecha_ultimo_estado) {
						values.fecha_penultimo_estado = values.fecha_ultimo_estado;
					}
					values.fecha_ultimo_estado = Moment();
				}

				let cargoID = "";

				if (IsOpen.tipo === TIPO_ADD) {
					delete values._id;
					let cargo = await CargoRequest.Agregar(licitacion_proyecto._id, values);
					cargoID = cargo._id;
					MainCTX.ShowSnackbar("Cargo agregado exitosamente.");
				} else if (IsOpen.tipo === TIPO_UPDATE) {
					await CargoRequest.Actualizar(licitacion_proyecto._id, values);
					cargoID = values._id;
					MainCTX.ShowSnackbar("Cargo actualizado exitosamente.");
				} else {
					throw new Error("Tipo de acción inválida.");
				}
				//Si se incluyen adjuntos.
				if (values.adjuntos.length > 0) {
					let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
					for (const adjunto of values.adjuntos.filter(a => !a._id)) {
						//Archivo con el nuevo nombre. https://pqina.nl/blog/rename-a-file-with-javascript/
						const renamedFile = new File([adjunto.file], String(adjunto.file.name).replace(/^.*\./, `${adjunto.nombre}.`), { type: adjunto.file.type });
						//Se sube el adjunto a la nube.
						const archivo = await uploadFileToCloudStorage(renamedFile, "adjuntos-test", `adjunto_${ahora}`);
						adjunto.adjunto = archivo;
						await AdjuntoRequest.Agregar(licitacion_proyecto._id, cargoID, adjunto.adjunto);
					}
				}
			} catch (error) {
				if (IsOpen.tipo === TIPO_ADD) {
					MainCTX.ShowSnackbar("Error al intentar agregar el cargo.", error);
				} else if (IsOpen.tipo === TIPO_UPDATE) {
					MainCTX.ShowSnackbar("Error al intentar actualizar el cargo.", error);
				} else {
					MainCTX.ShowSnackbar("Error desconocido.", error);
				}
			} finally {
				CargosSWR.mutate();
				handleClose();
				helper.resetForm({ values: FormikInitialValues });
			}
		},
	});

	useEffect(() => {
		if (CargosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de los cargos", CargosSWR.error);
		}
	}, [MainCTX, CargosSWR]);

	let columns = [
		{
			title: "Código",
			field: "codigo",
			width: 100,
		}, {
			title: "Nombre",
			field: "nombre",
			width: 300,
		}, {
			title: "Estado",
			field: "estado",
			render: (row) => EstadoCargo(row.estado),
		}, {
			title: "Vacantes",
			field: "cant_vacantes",
			width: 100,
		}, {
			title: "Asignados",
			field: "cant_asignados",
			width: 100,
		}, {
			title: tipo === TIPO_PROYECTO ? "Seleccionados" : tipo === TIPO_LICITACION ? "Presentados" : "Seleccionados/ Preparados",
			field: "cant_seleccionados_detail",
			width: 100,
		}, {
			title: "Avance",
			field: "porcentaje_avance",
			render: (row) => `${Math.min(100, Number(row.porcentaje_avance).toFixed(1))}%`,
			width: 50,
		}, {
			title: "Reclutador",
			field: "_reclutador_ref.nombre",
			render: (row) => <TooltipCustom tooltip={row._reclutador_ref?.contacto?.email}>{NombreAbreviado(row._reclutador_ref)}</TooltipCustom>,
			width: 100,
		}, {
			title: "Fecha requerimiento",
			field: "fecha_requerimiento",
			width: 100,
			render: (row) => FormatearFecha(row.fecha_requerimiento, "YYYY/MM/DD"),
		}
	];

	let actions = [
		{
			icon: "login",
			tooltip: "Entrar",
			onClick: (event, row) => history.push(`${history.location.pathname}${CARGO_LICITACION_PROYECTO.replace(":cargo_id", row._id)}`),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_VER),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_VER),
		}, {
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => SetIsOpen({ tipo: TIPO_UPDATE, data: row }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_EDITAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_EDITAR),
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => SetIsOpen({ tipo: TIPO_DELETE, data: row }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_ELIMINAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_ELIMINAR),
		}, {
			icon: "add",
			tooltip: "Nuevo Cargo",
			onClick: (event, row) => SetIsOpen({ tipo: TIPO_ADD, data: mandante }),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_AGREGAR),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_AGREGAR),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de cerrar el POPUP.
	 */
	const handleClose = () => {
		SetIsOpen(false);
		CargosSWR.mutate();
		formik.resetForm({ values: FormikInitialValues });
	}

	/**
	 * Método encargado de retornar los cargos, filtrados por estado.
	 * @returns Colección de cargos.
	 */
	const cargosFilter = () => {
		if (!CargosSWR.data) {
			return [];
		}
		for (const cargo of CargosSWR.data) {
			let cantSeleccionados = cargo.cant_seleccionados;
			let cantVacantes = cargo.cant_vacantes;
			if (cantSeleccionados > cantVacantes) {
				cargo["cant_seleccionados_detail"] = `${cantVacantes} (${cantSeleccionados - cantVacantes})`;
			} else {
				cargo["cant_seleccionados_detail"] = cantSeleccionados;
			}
		}
		return CargosSWR.data.filter(c => c.estado === cargo_estado);
	}

	return (
		<Fragment>
			<MaterialTable
				title="Cargos"
				is_loading={CargosSWR.isValidating}
				data={cargosFilter()}
				columns={columns}
				actions={actions}
			/>

			{/* DIALOG AGREGAR */}
			{IsOpen.tipo === TIPO_ADD && (
				<DialogAddCargo
					is_open={Boolean(IsOpen)}
					data={IsOpen.data}
					tipo={tipo}
					formik={formik}
					handle_close={handleClose}
				/>
			)}

			{/* DIALOG ACTUALIZACION */}
			{IsOpen.tipo === TIPO_UPDATE && (
				<DialogUpdateCargo
					is_open={Boolean(IsOpen)}
					data={IsOpen.data}
					formik={formik}
					handle_close={handleClose}
				/>
			)}

			{/* DIALOG ELIMINAR */}
			{IsOpen.tipo === TIPO_DELETE && (
				<DialogDeleteCargo
					is_open={Boolean(IsOpen)}
					data={IsOpen.data}
					handle_close={handleClose}
				/>
			)}
		</Fragment>
	);
}