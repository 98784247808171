import React, { Fragment, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar, Badge, Box, Paper, Tooltip, Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiFile, mdiFilePdfBox, mdiFileJpgBox, mdiFilePngBox, mdiFileMultiple } from "@mdi/js";
import { DejarCurriculumContext } from "./index";
import { FormatosText } from "./utils";
import { BytesToSize } from "../../../services/utilities/formatUtils";

/**
 * Componente para seleccionar o arrastrar archivos.
 * @param {*} nombre Nombre del campo en el formulario.
 * @param {*} titulo Título del campo de archivo(s). Valor predeterminado es "Archivo".
 * @param {*} cantidad Cantidad máxima de archivos permitidos. Valor predeterminado es 20, sin límite.
 * @param {*} formatos Formatos de archivos permitidos.
 */
export default function FileDrop(props) {
	const {
		nombre,
		titulo = "Archivo",
		cantidad = 20,
		formatos = undefined,
	} = props;

	const {
		formik,
	} = useContext(DejarCurriculumContext);

	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
	} = useDropzone({ maxFiles: cantidad, accept: formatos, multiple: cantidad === 1 ? false : true, });

	useEffect(() => {
		formik.setFieldValue(nombre, cantidad === 1 ? acceptedFiles[0] : acceptedFiles);
	}, [acceptedFiles, nombre, cantidad]);

	/**
	 * Método encargado de verificar y retorar un Tooltip.
	 * @returns Contenido del Tooltip.
	 */
	const filesTooptip = () => {
		if (acceptedFiles.length === 0) {
			return "";
		} else {
			return (
				<Fragment>
					{/* LISTADO DE ARCHIVOS */}
					{acceptedFiles.map((file, index) => (
						<Typography variant="subtitle2" key={`typography_${index}`}>{`${file.path} (${BytesToSize(file.size)})`}</Typography>
					))}

					{/* TAMAÑO TOTAL DE ARCHIVOS */}
					{acceptedFiles.length > 1 && (
						<Typography variant="subtitle2">{`Total: ${BytesToSize(acceptedFiles.reduce((previous, current) => previous + current.size, 0))}`}</Typography>
					)}
				</Fragment>
			);
		}
	};

	/**
	 * Método encargado retornar el icono asociado al formato del archivo.
	 * @returns Icono de formatos de archivos.
	 */
	const fileIcon = () => {
		if (acceptedFiles.length === 0) {
			return mdiFile;
		} else if (acceptedFiles.length === 1) {
			switch (acceptedFiles[0].type) {
				case "application/pdf":
					return mdiFilePdfBox;
				case "image/jpeg":
					return mdiFileJpgBox;
				case "image/jpg":
					return mdiFileJpgBox;
				case "image/png":
					return mdiFilePngBox;
				default:
					return mdiFile;
			}
		} else {
			return mdiFileMultiple;
		}
	}

	return (
		<Fragment>
			<Typography>{`${titulo} (${cantidad === 0 ? "9+" : cantidad})`}</Typography>
			<Box display="flex" flexDirection="row" pt={1}>
				<Tooltip title={filesTooptip()}>
					<Badge badgeContent={acceptedFiles.length || 0} color="primary">
						<Avatar>
							<Icon path={fileIcon()} size={1} />
						</Avatar>
					</Badge>
				</Tooltip>
				<Paper {...getRootProps()} style={{ marginLeft: 5, paddingLeft: 5, paddingRight: 5 }}>
					<input {...getInputProps()} />
					<Typography variant="subtitle2">{`Arrastra los archivos, o puede hacer click para buscarlos. Solo formatos ${FormatosText(formatos)}.`}</Typography>
				</Paper>
			</Box>
			{formik.errors && formik.errors[nombre] && (
				<Typography variant="caption" color="error">{formik.errors[nombre]}</Typography>
			)}
		</Fragment>
	);
}