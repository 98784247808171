import React, { useContext } from "react";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { PostulanteEditarContext } from "../index";
import AccordionComponent from "../../../../components/accordion";
import { AñosDesde } from "../../../../services/utilities/formatUtils";

export default function Personal(props) {
	const {
		paises,
		generos,
		formik,
	} = useContext(PostulanteEditarContext);

	/**
	 * Método encargado de calcular la cantidad de errores.
	 * @returns Cantidad de errores.
	 */
	function CantErrores() {
		let errores = 0;
		if (formik && formik.errors) {
			formik.errors.run && errores++;
			formik.errors.nombre && errores++;
			formik.errors.apellido_paterno && errores++;
			formik.errors.genero && errores++;
		}
		return errores;
	}

	return (
		<AccordionComponent title="Información Personal" errores={CantErrores()} expanded={true}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Tooltip title="SOLO en caso de no tener RUN, usar 12345678-9" placement="top">
						<TextField
							name="run"
							label="RUN"
							value={formik.values.run}
							onChange={formik.handleChange}
							variant="outlined"
							size="small"
							fullWidth
							required
							helperText={formik.errors.run}
							error={Boolean(formik.errors.run)}
						/>
					</Tooltip>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="nombre"
						label="Nombre"
						value={formik.values.nombre}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						required
						helperText={formik.errors.nombre}
						error={Boolean(formik.errors.nombre)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="apellido_paterno"
						label="Primer Apellido"
						value={formik.values.apellido_paterno}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						required
						helperText={formik.errors.apellido_paterno}
						error={Boolean(formik.errors.apellido_paterno)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="apellido_materno"
						label="Segundo Apellido"
						value={formik.values.apellido_materno}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.apellido_materno}
						error={Boolean(formik.errors.apellido_materno)}
					/>
				</Grid>
				<Grid item xs={6}>
					<DatePicker
						label="Fecha de Nacimiento"
						value={formik.values.fecha_nacimiento}
						onChange={date => {
							formik.setFieldValue("fecha_nacimiento", date);
							formik.setFieldValue("edad", `${AñosDesde(date)} años`);
						}}
						format="DD/MM/YYYY"
						variant="inline"
						inputVariant="outlined"
						size="small"
						fullWidth
						autoOk
						oklabel="Aceptar"
						cancellabel="Cancelar"
						helperText={formik.errors.fecha_nacimiento}
						error={Boolean(formik.errors.fecha_nacimiento)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="edad"
						label="Edad"
						value={formik.values.edad}
						variant="outlined"
						size="small"
						fullWidth
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="nacionalidad_object"
						options={paises || []}
						value={formik.values.nacionalidad_object}
						onChange={(event, value) => formik.setFieldValue("nacionalidad_object", value)}
						getOptionLabel={(pais) => pais.name || ""}
						size="small"
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Nacionalidad"
								variant="outlined"
								helperText={formik.errors.nacionalidad_object}
								error={Boolean(formik.errors.nacionalidad_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="genero"
						options={generos || []}
						value={formik.values.genero}
						onChange={(event, value) => formik.setFieldValue("genero", value)}
						getOptionLabel={(genero) => genero}
						size="small"
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Género"
								variant="outlined"
								helperText={formik.errors.genero}
								error={Boolean(formik.errors.genero)}
								{...params}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</AccordionComponent>
	);
}