import React, { Fragment } from "react";
import { LinearProgress, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

/**
 * Método encargado de retornar una línea de progreso con porcentaje de completitud, y el detalle.
 * @param {*} porcentaje Porcentaje de completitud.
 * @param {*} detalle Detalle del porcentaje de completitud.
 * @returns Componente.
 */
export default function LinearProgressComponent(props) {
	const {
		porcentaje,
		detalle,
	} = props;

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<HtmlTooltip
				title={
					<Fragment>
						{Array.from(detalle).map((d, index) => <Typography color="inherit" variant="subtitle1" key={`text_${index}`}>{d}</Typography>)}
					</Fragment>
				}
			>
				<BorderLinearProgress variant="determinate" value={porcentaje} />
			</HtmlTooltip>
		</div>
	);
}

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		// backgroundColor: '#f5f5f9',
		// color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		borderRadius: 15,
	},
}))(Tooltip);

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#a64c46',
	},
}))(LinearProgress);

const useStyles = makeStyles({
	root: {
		width: "100%",
	},
});