import { object, string } from "yup";

const REGEX_TELEFONO_ALTERNATIVO = /^((\+56)?)?\d{9}$/;

export const FormikInitialValues = {
	nombre: "",
	email: "",
	telefono: "",
	empresa: "",
	cargo: "",
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(100, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	email: string()
		.email("El correo debe tener el formato \"usuario@dominio\".")
		.optional(),
	telefono: string()
		.matches(REGEX_TELEFONO_ALTERNATIVO, { message: "El teléfono debe tener el formato \"[+56]987654321\"." })
		.optional(),
	empresa: string()
		.min(3, ({ min }) => `La empresa debe tener al menos ${min} caracteres.`)
		.max(100, ({ max }) => `La empresa debe tener a lo más ${max} caracteres.`)
		.optional(),
	cargo: string()
		.min(3, ({ min }) => `El cargo debe tener al menos ${min} caracteres.`)
		.max(100, ({ max }) => `El cargo debe tener a lo más ${max} caracteres.`)
		.optional(),
});