import { object, string, boolean } from "yup";

const REGEX_RUN = /^(\d{7}|\d{8})-[0-9kK]$/;
const REGEX_TELEFONO = /^((\+56)?(9))?\d{8}$/;

export const FormikInitialValues = {
	rut: "",
	nombre: "",
	rubro: "",
	is_publica: false,
	contacto: {
		telefono: "",
		telefono_alternativo: "",
		telefono_fijo: "",
		email: "",
		email_alternativo: "",
		sitio_web: "",
	},
}

export const FormikValidationSchema = object().shape({
	run: string()
		.min(9, ({ min }) => `El RUT debe tener al menos ${min} caracteres.`)
		.max(10, ({ max }) => `El RUT debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_RUN, { message: "El RUN debe tener el formato \"12345678-9\"." })
		.required("El RUT es requerido."),
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	rubro: string()
		.min(3, ({ min }) => `El rubro debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El rubro debe tener a lo más ${max} caracteres.`)
		.required("El rubro es requerido."),
	is_publica: boolean()
		.optional(),
	contacto: object()
		.shape({
			telefono: string()
				.min(8, ({ min }) => `El teléfono debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El teléfono debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			telefono_alternativo: string()
				.min(8, ({ min }) => `El teléfono alternativo debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El teléfono alternativo debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono alternativo debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			telefono_fijo: string()
				.min(8, ({ min }) => `El teléfono fijo debe tener al menos ${min} caracteres.`)
				.max(12, ({ max }) => `El teléfono fijo debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TELEFONO, { message: "El teléfono fijo debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			email: string()
				.email("El correo debe tener el formato \"usuario@dominio\".")
				.required("El correo es requerido."),
			email_alternativo: string()
				.email("El correo alternativo debe tener el formato \"usuario@dominio\".")
				.optional(),
			sitio_web: string()
				.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
				.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
				.required("El nombre es requerido."),
		})
		.required("La información de contacto es requerida."),
});