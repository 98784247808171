import { object, string } from "yup";

export const FormikInitialValues = {
	pretension_renta_formato: "",
	pretension_renta: 0,
	comentario: "",
}

export const FormikValidationSchema = object().shape({
	pretension_renta_formato: string()
		.optional(),
	comentario: string()
		.min(2, ({ min }) => `El comentario debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El comentario debe tener a lo más ${max} caracteres.`)
		.optional(),
});