import React, { useContext } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { Box, Button, Collapse, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import { FormikInitialValues, FormikValidationSchema } from "./referido_utils";
import { ActualizacionRequest, ReferidoRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function ReferidoDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { postulante_id } = useParams();
	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nuevo referido del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Se agregan referido y actualización del referido.
				let responses = await Promise.all([
					ReferidoRequest.Agregar(postulante_id, usuarioSesion.ref, values),
					ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref, "Nuevo referido"),
				]);

				//Nuevo referido al postulante.
				let referido = responses[0];
				//Nuevo registro de actualización del postulante.
				let actualizacion = responses[1];

				//Se actualizan los datos del postulante.
				formik.setFieldValue("referidos", [...formik.values.referidos, referido]);
				formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

				notistack.enqueueSnackbar("Referido del postulante agregado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar agregar el referido del postulante.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handleCloseDialog();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCloseDialog = () => {
		set_is_open(false);
		formikDialog.resetForm();
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formikDialog.isSubmitting ||
			!formikDialog.values.nombre ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					{/* CAMPO NOMBRE */}
					<Grid item xs={6}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formikDialog.values.nombre}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							required
							helperText={formikDialog.errors.nombre}
							error={Boolean(formikDialog.errors.nombre)}
						/>
					</Grid>

					{/* CAMPO EMAIL */}
					<Grid item xs={6}>
						<TextField
							name="email"
							label="Correo"
							value={formikDialog.values.email}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.email}
							error={Boolean(formikDialog.errors.email)}
						/>
					</Grid>

					{/* CAMPO TELÉFONO */}
					<Grid item xs={6}>
						<TextField
							name="telefono"
							label="Teléfono"
							value={formikDialog.values.telefono}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.telefono}
							error={Boolean(formikDialog.errors.telefono)}
						/>
					</Grid>

					{/* CAMPO EMPRESA */}
					<Grid item xs={6}>
						<TextField
							name="empresa"
							label="Empresa"
							value={formikDialog.values.empresa}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.empresa}
							error={Boolean(formikDialog.errors.empresa)}
						/>
					</Grid>

					{/* CAMPO CARGO */}
					<Grid item xs={6}>
						<TextField
							name="cargo"
							label="Cargo"
							value={formikDialog.values.cargo}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.cargo}
							error={Boolean(formikDialog.errors.cargo)}
						/>
					</Grid>

					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box pr={1}>
								<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
									Agregar referido
								</Button>
							</Box>
							<Box pl={1}>
								<Button variant="contained" onClick={handleCloseDialog} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}