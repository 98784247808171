import React, { Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { AccordionSummary, Collapse, IconButton, Paper, Typography } from "@material-ui/core";
import { Close, ExpandMore, Link, Star, StarBorder } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import AdjuntoDialog from "./adjunto_dialog";
import { ActualizacionRequest, AdjuntoRequest, PostulanteRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function Adjuntos(props) {
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);

	const { postulante_id } = useParams();
	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	/**
	 * Método encargado de verificar si el formulario de Formik tiene algún error.
	 * @returns TRUE: Si el formulario tiene errores. FALSE: Si el normulario no tiene errores.
	 */
	const FormikCheck = () => {
		let check = Object.keys(formik.errors).length > 0;
		return check;
	}

	/**
	 * Método encargado de verificar si el documento adjunto es el mismo CV.
	 * @param {*} adjunto Documento adjunto.
	 * @returns TRUE: El docuemnto es el mismo CV. FALSE: El documento no es el mismo CV.
	 */
	const CurriculumCheckSame = (adjunto) => {
		let nombreCV = formik.values.curriculum_vitae?.nombre;
		let nombreAdjunto = adjunto.adjunto.nombre;
		return nombreCV === nombreAdjunto;
	}

	let columns = [
		{
			title: "Nombre",
			field: "adjunto.nombre_original",
		}, {
			title: "URL",
			field: "adjunto.url",
			align: "center",
			width: 50,
			render: (row) => LinkIconButton(row.adjunto.url),
		}
	];

	let actions = [
		(row) => ({
			tooltip: CurriculumCheckSame(row) ? "Asignado" : "Asignar",
			icon: () => CurriculumCheckSame(row) ? <Star style={{ color: "#731f1f" }} /> : <StarBorder style={{ color: "#731f1f" }} />,
			onClick: (event, row) => handleAsignarAdjunto(row),
			// disabled: CurriculumCheckSame(row),
		}),
		(row) => ({
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarAdjunto(row),
			disabled: CurriculumCheckSame(row),
		}), {
			tooltip: FormikCheck() ? "Agregar (Primero complete la información requerida)" : "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			disabled: FormikCheck(),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de asignar un CV.
	 * @param {*} adjunto Datos del CV.
	 */
	const handleAsignarAdjunto = async (adjunto) => {
		try {
			let check = CurriculumCheckSame(adjunto);
			if (check) {
				notistack.enqueueSnackbar("CV ya asignado.", {
					variant: "info",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} else {
				notistack.enqueueSnackbar("Actualizando el CV del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Se actualiza el CV asignado del postulante y se ingresa nueva actualización.
				let responses = await Promise.all([
					PostulanteRequest.Actualizar(postulante_id, { curriculum_vitae: adjunto.adjunto }),
					ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref, "Nuevo CV asignado"),
				]);

				//Nuevo registro de actualización del postulante.
				let actualizacion = responses[1];
				formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);
				formik.setFieldValue("curriculum_vitae", adjunto.adjunto);

				notistack.enqueueSnackbar("Nuevo CV asignado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			}
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar agregar el adjunto del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} adjunto Datos del adjunto.
	 */
	const handleEliminarAdjunto = async (adjunto) => {
		try {
			//Se elimina el adjunto y se agrega actualización.
			let responses = await Promise.all([
				AdjuntoRequest.Eliminar(adjunto._postulante_ref, adjunto._id),
				ActualizacionRequest.Agregar(adjunto._postulante_ref, usuarioSesion.ref, "Adjunto eliminado"),
			]);

			//Nueva actualización.
			let actualizacion = responses[1];
			let adjuntos = Array.from(formik.values.adjuntos);
			//Se elimina el adjunto.
			adjuntos.splice(adjunto.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("adjuntos", adjuntos);
			formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

			notistack.enqueueSnackbar("Adjunto del postulante eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el adjunto del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE ADJUNTOS */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Adjuntos"
					is_loading={false}
					data={formik.values.adjuntos}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Adjuntos (${formik.values.adjuntos.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG ADJUNTO */}
			<AdjuntoDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}

/**
 * Método encargado de retornar un componente con link del adjunto.
 * @param {*} link 
 * @returns 
 */
function LinkIconButton(link) {
	return (
		<IconButton href={link} target="_blank" rel="noreferrer" size="small">
			<Link />
		</IconButton>
	);
}