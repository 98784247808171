import React, { useContext, useEffect, useState } from "react";
import Page from "./page";
import useSWR from "swr";
import { Constantes } from "../../database/postulantes/utils";
import { PostulanteRequest } from "../../../services/request/reclutamiento/postulantes";
import { CheckPermisosSome } from "../../../services/utilities/common";
import * as Permissions from "../../../constants/permissions";
import { MainContext } from "../../../App";

export default function Index(props) {
	const [Open, SetOpen] = useState();
	const CargadosSWR = useSWR("cargados", (key) => PostulanteRequest.ObtenerCargados(MainCTX.usuarioSesion.ref), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (CargadosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de mis CVs cargados.", CargadosSWR.error);
		}
	}, [MainCTX, CargadosSWR.error]);

	/**
	 * Método encargado de cerrar el Dialog actualmente abierto.
	 */
	const handleCloseDialog = () => {
		OpenState(null);
	}

	/**
	 * Método encargado de abrir el Dialog para agregar una nueva evaluación.
	 * @param {*} estudio Datos de la empresa.
	 */
	const handleAddEvaluacion = () => {
		let currentData = Object.assign({}, OpenState());
		handleCloseDialog();
		OpenState({ tipo: Constantes.EVALUACION_ADD, postulante: currentData.postulante });
	}

	/**
	 * Método encargado de abrir el Dialog para actualizar una evaluación.
	 * @param {*} evaluación Datos de la evaluación.
	 */
	const handleUpdateEvaluacion = (evaluacion) => {
		if (!CheckPermisosSome(MainCTX.permisos, Permissions.CANDIDATOS_EDITAR_COMENTARIOS, Permissions.CANDIDATOS_ELIMINAR_COMENTARIOS)) {
			let currentData = Object.assign({}, OpenState());
			handleCloseDialog();
			OpenState({ tipo: Constantes.EVALUACION_UPDATE, postulante: currentData.postulante, evaluacion });
		} else {
			MainCTX.ShowSnackbar("No tiene los permisos necesarios.", new Error("Sin permisos necesarios"));
		}
	}

	/**
	 * Función para interactuar con el State.
	 * @param {*} open Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const OpenState = (open = undefined) => {
		if (open !== undefined) {
			SetOpen(open);
		} else {
			return Open;
		}
	}

	return (
		<Page
			open_state={OpenState}
			postulantes={CargadosSWR.data}
			permisos={MainCTX.permisos}
			is_loading={CargadosSWR.isValidating}
			handle_close_dialog={handleCloseDialog}
			handle_add_evaluacion={handleAddEvaluacion}
			handle_update_evaluacion={handleUpdateEvaluacion}
			handle_actualizar_local={CargadosSWR.mutate}
		/>
	);
}