import React from "react";
import { Grid } from "@material-ui/core";
import Personal from "./formularios/personal";
import PersonalAdicional from "./formularios/personal_adicional";
import Ubicacion from "./formularios/ubicacion";
import Contacto from "./formularios/contacto";
import Estudios from "./formularios/estudios";
import Experiencias from "./formularios/experiencias";
import Referidos from "./formularios/referidos";
import Adjuntos from "./formularios/adjuntos";
import Asignaciones from "./formularios/asignaciones";
import Actualizaciones from "./formularios/actualizaciones";

export default function Formulario(props) {

	return (
		<Grid container spacing={1}>
			{/* SECCIÓN PERSONAL */}
			<Grid item xs={12}>
				<Personal />
			</Grid>

			{/* SECCIÓN CONTACTOS */}
			<Grid item xs={12}>
				<Contacto />
			</Grid>

			{/* SECCIÓN UBICACIÓN */}
			<Grid item xs={12}>
				<Ubicacion />
			</Grid>

			{/* SECCIÓN PERSONAL ADICIONAL */}
			<Grid item xs={12}>
				<PersonalAdicional />
			</Grid>

			{/* SECCIÓN ESTUDIOS */}
			<Grid item xs={12}>
				<Estudios />
			</Grid>

			{/* SECCIÓN EXPERIENCIAS */}
			<Grid item xs={12}>
				<Experiencias />
			</Grid>

			{/* SECCIÓN REFERIDOS */}
			<Grid item xs={12}>
				<Referidos />
			</Grid>

			{/* SECCIÓN ADJUNTOS */}
			<Grid item xs={12}>
				<Adjuntos />
			</Grid>

			{/* SECCIÓN ASIGNACIONES */}
			<Grid item xs={12}>
				<Asignaciones />
			</Grid>

			{/* SECCIÓN ACTUALIZACIONES */}
			<Grid item xs={12}>
				<Actualizaciones />
			</Grid>
		</Grid>
	);
}