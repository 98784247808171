import React from "react";
import useSWR from "swr";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import DialogTitleComponent from "../../../../components/dialog_title";
import PostulanteAsignar from "../../../../components/postulante_asignar";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";

/**
 * Componente para la asignación múltiple de postulantes.
 * @param {*} postulantes Colección de postulantes.
 * @param {*} handle_close Método encargado de cerrar el popup.
 * @returns Component.
 */
export default function DialogAsignacionMultiple(props) {
	const {
		postulantes,
		handle_close,
	} = props;

	const LicitacionesProyectosSWR = useSWR("licitaciones_proyectos", LicitacionProyectoRequets.Obtener, { revalidateOnFocus: false });

	return (
		<Dialog open={true} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent>Asignación Múltiple</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Ingresar toda la información de la nueva asignación para los ${postulantes.length} postulantes seleccionados.`}
				</DialogContentText>
				<PostulanteAsignar
					licitaciones_proyectos_swr={LicitacionesProyectosSWR}
					asignaciones={[]}
					postulantes_ids={postulantes.map(p => p._id)}
					handle_asignado={null}
					handle_cancelado={handle_close}
				/>
			</DialogContent>
		</Dialog>
	);
}