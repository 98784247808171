import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { useSnackbar } from "notistack";
import DialogTitleComponent from "../dialog_title";
import { CargoRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import { NombreCompleto } from "../../services/utilities/formatUtils";
import { MainContext } from "../../App";

/**
 * Dialog para elimicar un cargo.
 * @param {*} is_open TRUE: Dialog abierto | FALSE: Dialog cerrado.
 * @param {*} data Datos del cargo.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function DialogDeleteCargo(props) {
	const {
		is_open,
		data,
		handle_close,
	} = props;

	const { ShowSnackbar } = useContext(MainContext);
	const notistack = useSnackbar();

	/**
	 * Método encargado de eliminar el cargo.
	 */
	const handleDelete = async () => {
		try {
			notistack.enqueueSnackbar("Eliminando el cargo...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			await CargoRequest.Eliminar(data._licitacion_proyecto_ref, data._id);
			notistack.closeSnackbar();
			ShowSnackbar("Cargo eliminado exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar eliminar el cargo.", error);
		} finally {
			handle_close();
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Eliminar Cargo</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Debe confirmar la eliminación del cargo ${data ? `${data.nombre}, del mandante ${data.mandante}, cuyo responsable es ${NombreCompleto(data._reclutador_ref)}` : ""}.`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={handleDelete} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}