import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { TemplateRequest } from "../../../../services/request/reclutamiento/cartas_oferta";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const MainCTX = useContext(MainContext);
	const CartasOfertaSWR = useSWR("template_cartas_oferta", TemplateRequest.Obtener);

	useEffect(() => {
		if (CartasOfertaSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de las cartas de oferta.", CartasOfertaSWR.error);
		}
	}, [MainCTX, CartasOfertaSWR.error]);

	return (
		<Page
			cartas_oferta={CartasOfertaSWR.data}
			permisos={MainCTX.permisos}
			is_loading={CartasOfertaSWR.isValidating}
			mutate_cartas_oferta={CartasOfertaSWR.mutate}
		/>
	);
}