import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import DialogTitleComponent from "../../../../components/dialog_title";
import { Eliminar } from "../../../../services/request/reclutamiento/seleccionables";

export default function DialogDelete(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_estados_civiles,
	} = props;

	const notistack = useSnackbar();

	const handleDelete = async () => {
		try {
			notistack.enqueueSnackbar("Eliminando el estado civil...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			await Eliminar(data._id);
			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Estado civil eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el estado civil.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
			});
		} finally {
			mutate_estados_civiles();
			handle_close();
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Eliminar Estado Civil</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Debe confirmar la eliminación del estado civil "${data ? data.valor : ""}".`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={handleDelete} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}