import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Método encargado de obtener el nuevo código de cargo.
 * @param {*} tipo Tipo licitación o proyecto.
 * @returns Código de cargo.
 */
export async function GetCargoCodigo(tipo) {
	try {
		let url = `${RECLUTAMIENTO}/utilidades/obtener/cargo-codigo?tipo=${tipo}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener el nuevo código de licitación.
 * @param {*} gerencia Gerencia de la licitación.
 * @returns Código de licitación.
 */
export async function GetLicitacionCodigo(gerencia) {
	try {
		let url = `${RECLUTAMIENTO}/utilidades/obtener/licitacion-codigo?gerencia=${gerencia}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}