import React, { Fragment, useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CargoInfo from "./components/cargoInfo";
import PostulanteInfo from "./components/postulanteInfo";
import VistaEntrevista from "./components/vistaEntrevista";
import Comentarios from "./components/comentarios";
import DialogCartaOferta from "./components/cartaOfertaDialog";
import PostulanteDocumentos from "../postulante_documentos";
import { AsignacionRequest, AdjuntoRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import * as PostulantesRequests from "../../services/request/reclutamiento/postulantes";
import { PreviousRoute } from "../../services/utilities/common";
import { ASIGNACION_ESTADOS, TIPO_PROYECTO } from "../../constants/contexts";
import { HOME } from "../../constants/routes";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../App";
import { EstadoAsignacion } from "../../services/utilities/formatUtils";

export default function PostulanteInteractuar(props) {
	const { licitacion_id, proyecto_id, cargo_id, asignacion_id } = useParams();
	const LicitacionProyectoID = licitacion_id || proyecto_id;
	const { ShowSnackbar } = useContext(MainContext);
	const history = useHistory();
	const [Asignacion, SetAsignacion] = useState(null);
	const [CargoAdjuntos, SetCargoAdjuntos] = useState([]);
	const [PostulanteAdjuntos, SetPostulanteAdjuntos] = useState([]);
	const [IsOpenDialogCartaOferta, SetIsOpenDialogCartaOferta] = useState(false);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				await AsignacionRequest.Actualizar(LicitacionProyectoID, cargo_id, values);
				ShowSnackbar("Actualización de la asignación exitosa");
			} catch (error) {
				ShowSnackbar("Error al intentar actualizar la asignación", error);
			} finally {
				//Para recargar los datos después de un cambio de estado.
				LoadData();
			}
		}
	});

	/**
	 * Método encargado de cargar todos los datos asociados a esta asignación.
	 */
	const LoadData = async () => {
		try {
			let asignacion = await AsignacionRequest.ObtenerPorID(LicitacionProyectoID, cargo_id, asignacion_id);
			SetAsignacion(asignacion);
			formik.setFieldValue("_id", asignacion._id);
			formik.setFieldValue("fuente", asignacion.fuente);
			formik.setFieldValue("situacion_laboral", asignacion.situacion_laboral);
			formik.setFieldValue("titulo", asignacion.titulo);
			formik.setFieldValue("institucion", asignacion.institucion);
			formik.setFieldValue("exp_general", asignacion.exp_general);
			formik.setFieldValue("exp_especifica", asignacion.exp_especifica);
			formik.setFieldValue("evaluacion", asignacion.evaluacion);
			formik.setFieldValue("estado", asignacion.estado);
			let cargoAdjuntos = await AdjuntoRequest.Obtener(LicitacionProyectoID, cargo_id);
			SetCargoAdjuntos(cargoAdjuntos.map(adjunto => adjunto.adjunto));
			let postulanteAdjuntos = await PostulantesRequests.AdjuntoRequest.Obtener(asignacion._postulante_ref._id);
			SetPostulanteAdjuntos(postulanteAdjuntos);
		} catch (error) {
			throw error;
		}
	}

	useEffect(() => {
		LoadData()
			.catch(error => {
				ShowSnackbar("Error al intentar obtener los datos de la asignación", error);
				history.push(HOME);
			});
	}, [ShowSnackbar, history, LicitacionProyectoID, cargo_id, asignacion_id]);

	/**
	 * Método encargado de volver al listado de postulantes asignados.
	 */
	const handleVolver = () => {
		let previousRoute = PreviousRoute(history, 2);
		history.push(previousRoute);
	}

	/**
	 * Método encargado de cambiar el estado del postulante, en la asignación.
	 * @param {*} estado Nuevo estado del postulante.
	 */
	const cambioEstado = (estado) => {
		formik.setFieldValue("estado", estado);
	}

	/**
	 * Método encargado de retornar el color para el botón.
	 * @param {*} estado_check Estado de la asignación a verificar.
	 * @returns Color.
	 */
	const colorEstado = (estado_check) => {
		let estado = formik.values.estado;
		let check = estado.toUpperCase() === String(estado_check).toUpperCase();
		return check ? "secondary" : "default";
	}

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={6} container spacing={2}>
					<Grid item xs={12}>
						{/* CARGANDO DATOS */}
						{!Asignacion && (
							<Skeleton variant="rect" height={300} />
						)}
						{/* DATOS DEL CARGO */}
						{Asignacion && (
							<CargoInfo
								cargo={Asignacion._cargo_ref}
								licitacion_proyecto={Asignacion._licitacion_proyecto_ref}
								is_licitacion={Boolean(licitacion_id)}
								is_proyecto={Boolean(proyecto_id)}
								responsable={Asignacion._responsable_ref}
								reclutador={Asignacion._reclutador_ref}
								asignador={Asignacion._asignador_ref}
								adjuntos={CargoAdjuntos}
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						{/* CARGANDO DATOS */}
						{!Asignacion && (
							<Skeleton variant="rect" height={150} />
						)}
						{/* DATOS DEL POSTULANTE */}
						{Asignacion && (
							<PostulanteInfo
								postulante={Asignacion._postulante_ref}
							/>
						)}
					</Grid>
					{/* VISTA DE ENTREVISTA */}
					<Grid item xs={12}>
						<VistaEntrevista
							formik={formik}
						/>
					</Grid>
					<Grid item xs={12}>
						{!Asignacion && (
							<Skeleton variant="rect" height={100} />
						)}
						{/* COLECCIÓN DE COMENTARIOS */}
						{Asignacion && (
							<Comentarios
								postulante={Asignacion._postulante_ref}
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						{Asignacion && (
							<Box display="flex" justifyContent="center">
								<ButtonGroup variant="contained">
									{/* BOTÓN ESTADO PENDIENTE */}
									<Button onClick={() => cambioEstado(ASIGNACION_ESTADOS.PENDIENTE)} color={colorEstado(ASIGNACION_ESTADOS.PENDIENTE)}>
										{EstadoAsignacion(ASIGNACION_ESTADOS.PENDIENTE, Asignacion.tipo)}
									</Button>
									{/* BOTÓN ESTADO SHORT LIST */}
									<Button onClick={() => cambioEstado(ASIGNACION_ESTADOS.SHORT_LIST)} color={colorEstado(ASIGNACION_ESTADOS.SHORT_LIST)}>
										{EstadoAsignacion(ASIGNACION_ESTADOS.SHORT_LIST, Asignacion.tipo)}
									</Button>
									{/* BOTÓN ESTADO SELECCIONADO */}
									<Button onClick={() => cambioEstado(ASIGNACION_ESTADOS.SELECCIONADO)} color={colorEstado(ASIGNACION_ESTADOS.SELECCIONADO)}>
										{EstadoAsignacion(ASIGNACION_ESTADOS.SELECCIONADO, Asignacion.tipo)}
									</Button>
									{/* BOTÓN ESTADO FUERA DE PROCESO */}
									<Button onClick={() => cambioEstado(ASIGNACION_ESTADOS.FUERA_PROCESO)} color={colorEstado(ASIGNACION_ESTADOS.FUERA_PROCESO)}>
										{EstadoAsignacion(ASIGNACION_ESTADOS.FUERA_PROCESO, Asignacion.tipo)}
									</Button>
								</ButtonGroup>
							</Box>
						)}
					</Grid>
				</Grid>
				<Grid item xs={6}>
					{/* CARGANDO DATOS */}
					{!Asignacion && (
						<Skeleton variant="rect" height={500} />
					)}
					{/* ADJUNTOS DEL POSTULANTE */}
					{Asignacion && (
						<PostulanteDocumentos
							curriculum_vitae={Asignacion._postulante_ref.curriculum_vitae}
							adjuntos={PostulanteAdjuntos}
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
						{/* BOTÓN PARA GENERAR CARTA DE OFERTA */}
						{Asignacion && Asignacion.estado === ASIGNACION_ESTADOS.SELECCIONADO && Asignacion.tipo === TIPO_PROYECTO && (
							<Button onClick={() => SetIsOpenDialogCartaOferta(true)} variant="outlined" color="primary" style={{ margin: 10 }}>
								Carta Oferta
							</Button>
						)}
						{/* BOTÓN PARA GUARDAR LOS CAMBIOS */}
						<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary" style={{ margin: 10 }}>
							Guardar
						</Button>
						{/* BOTÓN PARA REGRESAS A LA VISTA ANTERIOR */}
						<Button onClick={handleVolver} variant="contained" color="secondary" style={{ margin: 10 }}>
							Volver
						</Button>
					</Box>
				</Grid>
			</Grid>

			{/* DIALOG PARA LA CARTA DE OFERTA GENERADA DEL POSTULANTE */}
			<DialogCartaOferta
				is_open={IsOpenDialogCartaOferta}
				asignacion={Asignacion}
				handle_close={() => SetIsOpenDialogCartaOferta(false)}
			/>
		</Fragment>
	);
}