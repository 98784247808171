import React, { Fragment, useEffect, useState } from "react";
import { Fab, Typography } from "@material-ui/core";
import { IconByMimeType } from "../../services/utilities/formatUtils";

/**
 * Componente para mostrar los documentos de un postulante.
 * @param {*} curriculum_vitae CV del postulante.
 * @param {*} adjuntos Documentos adicionales del postulante.
 * @returns Component.
 */
export default function PostulanteDocumentos(props) {
	const {
		curriculum_vitae,
		adjuntos,
	} = props;

	const [Doc, SetDoc] = useState(null);
	const [Docs, SetDocs] = useState([]);

	useEffect(() => {
		let documentos = [];
		if (curriculum_vitae) {
			curriculum_vitae["nombre_adjunto"] = "Curriculum Vitae";
			documentos.push(curriculum_vitae);
		}
		if (adjuntos && adjuntos.length > 0) {
			let adjuntosMap = Array.from(adjuntos).map((adjunto, index) => ({ ...adjunto.adjunto, nombre_adjunto: `Adjunto ${index + 1}` }));
			documentos.push(...adjuntosMap);
		}
		SetDocs(documentos);
	}, [curriculum_vitae, adjuntos]);

	/**
	 * Método encargado de generar el botón para acceder al documento.
	 * @param {*} nombre Nombre del documento.
	 * @param {*} file Datos del documento online.
	 * @param {*} key Key única para el componente.
	 * @returns Componente.
	 */
	const fabComponent = (nombre, file, key) => {
		if (file) {
			let icon = IconByMimeType(file.mime_type);
			return (
				<Fab onClick={() => SetDoc(file)} variant="extended" size="small" color="primary" style={{ margin: 2 }} key={key}>
					{icon}
					{nombre}
				</Fab>
			);
		}
	}

	return (
		<Fragment>
			<Typography variant="body1">
				DOCUMENTOS
			</Typography>

			{/* BOTONES DOCUMENTOS ADJUNTOS */}
			{Docs.map((adjunto, index) => (
				fabComponent(adjunto.nombre_adjunto, adjunto, `fab_key_${index}`)
			))}

			{/* DOCUMENTO NO ENCONTRADO */}
			{!Doc && (
				<Typography variant="h6" align="center" >
					Documento no encontrado
				</Typography>
			)}

			{/* DOCUMENTO TIPO PDF */}
			{Doc && Doc.mime_type === "application/pdf" && (
				<embed src={Doc.url} style={{ width: "100%", maxWidth: "100% ", height: "90%", maxHeight: "100%" }} />
			)}

			{/* DOCUMENTO TIPO IMAGEN */}
			{Doc && String("image/gif, image/png, image/jpeg, image/jpg").includes(Doc.mime_type) && (
				<img src={Doc.url} alt="Imagen" style={{ margin: 5, maxWidth: "98%" }} />
			)}
		</Fragment>
	);
}