import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Box, Button, Grid, Menu, MenuItem, Paper, Tooltip, Typography } from "@material-ui/core";
import { CargoRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { ObtenerDificultadPuntaje, ToTitleCase } from "../../../services/utilities/formatUtils";
import { CARGO_ESTADOS, GERENCIAS_VISIBLES } from "../../../constants/contexts";
import { MainContext } from "../../../App";

export default function Desempeno(props) {
	const [anchorElMes, setAnchorElMes] = useState(null);
	const [MesSelect, SetMesSelect] = useState(null);
	const [Cargos, SetCargos] = useState([]);
	const Meses = Array.from({ length: 6 }, (_, i) => Moment().add(-i, "month").utc());
	const CargosSWR = useSWR("cargos_kpi", (key) => CargoRequest.Obtener({ is_eliminado: false }, POPULATE_CARGOS));
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (CargosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los cargos.", CargosSWR.error);
		}
	}, [MainCTX, CargosSWR.error]);

	useEffect(() => {
		if (CargosSWR.data) {
			let cargos = Array.from(CargosSWR.data)
				//Filtro para los cargos mayores a la fecha minima.
				.filter(c => Moment(c.fecha_creacion).isSameOrAfter(Meses[5], "months"))
				//Filtro para los cargos menores a la fecha maxima.
				.filter(c => Moment(c.fecha_creacion).isSameOrBefore(Meses[0], "months"))
			SetCargos(cargos);
		}
	}, [CargosSWR.data]);

	/**
	 * Método encargado de asignar la opción seleccionada.
	 * @param {*} mes Opción seleccionada.
	 */
	const handleMes = (mes) => {
		SetMesSelect(mes);
		setAnchorElMes(null);
	}

	/**
	 * Método encargado de formatear una fecha.
	 * @param {*} date Datos de la fecha.
	 * @returns Fecha formateada.
	 */
	const FormatearFecha = (date) => `${ToTitleCase(Moment(date).format("MMMM"))} (${Moment(date).format("YYYY")})`;

	/**
	 * Método encargado de obtener los cargos, filtrados.
	 * @param {string} gerenciaID ID de la gerencia.
	 * @param {string} estado Estado de los cargos.
	 * @returns Cargos.
	*/
	const ObtenerCargos = (gerenciaID, estado) => {
		if (!gerenciaID || !estado) {
			throw new Error("Faltan datos.");
		}
		const cargos = Cargos.filter(c => c._gerencia_ref._id === gerenciaID)
			.filter(c => MesSelect !== null ? Moment(c.fecha_creacion).isSame(Meses[MesSelect], "month") : true)
			.filter(c => estado ? c.estado === estado : true);
		return cargos;
	}

	/**
	 * Método encargado de obtener los datos de dificultad KPI.
	 * @param {string} gerenciaID ID de la gerencia.
	 * @param {string} estado Estado de los cargos.
	 * @returns KPI de dificultad.
	 */
	const DificultadKPI = (gerenciaID, estado) => {
		const cargos = ObtenerCargos(gerenciaID, estado);
		const promedioPuntaje = cargos.reduce((a, b) => a + ObtenerDificultadPuntaje(b), 0);
		const resultado = (promedioPuntaje / cargos.length) * 5.25;
		return isNaN(resultado) ? "---" : `${resultado.toFixed(0)}%`;
	}

	/**
	 * Método encargado de obtener los datos de eficiencia KPI.
	 * @param {string} gerenciaID ID de la gerencia.
	 * @returns KPI de eficiencia.
	 */
	const EficienciaKPI = (gerenciaID) => {
		const cantActivos = ObtenerCargos(gerenciaID, CARGO_ESTADOS.ACTIVO).length;
		const cantCerrados = ObtenerCargos(gerenciaID, CARGO_ESTADOS.CERRADO).length;
		const eficiencia = (cantCerrados / (cantActivos + cantCerrados)) * 100;
		return isNaN(eficiencia) || eficiencia === 0 ? "---" : `${eficiencia.toFixed(2)}%`;
	}

	/**
	 * Método encargado de obtener los datos de merma KPI.
	 * @param {string} gerenciaID ID de la gerencia.
	 * @returns KPI de merma.
	 */
	const MermaKPI = (gerenciaID) => {
		const cantDetenidos = ObtenerCargos(gerenciaID, CARGO_ESTADOS.DETENIDO).length;
		const cantFallidos = ObtenerCargos(gerenciaID, CARGO_ESTADOS.FALLIDO).length;
		const total = cantDetenidos + cantFallidos;
		return total === 0 ? 0 : `${total}`;
	}

	return (
		<Fragment>
			{/* TITULO DE SECCION */}
			<Typography variant="h4">KPI Desempeño</Typography>
			<Grid container style={{ padding: 10 }}>
				{/* COLUMNA DE GERENCIAS */}
				<Grid item xs={2} container>
					{/* SLECTOR DE MES */}
					<Grid item xs={12}>
						{/* BOTÓN DE MES SELECICONADO */}
						<Button onClick={(event) => setAnchorElMes(event.currentTarget)} variant="outlined" color="primary" style={{ height: 60, width: "96%", margin: 5 }}>
							{/* TEXTO CON TODO EL PERIODO */}
							{MesSelect === null && `Datos de ${FormatearFecha(Meses[5])} a ${FormatearFecha(Meses[0])}`}
							{/* TEXTO CON MES SELECCIONADO */}
							{MesSelect !== null && `Datos de ${FormatearFecha(Meses[MesSelect])}`}
						</Button>
					</Grid>
					{/* LISTADO DE GERENCIAS */}
					{GERENCIAS_VISIBLES.map((gerencia, index) => (
						<Grid item xs={12} key={`grid_item_${index}`}>
							{/* SIGLA DE LA GERENCIA */}
							{TableDataComponent(gerencia.sigla, "", true)}
						</Grid>
					))}
				</Grid>

				{/* COLUMNA DE VALORES */}
				<Grid item xs={10} container>
					<Grid item xs={2}>
						{/* COLUMNA KPI VACANTES ACTIVAS */}
						{TableDataComponent("Activas", TOOLTIP_KPI_ACTIVOS, true)}
					</Grid>
					<Grid item xs={2}>
						{/* COLUMNA KPI DIFICULTAD VACANTES ACTIVAS */}
						{TableDataComponent("Dificultad Activas", TOOLTIP_KPI_DIFICULTAD_ACTIVOS, true)}
					</Grid>
					<Grid item xs={2}>
						{/* COLUMNA KPI VACANTES CERRADAS */}
						{TableDataComponent("Cerradas", TOOLTIP_KPI_CERRADOS, true)}
					</Grid>
					<Grid item xs={2}>
						{/* COLUMNA KPI DIFICULTAD VACANTES CERRADAS */}
						{TableDataComponent("Dificultad Cerradas", TOOLTIP_KPI_DIFICULTAD_CERRADOS, true)}
					</Grid>
					<Grid item xs={2}>
						{/* COLUMNA KPI EFICIENCIA */}
						{TableDataComponent("Eficiencia", TOOLTIP_KPI_EFICIENCIA, true)}
					</Grid>
					<Grid item xs={2}>
						{/* COLUMNA KPI MERMA */}
						{TableDataComponent("Merma", TOOLTIP_KPI_MERMA, true)}
					</Grid>

					{GERENCIAS_VISIBLES.map((gerencia, index) => (
						<Fragment key={`grids_gerencia_${index}`}>
							<Grid item xs={2}>
								{/* VALOR KPI VACANTES ACTIVAS */}
								{TableDataComponent(ObtenerCargos(gerencia.id, CARGO_ESTADOS.ACTIVO).length)}
							</Grid>
							<Grid item xs={2}>
								{/* VALOR KPI DIFICULTAD VACANTES ACTIVAS */}
								{TableDataComponent(DificultadKPI(gerencia.id, CARGO_ESTADOS.ACTIVO))}
							</Grid>
							<Grid item xs={2}>
								{/* VALOR KPI VACANTES CERRADAS */}
								{TableDataComponent(ObtenerCargos(gerencia.id, CARGO_ESTADOS.CERRADO).length)}
							</Grid>
							<Grid item xs={2}>
								{/* VALOR KPI DIFICULTAD VACANTES CERRADAS */}
								{TableDataComponent(DificultadKPI(gerencia.id, CARGO_ESTADOS.CERRADO))}
							</Grid>
							<Grid item xs={2}>
								{/* VALOR KPI EFICIENCIA */}
								{TableDataComponent(EficienciaKPI(gerencia.id))}
							</Grid>
							<Grid item xs={2}>
								{/* VALOR KPI MERMA */}
								{TableDataComponent(MermaKPI(gerencia.id), `${ObtenerCargos(gerencia.id, CARGO_ESTADOS.DETENIDO).length} Detenidos, ${ObtenerCargos(gerencia.id, CARGO_ESTADOS.FALLIDO).length} Fallidos`)}
							</Grid>
						</Fragment>
					))}
				</Grid>
			</Grid >


			{/* MENÚ DE MESES */}
			<Menu open={Boolean(anchorElMes)} anchorEl={anchorElMes} onClose={() => setAnchorElMes(null)} variant="menu" keepMounted>
				{/* SELECTOR DE TODO EL PERIODO */}
				<MenuItem onClick={() => handleMes(null)} selected={MesSelect === null}>
					Todo el periodo
				</MenuItem>
				{/* SELECTOR DE LOS MESES */}
				{Meses.map((mes, index) => (
					<MenuItem onClick={() => handleMes(index)} selected={MesSelect === index} key={`menu_item_${index}`}>
						{FormatearFecha(mes)}
					</MenuItem>
				))}
			</Menu>
		</Fragment >
	);
}

/**
 * Método encargado de generar un componente para la tabla de KPI.
 * @param {String} dato Contenido.
 * @param {String} tooltip (OPCIONAL) Contenido del tooltip para los headers.
 * @param {Boolean} isHeader (OPCIONAL) FLAG para diferencia headers de otros.
 * @returns Component.
 */
function TableDataComponent(dato, tooltip = "", isHeader = false) {
	return (
		<Paper elevation={10} style={{ backgroundColor: isHeader ? "#731f1f" : "white", borderRadius: "0px 25px 0px 0px", margin: 5 }}>
			<Box display="flex" justifyContent="center" alignItems="center" style={{ height: 60 }}>
				{/* ES TIPO HEADER */}
				{isHeader === false && (
					<Tooltip title={tooltip} placement="bottom">
						<Typography variant="h6" align="center">{dato}</Typography>
					</Tooltip>
				)}
				{/* NO ES TIPO HEADER */}
				{isHeader === true && (
					<Tooltip title={tooltip} placement="bottom">
						<Typography variant="h6" align="center" style={{ color: "white" }}>{dato}</Typography>
					</Tooltip>
				)}
			</Box>
		</Paper >
	);
}

/**
 * CONSTANTES
 */

const TOOLTIP_KPI_ACTIVOS = "N° vacantes activas";
const TOOLTIP_KPI_DIFICULTAD_ACTIVOS = "Promedio dificultad de las vacantes activas";
const TOOLTIP_KPI_CERRADOS = "N° vacantes cerradas";
const TOOLTIP_KPI_DIFICULTAD_CERRADOS = "Promedio dificultad de las vacantes cerradas";
const TOOLTIP_KPI_EFICIENCIA = "N° vacantes cerradas / (N° vacantes cerradas + N° vacantes activos)";
const TOOLTIP_KPI_MERMA = "N° vacantes detenidas + N° vacantes fallidas";
const POPULATE_CARGOS = "/exp_especifica/turno/modalidad/jornada/_gerencia_ref/estado/fecha_creacion";