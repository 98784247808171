import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import DialogTitleComponent from "../../../../components/dialog_title";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { ObtenerReclutadores } from "../../../../services/request/reclutamiento/usuariosPlataforma";
import { GetPersonas } from "../../../../services/request/usuarios/personas";
import { GetGerencias } from "../../../../services/request/portafolio/gerencias";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../../../App";

export default function DialogUpdateLicitacion(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_licitaciones,
	} = props;

	const [IsLoading, SetIsLoading] = useState(false);
	const { ShowSnackbar } = useContext(MainContext);
	const notistack = useSnackbar();
	const ReclutadoresSWR = useSWR("reclutadores", (key) => ObtenerReclutadores(), { revalidateOnFocus: false });
	const PersonasSWR = useSWR("personas", (key) => GetPersonas({ is_gsuite_talana: true }), { revalidateOnFocus: false });
	const GerenciasSWR = useSWR("gerencias", (key) => GetGerencias(), { revalidateOnFocus: false });

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Agregando la nueva licitación...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				values._responsable_ref = values.responsable._id;
				values._reclutador_ref = values.reclutador._id;
				values._gerencia_ref = values.gerencia._id;

				await LicitacionProyectoRequets.Actualizar(values);
				notistack.closeSnackbar();
				ShowSnackbar("Licitación agregada exitosamente.");
			} catch (error) {
				console.error(error);
				ShowSnackbar("Error al intentar agregar la licitación.", error);
			} finally {
				helper.resetForm();
				mutate_licitaciones();
				handle_close();
			}
		}
	});

	useEffect(() => {
		const LoadData = async () => {
			try {
				if (data && PersonasSWR.data && GerenciasSWR.data) {
					SetIsLoading(true);
					let licitacion = await LicitacionProyectoRequets.ObtenerPorID(data._id);
					formik.setValues({
						_id: licitacion._id,
						nombre: licitacion.nombre,
						codigo: licitacion.codigo,
						responsable: licitacion._responsable_ref,
						reclutador: licitacion._reclutador_ref,
						gerencia: licitacion._gerencia_ref,
						mandante: licitacion.mandante,
						descripcion: licitacion.descripcion,
						estado: licitacion.estado,
						cargos: licitacion.cargos,
						fecha_creacion: licitacion.fecha_creacion,
					});
				}
			} catch (error) {
				throw error;
			}
		}

		LoadData()
			.catch(error => ShowSnackbar("Error al intentar cargar los datos de la licitación.", error))
			.finally(() => SetIsLoading(false));
	}, [GerenciasSWR.data, PersonasSWR.data, ShowSnackbar, data]);

	useEffect(() => {
		if (ReclutadoresSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los reclutadores.", ReclutadoresSWR.error);
		}
		if (PersonasSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de las personas.", PersonasSWR.error);
		}
		if (GerenciasSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de las gerencias.", GerenciasSWR.error);
		}
	}, [ShowSnackbar, ReclutadoresSWR, PersonasSWR, GerenciasSWR]);

	/**
	 * Método encargado de verificar si se está cargando algún dato.
	 * @returns TRUE: Se están cargando datos. FALSE: No se están cargando datos.
	 */
	const IsLoadingData = () => {
		return IsLoading || PersonasSWR.isValidating || GerenciasSWR.isValidating;
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Actualizar Licitación</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Ingrese la información requerida de la licitación y luego guarde los cambios."}
				</DialogContentText>

				{/* SI ESTÁ CARGANDO LOS DATOS DE LA LICITACIÓN */}
				{IsLoadingData() && (
					<Box display="flex" justifyContent="center">
						<Box>
							<CircularProgress />
						</Box>
					</Box>
				)}

				{/* SI YA CARGÓ LOS DATOS DE LA LICITACIÓN */}
				{!IsLoadingData() && (
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								name="nombre"
								label="Nombre"
								value={formik.values.nombre}
								onChange={formik.handleChange}
								variant="outlined"
								required
								fullWidth
								size="small"
								helperText={formik.errors.nombre}
								error={Boolean(formik.errors.nombre)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="codigo"
								label="Código"
								value={formik.values.codigo}
								onChange={formik.handleChange}
								variant="outlined"
								required
								disabled
								fullWidth
								size="small"
								helperText={formik.errors.codigo}
								error={Boolean(formik.errors.codigo)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								value={formik.values.responsable}
								options={PersonasSWR.data?.filter(p => p.is_responsable) || []}
								getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
								onChange={(event, value) => formik.setFieldValue("responsable", value)}
								loading={PersonasSWR.isValidating}
								noOptionsText="Sin opciones"
								// disabled={!PersonasSWR}
								size="small"
								renderInput={(params) => (
									<TextField
										label="Responsable"
										helperText={formik.errors.responsable}
										error={Boolean(formik.errors.responsable)}
										variant="outlined"
										required
										{...params}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								options={ReclutadoresSWR.data || []}
								value={formik.values.reclutador}
								getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
								onChange={(event, value) => formik.setFieldValue("reclutador", value)}
								loading={ReclutadoresSWR.isValidating}
								noOptionsText="Sin opciones"
								disabled={!ReclutadoresSWR}
								size="small"
								renderInput={(params) => (
									<TextField
										label="Reclutador"
										helperText={formik.errors.reclutador}
										error={Boolean(formik.errors.reclutador)}
										variant="outlined"
										required
										{...params}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								options={GerenciasSWR.data || []}
								value={formik.values.gerencia}
								getOptionLabel={(gerencia) => `[${gerencia.sigla}] ${gerencia.nombre}`}
								onChange={(event, value) => formik.setFieldValue("gerencia", value)}
								loading={GerenciasSWR.isValidating}
								noOptionsText="Sin opciones"
								disabled
								size="small"
								renderInput={(params) => (
									<TextField
										label="Gerencia"
										helperText={formik.errors.gerencia}
										error={Boolean(formik.errors.gerencia)}
										variant="outlined"
										required
										{...params}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="mandante"
								label="Mandante"
								value={formik.values.mandante}
								onChange={formik.handleChange}
								variant="outlined"
								required
								fullWidth
								size="small"
								helperText={formik.errors.mandante}
								error={Boolean(formik.errors.mandante)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="descripcion"
								label="Descripción"
								value={formik.values.descripcion}
								onChange={formik.handleChange}
								variant="outlined"
								multiline
								rows={4}
								required
								fullWidth
								size="small"
								helperText={formik.errors.descripcion}
								error={Boolean(formik.errors.descripcion)}
							/>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}