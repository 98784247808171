import { AsignacionRequest, CargoRequest } from "./licitaciones_proyectos";
import { GERENCIAS_SUPERIORES } from "../../../constants/contexts";
import { FormatearFecha, AñosDesde, EstadoAsignacion } from "../../utilities/formatUtils";
import { JsonToExcel } from "../../utilities/data_export";

/**
 * Método encargado de generar un Excel con los datos de las vacantes. GPO tiene acceso a todo.
 * @param {*} gerenciaID ID de la gerencia del reclutador.
 */
export async function DescargarVacantes(gerenciaID) {
	try {
		const populates = "/_licitacion_proyecto_ref/_reclutador_ref";
		let cargos = await CargoRequest.Obtener({ is_eliminado: false }, populates);
		if (!GERENCIAS_SUPERIORES.includes(gerenciaID)) {
			cargos = cargos.filter(c => c._reclutador_ref.gerencia_ref === gerenciaID);
		}

		let cargosMap = cargos.map(c => ({
			"Tipo": c._licitacion_proyecto_ref.tipo,
			"Status": c._licitacion_proyecto_ref.estado,
			"Código": c._licitacion_proyecto_ref.codigo,
			"Nombre": c._licitacion_proyecto_ref.nombre,
			"Código Cargo": c.codigo,
			"Cargo Buscado": c.nombre,
			"Reclutador": c._reclutador_ref.nombre_abreviado,
			"Estado": c.estado,
			"Vacantes": c.cant_vacantes,
			"Asignados": c.cant_asignados,
			"Seleccionados": c.cant_seleccionados,
			"Fecha Requerimiento": FormatearFecha(c.fecha_requerimiento),
		}));

		//Se genera el archivo Excel.
		JsonToExcel(cargosMap, "Vacantes", "Vacantes");
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de generar un Excel con los datos de las asignaciones. GPO tiene acceso a todo.
 * @param {*} gerenciaID ID de la gerencia del reclutador.
 */
export async function DescargarAsignados(gerenciaID) {
	try {
		const populates = "/_postulante_ref/_cargo_ref/_asignador_ref/_licitacion_proyecto_ref";
		let asignaciones = [];
		if (GERENCIAS_SUPERIORES.includes(gerenciaID)) {
			asignaciones = await AsignacionRequest.Obtener(null, populates);
		} else {
			asignaciones = await AsignacionRequest.Obtener({ _gerencia_ref: gerenciaID }, populates);
		}

		let asignacionesMap = asignaciones.filter(a => a._postulante_ref).map(a => ({
			//INFORMACIÓN DEL CARGO.
			"Fecha Asignación": FormatearFecha(a.fecha_creacion),
			"Estado": EstadoAsignacion(a.estado, a.tipo),
			"Vacante": a._cargo_ref.estado,
			"Código": a._licitacion_proyecto_ref.codigo,
			"Proyecto / Licitación": a._licitacion_proyecto_ref.nombre,
			"ID Cargo": a._cargo_ref.codigo,
			"Cargo": a._cargo_ref.nombre,
			"Turno": a._cargo_ref.turno,
			"Modalidad": a._cargo_ref.modalidad,
			"Asginado Por": a._asignador_ref.nombre_abreviado,
			"Gerencia": a.gerencia_sigla,
			//INFORMACIÓN DEL POSTULANTE.
			"Rut": a._postulante_ref.run,
			"Nombre": a._postulante_ref.nombre,
			"Primer Apellido": a._postulante_ref.apellido_paterno,
			"Segundo Apellido": a._postulante_ref.apellido_materno,
			"Fecha de Nacimiento": FormatearFecha(a._postulante_ref.fecha_nacimiento),
			"Edad": AñosDesde(a._postulante_ref.fecha_nacimiento, 1),
			"Nacionalidad": a._postulante_ref.nacionalidad,
			"Género": a._postulante_ref.genero,
			"Numero": a._postulante_ref.contacto?.telefono,
			"Mail": a._postulante_ref.contacto?.email,
			"Región": a._postulante_ref.ubicacion?.region,
			"Comuna": a._postulante_ref.ubicacion?.comuna,
			"Dirección": a._postulante_ref.ubicacion?.direccion,
			"Nivel Inglés": a._postulante_ref.detalle.nivel_ingles,
			"Licencia Conducir": Array.from(a._postulante_ref.detalle.licencias_conducir).join(", "),
			"Discapacidad": Array.from(a._postulante_ref.detalle.discapacidades).join(", "),
			"Relación con CyD": a._postulante_ref.detalle.relacion_cyd,
			"Estaría dispuesto a trabajar en": "",
			"¿Trabajaría por turnos?": "",
			//INFORMACIÓN DE LA ASIGNACIÓN.
			"Pretenciones de renta líquida": a.pretension_renta,
			"Institución": a.institucion,
			"Nivel Estudio": a.titulo,
			"Título": a.titulo,
			"Experiencia general": a.exp_general,
			"Experiencia específica": a.exp_especifica,
			"Comentarios": Array.from(a.comentarios).map((c, i) => `Comentario ${i + 1}: ${c}`).join(", "),
		}));

		//Se genera el archivo Excel.
		JsonToExcel(asignacionesMap, "Asignados", "Asignados");
	} catch (error) {
		console.error(error);
		throw error;
	}
}