import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import DialogTitleComponent from "../../../../components/dialog_title";
import { Actualizar } from "../../../../services/request/reclutamiento/seleccionables";
import { FormikInitialValues, FormikValidationSchema } from "./utils";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_estados_civiles,
	} = props;

	const notistack = useSnackbar();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Actualizando el estado civil...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				values["_id"] = data._id;
				await Actualizar(values);
				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Estado civil actualizado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar actualizar el estado civil.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} finally {
				helper.resetForm();
				mutate_estados_civiles();
				handle_close();
			}
		}
	});

	const loadData = () => {
		formik.resetForm({ values: data });
	}

	/**
	 * Método encargado de cerrar el popup y cerrar los paneles.
	 */
	const handleCloseDialog = () => {
		handle_close();
		formik.resetForm();
	}

	return (
		<Dialog open={is_open} onClose={handleCloseDialog} onEntered={loadData} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handleCloseDialog}>Actualizar Estado Civil</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria del estado civil y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="valor"
							label="Nombre"
							value={formik.values.valor}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.valor}
							error={Boolean(formik.errors.valor)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}