import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { MTableGroupRow } from "@material-table/core";
import { IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { MaterialTableToExcel, MaterialTableToSpreadsheet } from "../../services/utilities/data_export";

/**
 * Componente para hacer la tabla con lostado de datos.
 * @param {*} title Título de la tabla.
 * @param {*} is_loading Flag para determinar si está cargando los datos.
 * @param {*} data Datos para mostrar.
 * @param {*} columns Columnas seleccionadas para mostrar, con RENDER si aplica.
 * @param {*} actions Acciones (botones) por fila y libres.
 * @param {*} components Modificación de componentes (opcional).
 * @param {*} export_pdf Flag para determinar si se permite exportar datos en PDF.
 * @param {*} export_csv Flag para determinar si se permite exportar datos en CSV.
 * @param {*} export_excel Flag para determinar si se permite exportar datos en Excel.
 * @param {*} export_spreadsheet Flag para determinar si se permite exportar datos en Spreadsheet.
 * @param {*} filtering Flag para determinar si incluye Filtering.
 * @param {*} grouping Flag para determinar si incluye Grouping.
 * @param {*} searching Flag para determinar si incluye Search.
 * @param {*} empty_rows Flag para determinar si se rellena o no con filas vacías.
 * @param {*} exportButton Flag para determinar si se permite exportar los datos.
 * @param {*} columnsButton Flag para determinar si se mostrará el botón de las columnas.
 * @param {*} exportAllData Flag para determinar si se exportarán todos los datos o solo la página actual.
 * @param {*} page_size Tamaño inicial de elementos por página.
 * @param {*} handle_filter_change Evento para los cambios en los filtros.
 * @param {*} handle_hidden_column_change Evento para los cambios en las columnas ocultas.
 * @param {*} row_style Estilo custom para aplicar a las filas.
 * @param {*} after_export Función que se ejecutará después de exportar datos.
 */
export default function Index(props) {
	const {
		title,
		is_loading,
		data,
		columns,
		actions,
		components,
		onRowClick,
		toolbar = true,
		showTitle = true,
		export_pdf = false,
		export_csv = false,
		export_excel = false,
		export_spreadsheet = false,
		filtering = true,
		grouping = true,
		searching = true,
		empty_rows = false,
		exportButton = true,
		columnsButton = true,
		exportAllData = true,
		page_size = 20,
		lockheaders = false.valueOf,
		detailPanel,
		handle_filter_change = undefined,
		handle_hidden_column_change = undefined,
		row_style = undefined,
		after_export = undefined,
	} = props;

	const [PageSize, SetPageSize] = useState(page_size);
	const [PageSizes, SetPageSizes] = useState([]);

	const notistack = useSnackbar();

	useEffect(() => {
		let pageSizes = [];
		if (data && data.length > 0) {
			pageSizes.push(20);
			pageSizes.push(50);
			pageSizes.push(100);
			pageSizes.push(200);
		}
		if (!pageSizes.find(a => a === page_size)) {
			pageSizes.push(page_size);
		}

		pageSizes.sort((a, b) => a - b);
		SetPageSizes(pageSizes);
	}, [data, page_size]);

	useEffect(() => {
		SetPageSize(PageSizes[0]);
	}, [PageSizes]);

	/**
	 * Método encargado de generar la colección de botones exportar disponibles.
	 * @returns Colección de botones exportar.
	 */
	function ExportMenu() {
		let menu = [];
		if (export_pdf) {
			menu.push({
				label: "Exportar PDF",
				exportFunc: (cols, datas) => {
					ExportPdf(cols, datas, "pdf_export");
					if (after_export) {
						after_export();
					}
				},
			});
		}
		if (export_csv) {
			menu.push({
				label: "Exportar CSV",
				exportFunc: (cols, datas) => {
					ExportCsv(cols, datas, "csv_export");
					if (after_export) {
						after_export();
					}
				},
			});
		}
		if (export_excel) {
			menu.push({
				label: "Exportar Excel",
				exportFunc: (cols, datas) => {
					let columnas = Array.from(columns);
					let titulos = columnas.map(f => f.title);
					//https://stackoverflow.com/a/33066852
					let datos = Array.from(data).map(d => columnas.map(c => String(c.field).split(".").reduce((o, key) => o[key], d)));
					MaterialTableToExcel(titulos, datos, title, title);
					if (after_export) {
						after_export();
					}
				},
			});
		}
		if (export_spreadsheet) {
			menu.push({
				label: "Exportar Spreadsheet",
				exportFunc: (cols, datas) => {
					notistack.enqueueSnackbar("Exportando los datos...", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});
					MaterialTableToSpreadsheet(cols, datas, title, title)
						.then(response => {
							notistack.closeSnackbar();
							notistack.enqueueSnackbar(response.message, {
								variant: response.variant,
								anchorOrigin: {
									horizontal: "center",
									vertical: "bottom"
								},
								action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
							});
							if (response.url) {
								window.open(response.url, "_blank");
							}
						})
						.catch(error => {
							console.error(error);
							notistack.closeSnackbar();
							notistack.enqueueSnackbar("Error al intentar exportar los datos.", {
								variant: "error",
								anchorOrigin: {
									horizontal: "center",
									vertical: "bottom"
								},
								action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
							});
						});
				},
			});
		}
		return menu;
	}

	let options = {
		padding: "dense",
		pageSize: PageSize,
		pageSizeOptions: PageSizes,
		emptyRowsWhenPaging: empty_rows,
		columnsButton: columnsButton,
		exportButton: exportButton,
		exportMenu: ExportMenu(),
		exportAllData: exportAllData,
		search: searching,
		grouping: grouping,
		toolbar: toolbar,
		showTitle: showTitle,
		filtering: filtering,
		actionsColumnIndex: 0,
		headerStyle: {
			fontWeight: "bold",
			color: "#731f1f",
			textAlign: "center",
		},
		rowStyle: row_style,
		maxBodyHeight: lockheaders ? "70vh" : "100%",
	}

	let localComponents = {
		GroupRow: props => {
			//Permite mostrar la cantidad de elementos agrupados.
			let valor = props.groupData.value;
			let cantidad = props.groupData.data.length;
			if (!String(props.groupData.value).includes("(")) {
				props.groupData.value = `${valor} (${cantidad})`;
			}
			return <MTableGroupRow {...props} />;
		}
	}

	return (
		<MaterialTable
			title={<Typography color="primary" variant="h6" style={{ fontWeight: "bold" }}>{title}</Typography>}
			isLoading={is_loading}
			columns={columns}
			options={options}
			localization={location}
			actions={actions}
			data={data}
			onRowClick={onRowClick}
			detailPanel={detailPanel}
			components={Object.assign(localComponents, components)}
			style={{ borderRadius: 25 }}
			onFilterChange={handle_filter_change}
			onChangeColumnHidden={handle_hidden_column_change}
		/>
	);
}

const location = {
	"toolbar": {
		"addRemoveColumns": "Agregar o Quitar",
		"exportTitle": "Exportar",
		"exportCSVName": "Exportar Excel",
		"exportPDFName": "Exportar PDF",
		"nRowsSelected": "{0} fila(s) selecionadas",
		"searchPlaceholder": "Buscar",
		"searchTooltip": "Buscar",
		"showColumnsTitle": "Mostrar Columnas"
	},
	"pagination": {
		"labelRowsSelect": "Filas",
		"labelDisplayedRows": "{from}-{to} de {count}",
		"firstAriaLabel": "Primera Página",
		"firstTooltip": "Primera Página",
		"previousAriaLabel": "Página Anterior",
		"previousTooltip": "Página Anterior",
		"nextAriaLabel": "Siguiente Página",
		"nextTooltip": "Siguiente Página",
		"lastAriaLabel": "Última Página",
		"lastTooltip": "Última Página"
	},
	"grouping": {
		"placeholder": "Arrastre los encabezados aquí para agrupar "
	},
	"header": {
		"actions": "Acciones"
	},
	"body": {
		"emptyDataSourceMessage": "Sin Registros",
		"filterRow": {
			"filterTooltip": "Filtrar"
		}
	}
};