import React, { useContext, useEffect, useState } from "react";
import Page from "./page";
import { useParams, useHistory } from "react-router-dom";
import { CARPETA_LICITACIONES } from "../../../../constants/routes";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const [Licitacion, SetLicitacion] = useState(null);
	const { ShowSnackbar } = useContext(MainContext);
	const { licitacion_id } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!licitacion_id) {
			history.replace(CARPETA_LICITACIONES);
		} else {
			LicitacionProyectoRequets.ObtenerPorID(licitacion_id)
				.then(licitacion => {
					SetLicitacion(licitacion);
				})
				.catch(error => {
					console.error(error);
					ShowSnackbar("Error al intentar obtener los datos de la licitación.", error);
					history.replace(CARPETA_LICITACIONES);
				});
		}
	}, [ShowSnackbar, history, licitacion_id]);

	return Licitacion && (
		<Page
			licitacion={Licitacion}
		/>
	);
}