import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

/**
 * Método encargado de retornar un componente Dialog para mostrar la descripción del cargo.
 * @param {*} is_open FLAG para mostrar u ocultar el Dialog.
 * @param {*} cargo_descripcion Descripción del cargo.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function FuncionesDialog(props) {
	const {
		is_open,
		cargo_descripcion,
		handle_close,
	} = props;

	return (
		<Dialog
			open={is_open}
			onClose={handle_close}
		>
			<DialogTitle>
				Funciones del Cargo
			</DialogTitle>
			<DialogContent>
				<pre style={{ fontFamily: "Poppins", margin: 0, padding: 0 }}>
					{cargo_descripcion}
				</pre>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="contained" color="secondary">
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
}