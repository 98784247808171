import React, { Fragment } from "react";
import { Paper, Typography } from "@material-ui/core";

export default function DetalleHabilidades(props) {
	const {
		habilidades,
	} = props;

	return (
		<Fragment>
			{Array.from(habilidades).map((habilidad, index) => (
				<Fragment key={`fragment_${index}`}>
					<Paper elevation={5} style={{ padding: 10 }}>
						<Typography>{`Manejor de software ${habilidad.software} en ${habilidad.comment} con nivel ${habilidad.level?.description}`}</Typography>
					</Paper>
					<br />
				</Fragment>
			))}
		</Fragment>
	);
}