import { AñosDesde, EdadFormato, FormatearFecha, NombreCompleto } from "../../../services/utilities/formatUtils";

/**
 * Constantes del módulo.
 */
export const Constantes = {
	POSTULANTE: "POSTULANTE",
	DETALLES: "DETALLES",
	COMENTARIOS: "COMENTARIOS",

	CONTACTO_ADD: "CONTACTO_ADD",

	ESTUDIO_ADD: "ESTUDIO_ADD",
	ESTUDIO_UPDATE: "ESTUDIO_UPDATE",
	ESTUDIO_DELETE: "ESTUDIO_DELETE",

	EXPERIENCIA_ADD: "EXPERIENCIA_ADD",
	EXPERIENCIA_UPDATE: "EXPERIENCIA_UPDATE",
	EXPERIENCIA_DELETE: "EXPERIENCIA_DELETE",

	EVALUACIONES: "EVALUACIONES",
	EVALUACION_ADD: "EVALUACION_ADD",
	EVALUACION_UPDATE: "EVALUACION_UPDATE",
	EVALUACION_DELETE: "EVALUACION_DELETE",

	ASIGNACIONES: "ASIGNACIONES",
	ASIGNACION_MULTIPLE: "ASIGNACION_MULTIPLE",

	DOCUMENTOS: "DOCUMENTOS",

	POPUP_INFO: "POPUP_INFO",
}

/**
 * Método encargado de agregar unos datos adicionales al postulante.
 * @param {*} postulante Datos originales del postulante.
 * @returns Datos del postulante con información adicional.
 */
export function AgregarDatosPostulante(postulante) {
	postulante["nombre_completo"] = NombreCompleto(postulante);
	postulante["titulo_corto"] = `${String(postulante.ultimo_titulo).slice(0, 12)}...`;
	postulante["fecha_nacimiento_formato"] = FormatearFecha(postulante.fecha_nacimiento, "YYYY/MM/DD");
	postulante["edad"] = EdadFormato(postulante.fecha_nacimiento);
	postulante["porcentaje"] = Number(postulante.porcentaje).toFixed(1);
	postulante["anos_experiencia"] = AñosDesde(postulante.inicio_vida_laboral);
	postulante["fecha_cv"] = postulante.curriculum_vitae ? FormatearFecha(postulante.curriculum_vitae.fecha_creacion, "YYYY/MM/DD") : "";
	return postulante;
}