import Axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { STORAGE } from "../../constants/urls";

const BUCKET_NAME = "reclutamiento-cyd";

/**
 * Método encargado de subir una foto a Cloud Storage.
 * @param {*} file Archivo seleccionado.
 * @param {*} route Ruta de carpeta donde se subirá.
 * @param {*} name Nombre del archivo.
 * @returns URL de la imagen subida.
 */
export async function uploadFileToCloudStorage(file, route, name) {
	try {
		var formData = new FormData();
		formData.append("folder", route);
		formData.append("bucket", BUCKET_NAME);
		formData.append("name", name);
		formData.append("file", file);

		let upload = await Axios.post(`${STORAGE}/upload`, formData);
		if (!Boolean(upload.status === 201 || upload.status === 200)) {
			throw new Error("Error al intentar subir el archivo.");
		}
		let uploadedFile = {
			mime_type: upload.data.content_type,
			nombre: name,
			nombre_original: file.name,
			repositorio: `${upload.data.bucket}/${route}`,
			url: upload.data.pathname,
		}
		return uploadedFile;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de descargar un archivo desde una URL, como Blob.
 * @param {*} url URL del archivo.
 * @returns Blob del archivo.
 */
export async function downloadFileFromURL(url) {
	try {
		let response = await Axios(url, { responseType: "blob" });
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de descargar un conjunto de archivos, como ZIP.
 * @param {*} files Colección de archivos, con formato {contenido, nombre}.
 * @param {*} filename Nombre del archivo ZIP.
 */
export async function zipFiles(files, filename) {
	try {
		var zip = new JSZip();
		files.forEach((file, index) => {
			zip.file(file.nombre, file.contenido, { binary: true });
		});
		let contenido = await zip.generateAsync({ type: "blob" });
		saveAs(contenido, filename);
	} catch (error) {
		console.error(error);
		throw error;
	}
}