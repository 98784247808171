import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerDiscapacidades } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: discapacidades,
		isValidating: loadingDiscapacidades,
		mutate: mutateDiscapacidades,
		error: errorDiscapacidades,
	} = useSWR("discapacidades_seleccionables", ObtenerDiscapacidades, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorDiscapacidades) {
			ShowSnackbar("Error al intentar cargar los datos de las discapacidades.", errorDiscapacidades);
		}
	}, [ShowSnackbar, errorDiscapacidades]);

	return (
		<Page
			discapacidades={discapacidades}
			is_loading={loadingDiscapacidades}
			mutate_discapacidades={mutateDiscapacidades}
		/>
	);
}