import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerNivelesEstudio } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: nivelesEstudio,
		isValidating: loadingNivelesEstudio,
		mutate: mutateNivelesEstudio,
		error: errorNivelesEstudio,
	} = useSWR("niveles_estudio_seleccionables", ObtenerNivelesEstudio, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorNivelesEstudio) {
			ShowSnackbar("Error al intentar cargar los datos de los niveles de estudio.", errorNivelesEstudio);
		}
	}, [ShowSnackbar, errorNivelesEstudio]);

	return (
		<Page
			niveles_estudio={nivelesEstudio}
			is_loading={loadingNivelesEstudio}
			mutate_niveles_estudio={mutateNivelesEstudio}
		/>
	);
}