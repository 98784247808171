import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FormatearFecha, IconByMimeType } from "../../../services/utilities/formatUtils";

/**
 * Método encargado de retornar un componente Dialog para mostrar la descripción del cargo.
 * @param {*} is_open FLAG para mostrar u ocultar el Dialog.
 * @param {*} adjuntos Colección de archivos adjuntos del cargo.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function AdjuntosDialog(props) {
	const {
		is_open,
		adjuntos,
		handle_close,
	} = props;

	return (
		<Dialog
			open={is_open}
			onClose={handle_close}
		>
			<DialogTitle>
				Documentos Adjuntos
			</DialogTitle>
			<DialogContent>
				<List dense>
					{Array.from(adjuntos).map((adjunto, index) => (
						<ListItem button onClick={() => window.open(adjunto.url, "_blank")} key={`item_${index}`}>
							<ListItemIcon>
								{IconByMimeType(adjunto.mime_type)}
							</ListItemIcon>
							<ListItemText
								primary={adjunto.nombre_original}
								secondary={FormatearFecha(adjunto.fecha_creacion)}
							/>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="contained" color="secondary">
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
}