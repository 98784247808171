import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Paper } from "@material-ui/core";
import PostulanteAsignar from "../../../../components/postulante_asignar";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import { ActualizacionRequest } from "../../../../services/request/reclutamiento/postulantes";
import { AsignacionRequest } from "../../../../services/request/reclutamiento/licitaciones_proyectos";

export default function AsignacionDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { postulante_id } = useParams();
	const { ShowSnackbar } = useContext(MainContext);
	const { formik, licitaciones_proyectos } = useContext(PostulanteEditarContext);

	/**
	 * Método encargado de actualizar los datos, después de una asignación exitosa.
	 */
	const handleAsignado = async () => {
		try {
			//Se agregan adjunto y actualización del postulante.
			let responses = await Promise.all([
				AsignacionRequest.Obtener({ _postulante_ref: postulante_id }, "/_postulante_ref/_cargo_ref/_reclutador_ref/_asignador_ref"),
				ActualizacionRequest.Obtener(postulante_id),
			]);

			//Nueva asignación del postulante.
			let asignaciones = responses[0];
			//Nuevo registro de actualización del postulante.
			let actualizaciones = responses[1];

			//Se actualizan los datos del postulante.
			formik.setFieldValue("asignaciones", asignaciones);
			formik.setFieldValue("actualizaciones", actualizaciones);
		} catch (error) {
			ShowSnackbar("Error al intentar cargar los nuevos datos de asignaciones y actualizaciones.", error);
		}
	}

	/**
	 * Método encargado de ocultar el DIALOG de asignación.
	 */
	const handleCancelado = () => {
		set_is_open(false);
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<PostulanteAsignar
					licitaciones_proyectos_swr={licitaciones_proyectos}
					asignaciones={formik.values.asignaciones}
					postulantes_ids={[postulante_id]}
					handle_asignado={handleAsignado}
					handle_cancelado={handleCancelado}
				/>
			</Paper>
		</Collapse>
	);
}