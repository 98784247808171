import { object, string, date, mixed } from "yup";
import { ASIGNACION_ESTADOS, TIPO_LICITACION, TIPO_PROYECTO } from "../../constants/contexts";

export const REGEX_RUN = /^(\d{7}|\d{8})-[0-9kK]$/;

export const FormikInitialValues = {
	run: "",
	nombre: "",
	apellido_paterno: "",
	apellido_materno: "",
	fecha_nacimiento: null,
	nacionalidad: "",
	nacionalidad_object: null,
	ubicacion: {
		comuna: "",
		region: "",
		region_object: null,
	},
	contacto: {
		email: "",
	},
	detalle: {
		pretencion_renta_formato: "",
		pretencion_renta: 0,
		_reclutador_ref: null,
	},
	origen: "Individual",
	file: null,
	cv: null,
	estado: "",
}

export const FormikValidationSchema = object().shape({
	run: string()
		.nullable()
		.min(9, ({ min }) => `El RUN debe tener al menos ${min} caracteres.`)
		.max(10, ({ max }) => `El RUN debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_RUN, { message: "El RUN debe tener el formato \"12345678-9\"." })
		.required("El RUN es requerido."),
	nombre: string()
		.nullable()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	apellido_paterno: string()
		.nullable()
		.min(3, ({ min }) => `El primer apellido debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El primer apellido debe tener a lo más ${max} caracteres.`)
		.required("El primer apellido es requerido."),
	apellido_materno: string()
		.nullable()
		.min(3, ({ min }) => `El segundo apellido debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El segundo apellido debe tener a lo más ${max} caracteres.`)
		.required("El segundo apellido es requerido."),
	fecha_nacimiento: date()
		.max(new Date(), "La fecha de nacimiento no puede ser superior a la fecha actual.")
		.nullable()
		.optional(),
	nacionalidad: string()
		.optional(),
	nacionalidad_object: object()
		.nullable()
		.optional(),
	ubicacion: object()
		.shape({
			ciudad: string()
				.min(2, ({ min }) => `La ciudad debe tener al menos ${min} caracteres.`)
				.max(200, ({ max }) => `La ciudad debe tener a lo más ${max} caracteres.`)
				.optional(),
			comuna: string()
				.nullable()
				.optional(),
			region_object: object()
				.nullable()
				.optional(),
		})
		.optional(),
	contacto: object()
		.shape({
			email: string()
				.email("El correo debe tener el formato \"usuario@dominio\".")
				.optional(),
		})
		.required("La información de contacto es requerida."),
	detalle: object()
		.shape({
			pretencion_renta_formato: string()
				.nullable()
				.optional(),
		})
		.optional(),
	origen: string()
		.required("El origen del postulante es requerido."),
	file: mixed()
		.nullable()
		.required("El CV es requerido."),
	estado: string()
		.required("El estado es requerido."),
});

/**
 * Método encargado de traducir una pestaña seleccionada a estado, para aplicar los filtros.
 * @param {*} tabIndex Número de pestaña seleccionada.
 * @returns Estado de asignación.
 */
export function TabToEstado(tabIndex) {
	switch (tabIndex) {
		case 1:
			return ASIGNACION_ESTADOS.PENDIENTE
		case 2:
			return ASIGNACION_ESTADOS.SHORT_LIST;
		case 3:
			return ASIGNACION_ESTADOS.SELECCIONADO;
		case 4:
			return ASIGNACION_ESTADOS.FUERA_PROCESO;
		default:
			return "";
	}
}

/**
 * Método encargado de retornar el texto para usuario.
 * @param {*} tipo Tipo licitacion o proyecto.
 * @returns Texto del tipo para usuario.
 */
export function TipoLicitacionProyecto(tipo) {
	switch (tipo) {
		case TIPO_LICITACION:
			return "Licitación";
		case TIPO_PROYECTO:
			return "Proyecto";
		default:
			return "";
	}
}