import { object, string, mixed } from "yup";

export const FormikInitialValues = {
	nombre: "",
	adjunto: null,
	file: null,
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(50, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre del adjunto es requerido."),
	adjunto: mixed()
		.nullable()
		.optional(),
	file: mixed()
		.nullable()
		.required("El adjunto es requerido."),
});