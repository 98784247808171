import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { TemplateRequest, GeneradasRequest } from "../../../services/request/reclutamiento/cartas_oferta";
import { GenerarCartaOferta } from "../../../services/utilities/google_apps_scripts";
import { FormatearFecha, NombreCompleto } from "../../../services/utilities/formatUtils";
import { MainContext } from "../../../App";

/**
 * Método encargado de retornar un componente Dialog para mostrar la descripción del cargo.
 * @param {*} is_open FLAG para mostrar u ocultar el Dialog.
 * @param {*} asignacion Datos de la asignación.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function CartaOfertaDialog(props) {
	const {
		is_open,
		asignacion,
		handle_close,
	} = props;

	const [CartaOfertaGenerada, SetCartaOfertaGenerada] = useState(false);
	const [GenerandoCartaOferta, SetGenerandoCartaOferta] = useState(false);
	const CartasOfertaTemplateSWR = useSWR("cartas_oferta_template", TemplateRequest.Obtener);
	const MainCTX = useContext(MainContext);
	const classes = useStyles();

	useEffect(() => {
		loadCartaOfertaGenerada();
	}, [asignacion, is_open]);

	useEffect(() => {
		if (CartasOfertaTemplateSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los templates de cartas de oferta.", CartasOfertaTemplateSWR.error);
		}
	}, [MainCTX, CartasOfertaTemplateSWR.error]);

	/**
	 * Método encargado de cargar los datos de la carta de oferta generada, si existe.
	 */
	const loadCartaOfertaGenerada = async () => {
		try {
			if (asignacion && asignacion._id) {
				let cartaOferta = await GeneradasRequest.ObtenerPorAsignacionID(asignacion._id);
				if (cartaOferta && cartaOferta._carta_oferta_template_ref) {
					//Si hay datos de carta de oferta y aún existe el template.
					SetCartaOfertaGenerada(cartaOferta);
				} else if (cartaOferta && !cartaOferta._carta_oferta_template_ref) {
					//Si hay carta de oferta pero no existe el template.
					await GeneradasRequest.Eliminar(cartaOferta._id);
					SetCartaOfertaGenerada(null);
				} else {
					SetCartaOfertaGenerada(null);
					//Si no hay carta de oferta.
				}
			}
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar obtener la carta de oferta generada.", error);
		}
	}

	/**
	 * Método encargado de generar la carta de oferta.
	 * @param {*} carta_oferta_template Datos de la carta de oferta template.
	 */
	const handleGenerarCartaOferta = async (carta_oferta_template) => {
		try {
			handle_close();
			SetGenerandoCartaOferta(true);
			//Se elimina parte de la URL para obtener el ID.
			let templateID = String(carta_oferta_template.link_template).replace("https://docs.google.com/document/d/", "");
			let postulante = asignacion._postulante_ref;
			let proyecto = asignacion._licitacion_proyecto_ref;
			let cargo = asignacion._cargo_ref;
			let responsable = asignacion._responsable_ref;

			//Llamado a Apps Script para generar carta de oferta.
			let googleDocURL = await GenerarCartaOferta(templateID, postulante, proyecto, cargo, responsable);

			//Datos para la carta de oferta.
			let cartaOfertaGenerada = {
				_usuario_ref: MainCTX.usuarioSesion.ref,
				_postulante_ref: postulante._id,
				_carta_oferta_template_ref: carta_oferta_template._id,
				_licitacion_proyecto_cargo_asignacion_ref: asignacion._id,
				_gerencia_ref: asignacion._gerencia_ref,
				carta_oferta_url: googleDocURL,
			}

			//Se agrega la carta de oferta la base de datos.
			await GeneradasRequest.Agregar(cartaOfertaGenerada);
			window.open(googleDocURL, "_blank");

			MainCTX.ShowSnackbar("Carta de oferta generada exitosamente.");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar generar la carta de oferta", error);
		} finally {
			SetGenerandoCartaOferta(false);
		}
	}

	/**
	 * Método encargado de eliminar una carta de oferta.
	 */
	const handleEliminar = async () => {
		try {
			await GeneradasRequest.Eliminar(CartaOfertaGenerada._id);
			MainCTX.ShowSnackbar("Carta de oferta elimina exitosamente");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar eliminar la carta de oferta generada.", error);
		} finally {
			handle_close();
		}
	}

	return (
		<Fragment>
			<Dialog open={is_open} onClose={handle_close}>
				<DialogTitle>
					Carta de oferta generada
				</DialogTitle>
				<DialogContent>
					{/* INFORMACIÓN DE LA CARTA GENERADA */}
					{CartaOfertaGenerada && (
						<Fragment>
							<Typography>{`Usuario generador: ${NombreCompleto(CartaOfertaGenerada._usuario_ref)}`}</Typography>
							<Typography>{`Carta de oferta: ${CartaOfertaGenerada._carta_oferta_template_ref.nombre}`}</Typography>
							<Typography>Carta de oferta generada: <a href={CartaOfertaGenerada.carta_oferta_url} target="_blank" rel="noreferrer">link</a></Typography>
							<Typography>{`Fecha de generación: ${FormatearFecha(CartaOfertaGenerada.fecha_creacion, "DD/MM/YYYY HH:mm:ss")}`}</Typography>
						</Fragment>
					)}

					{/* MENÚ DE OPCIONES PARA GENERAR LA CARTA DE OFERTA */}
					{!CartaOfertaGenerada && CartasOfertaTemplateSWR.data && (
						<List dense>
							{Array.from(CartasOfertaTemplateSWR.data.data).map((cartaOferta, index) => (
								<ListItem button onClick={() => handleGenerarCartaOferta(cartaOferta)} key={`list_item_${index}`}>
									<ListItemIcon>
										<Description fontSize="small" />
									</ListItemIcon>
									<ListItemText primary={cartaOferta.nombre} />
								</ListItem>
							))}
						</List>
					)}
				</DialogContent>
				<DialogActions>
					{CartaOfertaGenerada && (
						<Button onClick={handleEliminar} variant="outlined" color="primary">
							Eliminar
						</Button>
					)}
					<Button onClick={handle_close} variant="contained" color="secondary">
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>

			{/* PANTALLA DE CARGA DE GENERACIÓN DE CARTA DE OFERTA */}
			<Backdrop open={GenerandoCartaOferta} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography align="center" variant="h5">Generando la carta de oferta...</Typography>
			</Backdrop>
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 100,
		color: "#fff",
	},
}));