import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { ToTitleCase } from "../../services/utilities/formatUtils";

/**
 * Método encargado de retornar un componente Tooltip.
 * @param {*} children Texto para mostrar.
 * @param {*} tooltip Texto en popup.
 * @param {*} custom_color Color alternativo (opcional). TRUE: color secundario | FALSE: color primario.
 */
export default function TooltipCustom(props) {
	const {
		children,
		tooltip,
		custom_color = false,
	} = props;

	return (
		<Tooltip title={ToTitleCase(tooltip)}>
			<Typography style={{ color: custom_color ? "white" : undefined }}>
				{ToTitleCase(children)}
			</Typography>
		</Tooltip>
	);
}