import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { Box, Dialog, DialogContent, DialogContentText, LinearProgress, Paper, Switch, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { mdiTimelineText, mdiFormatListBulleted } from "@mdi/js";
import { Icon } from "@mdi/react";
import MaterialTable from "../../../../components/materialTable";
import DialogTitleComponent from "../../../../components/dialog_title";
import { AsignacionRequest } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { FormatearFecha, NombreCompleto } from "../../../../services/utilities/formatUtils";
import { MainContext } from "../../../../App";

/**
 * Componente para ver las asignaciones de un postulante.
 * @param {*} postulante Datos del postulante.
 * @param {*} handle_close Método encargado de cerrar el popup.
 * @returns Component.
 */
export default function DialogAsignaciones(props) {
	const {
		postulante,
		handle_close,
	} = props;

	const [Flag, SetFlag] = useState(false);
	const { ShowSnackbar } = useContext(MainContext);
	const classes = useStyles();
	const AsignacionesSWR = useSWR(`asignaciones_${postulante._id}`, (key) => AsignacionRequest.Obtener({ _postulante_ref: postulante._id }, "/_postulante_ref/_reclutador_ref"), { revalidateOnFocus: false });

	useEffect(() => {
		if (AsignacionesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las asignaciones.", AsignacionesSWR.error);
		}
	}, [ShowSnackbar, AsignacionesSWR]);


	if (AsignacionesSWR && AsignacionesSWR.data) {
		Array.from(AsignacionesSWR.data).forEach((asignacion, index) => {
			AsignacionesSWR.data[index]["fecha_formato"] = FormatearFecha(asignacion.fecha_creacion, "DD/MM/YYYY HH:mm");
			AsignacionesSWR.data[index]["reclutador_nombre"] = NombreCompleto(asignacion._reclutador_ref);
		});
	}

	let columns = [{
		title: "Cargo",
		field: "cargo_nombre",
	}, {
		title: "Reclutador",
		field: "reclutador_nombre",
	}, {
		title: "Gerencia",
		field: "gerencia_sigla",
		maxWidth: 120,
	}, {
		title: "Estado",
		field: "estado",
		maxWidth: 120,
	}, {
		title: "Fecha",
		field: "fecha_formato",
		maxWidth: 200,
	}];

	return (
		<Dialog open={true} onClose={handle_close} maxWidth="lg" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Asignaciones</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Listado de asignaciones del candidato ${NombreCompleto(postulante)}.`}
				</DialogContentText>
				<Fragment>
					{/* SWITCH Y BOTÓN AGREGAR */}
					<Box display="flex" flexDirection="row" className={classes.box_main}>
						<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" className={classes.box}>
							<Tooltip title="Modo Línea de Tiempo">
								<Icon path={mdiTimelineText} size={1} />
							</Tooltip>
							<Switch checked={Flag} onChange={(event, checked) => SetFlag(checked)} />
							<Tooltip title="Modo Lista">
								<Icon path={mdiFormatListBulleted} size={1} />
							</Tooltip>
						</Box>
					</Box>

					{/* MODO LÍNEA DE TIEMPO */}
					{!Flag && (
						<Timeline align="alternate">
							{(AsignacionesSWR.isValidating) && (
								<LinearProgress />
							)}
							{(!AsignacionesSWR.data || AsignacionesSWR.data.length === 0) && !AsignacionesSWR.isValidating && (
								"sin datos"
							)}
							{AsignacionesSWR.data && !AsignacionesSWR.isValidating && AsignacionesSWR.data.map((d, index) => (
								<TimelineItem key={`timeline_item_${index}`}>
									<TimelineOppositeContent style={{ alignSelf: "center" }}>
										<Typography color="textSecondary">{d.fecha_formato}</Typography>
									</TimelineOppositeContent>
									<TimelineSeparator >
										<TimelineDot color="primary" />
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent>
										<Paper elevation={10} className={classes.paper}>
											<Typography variant="subtitle1">
												{`Cargo: ${d.cargo_nombre}`}
											</Typography>
											<Typography variant="subtitle2">
												{`Gerencia: ${d.gerencia_sigla} | Reclutador: ${d.reclutador_nombre}`}
											</Typography>
											<Typography variant="subtitle2">
												{`Estado: ${d.estado}`}
											</Typography>
										</Paper>
									</TimelineContent>
								</TimelineItem>
							))}
						</Timeline>
					)}

					{/* MODO LISTA */}
					{Flag && (
						<MaterialTable
							title="Asignaciones"
							is_loading={AsignacionesSWR.isValidating}
							data={AsignacionesSWR.data}
							columns={columns}
							grouping={false}
						/>
					)}
				</Fragment>
			</DialogContent>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	box_main: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(10),
	},
	box: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(1, 2),
	},
}));