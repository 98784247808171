export const INSTITUCIONES = "instituciones";
export const TITULOS = "titulos";
export const LICENCIAS_CONDUCIR = "licencias_conducir";
export const NIVELES_INGLES = "niveles_ingles";
export const DISCAPACIDADES = "discapacidades";
export const SITUACIONES_LABORALES = "situaciones_laborales";
export const ESTADOS_CIVILES = "estados_civiles";
export const RELACIONES_CYD = "relaciones_cyd";
export const NIVELES_ESTUDIOS = "niveles_estudios";

export const RELACION_CYD = "Trabajador vigente en CyD";
export const RELACION_CYD_TRABAJADOR = "Trabajador CyD";
export const RELACION_CYD_BLOQUEADO = "Bloqueado";
export const RELACION_CYD_DESBLOQUEADO = "Desbloqueado";

export const TIPO_LICITACION = "LICITACION";
export const TIPO_PROYECTO = "PROYECTO";
export const LICITACION_PROYECTO_ESTADOS = {
	RECLUTANDO: "RECLUTANDO",
	SIN_RECLUTAMIENTO: "SIN_RECLUTAMIENTO",
	TERMINADO: "TERMINADO",
};

export const CARGO_ESTADOS = {
	ACTIVO: "ACTIVO",
	CERRADO: "CERRADO",
	DETENIDO: "DETENIDO",
	FALLIDO: "FALLIDO",
}

export const ASIGNACION_ESTADOS = {
	PENDIENTE: "PENDIENTE",
	SHORT_LIST: "SHORT_LIST",
	SELECCIONADO: "SELECCIONADO",
	FUERA_PROCESO: "FUERA_PROCESO",
};

export const REGISTROS_KPI = {
	SESSION: "SESSION",
	BUSQUEDA: "BUSQUEDA",
}

export const GERENCIAS_SUPERIORES = [
	"5e0f28b070edaf00137fcf4b",//GPO
	"61dddea26085dd001210f48d",//TEC
];

export const GERENCIAS_VISIBLES = [
	{
		sigla: "GMI",
		id: "5e0f28b070edaf00137fcf48",
	}, {
		sigla: "GOP",
		id: "5e0f28b070edaf00137fcf4a",
	}, {
		sigla: "GEI",
		id: "5e0f28b070edaf00137fcf45",
	}, {
		sigla: "GIT",
		id: "5e0f28b070edaf00137fcf47",
	}, {
		sigla: "GPO",
		id: "5e0f28b070edaf00137fcf4b",
	},
];

export const LUCES_SEMAFORO = {
	VERDE: "green",
	AMARILLO: "#EAD31A",
	ROJO: "red",
}