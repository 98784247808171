import React, { Fragment, useContext } from "react";
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import FileDrop from "./file_drop";
import { DejarCurriculumContext } from "./index";
import Origenes from "../../../services/data/origenes.json";

const filter = createFilterOptions();

export default function Page(props) {
	const {
		handle_submit,
		is_submitting,
		tag_state,
		option_state,
	} = props;

	const classes = useStyles();

	const {
		formik,
	} = useContext(DejarCurriculumContext);

	/**
	 * Evento encargado de ejecutarse con CTRL + Enter.
	 * @link https://stackoverflow.com/questions/16006583/capturing-ctrlz-key-combination-in-javascript.
	 * @param {*} e Evento.
	 */
	document.onkeydown = function (e) {
		if (e.code === "Enter" && e.ctrlKey) {
			handle_submit();
		}
	}

	const isDisabled = () => {
		let check = Boolean(
			is_submitting ||
			!formik.values.curriculum_vitae ||
			!formik.values.origen
		);
		return check;
	}

	return (
		<Fragment>
			<Grid container spacing={2} style={{ paddingLeft: "20%", paddingRight: "20%" }}>
				{/* SELECTOR DE CURRICULUM VITAE */}
				<Grid item xs={6}>
					<FileDrop
						nombre="curriculum_vitae"
						titulo="Curriculum Vitae"
						cantidad={1}
						formatos={["application/pdf"]}
					/>
				</Grid>

				{/* SELECTOR DE CERTIFICADO DE ENSEÑANZA MEDIA */}
				<Grid item xs={6}>
					<FileDrop
						nombre="ensenanza_media"
						titulo="Certificado de Enseñanza Media"
						cantidad={1}
						formatos={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
					/>
				</Grid>

				{/* SELECTOR DE HOJA DE VIDA DEL CONDUCTOR */}
				<Grid item xs={6}>
					<FileDrop
						nombre="hoja_vida_conductor"
						titulo="Hoja de Vida del Conductor"
						cantidad={1}
						formatos={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
					/>
				</Grid>

				{/* SELECTOR DE ESTUDIOS Y TÍTULOS */}
				<Grid item xs={6}>
					<FileDrop
						nombre="titulos"
						titulo="Títulos y Estudios Adicionales"
						formatos={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
					/>
				</Grid>

				{/* SEPARADOR DE SECCIÓN */}
				<Grid item xs={12}>
					<Divider />
				</Grid>

				{/* CAMPO PARA INGRESAR TAGS */}
				<Grid item xs={9}>
					<Box>
						<Autocomplete
							value={tag_state()}
							onChange={(event, value) => {
								let newValues = [];

								//Por cada valor ingresado como palabra clave.
								value.forEach((valor, index) => {
									if (typeof valor === "string") {
										//Si es de tipo texto.
										newValues.push({ value: valor, is_new: false });
									}
									if (typeof valor === "object") {
										//Si es de tipo objeto.
										newValues.push({ ...valor, is_new: false });
									}
								});

								tag_state(newValues);
								//Se combierten en texto, para buscar repetidos.
								let values = [...option_state(), ...newValues].map(v => JSON.stringify(v));
								//Se filtran los valores repetidos.
								option_state(Array.from(new Set(values)).map(v => JSON.parse(v)));
							}}
							filterOptions={(options, params) => {
								options = options.filter(o => !tag_state().find(v => v.value === o.value));
								const filtered = filter(options, params);
								// Suggest the creation of a new value
								if (params.inputValue !== '') {
									filtered.push({
										value: params.inputValue,
										is_new: true,
									});
								}
								return filtered;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							options={option_state()}
							getOptionLabel={(option) => option.value}
							renderOption={(option) => option.is_new ? `Incluir "${option.value}"` : option.value}
							ChipProps={{ color: "primary" }}
							freeSolo
							multiple
							fullWidth
							size="small"
							disabled={is_submitting}
							// style={{ paddingLeft: 50, paddingRight: 50 }}
							noOptionsText="Sin opciones"
							renderInput={(params) => (
								<TextField
									label="Tags (Opcional)"
									variant="outlined"
									style={{ backgroundColor: "white" }}
									fullWidth
									{...params}
								/>
							)}
						/>
						<Typography align="center" variant="h6">Puede incluir TAGs para clasificar y realizar búsquedas futuras</Typography>
					</Box>
				</Grid>

				{/* CAMPO PARA EL ORIGEN DEL DOCUMENTO */}
				<Grid item xs={3}>
					<Box display="flex" justifyContent="center">
						<Autocomplete
							name="origen"
							options={Origenes || []}
							value={formik.values.origen}
							onChange={(event, value) => formik.setFieldValue("origen", value)}
							getOptionLabel={(origen) => origen}
							size="small"
							fullWidth
							noOptionsText="Sin opciones"
							renderInput={(params) => (
								<TextField
									label="Origen"
									variant="outlined"
									required
									style={{ backgroundColor: "white" }}
									// helperText={formik.errors.origen}
									error={Boolean(formik.errors.origen)}
									{...params}
								/>
							)}
						/>
					</Box>
				</Grid>

				{/* SEPADADOR DE SECCIÓN */}
				<Grid item xs={12}>
					<Divider />
				</Grid>

				{/* BOTÓN PARA ENVIAR DATOS */}
				<Grid item xs={12}>
					{/* BOTÓN PARA CARGAR LOS ARCHIVOS SELECCIONADOS */}
					<Box display="flex" justifyContent="center">
						<Tooltip title="CTRL + Enter" style={{ margin: 20 }}>
							<Button onClick={handle_submit} disabled={isDisabled()} variant="contained" color="primary" size="large">
								Cargar Documentos
							</Button>
						</Tooltip>
					</Box>
				</Grid>
			</Grid>

			{/* COMPONENTE DE CARGA */}
			<Backdrop open={is_submitting} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography align="center" variant="h5">Cargando datos...</Typography>
			</Backdrop>
		</Fragment >
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 100,
		color: "#fff",
	},
}));