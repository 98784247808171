import React, { useEffect } from "react";
import MaterialTable from "../../../components/materialTable";
import TooltipCustom from "../../../components/tooltip_custom";
import * as Permissions from "../../../constants/permissions";
import { CheckPermiso, CheckPermisosSome } from "../../../services/utilities/common";
import { RedirectLicitacionProyectoCargo, RedirectPostulanteCV, RedirectPostulanteEditar } from "../../../services/utilities/redirect";
import { AgregarDatosPostulante } from "../../database/postulantes/utils";

export default function Page(props) {
	const {
		asignaciones,
		permisos,
		is_loading,
	} = props;

	useEffect(() => {
		if (asignaciones) {
			for (const asignacion of asignaciones) {
				if (asignacion._postulante_ref) {
					asignacion["_postulante_ref"] = AgregarDatosPostulante(asignacion._postulante_ref);
				}
			}
		}
	}, [asignaciones]);

	let columns = [
		{
			title: "RUN",
			field: "_postulante_ref.run",
			hidden: false,
			maxWidth: 120,
		}, {
			title: "Nombre",
			field: "_postulante_ref.nombre_completo",
			hidden: false,
			maxWidth: 350,
		}, {
			title: "Ubicación",
			field: "_postulante_ref.ubicacion.comuna",
			hidden: false,
			render: row => row._postulante_ref.ubicacion ? <TooltipCustom tooltip={row._postulante_ref.ubicacion.region}>{row._postulante_ref.ubicacion.comuna}</TooltipCustom> : null,
		}, {
			title: "Teléfono",
			field: "_postulante_ref.contacto.telefono",
			hidden: true,
		}, {
			title: "Email",
			field: "_postulante_ref.contacto.email",
			hidden: true,
		}, {
			title: "Tipo",
			field: "tipo",
			maxWidth: 120,
		}, {
			title: "Cargo",
			field: "cargo_nombre",
		}, {
			title: "Gerencia",
			field: "gerencia_sigla",
			maxWidth: 100,
		},
	];

	let actions = [{
		icon: "login",
		tooltip: "Licitación/Proyecto",
		onClick: (event, row) => RedirectLicitacionProyectoCargo(row._licitacion_proyecto_ref, row._cargo_ref, row.tipo),
		disabled: !CheckPermisosSome(permisos, Permissions.CARPETA_LICITACIONES_CARGO_VER, Permissions.CARPETA_PROYECTOS_CARGO_VER),
	}, (row) => ({
		icon: "find_in_page",
		tooltip: "Ver Adjunto",
		onClick: (event, row) => RedirectPostulanteCV(row._postulante_ref.curriculum_vitae.url),
		disabled: !row._postulante_ref.curriculum_vitae || !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_ADJUNTO),
	}), {
		icon: "folder_shared",
		tooltip: "Detalles",
		onClick: (event, row) => RedirectPostulanteEditar(row._postulante_ref._id),
		disabled: !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_DETALLE),
	}];

	return (
		<MaterialTable
			title="Postulantes asignados"
			is_loading={is_loading}
			data={asignaciones?.filter(a => a._postulante_ref)}
			columns={columns}
			actions={actions}
		/>
	);
}