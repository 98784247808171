import { object, string } from "yup";
import { SITUACIONES_LABORALES } from "../../../../constants/contexts";

export const FormikInitialValues = {
	valor: "",
	contexto: SITUACIONES_LABORALES,
}

export const FormikValidationSchema = object().shape({
	valor: string()
		.min(3, ({ min }) => `La situación laboral debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La situación laboral debe tener a lo más ${max} caracteres.`)
		.required("La situación laboral es requerida."),
});