import { object, string, number } from "yup";

export const FormikInitialValues = {
	valor: 0,
	comentario: "",
}

export const FormikValidationSchema = object().shape({
	valor: number()
		.min(1, "Ingrese su evaluación.")
		.max(5, ({ max }) => `La evaluación debe ser a lo más ${max}.`)
		.required("La evaluación es requerida."),
	comentario: string()
		.min(2, ({ min }) => `El comentario debe tener al menos ${min} caracteres.`)
		.max(2000, ({ max }) => `El comentario debe tener a lo más ${max} caracteres.`)
		.optional(),
});