import React, { Fragment, useContext, useEffect, useState } from "react";
import Moment from "moment";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { Warning } from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiFileFind, mdiFilePdfBox } from "@mdi/js";
import DialogTitleComponent from "../dialog_title";
import { ActualizacionRequest, PostulanteRequest } from "../../services/request/reclutamiento/postulantes";
import { AsignacionRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import * as CVRequests from "../../services/request/reclutamiento/curriculums";
import { GetGerencia } from "../../services/request/portafolio/gerencias";
import { Paises } from "../../services/request/common";
import { uploadFileToCloudStorage } from "../../services/utilities/files";
import RegionesComunas from "../../services/data/regiones_comunas.json";
import { EstadoAsignacion, MonedaToMonto, MontoToMoneda, PorcentajeCompletitud, ToTitleCase } from "../../services/utilities/formatUtils";
import { ASIGNACION_ESTADOS, TIPO_LICITACION, TIPO_PROYECTO } from "../../constants/contexts";
import { FormikInitialValues, FormikValidationSchema, REGEX_RUN } from "./utils";
import { MainContext } from "../../App";

/**
 * Método encargado de retornar con componente con el formulario de asignación.
 * @param {*} is_open TRUE: Dialog abierto | FALSE: Dialog cerrado.
 * @param {*} cargo Datos del cargo.
 * @param {*} runs Colección de RUNs de postulantes asignados al cargo.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function DialogAsignacion(props) {
	const {
		is_open,
		cargo,
		runs = [],
		handle_close,
	} = props;

	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const [AsignacionesEncontradas, SetAsignacionesEncontradas] = useState([]);
	const Dropzone = useDropzone({ maxFiles: 1, accept: ["application/pdf"], multiple: false });

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: (async (values, helper) => {
			try {
				//VERIFICAR POSTULANTE EXISTE
				let postulante = await PostulanteRequest.ObtenerPorRUN(values.run.toLowerCase());
				if (postulante) {
					//Si el postulante existe.
					if (!postulante.curriculum_vitae) {
						//Si el postunlante no existe.
						let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
						//Se sube el CV a la nube.
						let uploadedCurriculum = await uploadFileToCloudStorage(values.file, "curriculums-test", `curriculum_vitae_${ahora}`);
						let updates = {
							ultimo_curriculum: uploadedCurriculum.url,
							curriculum_vitae: uploadedCurriculum,
						}
						//Se actualiza el CV del postulante, con el archivo seleccionado.
						await PostulanteRequest.Actualizar(postulante._id, updates);
					}
				} else {
					//Si el postunlante no existe.
					let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
					//Se sube el CV a la nube.
					let uploadedCurriculum = await uploadFileToCloudStorage(values.file, "curriculums-test", `curriculum_vitae_${ahora}`);

					//Datos del postulante.
					let datosPostulante = {
						run: values.run,
						nombre: values.nombre,
						apellido_paterno: values.apellido_paterno,
						apellido_materno: values.apellido_materno,
						fecha_nacimiento: values.fecha_nacimiento,
						nacionalidad: values.nacionalidad_object?.name,
						ubicacion: {
							comuna: values.ubicacion.comuna,
							region: values.ubicacion.region_object?.nombre,
						},
						contacto: values.contacto,
						detalle: {
							...values.detalle,
							_reclutador_ref: usuarioSesion.ref,
							pretencion_renta: MonedaToMonto(values.detalle.pretencion_renta_formato),
						},
						ultimo_curriculum: uploadedCurriculum.url,
						origen: values.origen,
						curriculum_vitae: uploadedCurriculum,
					}
					//Se calcula y asigna el porcentaje de completitud.
					datosPostulante.porcentaje = PorcentajeCompletitud(datosPostulante, 0, 0, true);

					//Se crear un nuevo postulante con los datos ingresados en el formulario.
					let response = await PostulanteRequest.Agregar(datosPostulante);
					postulante = response.data;

					// //SUBIR CV
					let datosCV = {
						_postulante_ref: postulante._id,
						_reclutador_ref: usuarioSesion.ref,
						_gerencia_ref: usuarioSesion.gerencia._id,
						nombre: `${postulante.nombre} ${postulante.apellido_paterno} ${postulante.apellido_materno}`,
						run_postulante: postulante.run,
						curriculum_vitae: uploadedCurriculum,
						ensenanza_media: null,
						hoja_vida_conductor: null,
						titulos: [],
						tags: [],
						origen: postulante.origen,
					}
					//Se guarda el CV en la base de datos.
					await CVRequests.Agregar(datosCV);
				}

				//ASIGNAR POSTULANTE
				let datosGerencia = await GetGerencia(cargo._licitacion_proyecto_ref._gerencia_ref);

				let licitacionProyectoID = cargo._licitacion_proyecto_ref._id;
				let datosAsignacion = {
					_licitacion_proyecto_ref: licitacionProyectoID,
					tipo: cargo._licitacion_proyecto_ref.tipo,
					_cargo_ref: cargo._id,
					cargo_nombre: cargo.nombre,
					cargo_codigo: cargo.codigo,
					licitacion_proyecto_codigo: cargo._licitacion_proyecto_ref.codigo,
					_gerencia_ref: datosGerencia._id,
					gerencia_sigla: datosGerencia.sigla,
					_postulante_ref: postulante._id,
					_responsable_ref: cargo._responsable_ref,
					_reclutador_ref: cargo._reclutador_ref,
					_asignador_ref: usuarioSesion.ref,
					estado: values.estado,
					pretension_renta: postulante.detalle.pretencion_renta,
				}

				//Se verifica si ya existe una asignación al postulante.
				let checkAsignaciones = await AsignacionRequest.Obtener({ _licitacion_proyecto_ref: licitacionProyectoID, _cargo_ref: cargo._id, _postulante_ref: postulante._id });
				if (checkAsignaciones.length === 0) {
					//Si no existe una postulación.
					await AsignacionRequest.Agregar(licitacionProyectoID, cargo._id, datosAsignacion);
					await ActualizacionRequest.Agregar(postulante._id, usuarioSesion.ref, `Nueva asignación al cargo ${cargo.nombre} (${cargo.codigo})`);
					ShowSnackbar("Postulante asignado exitosamente.");
				} else {
					throw new Error("Postulante ya asignado.");
				}
			} catch (error) {
				ShowSnackbar("Error al intentar asignar al postulante.", error);
			} finally {
				handle_close();
				helper.resetForm(FormikInitialValues);
			}
		})
	});

	/**
	 * Método encargado de buscar un postulante, si existe, y asignar los datos encontrados.
	 * @param {*} run RUN del postulante.
	 */
	const BuscarPostulante = async (run) => {
		try {
			let postulante = await PostulanteRequest.ObtenerPorRUN(run);
			if (postulante) {
				formik.setFieldValue("nombre", ToTitleCase(postulante.nombre));
				formik.setFieldValue("apellido_paterno", ToTitleCase(postulante.apellido_paterno));
				formik.setFieldValue("apellido_materno", ToTitleCase(postulante.apellido_materno));
				formik.setFieldValue("fecha_nacimiento", postulante.fecha_nacimiento || null);
				formik.setFieldValue("nacionalidad", postulante.nacionalidad);
				formik.setFieldValue("nacionalidad_object", Paises().find(p => p.name.toUpperCase() === postulante.nacionalidad?.toUpperCase()));
				formik.setFieldValue("ubicacion", postulante.ubicacion || {});
				formik.setFieldValue("ubicacion.region_object", RegionesComunas.find(r => r.nombre.toUpperCase() === postulante.ubicacion?.region.toUpperCase()));
				formik.setFieldValue("contacto.email", postulante.contacto.email);
				formik.setFieldValue("detalle", postulante.detalle);
				formik.setFieldValue("origen", postulante.origen);
				formik.setFieldValue("cv", postulante.curriculum_vitae);
				//Se asigna file para quitar las validaciones de formik.
				formik.setFieldValue("file", postulante.curriculum_vitae);

				let asignacionesEncontradas = await AsignacionRequest.Obtener({ _postulante_ref: postulante._id });
				SetAsignacionesEncontradas(asignacionesEncontradas);
			}
		} catch (error) {
			ShowSnackbar("Error al intentar obtener los datos del postulante encontrado.", error);
		}
	};

	useEffect(() => {
		if (Dropzone.acceptedFiles && Dropzone.acceptedFiles[0]) {
			formik.setFieldValue("file", Dropzone.acceptedFiles[0]);
		}
	}, [Dropzone.acceptedFiles]);

	useEffect(() => {
		let run = formik.values.run;
		let check = REGEX_RUN.test(run);
		if (check) {
			BuscarPostulante(run);
		}
	}, [formik.values.run]);

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	/**
	 * Método encargado de redireccionar al detalle del cargo.
	 * @param {*} asignacion Datos de la asignación.
	 */
	const handleRedirectCargo = (asignacion) => {
		let licitacionProyectoID = asignacion._licitacion_proyecto_ref;
		let cargoID = asignacion._cargo_ref;
		let url = `https://dev-reclutamiento.cydocs.cl/carpeta-proyectos/${licitacionProyectoID}/cargos/${cargoID}`;
		window.open(url, "_blank");
	}

	/**
	 * Método encargado de verificar si el RUN ingresado ya está asignado.
	 * @param {*} postulante_run RUN que será verificado.
	 * @returns TRUE: RUN ya asignado | FALSE: RUN no asignado.
	 */
	const CheckRUN = (postulante_run) => Array.from(runs)
		.map(run => String(run).toUpperCase())
		.includes(String(postulante_run).toUpperCase());

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="lg" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Agregar Asignación</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{
						`Ingrese la información requerida del postulante
						para ${InfoLicitacionProyecto(cargo._licitacion_proyecto_ref)} 
						en el cargo ${cargo.nombre}
						y luego guarde los cambios.`
					}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextField
							name="run"
							label="RUN"
							value={formik.values.run}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.run || (CheckRUN(formik.values.run) ? "Postulante ya asignado" : AsignacionesEncontradas.length > 0 ? WarningHelperText() : "")}
							error={Boolean(formik.errors.run) || CheckRUN(formik.values.run)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="apellido_paterno"
							label="Primer Apellido"
							value={formik.values.apellido_paterno}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.apellido_paterno}
							error={Boolean(formik.errors.apellido_paterno)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="apellido_materno"
							label="Segundo Apellido"
							value={formik.values.apellido_materno}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.apellido_materno}
							error={Boolean(formik.errors.apellido_materno)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.email"
							label="Email"
							value={formik.values.contacto.email}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.email}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.email)}
						/>
					</Grid>
					<Grid item xs={4}>
						<DatePicker
							label="Fecha de nacimiento"
							value={formik.values.fecha_nacimiento}
							onChange={date => formik.setFieldValue("fecha_nacimiento", date)}
							format="DD/MM/YYYY"
							inputVariant="outlined"
							size="small"
							fullWidth
							autoOk
							clearable
							okLabel="Aceptar"
							cancelLabel="Cancelar"
							clearLabel="Limpiar"
							helperText={formik.errors.fecha_nacimiento}
							error={Boolean(formik.errors.fecha_nacimiento)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							value={formik.values.nacionalidad_object}
							options={Paises()}
							getOptionLabel={(pais) => pais.name}
							onChange={(event, value) => formik.setFieldValue("nacionalidad_object", value)}
							noOptionsText="Sin opciones"
							size="small"
							renderInput={(params) => (
								<TextField
									label="Nacionalidad"
									helperText={formik.errors.nacionalidad_object}
									error={Boolean(formik.errors.nacionalidad_object)}
									variant="outlined"
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							name="ubicacion.region_object"
							value={formik.values.ubicacion.region_object}
							options={RegionesComunas}
							getOptionLabel={(region) => region.nombre}
							onChange={(eveny, value) => {
								formik.setFieldValue("ubicacion.comuna", null);
								formik.setFieldValue("ubicacion.region_object", value);
							}}
							size="small"
							fullWidth
							renderInput={(params) => (
								<TextField
									label="Región"
									variant="outlined"
									helperText={formik.errors.ubicacion && formik.errors.ubicacion.region_object}
									error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.region_object)}
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							name="ubicacion.comuna"
							value={formik.values.ubicacion.comuna}
							options={RegionesComunas && formik.values.ubicacion.region_object ? RegionesComunas.find(rc => rc.nombre === formik.values.ubicacion.region_object.nombre)?.comunas : []}
							getOptionLabel={(comuna) => comuna}
							onChange={(eveny, value) => formik.setFieldValue("ubicacion.comuna", value)}
							disabled={!formik.values.ubicacion.region_object}
							size="small"
							fullWidth
							renderInput={(params) => (
								<TextField
									label="Comuna"
									variant="outlined"
									helperText={formik.errors.ubicacion && formik.errors.ubicacion.comuna}
									error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.comuna)}
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="detalle.pretencion_renta_formato"
							label="Renta líquida (pesos)"
							value={formik.values.detalle.pretencion_renta_formato}
							onChange={handleChangePretencionRenta}
							variant="outlined"
							size="small"
							fullWidth
							helperText={formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato}
							error={Boolean(formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Autocomplete
							options={Object.values(ASIGNACION_ESTADOS)}
							getOptionLabel={(estado) => estado}
							renderOption={(estado) => EstadoAsignacion(estado, cargo._licitacion_proyecto_ref.tipo)}
							onChange={(event, value) => formik.setFieldValue("estado", value)}
							noOptionsText="Sin opciones"
							size="small"
							renderInput={(params) => (
								<TextField
									label="Estado"
									helperText={formik.errors.estado}
									error={Boolean(formik.errors.estado)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* SI ES UN POSTULANTE NUEVO O SIN CV */}
						{!formik.values.cv && (
							<Paper elevation={1} {...Dropzone.getRootProps()}>
								<Box display="flex" alignItems={"center"} style={{ height: 38 }}>
									<input {...Dropzone.getInputProps()} />
									{/* SI AÚN NO SE SELECCIONA UN ARCHIVO */}
									{(!Dropzone.acceptedFiles || !Dropzone.acceptedFiles[0]) && (
										<Fragment>
											<Icon path={mdiFileFind} size={1} color={formik.errors.file ? "#f44336" : "black"} />
											<Typography color={formik.errors.file ? "error" : "inherit"}>Buscar CV del postulante</Typography>
										</Fragment>
									)}
									{/* SI YA SE SELECCIONÓ UN ARCHIVO */}
									{Dropzone.acceptedFiles && Dropzone.acceptedFiles[0] && (
										<Fragment>
											<Icon path={mdiFilePdfBox} size={1} />
											<Tooltip title={Dropzone.acceptedFiles[0].name}>
												<Typography>{`Archivo ${String(Dropzone.acceptedFiles[0].name).substring(0, 20)}... seleccionado`}</Typography>
											</Tooltip>
										</Fragment>
									)}
								</Box>
							</Paper>
						)}

						{/* SI ES UN POSTULANTE EXISTENTE Y CON CV */}
						{formik.values.cv && (
							<Paper elevation={1}>
								<Box display="flex" alignItems={"center"} style={{ height: 38 }}>
									<Fragment>
										<Icon path={mdiFilePdfBox} size={1} />
										<Tooltip title={formik.values.cv.nombre_original}>
											<Typography>{`Archivo ${String(formik.values.cv.nombre_original).substring(0, 20)}... seleccionado`}</Typography>
										</Tooltip>
									</Fragment>
								</Box>
							</Paper>
						)}
					</Grid>
				</Grid>
				{/* ASIGNACIONES DEL POSTULANTE */}
				{AsignacionesEncontradas.length > 0 && (
					<Fragment>
						{/* SEPARADOR DE SECCIÓN */}
						<Divider style={{ marginTop: 10, marginBottom: 10 }} />
						{/* TÍTULO DE LA SECCIÓN */}
						<Typography variant="subtitle1">
							Asignaciones vigentes donde está participando
						</Typography>
						{/* COLECCIÓN DE ASIGNACIONES DEL POSTULANTE */}
						{AsignacionesEncontradas.map((asignacion, index) => (
							<Tooltip title={`[${asignacion.gerencia_sigla}] ${asignacion.cargo_nombre}`} key={`tooltip_${index}`}>
								<Chip
									label={asignacion.cargo_codigo}
									color="primary"
									onClick={() => handleRedirectCargo(asignacion)}
									style={{ margin: 2 }}
								/>
							</Tooltip>
						))}
					</Fragment>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={!formik.isValid || formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog >
	);
}

/**
 * Método encargado de generar el componente para mensaje de advertencia en caso de postulante con asignaciones.
 * @returns Component.
 */
function WarningHelperText() {
	return (
		<Box display="flex" alignItems="center">
			<Warning style={{ fontSize: 20, color: "#ff9800" }} />
			<Typography variant="caption" color="error" style={{ marginTop: 2, marginRight: 3 }}>
				Participa en otras búsquedas
			</Typography>
		</Box>
	);
}

/**
 * Método encargado de obtener información de la licitación/proyecto.
 * @param {*} LicitacionProyecto Datos de la licitación/proyecto.
 * @returns Información formateada.
 */
function InfoLicitacionProyecto(LicitacionProyecto) {
	switch (LicitacionProyecto.tipo) {
		case TIPO_LICITACION:
			return `la licitación ${LicitacionProyecto.nombre}`;
		case TIPO_PROYECTO:
			return `el proyecto ${LicitacionProyecto.nombre}`;
		default:
			return "";
	}
}