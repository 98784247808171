import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { GeneradasRequest } from "../../../services/request/reclutamiento/cartas_oferta";
import { MainContext } from "../../../App";

export default function Index(props) {
	const CartasSWR = useSWR("cartas-oferta", (key) => GeneradasRequest.ObtenerPorReclutadorID(MainCTX.usuarioSesion.ref), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (CartasSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de mis cartas de oferta.", CartasSWR.error);
		}
	}, [MainCTX, CartasSWR.error]);

	return (
		<Page
			cartas_oferta={CartasSWR.data}
			is_loading={CartasSWR.isValidating}
		/>
	);
}