import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { Obtener } from "../../../../services/request/reclutamiento/formulariosPostulacion";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const FormulariosPostulacionSWR = useSWR("formularios-postulacion", Obtener);
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (FormulariosPostulacionSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de los formularios de postulación.", FormulariosPostulacionSWR.error);
		}
	}, [MainCTX, FormulariosPostulacionSWR.error]);

	return (
		<Page
			formularios_postulacion={FormulariosPostulacionSWR.data}
			permisos={MainCTX.permisos}
			is_loading={FormulariosPostulacionSWR.isValidating}
			mutate_formularios_postulacion={FormulariosPostulacionSWR.mutate}
		/>
	);
}