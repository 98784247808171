import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../../App";

export default function Index(props) {
	const ProyectosSWR = useSWR("proyectos", (key) => LicitacionProyectoRequets.ObtenerProyectos(), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (ProyectosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de los proyectos.", ProyectosSWR.error);
		}
	}, [MainCTX, ProyectosSWR.error]);

	return (
		<Page
			proyectos={ProyectosSWR.data}
			is_loading={ProyectosSWR.isValidating}
			gerencia={MainCTX.usuarioSesion.gerencia.sigla}
			mutate_proyectos={ProyectosSWR.mutate}
		/>
	);
}