import React, { Fragment, useContext, useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import * as Permissions from "../../../constants/permissions";
import TabPanelComponent from "../../../components/tab_panel";
import AccessDeniedComponent from "../../../components/access_denied";
import Empresas from "./empresas";
import Discapacidades from "./discapacidades";
import EstadosCiviles from "./estados_civiles";
import Instituciones from "./instituciones";
import LicenciasConducir from "./licencias_conducir";
import NivelesIngles from "./niveles_ingles";
import SituacionesLaborales from "./situaciones_laborales";
import Titulos from "./titulos";
import NivelesEstudio from "./niveles_estudio";
import { CheckPermiso } from "../../../services/utilities/common";
import { MainContext } from "../../../App";

export default function Page(props) {
	const [TabIndex, SetTabIndex] = useState(0);
	const MainCTX = useContext(MainContext);

	const handleChange = (event, value) => {
		SetTabIndex(value);
	};

	return (
		<Fragment>
			<AppBar position="static" color="inherit">
				<Tabs value={TabIndex} onChange={handleChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
					<Tab label="Empresas" />
					<Tab label="Discapacidades" />
					<Tab label="Estados Civiles" />
					<Tab label="Instituciones" />
					<Tab label="Licencias de Conducir" />
					<Tab label="Niveles de Inglés" />
					<Tab label="Situaciones Laborales" />
					<Tab label="Títulos" />
					<Tab label="Niveles de Estudio" />
				</Tabs>
			</AppBar>

			{/* PESTAÑA EMPRESAS */}
			<TabPanelComponent value={TabIndex} index={0}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_EMPRESAS_VER) ? <Empresas /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA DISCAPACIDADES */}
			<TabPanelComponent value={TabIndex} index={1}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_DISCAPACIDADES_VER) ? <Discapacidades /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA ESTADOS CIVILES */}
			<TabPanelComponent value={TabIndex} index={2}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_ESTADOS_CIVILES_VER) ? <EstadosCiviles /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA INSTITUCIONES */}
			<TabPanelComponent value={TabIndex} index={3}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_INSTITUCIONES_VER) ? <Instituciones /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA LICENCIAS DE CONDUCIR */}
			<TabPanelComponent value={TabIndex} index={4}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_LICENCIAS_CONDUCIR_VER) ? <LicenciasConducir /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA NIVELES INGLÉS */}
			<TabPanelComponent value={TabIndex} index={5}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_NIVELES_INGLES_VER) ? <NivelesIngles /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA SITUACIONES LABORALES */}
			<TabPanelComponent value={TabIndex} index={6}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_SITUACIONES_LABORALES_VER) ? <SituacionesLaborales /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA TÍTULOS */}
			<TabPanelComponent value={TabIndex} index={7}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_TITULOS_VER) ? <Titulos /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA NIVELES DE ESTUDIO */}
			<TabPanelComponent value={TabIndex} index={8}>
				{CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_VER) ? <NivelesEstudio /> : <AccessDeniedComponent />}
			</TabPanelComponent>
		</Fragment>
	);
}