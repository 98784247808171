import React, { Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { AccordionSummary, Collapse, IconButton, Paper, Typography } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import ReferidoDialog from "./referido_dialog";
import { ActualizacionRequest, ReferidoRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function Referidos(props) {
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);

	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Correo",
			field: "email",
		}, {
			title: "Teléfono",
			field: "telefono",
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarReferido(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} referido Datos del referido.
	 */
	const handleEliminarReferido = async (referido) => {
		try {
			//
			let responses = await Promise.all([
				ReferidoRequest.Eliminar(referido._postulante_ref, referido._id),
				ActualizacionRequest.Agregar(referido._postulante_ref, usuarioSesion.ref, "Referido eliminado"),
			]);

			//Nueva actualización.
			let actualizacion = responses[1];
			let referidos = Array.from(formik.values.referidos);
			//Se elimina el referido.
			referidos.splice(referido.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("referidos", referidos);
			formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

			notistack.enqueueSnackbar("Referido del postulante eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el referido del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE REFERIDOS */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Referidos"
					is_loading={false}
					data={formik.values.referidos}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Referidos (${formik.values.referidos.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG REFERIDO */}
			<ReferidoDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}