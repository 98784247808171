import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { Box, Chip, Dialog, DialogContent, DialogContentText, Fab, LinearProgress, Paper, Switch, Tooltip, Typography } from "@material-ui/core";
import { Add as AddIcon, Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Rating, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { mdiTimelineText, mdiFormatListBulleted } from "@mdi/js";
import { Icon } from "@mdi/react";
import MaterialTable from "../../../../components/materialTable";
import DialogTitleComponent from "../../../../components/dialog_title";
import * as Permissions from "../../../../constants/permissions";
import { CARPETA_LICITACIONES, CARPETA_PROYECTOS, CARGO_LICITACION_PROYECTO, HOME } from "../../../../constants/routes";
import { TIPO_LICITACION, TIPO_PROYECTO } from "../../../../constants/contexts";
import { EvaluacionRequest } from "../../../../services/request/reclutamiento/postulantes";
import { InteraccionRequest } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { FormatearFecha, NombreCompleto } from "../../../../services/utilities/formatUtils";
import { CheckPermisosSome } from "../../../../services/utilities/common";
import { MainContext } from "../../../../App";

/**
 * Componente para visualizar las evaluaciones del postulante.
 * @param {*} postulante Datos del postulante.
 * @param {*} handle_add Método encargado de agregar una nueva evaluación.
 * @param {*} handle_update Método encargado de actualizar una evaluación.
 * @param {*} handle_close Método encargado de cerrar el popup.
 * @returns Component.
 */
export default function DialogEvaluaciones(props) {
	const {
		postulante,
		handle_add,
		handle_update,
		handle_close,
	} = props;

	const [Flag, SetFlag] = useState(false);
	const [Comentarios, SetComentarios] = useState([]);
	const MainCTX = useContext(MainContext);
	const history = useHistory();
	const classes = useStyles();
	const EvaluacionesSWR = useSWR("evaluaciones_detalles", (key) => EvaluacionRequest.Obtener(postulante._id), { revalidateOnFocus: false });
	const InteraccionesSWR = useSWR("interacciones", (key) => InteraccionRequest.Obtener({ _postulante_ref: postulante._id }, "/_asignacion_ref/_reclutador_ref"), { revalidateOnFocus: false });

	useEffect(() => {
		if (EvaluacionesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener datos de las evaluaciones.", EvaluacionesSWR.error);
		}

		if (InteraccionesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener datos de los comentarios.", EvaluacionesSWR.error);
		}
	}, [MainCTX, EvaluacionesSWR.error, InteraccionesSWR.error]);

	useEffect(() => {
		let comentarios = [];
		if (EvaluacionesSWR.data) {
			let evaluaciones = Array.from(EvaluacionesSWR.data).map(evaluacion => ({
				reclutador: NombreCompleto(evaluacion._reclutador_ref),
				comentario: evaluacion.comentario,
				valor: evaluacion.valor,
				fecha_formato: FormatearFecha(evaluacion.fecha_creacion, "DD/MM/YYYY HH:mm"),
				fecha_creacion: evaluacion.fecha_creacion,
				tipo: TIPO_EVALUACION,
			}));
			comentarios.push(evaluaciones);
		}

		if (InteraccionesSWR.data) {
			let interacciones = Array.from(InteraccionesSWR.data).map(interaccion => ({
				reclutador: NombreCompleto(interaccion._reclutador_ref),
				codigo: interaccion._asignacion_ref.cargo_codigo,
				comentario: interaccion.comentario,
				valor: interaccion.evaluacion,
				fecha_formato: FormatearFecha(interaccion.fecha_creacion, "DD/MM/YYYY HH:mm"),
				fecha_creacion: interaccion.fecha_creacion,
				licitacionProyectoID: interaccion._asignacion_ref._licitacion_proyecto_ref,
				cargoID: interaccion._asignacion_ref._cargo_ref,
				cargoNombre: interaccion._asignacion_ref.cargo_nombre,
				tipoLP: interaccion._asignacion_ref.tipo,
				tipo: TIPO_INTERACCION,
			}));
			comentarios.push(interacciones);
		}

		SetComentarios(comentarios.flat().sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)));
	}, [EvaluacionesSWR.data, InteraccionesSWR.data]);

	/**
	 * 
	 * @param {*} datos 
	 */
	const redireccionarCargo = (datos) => {
		let rutaCargo = CARGO_LICITACION_PROYECTO.replace(":cargo_id", datos.cargoID);
		if (datos.tipoLP === TIPO_LICITACION) {
			window.open(`${CARPETA_LICITACIONES}/${datos.licitacionProyectoID}${rutaCargo}`, "_blank");
		} else if (datos.tipoLP === TIPO_PROYECTO) {
			window.open(`${CARPETA_PROYECTOS}/${datos.licitacionProyectoID}${rutaCargo}`, "_blank");
		} else {
			history.push(HOME);
		}
	}

	/**
	 * Método encargado de retornar el componente, según tipo de dato.
	 * @param {*} datos Datos del registro.
	 * @returns Componente.
	 */
	const EvaluacionCodigoComponent = (datos) => {
		if (datos.tipo === TIPO_EVALUACION) {
			return (
				<Rating name="valor" value={datos.valor} size="large" readOnly />
			);
		} else if (datos.tipo === TIPO_INTERACCION) {
			return (
				<Tooltip title={datos.cargoNombre} placement="right">
					<Chip label={datos.codigo} onClick={() => redireccionarCargo(datos)} color="primary" size="small" />
				</Tooltip>
			);
		} else {
			return null;
		}
	}

	let columns = [{
		title: "Reclutador",
		field: "reclutador",
		width: 200,
	}, {
		title: "Comentario",
		field: "comentario",
	}, {
		title: "Evaluación/Código",
		render: (row) => EvaluacionCodigoComponent(row),
		maxWidth: 120,
	}, {
		title: "Fecha",
		field: "fecha_formato",
		maxWidth: 120,
	}];

	let actions = [(row) => ({
		tooltip: "Actualizar o Eliminar",
		icon: "edit",
		onClick: (event, row) => handle_update(row),
		disabled: !CheckPermisosSome(MainCTX.permisos, Permissions.CANDIDATOS_EDITAR_COMENTARIOS, Permissions.CANDIDATOS_ELIMINAR_COMENTARIOS),
		hidden: row.tipo !== TIPO_EVALUACION,
	})];

	return (
    <Dialog
      open={true}
      onClose={handle_close}
      maxWidth="lg"
      PaperProps={{ style: { borderRadius: 20 } }}
      fullWidth
    >
      <DialogTitleComponent onClose={handle_close}>
        Comentarios
      </DialogTitleComponent>
      <DialogContent>
        <DialogContentText>
          {`Listado de comentarios del candidato ${NombreCompleto(
            postulante
          )}.`}
        </DialogContentText>
        <Fragment>
          {/* SWITCH Y BOTÓN AGREGAR */}
          <Box display="flex" flexDirection="row" className={classes.box_main}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              className={classes.box}
            >
              <Tooltip title="Modo Línea de Tiempo">
                <Icon path={mdiTimelineText} size={1} />
              </Tooltip>
              <Switch
                checked={Flag}
                onChange={(event, checked) => SetFlag(checked)}
              />
              <Tooltip title="Modo Lista">
                <Icon path={mdiFormatListBulleted} size={1} />
              </Tooltip>
            </Box>
            <Fab
              onClick={() => handle_add()}
              variant="extended"
              size="small"
              color="primary"
            >
              <AddIcon />
              Agregar
            </Fab>
          </Box>

          {/* MODO LÍNEA DE TIEMPO */}
          {!Flag && (
            <Timeline align="alternate" style={{ height: 300 }}>
              {(EvaluacionesSWR.isValidating ||
                InteraccionesSWR.isValidating) && <LinearProgress />}
              {!EvaluacionesSWR.isValidating &&
                !InteraccionesSWR.isValidating &&
                Comentarios.length === 0 &&
                "sin datos"}
              {Comentarios.length > 0 &&
                Comentarios.map((d, index) => (
                  <TimelineItem key={`timeline_item_${index}`}>
                    <TimelineOppositeContent style={{ alignSelf: "center" }}>
                      <Typography color="textSecondary">
                        {d.fecha_formato}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={10} className={classes.paper}>
                        <Box display={"inline-flex"} alignItems="center">
                          {/* COMPONENTES IZQUIERDA */}
                          {index % 2 === 1 && (
                            <Fragment>
                              {/* NOMBRE RECLUTADOR */}
                              <Typography style={{ paddingRight: 10 }}>
                                {`Reclutador: ${d.reclutador}`}
                              </Typography>

                              {/* FAB EDIT COMENTARIO */}
                              {d.tipo === TIPO_EVALUACION && (
                                <Fab
                                  onClick={() => handle_update(d)}
                                  size="small"
                                  style={{
                                    position: "absolute",
                                    left: 1,
                                    top: 15,
                                  }}
                                >
                                  <Tooltip title="Click para Actualizar o Eliminar">
                                    <Edit />
                                  </Tooltip>
                                </Fab>
                              )}
                            </Fragment>
                          )}

                          {/* CÓDIGO CARGO */}
                          {d.tipo === TIPO_INTERACCION && (
                            <Tooltip title={d.cargoNombre} placement="top">
                              <Chip
                                label={d.codigo}
                                onClick={() => redireccionarCargo(d)}
                                color="primary"
                                size="small"
                              />
                            </Tooltip>
                          )}

                          {/* RATING */}
                          {d.tipo === TIPO_EVALUACION && (
                            <Rating
                              name="valor"
                              value={d.valor}
                              size="medium"
                              readOnly
                            />
                          )}

                          {/* COMPONENTES DERECHA */}
                          {index % 2 === 0 && (
                            <Fragment>
                              {/* NOMBRE RECLUTADOR */}
                              <Typography style={{ paddingLeft: 10 }}>
                                {`Reclutador: ${d.reclutador}`}
                              </Typography>

                              {/* FAB EDIT COMENTARIO */}
                              {d.tipo === TIPO_EVALUACION && (
                                <Fab
                                  onClick={() => handle_update(d)}
                                  size="small"
                                  style={{
                                    position: "absolute",
                                    right: 1,
                                    top: 15,
                                  }}
                                >
                                  <Tooltip title="Click para Actualizar o Eliminar">
                                    <Edit />
                                  </Tooltip>
                                </Fab>
                              )}
                            </Fragment>
                          )}
                        </Box>

                        {/* COMENTARIO */}
                        {d.comentario && (
                          <Typography variant="subtitle2">
                            {d.comentario}
                          </Typography>
                        )}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              <br />
            </Timeline>
          )}

          {/* MODO LISTA */}
          {Flag && (
            <div style={{ height: 330 }}>
              <MaterialTable
                title="Comentarios"
                is_loading={
                  EvaluacionesSWR.isValidating || InteraccionesSWR.isValidating
                }
                data={Comentarios}
                columns={columns}
                //actions={actions}		// Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279
                grouping={false}
              />
              <br />
            </div>
          )}
        </Fragment>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
	box_main: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(10),
	},
	box: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(1, 2),
	},
}));

const TIPO_EVALUACION = "EVALUACION";
const TIPO_INTERACCION = "INTERACCION";