import React, { Fragment, useEffect, useState } from "react";
import MaterialTable from "../../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import * as Permissions from "../../../../constants/permissions";
import { CheckPermiso } from "../../../../services/utilities/common";
import { FormatearFecha } from "../../../../services/utilities/formatUtils";

export default function Page(props) {
	let {
		formularios_postulacion,
		permisos,
		is_loading,
		mutate_formularios_postulacion,
	} = props;

	const [IsOpen, SetIsOpen] = useState({});

	useEffect(() => {
		if (formularios_postulacion) {
			for (const formulario of formularios_postulacion.data) {
				formulario["fecha_format"] = FormatearFecha(formulario.fecha_creacion, "DD/MM/YYYY HH:mm");
			}
		}
	}, [formularios_postulacion]);

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Link",
			field: "link_formulario",
			width: 200,
			render: (row) => <a href={row.link_formulario} target="_blank" rel="noreferrer">Link</a>
		}, {
			title: "Fecha",
			field: "fecha_format",
			width: 200,
		}
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			hidden: true || !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_EDITAR),
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			hidden: !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_ELIMINAR),
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
		}, {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			hidden: !CheckPermiso(permisos, Permissions.ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_AGREGAR),
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: null }),
		},
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	return (
		<Fragment>
			{/* LISTADO DE FORMULARIOS DE POSTULACIÓN */}
			<MaterialTable
				title="Formularios de postulación"
				is_loading={is_loading}
				data={formularios_postulacion?.data}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					handle_close={handleCloseDialog}
					mutate_formularios_postulacion={mutate_formularios_postulacion}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_formularios_postulacion={mutate_formularios_postulacion}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_formularios_postulacion={mutate_formularios_postulacion}
				/>
			)}
		</Fragment>
	);
}