import React, { Fragment } from "react";
import { Paper, Typography } from "@material-ui/core";

export default function DetalleIdiomas(props) {
	const {
		idiomas,
	} = props;

	return (
		<Fragment>
			{Array.from(idiomas).map((idioma, index) => (
				<Fragment key={`fragment_${index}`}>
					<Paper elevation={5} style={{ padding: 10 }}>
						<Typography>{`Idioma ${idioma.language.description}, nivel escrito ${idioma.written.description}, nivel hablado ${idioma.spoken.description} y nivel de traducción ${idioma.translation.description}`}</Typography>
					</Paper>
					<br />
				</Fragment>
			))}
		</Fragment>
	);
}