import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

const RUTA = "licitaciones-proyectos";
const RUTA_CARGOS = "cargos";
const RUTA_ADJUNTOS = "adjuntos";
const RUTA_ASIGNACIONES = "asignaciones";
const RUTA_INTERACCIONES = "interacciones";

/**
 * Request de licitaciones/proyectos.
 */
export const LicitacionProyectoRequets = {
	/**
	 * Método encargado de agregar una nueva licitación o proyecto.
	 * @param {*} licitacionProyecto Datos de la licitación o proyecto.
	 * @returns Response.
	 */
	Agregar: async function (licitacionProyecto) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}`;
			let response = await Axios.post(url, licitacionProyecto);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una licitación o proyecto.
	 * @param {*} licitacionProyecto Datos actualizados de la licitación o proyecto.
	 * @returns Response.
	 */
	Actualizar: async function (licitacionProyecto) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyecto._id}`;
			let response = await Axios.put(url, licitacionProyecto);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una licitación o proyecto.
	 * @param {*} licitacionProyectoID ID de la licitación o proyecto.
	 * @returns Response.
	 */
	Eliminar: async function (licitacionProyectoID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}`;
			let response = await Axios.delete(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la colección de licitaciones/proyectos.
	 * @returns Colección de licitaciones/proyectos.
	 */
	Obtener: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/nombre/codigo/_responsable_ref/_reclutador_ref/_gerencia_ref.nombre/_gerencia_ref.sigla/mandante/descripcion/estado/tipo/fecha_creacion`;
			let response = await Axios.get(url);
			return response.data.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la colección de licitaciones.
	 * @returns Colección de licitaciones.
	 */
	ObtenerLicitaciones: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/licitaciones/_responsable_ref/_reclutador_ref/_gerencia_ref/_contrato_ref/cant_vacantes_abiertas/cant_vacantes_cerradas`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la colección de proyectos.
	 * @returns Colección de proyectos.
	 */
	ObtenerProyectos: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/proyectos/_responsable_ref/_reclutador_ref/_gerencia_ref/_contrato_ref/cant_vacantes_abiertas/cant_vacantes_cerradas`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una licitación o proyecto, por su ID.
	 * @param {*} licitacionProyectoID ID de la licitación o proyecto.
	 * @returns Response.
	 */
	ObtenerPorID: async function (licitacionProyectoID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/_responsable_ref/_reclutador_ref/_gerencia_ref/_contrato_ref`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Requests de cargos de licitaciones/proyectos.
 */
export const CargoRequest = {
	/**
	 * Método encargado de agregar un cargo a una licitación o proyecto.
	 * @param {*} licitacionProyectoID ID de la licitación o proyecto.
	 * @param {*} cargo Datos del cargo.
	 * @returns Response.
	 */
	Agregar: async function (licitacionProyectoID, cargo) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}`;
			let response = await Axios.post(url, cargo);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una licitación o proyecto.
	 * @param {*} licitacionProyecto Datos actualizados de la licitación o proyecto.
	 * @param {*} cargo Datos del cargo.
	 * @returns Response.
	 */
	Actualizar: async function (licitacionProyectoID, cargo) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargo._id}`;
			let response = await Axios.put(url, cargo);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una licitación o proyecto.
	 * @param {*} licitacionProyectoID ID de la licitación o proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @returns Response.
	 */
	Eliminar: async function (licitacionProyectoID, cargoID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}`;
			let response = await Axios.delete(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los cargos.
	 * @param {*} filtros Filtros de la consulta.
	 * @param {*} populates Texto con la colección de datos a popular.
	 * @returns Colección de asignaciones filtradas.
	 * @link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams.
	 * @link https://stackoverflow.com/a/34702138
	 */
	Obtener: async (filtros = null, populates = "") => {
		try {
			let params = new URLSearchParams(JSON.parse(JSON.stringify(filtros))).toString();
			let url = `${RECLUTAMIENTO}/${RUTA}/${RUTA_CARGOS}${populates}?${params}`;
			let response = await Axios.get(url);
			let cargos = Array.from(response.data);
			cargos.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
			return cargos;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
 * Método encargado de obtener los cargos de una licitación o proyecto, por su ID, con los datos del reclutador.
 * @param {*} licitacionProyectoID ID de la licitación o proyecto.
 * @returns Response.
 */
	ObtenerPorLicitacionProyecto: async function (licitacionProyectoID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/_reclutador_ref`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener un cargo, por su ID, con los datos de la licitación/proyecto y reclutador.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @returns Response.
	 */
	ObtenerPorID: async function (licitacionProyectoID, cargoID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/_licitacion_proyecto_ref/_reclutador_ref`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener el KPI de los cargos.
	 * @returns Datos de KPI de los cargos.
	 */
	ObtenerKPI: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/cargos-kpi`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de adjuntos de cargo.
 */
export const AdjuntoRequest = {
	/**
	 * Método encargado de agregar un adjunto a un cargo.
	 * @param {*} licitacionProyectoID ID de la licitacion/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} adjunto Datos del adjunto.
	 * @returns Response.
	 */
	Agregar: async (licitacionProyectoID, cargoID, adjunto) => {
		try {
			if (licitacionProyectoID && cargoID && adjunto) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ADJUNTOS}`;
				let response = await Axios.post(url, { _cargo_ref: cargoID, adjunto: adjunto });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar un adjunto de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} adjunto Datos del adjunto.
	 * @returns Response.
	 */
	Actualizar: async (licitacionProyectoID, cargoID, adjunto) => {
		try {
			if (licitacionProyectoID && cargoID && adjunto) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ADJUNTOS}/${adjunto._id}`;
				let response = await Axios.put(url, adjunto);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar un adjunto de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} adjuntoID ID del adjunto.
	 * @returns Response.
	 */
	Eliminar: async (licitacionProyectoID, cargoID, adjuntoID) => {
		try {
			if (licitacionProyectoID && cargoID && adjuntoID) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ADJUNTOS}/${adjuntoID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los adjuntos de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @returns Colección de adjuntos de un cargo.
	 */
	Obtener: async (licitacionProyectoID, cargoID) => {
		try {
			if (licitacionProyectoID && cargoID) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ADJUNTOS}`;
				let response = await Axios.get(url);
				let adjuntos = Array.from(response.data);
				adjuntos.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
				return adjuntos;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de asignación de cargo.
 */
export const AsignacionRequest = {
	/**
	 * Método encargado de agregar una asignación, de un postulante a un cargo, en una licitación/proyecto.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacion Datos de la asignación.
	 * @returns Response.
	 */
	Agregar: async (licitacionProyectoID, cargoID, asignacion) => {
		try {
			if (licitacionProyectoID && cargoID && asignacion) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}`;
				let response = await Axios.post(url, asignacion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una asignación de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacion Datos de la asignación.
	 * @returns Response.
	 */
	Actualizar: async (licitacionProyectoID, cargoID, asignacion) => {
		try {
			if (licitacionProyectoID && cargoID && asignacion) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacion._id}`;
				let response = await Axios.put(url, asignacion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una asignacion de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @returns Response.
	 */
	Eliminar: async (licitacionProyectoID, cargoID, asignacionID) => {
		try {
			if (licitacionProyectoID && cargoID && asignacionID) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las asignaciones.
	 * @param {*} filtros Filtros de la consulta.
	 * @param {*} populates Texto con la colección de datos a popular.
	 * @returns Colección de asignaciones filtradas.
	 * @link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams.
	 * @link https://stackoverflow.com/a/34702138
	 */
	Obtener: async (filtros = null, populates = "") => {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${RUTA_ASIGNACIONES}${populates}`;
			if (filtros) {
				let params = new URLSearchParams(JSON.parse(JSON.stringify(filtros))).toString();
				url = `${url}?${params}`;
			}
			let response = await Axios.get(url);
			let asignaciones = Array.from(response.data);
			asignaciones.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
			return asignaciones;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una asignación, por su ID, con los datos del cargo, reclutador y postulante.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @returns Response.
	 */
	ObtenerPorID: async function (licitacionProyectoID, cargoID, asignacionID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}/_licitacion_proyecto_ref/_cargo_ref/_responsable_ref/_reclutador_ref/_asignador_ref/_postulante_ref`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener el KPI de las asignaciones.
	 * @returns Datos de KPI de las asignaciones.
	 */
	ObtenerKPI: async function () {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/asignaciones-kpi`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
}

/**
 * Request de interación.
 */
export const InteraccionRequest = {
	/**
	 * Método encargado de agregar una interacción.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @param {*} interaccion Datos de la interacción.
	 * @returns Response.
	 */
	Agregar: async (licitacionProyectoID, cargoID, asignacionID, interaccion) => {
		try {
			if (licitacionProyectoID && cargoID && asignacionID && interaccion) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}/${RUTA_INTERACCIONES}`;
				let response = await Axios.post(url, interaccion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una interacción.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @param {*} interaccion Datos de la interacción.
	 * @returns Response.
	 */
	Actualizar: async (licitacionProyectoID, cargoID, asignacionID, interaccion) => {
		try {
			if (licitacionProyectoID && cargoID && asignacionID && interaccion) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}/${RUTA_INTERACCIONES}/${interaccion._id}`;
				let response = await Axios.put(url, interaccion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una asignacion de un cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @param {*} interaccionID ID de la interaccion.
	 * @returns Response.
	 */
	Eliminar: async (licitacionProyectoID, cargoID, asignacionID, interaccionID) => {
		try {
			if (licitacionProyectoID && cargoID && asignacionID && interaccionID) {
				let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}/${RUTA_INTERACCIONES}/${interaccionID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las interacciones.
	 * @param {*} filtros Filtros de la consulta.
	 * @param {*} populates Texto con la colección de datos a popular.
	 * @returns Colección de interacciones filtradas.
	 * @link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams.
	 */
	Obtener: async (filtros = null, populates = "") => {
		try {
			let params = new URLSearchParams(JSON.parse(JSON.stringify(filtros))).toString();
			let url = `${RECLUTAMIENTO}/${RUTA}/${RUTA_INTERACCIONES}${populates}?${params}`;
			let response = await Axios.get(url);
			let interacciones = Array.from(response.data);
			interacciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
			return interacciones;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener una interacción, por su ID.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} asignacionID ID de la asignación.
	 * @param {*} interaccionID ID de la interacción.
	 * @returns Response.
	 */
	ObtenerPorID: async function (licitacionProyectoID, cargoID, asignacionID, interaccionID) {
		try {
			let url = `${RECLUTAMIENTO}/${RUTA}/${licitacionProyectoID}/${RUTA_CARGOS}/${cargoID}/${RUTA_ASIGNACIONES}/${asignacionID}/${RUTA_INTERACCIONES}/${interaccionID}`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}