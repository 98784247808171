import React, { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close, Link } from "@material-ui/icons";
import MaterialTable from "../materialTable";
import TooltipIfLongerThan from "../tooltip_if_longer_than";
import AdjuntoDialog from "./_adjunto_form";

/**
 * MaterialTable con listado de adjuntos y Dialog.
 * @param {*} formik Datos del formulario.
 * @returns Component.
 */
export default function Adjuntos(props) {
	const { formik } = props;
	const [IsOpen, SetIsOpen] = useState(false);
	const notistack = useSnackbar();

	let columns = [
		{
			ttitle: "Nombre",
			field: "adjunto.nombre_original",
			render: (row) => <TooltipIfLongerThan texto={`${row.adjunto?.nombre_original || row.file?.name}`} largo={35} />,
		}, {
			title: "URL",
			field: "adjunto.url",
			width: 50,
			render: (row) => row.adjunto ? LinkIconButton(row.adjunto.url) : null,
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarAdjunto(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} adjunto Datos del adjunto.
	 */
	const handleEliminarAdjunto = async (adjunto) => {
		try {
			let adjuntos = Array.from(formik.values.adjuntos);
			//Se elimina el adjunto.
			adjuntos.splice(adjunto.tableData.id, 1);

			//Se actualizan los datos del cargo.
			formik.setFieldValue("adjuntos", adjuntos);

			notistack.enqueueSnackbar("Adjunto eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el adjunto.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE ADJUNTOS */}
			{!IsOpen && (
				<MaterialTable
					title="Adjuntos"
					is_loading={false}
					data={formik.values.adjuntos}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			)}

			{/* DIALOG ADJUNTO */}
			<AdjuntoDialog
				is_open={IsOpen}
				formik={formik}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}

/**
 * Método encargado de retornar un componente con link del adjunto.
 * @param {*} link URK del archivo.
 * @returns Botón con redireccionamiento al archivo, en nueva pestaña.
 */
function LinkIconButton(link) {
	return (
		<IconButton href={link} target="_blank" rel="noreferrer" size="small" style={{ padding: 0, margin: 0 }}>
			<Link />
		</IconButton>
	);
}