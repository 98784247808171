/************************************************************************
 * CONSTANTES
 ***********************************************************************/

/**
 * Constante de grupos para permisos.
 */
const GRUPOS = {
	VER: "Ver",
	AGREGAR: "Agregar",
	EDITAR: "Editar",
	ELIMINAR: "Eliminar",
}

/************************************************************************
 * CANDIDATOS
 ***********************************************************************/

/**
 * Permiso para ver candidatos.
 */
export const CANDIDATOS_VER = {
	value: "candidatosVer",
	label: "Permiso para ver candidatos",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el CV adjunto de los candidatos.
 */
export const CANDIDATOS_VER_ADJUNTO = {
	value: "candidatosVerAdjunto",
	label: "Permiso para ver el CV adjunto de los candidatos",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el detalle de los candidatos.
 */
export const CANDIDATOS_VER_DETALLE = {
	value: "candidatosVerDetalle",
	label: "Permiso para ver el detalle de los candidatos",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver comentarios de los candidatos.
 */
export const CANDIDATOS_VER_COMENTARIOS = {
	value: "candidatosVerComentarios",
	label: "Permiso para ver comentarios de los candidatos",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar comentarios de los candidatos.
 */
export const CANDIDATOS_AGREGAR_COMENTARIOS = {
	value: "candidatosAgregarComentarios",
	label: "Permiso para agregar comentarios de los candidatos",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar comentarios de los candidatos.
 */
export const CANDIDATOS_EDITAR_COMENTARIOS = {
	value: "candidatosEditarComentarios",
	label: "Permiso para editar comentarios de los candidatos",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar comentarios de los candidatos.
 */
export const CANDIDATOS_ELIMINAR_COMENTARIOS = {
	value: "candidatosEliminarComentarios",
	label: "Permiso para eliminar comentarios de los candidatos",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para agregar asignaciones a cargos.
 */
export const CANDIDATOS_AGREGAR_ASIGNACIONES = {
	value: "candidatosAgregarAsignaciones",
	label: "Permiso para agregar asignaciones de los candidatos",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para eliminar asignaciones de cargos.
 */
export const CANDIDATOS_ELIMINAR_ASIGNACIONES = {
	value: "candidatosEliminarAsignaciones",
	label: "Permiso para eliminar asignaciones de los candidatos",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver candidatos bloqueados.
 */
export const CANDIDATOS_VER_BLOQUEADOS = {
	value: "candidatosVerBloqueados",
	label: "Permiso para ver candidatos bloqueados",
	group: GRUPOS.VER,
}

/**
 * Permiso para manbdar correo solicitando CV.
 */
export const CANDIDATOS_CORREO_SOLICITAR_CV = {
	value: "candidatosCorreoSolicitarCV",
	label: "Permiso para mandar correo solicitando CV",
	group: GRUPOS.VER,
}

/************************************************************************
 * CARGA DE CVs
 ***********************************************************************/

/**
 * Permiso para cargar CVs individuales.
 */
export const CARGAR_CV_INDIVIDUAL = {
	value: "cargarCVsIndividual",
	label: "Permiso para cargar CVs individuales",
	group: GRUPOS.VER,
}

/**
 * Permiso para cargar CVs masivos.
 */
export const CARGAR_CV_MASIVA = {
	value: "cargarCVsMasiva",
	label: "Permiso para cargar CVs masivos",
	group: GRUPOS.VER,
}

/**
 * Permiso para cargar CVs desde Trabajando.com
 */
export const CARGAR_CV_TRABAJANDO_COM = {
	value: "cargarCVstrabajandoCom",
	label: "Permiso para cargar CVs desde Trabajando.com",
	group: GRUPOS.VER,
}

/************************************************************************
 * CARPETAS
 ***********************************************************************/

/**
 * Permiso para ver el módulo de carpetas.
 */
export const CARPETAS_VER = {
	value: "carpetasVer",
	label: "Permiso para ver el módulo de carpetas",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de licitaciones.
 */
export const CARPETA_LICITACIONES_VER = {
	value: "carpetaLicitacionesVer",
	label: "Permiso para ver el módulo de carpeta de licitaciones",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el detalle de una licitación.
 */
export const CARPETA_LICITACIONES_DETALLE = {
	value: "carpetaLicitacionesDetalle",
	label: "Permiso para ver el detalle de una licitación",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar una nueva licitación.
 */
export const CARPETA_LICITACIONES_AGREGAR = {
	value: "carpetaLicitacionesAgregar",
	label: "Permiso para agregar una nueva licitación",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar el detalle de una licitación.
 */
export const CARPETA_LICITACIONES_EDITAR = {
	value: "carpetaLicitacionesEditar",
	label: "Permiso para editar el detalle de una licitación",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar una licitación.
 */
export const CARPETA_LICITACIONES_ELIMINAR = {
	value: "carpetaLicitacionesEliminar",
	label: "Permiso para eliminar una licitación",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver la información del cargo de una licitación.
 */
export const CARPETA_LICITACIONES_CARGO_VER = {
	value: "carpetaLicitacionesCargoVer",
	label: "Permiso para ver la información del cargo de una licitación",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar un nuevo cargo a una licitación.
 */
export const CARPETA_LICITACIONES_CARGO_AGREGAR = {
	value: "carpetaLicitacionesCargoAgregar",
	label: "Permiso para agregar un nuevo cargo a una licitación",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar la información del cargo de una licitación.
 */
export const CARPETA_LICITACIONES_CARGO_EDITAR = {
	value: "carpetaLicitacionesCargoEditar",
	label: "Permiso para editar la información del cargo de una licitación",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar un cargo de una licitación.
 */
export const CARPETA_LICITACIONES_CARGO_ELIMINAR = {
	value: "carpetaLicitacionesCargoEliminar",
	label: "Permiso para eliminar un cargo de una licitación",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para interactuar con un postulante de un cargo de una licitación.
 */
export const CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER = {
	value: "carpetaLicitacionesCargoInteractuarVer",
	label: "Permiso para interactuar con un postulante de un cargo de una licitación",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de proyectos.
 */
export const CARPETA_PROYECTOS_VER = {
	value: "carpetaProyectosVer",
	label: "Permiso para ver el módulo de carpeta de proyectos",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el detalle de un proyecto.
 */
export const CARPETA_PROYECTOS_DETALLE = {
	value: "carpetaProyectosDetalle",
	label: "Permiso para ver el detalle de un proyecto",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar un nuevo proyecto.
 */
export const CARPETA_PROYECTOS_AGREGAR = {
	value: "carpetaProyectosAgregar",
	label: "Permiso para agregar un nuevo proyecto",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar el detalle de un proyecto.
 */
export const CARPETA_PROYECTOS_EDITAR = {
	value: "carpetaProyectosEditar",
	label: "Permiso para editar el detalle de un proyecto",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar un proyecto.
 */
export const CARPETA_PROYECTOS_ELIMINAR = {
	value: "carpetaProyectosEliminar",
	label: "Permiso para eliminar un proyecto",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver la información del cargo de un proyecto.
 */
export const CARPETA_PROYECTOS_CARGO_VER = {
	value: "carpetaProyectosCargoVer",
	label: "Permiso para ver la información del cargo de un proyecto",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar un nuevo cargo a un proyecto.
 */
export const CARPETA_PROYECTOS_CARGO_AGREGAR = {
	value: "carpetaProyectosCargoAgregar",
	label: "Permiso para agregar un nuevo cargo a un proyecto",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar la información del cargo de un proyecto.
 */
export const CARPETA_PROYECTOS_CARGO_EDITAR = {
	value: "carpetaProyectosCargoEditar",
	label: "Permiso para editar la información del cargo de un proyecto",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar un cargo de un proyecto.
 */
export const CARPETA_PROYECTOS_CARGO_ELIMINAR = {
	value: "carpetaProyectosCargoEliminar",
	label: "Permiso para eliminar un cargo de un proyecto",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para interactuar con un postulante de un cargo de un proyecto.
 */
export const CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER = {
	value: "carpetaProyectosCargoInteractuarVer",
	label: "Permiso para interactuar con un postulante de un cargo de un proyecto",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de favoritos.
 */
export const CARPETA_FAVORITOS_VER = {
	value: "carpetaFavoritosVer",
	label: "Permiso para ver el módulo de carpeta de favoritos",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de cartas de oferta.
 */
export const CARPETA_CARTAS_OFERTA_VER = {
	value: "carpetaCartasOfertaVer",
	label: "Permiso para ver el módulo de carpeta de cartas de oferta",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de CVs cargados.
 */
export const CARPETA_CVS_CARGADOS_VER = {
	value: "carpetaCVsCargadosVer",
	label: "Permiso para ver el módulo de carpeta de CVs cargados",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de asignados.
 */
export const CARPETA_ASIGNADOS_VER = {
	value: "carpetaHistorialDesignadosVer",
	label: "Permiso para ver el módulo de carpeta de asignados",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver el módulo de carpeta de seleccionados.
 */
export const CARPETA_SELECCIONADOS_VER = {
	value: "carpetaHistorialSeleccionadosVer",
	label: "Permiso para ver el módulo de carpeta de seleccionados",
	group: GRUPOS.VER,
}

/************************************************************************
 * DASHBOARD
 ***********************************************************************/

/**
 * Permiso para ver el módulo de Dashboard.
 */
export const DASHBOARD_VER = {
	value: "dashboardVer",
	label: "Permiso para ver el módulo de Dashboard",
	group: GRUPOS.VER,
}

/************************************************************************
 * AJUSTES
 ***********************************************************************/

/**
 * Permiso para ver el módulo de ajustes.
 */
export const ADMIN_AJUSTES_VER = {
	value: "adminAjustesVer",
	label: "Permiso para ver el módulo de ajustes",
	group: GRUPOS.VER,
}

/************************************************************************
 * AJUSTES USUARIOS PLATAFORMA
 ***********************************************************************/

/**
 * Permiso para ver usuarios de plataforma.
 */
export const ADMIN_AJUSTES_USUARIOS_PLATAFORMA_VER = {
	value: "adminAjustesUsuariosPlataformaVer",
	label: "Permiso para ver usuarios de plataforma",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar usuarios de plataforma.
 */
export const ADMIN_AJUSTES_USUARIOS_PLATAFORMA_AGREGAR = {
	value: "adminAjustesUsuariosPlataformaAgregar",
	label: "Permiso para agregar usuarios de plataforma",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar usuarios de plataforma.
 */
export const ADMIN_AJUSTES_USUARIOS_PLATAFORMA_EDITAR = {
	value: "adminAjustesUsuariosPlataformaEditar",
	label: "Permiso para editar usuarios de plataforma",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar usuarios de plataforma.
 */
export const ADMIN_AJUSTES_USUARIOS_PLATAFORMA_ELIMINAR = {
	value: "adminAjustesUsuariosPlataformaEliminar",
	label: "Permiso para eliminar usuarios de plataforma",
	group: GRUPOS.ELIMINAR,
}

/************************************************************************
 * AJUSTES CARTAS OFERTA
 ***********************************************************************/

/**
 * Permiso para ver cartas de oferta.
 */
export const ADMIN_AJUSTES_CARTAS_OFERTA_VER = {
	value: "adminAjustesCartasOfertaVer",
	label: "Permiso para ver las cartas de oferta",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar cartas de oferta.
 */
export const ADMIN_AJUSTES_CARTAS_OFERTA_AGREGAR = {
	value: "adminAjustesCartasOfertaAgregar",
	label: "Permiso para agregar cartas de oferta",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar cartas de oferta.
 */
export const ADMIN_AJUSTES_CARTAS_OFERTA_EDITAR = {
	value: "adminAjustesCartasOfertaEditar",
	label: "Permiso para editar cartas de oferta",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar cartas de oferta.
 */
export const ADMIN_AJUSTES_CARTAS_OFERTA_ELIMINAR = {
	value: "adminAjustesCartasOfertaEliminar",
	label: "Permiso para eliminar cartas de oferta",
	group: GRUPOS.ELIMINAR,
}

/************************************************************************
 * AJUSTES FORMULARIOS POSTULACIÓN
 ***********************************************************************/

/**
 * Permiso para ver formularios de postulación.
 */
export const ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_VER = {
	value: "adminAjustesFormulariosPostulacionVer",
	label: "Permiso para ver los formularios de postulación",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar formularios de postulación.
 */
export const ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_AGREGAR = {
	value: "adminAjustesFormulariosPostulacionAgregar",
	label: "Permiso para agregar formularios de postulación",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar formularios de postulación.
 */
export const ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_EDITAR = {
	value: "adminAjustesFormulariosPostulacionEditar",
	label: "Permiso para editar formularios de postulación",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar formularios de postulación.
 */
export const ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_ELIMINAR = {
	value: "adminAjustesFormulariosPostulacionEliminar",
	label: "Permiso para eliminar formularios de postulación",
	group: GRUPOS.ELIMINAR,
}

/************************************************************************
 * AJUSTES SELECCIONABLES
 ***********************************************************************/

/**
 * Permiso para ver el módulo de seleccionables.
 */
export const ADMIN_SELECCIONABLE_VER =
{
	value: "adminSeleccionableVer",
	label: "Permiso para ver el módulo de seleccionables",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver empresas.
 */
export const ADMIN_SELECCIONABLE_EMPRESAS_VER = {
	value: "adminSeleccionableEmpresasVer",
	label: "Permiso para ver empresas",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar empresas.
 */
export const ADMIN_SELECCIONABLE_EMPRESAS_AGREGAR = {
	value: "adminSeleccionableEmpresasAgregar",
	label: "Permiso para agregar empresas",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar empresas.
 */
export const ADMIN_SELECCIONABLE_EMPRESAS_EDITAR = {
	value: "adminSeleccionableEmpresasEditar",
	label: "Permiso para editar empresas",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar empresas.
 */
export const ADMIN_SELECCIONABLE_EMPRESAS_ELIMINAR = {
	value: "adminSeleccionableEmpresasEliminar",
	label: "Permiso para eliminar empresas",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver discapacidades.
 */
export const ADMIN_SELECCIONABLE_DISCAPACIDADES_VER = {
	value: "adminSeleccionableDiscapacidadesVer",
	label: "Permiso para ver discapacidades",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar discapacidades.
 */
export const ADMIN_SELECCIONABLE_DISCAPACIDADES_AGREGAR = {
	value: "adminSeleccionableDiscapacidadesAgregar",
	label: "Permiso para agregar discapacidades",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar discapacidades.
 */
export const ADMIN_SELECCIONABLE_DISCAPACIDADES_EDITAR = {
	value: "adminSeleccionableDiscapacidadesEditar",
	label: "Permiso para editar discapacidades",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar discapacidades.
 */
export const ADMIN_SELECCIONABLE_DISCAPACIDADES_ELIMINAR = {
	value: "adminSeleccionableDiscapacidadesEliminar",
	label: "Permiso para eliminar discapacidades",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver estados civiles.
 */
export const ADMIN_SELECCIONABLE_ESTADOS_CIVILES_VER = {
	value: "adminSeleccionableEstadosCivilesVer",
	label: "Permiso para ver estados civiles",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar estados civiles.
 */
export const ADMIN_SELECCIONABLE_ESTADOS_CIVILES_AGREGAR = {
	value: "adminSeleccionableEstadosCivilesAgregar",
	label: "Permiso para agregar estados civiles",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar estados civiles.
 */
export const ADMIN_SELECCIONABLE_ESTADOS_CIVILES_EDITAR = {
	value: "adminSeleccionableEstadosCivilesEditar",
	label: "Permiso para editar estados civiles",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar estados civiles.
 */
export const ADMIN_SELECCIONABLE_ESTADOS_CIVILES_ELIMINAR = {
	value: "adminSeleccionableEstadosCivilesEliminar",
	label: "Permiso para eliminar estados civiles",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver instituciones.
 */
export const ADMIN_SELECCIONABLE_INSTITUCIONES_VER = {
	value: "adminSeleccionableInstitucionesVer",
	label: "Permiso para ver instituciones",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar instituciones.
 */
export const ADMIN_SELECCIONABLE_INSTITUCIONES_AGREGAR = {
	value: "adminSeleccionableInstitucionesAgregar",
	label: "Permiso para agregar instituciones",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar instituciones.
 */
export const ADMIN_SELECCIONABLE_INSTITUCIONES_EDITAR = {
	value: "adminSeleccionableInstitucionesEditar",
	label: "Permiso para editar instituciones",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar instituciones.
 */
export const ADMIN_SELECCIONABLE_INSTITUCIONES_ELIMINAR = {
	value: "adminSeleccionableInstitucionesEliminar",
	label: "Permiso para eliminar instituciones",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver licencias de conducir.
 */
export const ADMIN_SELECCIONABLE_LICENCIAS_CONDUCIR_VER = {
	value: "adminSeleccionableLicenciasConducirVer",
	label: "Permiso para ver licencias de conducir",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar licencias de conducir.
 */
export const ADMIN_SELECCIONABLE_LICENCIAS_CONDUCIR_AGREGAR = {
	value: "adminSeleccionableLicenciasConducirAgregar",
	label: "Permiso para agregar licencias de conducir",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar licencias de conducir.
 */
export const ADMIN_SELECCIONABLE_LICENCIAS_CONDUCIR_EDITAR = {
	value: "adminSeleccionableLicenciasConducirEditar",
	label: "Permiso para editar licencias de conducir",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar licencias de conducir.
 */
export const ADMIN_SELECCIONABLE_LICENCIAS_CONDUCIR_ELIMINAR = {
	value: "adminSeleccionableLicenciasConducirEliminar",
	label: "Permiso para eliminar licencias de conducir",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver niveles de inglés.
 */
export const ADMIN_SELECCIONABLE_NIVELES_INGLES_VER = {
	value: "adminSeleccionableNivelesInglesVer",
	label: "Permiso para ver niveles de inglés",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar niveles de inglés.
 */
export const ADMIN_SELECCIONABLE_NIVELES_INGLES_AGREGAR = {
	value: "adminSeleccionableNivelesInglesAgregar",
	label: "Permiso para agregar niveles de inglés",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar niveles de inglés.
 */
export const ADMIN_SELECCIONABLE_NIVELES_INGLES_EDITAR = {
	value: "adminSeleccionableNivelesInglesEditar",
	label: "Permiso para editar niveles de inglés",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar niveles de inglés.
 */
export const ADMIN_SELECCIONABLE_NIVELES_INGLES_ELIMINAR = {
	value: "adminSeleccionableNivelesInglesEliminar",
	label: "Permiso para eliminar niveles de inglés",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver situaciones laborales
 */
export const ADMIN_SELECCIONABLE_SITUACIONES_LABORALES_VER = {
	value: "adminSeleccionableSituacionesLaboralesVer",
	label: "Permiso para ver situaciones laborales",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar situaciones laborales.
 */
export const ADMIN_SELECCIONABLE_SITUACIONES_LABORALES_AGREGAR = {
	value: "adminSeleccionableSituacionesLaboralesAgregar",
	label: "Permiso para agregar situaciones laborales",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar situaciones laborales.
 */
export const ADMIN_SELECCIONABLE_SITUACIONES_LABORALES_EDITAR = {
	value: "adminSeleccionableSituacionesLaboralesEditar",
	label: "Permiso para editar situaciones laborales",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar situaciones laborales.
 */
export const ADMIN_SELECCIONABLE_SITUACIONES_LABORALES_ELIMINAR = {
	value: "adminSeleccionableSituacionesLaboralesEliminar",
	label: "Permiso para eliminar situaciones laborales",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver títulos.
 */
export const ADMIN_SELECCIONABLE_TITULOS_VER = {
	value: "adminSeleccionableTitulosVer",
	label: "Permiso para ver títulos",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar títulos.
 */
export const ADMIN_SELECCIONABLE_TITULOS_AGREGAR = {
	value: "adminSeleccionableTitulosAgregar",
	label: "Permiso para agregar títulos",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar títulos.
 */
export const ADMIN_SELECCIONABLE_TITULOS_EDITAR = {
	value: "adminSeleccionableTitulosEditar",
	label: "Permiso para editar títulos",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar títulos.
 */
export const ADMIN_SELECCIONABLE_TITULOS_ELIMINAR = {
	value: "adminSeleccionableTitulosEliminar",
	label: "Permiso para eliminar títulos",
	group: GRUPOS.ELIMINAR,
}

/**
 * Permiso para ver niveles de estudio.
 */
export const ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_VER = {
	value: "adminSeleccionableNivelesEstudioVer",
	label: "Permiso para ver niveles de estudio",
	group: GRUPOS.VER,
}

/**
 * Permiso para agregar niveles de estudio.
 */
export const ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_AGREGAR = {
	value: "adminSeleccionableNivelesEstudioAgregar",
	label: "Permiso para agregar niveles de estudio",
	group: GRUPOS.AGREGAR,
}

/**
 * Permiso para editar niveles de estudio.
 */
export const ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_EDITAR = {
	value: "adminSeleccionableNivelesEstudioEditar",
	label: "Permiso para editar niveles de estudio",
	group: GRUPOS.EDITAR,
}

/**
 * Permiso para eliminar niveles de estudio.
 */
export const ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_ELIMINAR = {
	value: "adminSeleccionableNivelesEstudioEliminar",
	label: "Permiso para eliminar niveles de estudio",
	group: GRUPOS.ELIMINAR,
}

/************************************************************************
 * VERIFICAR RUN
 ***********************************************************************/

/**
 * Permiso para ver formulario para verificar RUN.
 */
export const FORMULARIO_VERIFICAR_RUN = {
	value: "formularioVerificarRUN",
	label: "Permiso para ver formulario para verificar RUN",
	group: GRUPOS.VER,
}

/************************************************************************
 * DESCARGAR DATOS
 ***********************************************************************/

/**
 * Permiso para ver botón de descarga de vacantes.
 */
export const DESCARGAR_VACANTES = {
	value: "descargarVacantes",
	label: "Permiso para ver botón de descarga de vacantes",
	group: GRUPOS.VER,
}

/**
 * Permiso para ver botón de descarga de asignados.
 */
export const DESCARGAR_ASIGNADOS = {
	value: "descargarAsignados",
	label: "Permiso para ver botón de descarga de asignados",
	group: GRUPOS.VER,
}