import { object, string } from "yup";
import { LICITACION_PROYECTO_ESTADOS, TIPO_LICITACION } from "../../../../constants/contexts";

export const FormikInitialValues = {
	_id: "",
	nombre: "",
	codigo: "",
	responsable: null,
	_responsable_ref: "",
	reclutador: null,
	_reclutador_ref: "",
	gerencia: null,
	_gerencia_ref: "",
	mandante: "",
	descripcion: "",
	estado: LICITACION_PROYECTO_ESTADOS.SIN_RECLUTAMIENTO,
	cargos: [],
	tipo: TIPO_LICITACION,
	fecha_creacion: null,
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	codigo: string()
		.min(3, ({ min }) => `El código debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El código debe tener a lo más ${max} caracteres.`)
		.required("El código es requerido."),
	responsable: object()
		.nullable()
		.required("El responsable es requerido."),
	reclutador: object()
		.nullable()
		.required("El reclutador es requerido."),
	gerencia: object()
		.nullable()
		.required("La gerencia es requerida."),
	mandante: string()
		.min(3, ({ min }) => `El mandante debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El mandante debe tener a lo más ${max} caracteres.`)
		.required("El mandante es requerido."),
	descripcion: string()
		.min(3, ({ min }) => `La descripción debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La descripción debe tener a lo más ${max} caracteres.`)
		.required("La descripción es requerida."),
});