import React, { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { ErrorCode } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Page from "./page";
import { Agregar } from "../../../services/request/reclutamiento/curriculums";
import { uploadFileToCloudStorage } from "../../../services/utilities/files";
import { HOME } from "../../../constants/routes";
import { MainContext } from "../../../App";

const LIMITE_ARCHIVOS = 100;

export default function Index(props) {
	const [IsLoading, SetIsLoading] = useState(false);
	const [LoadingStatus, SetLoadingStatus] = useState(0);
	const { usuarioSesion } = useContext(MainContext);
	const notistack = useSnackbar();
	const history = useHistory();

	/**
	 * Método encargado de mostrar un mensaje de error.
	 * Muestra mensaje de formato no válido, si selecciona archivos que no son PDF.
	 * Muestra mensaje de límite de archivos superados, si selecciona más de 250 archivos.
	 * @param {*} files Archivos seleccionados.
	 */
	const RejecterFunction = (files) => {
		let codigosErrores = Array.from(files).map(f => f.errors).flat().map(e => e.code);
		let erroresUnicos = Array.from(new Set(codigosErrores));

		if (erroresUnicos.includes(ErrorCode.TooManyFiles)) {
			notistack.enqueueSnackbar(`Error, cantidad de archivos seleccionados (${files.length}) es superior al límite de ${LIMITE_ARCHIVOS}.`, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}

		if (erroresUnicos.includes(ErrorCode.FileInvalidType)) {
			notistack.enqueueSnackbar(`Error, ${files.length} archivos seleccionados no son formato PDF y serán excluidos.`, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}

		if (erroresUnicos.includes(ErrorCode.FileTooSmall)) {
			notistack.enqueueSnackbar(`Error, ${files.length} archivos seleccionados tiene tamaño 0 KB y serán excluidos.`, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	/**
	 * Método encargado de subir los archivos y actualizar la cantidad de completación.
	 * @link https://dev.to/jrdev_/how-to-display-the-progress-of-promises-in-javascript-lh0
	 * @link https://stackoverflow.com/questions/42341331/es6-promise-all-progress/42342373
	 */
	const UploaderFunction = async (acceptedFiles, inputTags, origen) => {
		try {
			//Se muestra la pantalla de carga.
			SetIsLoading(true);
			//Contador de promesas finalizadas.
			let countFinished = 0;

			//Archivos que tienen tamaño.
			let files = Array.from(acceptedFiles).filter(file => file.size > 0);
			//Colección de los textos de los Tags.
			let tags = inputTags.map(t => t.value);

			//Colección de promesas para subir archivos a Google Cloud.
			let promisesStorage = files.map((file, index) => uploadFileToCloudStorage(file, "curriculums-test", NameFormat(index)));
			//Por cada promsesa completada de subir archivo, se aumenta la cantidad.
			promisesStorage.forEach(promise => promise.then(() => {
				countFinished += (50 / promisesStorage.length);
				SetLoadingStatus(countFinished);
			}));

			//Archivos subidos a la nube.
			let uploadedFiles = await Promise.all(promisesStorage);
			//Se mapea el resultado de los archivos subidos a Google Cloud.
			let curriculums = uploadedFiles.map(curriculum_vitae => ({
				curriculum_vitae,
				tags,
				origen,
				_reclutador_ref: usuarioSesion.ref,
				_gerencia_ref: usuarioSesion.gerencia._id,
			}));

			//Colección de promesas para subir los curriculums a la base de datos.
			let promiseUpload = curriculums.map(curriculum => Agregar(curriculum));
			//Por cada promsesa completada de subir curriculums, se aumenta la cantidad.
			promiseUpload.forEach(promise => promise.then(() => {
				countFinished += (50 / promiseUpload.length);
				SetLoadingStatus(countFinished);
			}).catch(error => {
				countFinished += (50 / promiseUpload.length);
				SetLoadingStatus(countFinished);
			}));

			//Registros subidos a la base de datos.
			await Promise.allSettled(promiseUpload);

			countFinished++;
			SetLoadingStatus(countFinished);

			notistack.enqueueSnackbar("Documentos subidos exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
		} finally {
			SetIsLoading(false);
			SetLoadingStatus(0);
			//Se retorna a HOME despues de terminar.
			history.push(HOME);
		}
	}

	return (
		<Page
			uploader={UploaderFunction}
			rejecter={RejecterFunction}
			is_loading={IsLoading}
			loading_status={LoadingStatus}
			limite_archivos={LIMITE_ARCHIVOS}
		/>
	);
}

/**
 * Método encargado de formatear el nombre de un archivo.
 * @param {*} index Index del archivo.
 * @returns Nombre de archivo formateado.
 */
function NameFormat(index) {
	let filename = `curriculum_${index}_${Moment().format("DD_MM_YYYY_HH_mm_ss")}`;
	return filename;
}