import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerSituacionesLaborales } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: situacionesLaborales,
		isValidating: loadingSituacionesLaborales,
		mutate: mutateSituacionesLaborales,
		error: errorSituacionesLaborales,
	} = useSWR("situaciones_laborales_seleccionables", ObtenerSituacionesLaborales, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorSituacionesLaborales) {
			ShowSnackbar("Error al intentar cargar los datos de las situaciones laborales.", errorSituacionesLaborales);
		}
	}, [ShowSnackbar, errorSituacionesLaborales]);

	return (
		<Page
			situaciones_laborales={situacionesLaborales}
			is_loading={loadingSituacionesLaborales}
			mutate_situaciones_laborales={mutateSituacionesLaborales}
		/>
	);
}