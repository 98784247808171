import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import useSWR from "swr";
import Moment from "moment";
import DialogTitleComponent from "../dialog_title";
import ListadoTitulos from "./_titulos";
import ListadoAdjuntos from "./_adjuntos";
import { ObtenerReclutadores } from "../../services/request/reclutamiento/usuariosPlataforma";
import { GetCargoCodigo } from "../../services/request/reclutamiento/utilidades";
import { MontoToMoneda } from "../../services/utilities/formatUtils";
import Turnos from "../../services/data/turnos.json";
import Jornadas from "../../services/data/jornadas.json";
import Modalidades from "../../services/data/modalidades.json";
import TiposContratos from "../../services/data/tipos_contratos.json";
import { COMPROMISO_REMUNERACIONAL_TOOLTIP } from "./utils";
import { MainContext } from "../../App";

const filter = createFilterOptions();

/**
 * Dialog para agregar cargos.
 * @param {*} is_open TRUE: Dialog abierto | FALSE: Dialog cerrado.
 * @param {*} data Datos del cargo.
 * @param {*} tipo Tipo licitación o proyecto.
 * @param {*} formik Datos del formulario.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function DialogAddCargo(props) {
	const {
		is_open,
		data,
		tipo,
		formik,
		handle_close,
	} = props;

	const ReclutadoresSWR = useSWR("reclutadores", (key) => ObtenerReclutadores(), { revalidateOnFocus: false });
	const CargoCodigoSWR = useSWR("cargo_codigo", (key) => GetCargoCodigo(tipo));
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		formik.setFieldValue("mandante", data);
	}, [data]);

	useEffect(() => {
		if (ReclutadoresSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los reclutadores.", ReclutadoresSWR.error);
		}
	}, [ShowSnackbar, ReclutadoresSWR]);

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" style={{ paddingTop: 50 }} PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Agregar Nuevo Cargo</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Ingrese la información requerida del cargo y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						{/* CÓDIGO */}
						<TextField
							name="codigo"
							label="Código (Puede variar)"
							value={CargoCodigoSWR.data || ""}
							variant="outlined"
							disabled
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={4}>
						{/* NOMBRE */}
						<TextField
							name="nombre"
							label="Nombre"
							// value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* CANTIDAD DE VACANTES */}
						<TextField
							name="cant_vacantes"
							label="Cantidad vacantes"
							// value={formik.values.cant_vacantes}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 1, max: 99 }}
							required
							fullWidth
							size="small"
							helperText={formik.errors.cant_vacantes}
							error={Boolean(formik.errors.cant_vacantes)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA MÍNIMA */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_min_formato"
								label="Renta líquida mínima (Pesos)"
								value={formik.values.renta_liquida_min_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_min_formato}
								error={Boolean(formik.errors.renta_liquida_min_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA OBJETIVO */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_objetivo_formato"
								label="Renta líquida objetivo (Pesos)"
								value={formik.values.renta_liquida_objetivo_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_objetivo_formato}
								error={Boolean(formik.errors.renta_liquida_objetivo_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA MÁXIMA */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_max_formato"
								label="Renta líquida máxima (Pesos)"
								value={formik.values.renta_liquida_max_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_max_formato}
								error={Boolean(formik.errors.renta_liquida_max_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* FECHA DE REQUERIMIENTO */}
						<DatePicker
							label="Fecha inicio"
							value={formik.values.fecha_requerimiento}
							onChange={date => formik.setFieldValue("fecha_requerimiento", date)}
							format="DD/MM/YYYY"
							inputVariant="outlined"
							size="small"
							fullWidth
							autoOk
							clearable
							okLabel="Aceptar"
							cancelLabel="Cancelar"
							clearLabel="Limpiar"
							helperText={formik.errors.fecha_requerimiento}
							error={Boolean(formik.errors.fecha_requerimiento)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* DURACIÓN */}
						<TextField
							name="duracion"
							label="Duración (Meses)"
							value={formik.values.duracion}
							onChange={(event) => {
								let duracion = Number(event.target.value);
								formik.setFieldValue("duracion", duracion);
								let fechaInicio = formik.values.fecha_requerimiento || Moment();
								formik.setFieldValue("fecha_termino", Moment(fechaInicio).add(duracion, "months"));
							}}
							variant="outlined"
							type="number"
							inputProps={{ min: 1, max: 999 }}
							required
							fullWidth
							size="small"
							helperText={formik.errors.duracion}
							error={Boolean(formik.errors.duracion)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* FECHA DE TÉRMINO */}
						<DatePicker
							label="Fecha Término"
							value={formik.values.fecha_termino}
							onChange={date => formik.setFieldValue("fecha_termino", date)}
							minDate={formik.values.fecha_requerimiento || undefined}
							format="DD/MM/YYYY"
							inputVariant="outlined"
							size="small"
							fullWidth
							autoOk
							clearable
							okLabel="Aceptar"
							cancelLabel="Cancelar"
							clearLabel="Limpiar"
							helperText={formik.errors.fecha_termino}
							error={Boolean(formik.errors.fecha_termino)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* UBICACIÓN */}
						<TextField
							name="ubicacion"
							label="Ubicación de la obra"
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.ubicacion}
							error={Boolean(formik.errors.ubicacion)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* MODALIDAD DE TRABAJO */}
						<Autocomplete
							options={Modalidades}
							getOptionLabel={(modalidad) => modalidad}
							onChange={(event, value) => formik.setFieldValue("modalidad", value)}
							loading={!Modalidades}
							noOptionsText="Sin opciones"
							disabled={!Modalidades}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Modalidad"
									helperText={formik.errors.modalidad}
									error={Boolean(formik.errors.modalidad)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* TIPO DE CONTRATO */}
						<Autocomplete
							options={TiposContratos}
							getOptionLabel={(tipoContrato) => tipoContrato}
							onChange={(event, value) => formik.setFieldValue("tipo_contrato", value)}
							loading={!TiposContratos}
							noOptionsText="Sin opciones"
							disabled={!TiposContratos}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Tipo de contrato"
									helperText={formik.errors.tipo_contrato}
									error={Boolean(formik.errors.tipo_contrato)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* HORARIO */}
						<TextField
							name="horario"
							label="Horario (ej: De 8 a 18)"
							onChange={formik.handleChange}
							variant="outlined"
							// required
							fullWidth
							size="small"
							helperText={formik.errors.horario}
							error={Boolean(formik.errors.horario)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* LUGAR DE TRABAJO */}
						<TextField
							name="lugar_trabajo"
							label="Lugar de trabajo"
							onChange={formik.handleChange}
							variant="outlined"
							// required
							fullWidth
							size="small"
							helperText={formik.errors.lugar_trabajo}
							error={Boolean(formik.errors.lugar_trabajo)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* TURNO */}
						<Autocomplete
							value={formik.values.turno}
							options={Turnos}
							loading={!Turnos}
							noOptionsText="Sin opciones"
							disabled={!Turnos}
							size="small"
							onChange={(event, value) => {
								let turno = String(value || "").replace("Agregar ", "");
								if (typeof value === "string") {
									formik.setFieldValue("turno", turno);
								} else if (value && value.inputValue) {
									formik.setFieldValue("turno", turno);
								} else {
									formik.setFieldValue("turno", turno);
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								if (params.inputValue !== "" && /\d{1,2}x\d{1,2}/.test(params.inputValue)) {
									filtered.push(`Agregar ${params.inputValue}`);
								}
								return filtered;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							getOptionLabel={(option) => {
								if (typeof option === "string") {
									return option;
								}
								if (option.inputValue) {
									return option.inputValue;
								}
								return option;
							}}
							renderOption={(option) => option}
							freeSolo
							renderInput={(params) => (
								<TextField
									label="Turno"
									helperText={formik.errors.turno}
									error={Boolean(formik.errors.turno)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* JORNADA */}
						<Autocomplete
							options={Jornadas}
							getOptionLabel={(jornada) => jornada}
							onChange={(event, value) => formik.setFieldValue("jornada", value)}
							loading={!Jornadas}
							noOptionsText="Sin opciones"
							disabled={!Jornadas}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Jornada"
									helperText={formik.errors.jornada}
									error={Boolean(formik.errors.jornada)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* MANDANTE */}
						<TextField
							name="mandante"
							label="Mandante"
							value={formik.values.mandante}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.mandante}
							error={Boolean(formik.errors.mandante)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* RECLUTADOR ENCARGADO */}
						<Autocomplete
							options={ReclutadoresSWR ? ReclutadoresSWR.data : []}
							getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
							onChange={(event, value) => formik.setFieldValue("reclutador", value)}
							loading={ReclutadoresSWR.isValidating}
							noOptionsText="Sin opciones"
							disabled={!ReclutadoresSWR}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Reclutador"
									helperText={formik.errors.reclutador}
									error={Boolean(formik.errors.reclutador)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* EXPERIENCIA GENERAL */}
						<TextField
							name="exp_general"
							label="E. general"
							value={formik.values.exp_general}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 99 }}
							fullWidth
							size="small"
							helperText={formik.errors.exp_general}
							error={Boolean(formik.errors.exp_general)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* EXPERIENCIA ESPECÍFICA */}
						<TextField
							name="exp_especifica"
							label="E. específica"
							value={formik.values.exp_especifica}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 99 }}
							fullWidth
							required
							size="small"
							helperText={formik.errors.exp_especifica}
							error={Boolean(formik.errors.exp_especifica)}
						/>
					</Grid>
					<Grid item xs={12} container spacing={1}>
						{/* LISTADO Y FORMULARIO DE TÍTULOS */}
						<Grid item xs={6}>
							<ListadoTitulos
								formik={formik}
							/>
						</Grid>
						{/* LISTADO Y FORMULARIO DE ADJUNTOS */}
						<Grid item xs={6}>
							<ListadoAdjuntos
								formik={formik}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{/* DESCRIPCIÓN */}
						<TextField
							name="descripcion"
							label="Descripción"
							onChange={formik.handleChange}
							variant="outlined"
							multiline
							rows={4}
							required
							fullWidth
							size="small"
							helperText={formik.errors.descripcion}
							error={Boolean(formik.errors.descripcion)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}