import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerLicenciasConducir } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: licenciasConducir,
		isValidating: loadingLicenciasConducir,
		mutate: mutateLicenciasConducir,
		error: errorLicenciasConducir,
	} = useSWR("licencias_conducir_seleccionables", ObtenerLicenciasConducir, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorLicenciasConducir) {
			ShowSnackbar("Error al intentar cargar los datos de las licencias de conducir.", errorLicenciasConducir);
		}
	}, [ShowSnackbar, errorLicenciasConducir]);

	return (
		<Page
			licencias_conducir={licenciasConducir}
			is_loading={loadingLicenciasConducir}
			mutate_licencias_conducir={mutateLicenciasConducir}
		/>
	);
}