import { object, string } from "yup";
import { INSTITUCIONES } from "../../../../constants/contexts";

export const FormikInitialValues = {
	valor: "",
	contexto: INSTITUCIONES,
}

export const FormikValidationSchema = object().shape({
	valor: string()
		.min(3, ({ min }) => `La institución debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La institución debe tener a lo más ${max} caracteres.`)
		.required("La institución es requerida."),
});