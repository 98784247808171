import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Grid, IconButton, Switch, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import DialogTitleComponent from "../../../../components/dialog_title";
import { Actualizar } from "../../../../services/request/reclutamiento/empresas";
import { FormikInitialValues, FormikValidationSchema } from "./utils";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_empresas,
	} = props;

	const notistack = useSnackbar();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Actualizando la empresa...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				values["_id"] = data._id;
				await Actualizar(values);
				notistack.closeSnackbar();
				notistack.enqueueSnackbar("Empresa actualizada exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar actualizar la empresa.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><CloseIcon /></IconButton>
				});
			} finally {
				helper.resetForm();
				mutate_empresas();
				handle_close();
			}
		}
	});

	const loadData = () => {
		formik.resetForm({ values: data });
	}

	/**
	 * Método encargado de cerrar el popup y cerrar los paneles.
	 */
	const handleCloseDialog = () => {
		handle_close();
		formik.resetForm();
	}

	return (
		<Dialog open={is_open} onClose={handleCloseDialog} onEntered={loadData} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handleCloseDialog}>Actualizar Empresa</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria de la empresa y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextField
							name="rut"
							label="RUT"
							value={formik.values.rut}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.rut}
							error={Boolean(formik.errors.rut)}
						/>
					</Grid>
					<Grid item xs={8}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="rubro"
							label="Rubro"
							value={formik.values.rubro}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.rubro}
							error={Boolean(formik.errors.rubro)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.telefono"
							label="Teléfono"
							value={formik.values.contacto.telefono}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.telefono}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.telefono_alternativo"
							label="Teléfono Alternativo"
							value={formik.values.contacto.telefono_alternativo}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.telefono_alternativo}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono_alternativo)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.telefono_fijo"
							label="Teléfono Fijo"
							value={formik.values.contacto.telefono_fijo}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.telefono_fijo}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono_fijo)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.email"
							label="Email"
							value={formik.values.contacto.email}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.email}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.email)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.email_alternativo"
							label="Email Alternativo"
							value={formik.values.contacto.email_alternativo}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.email_alternativo}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.email_alternativo)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name="contacto.sitio_web"
							label="Sitio Web"
							value={formik.values.contacto.sitio_web}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.contacto && formik.errors.contacto.sitio_web}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.sitio_web)}
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControlLabel
							label="¿Es Pública?"
							control={
								<Switch
									name="is_publica"
									checked={formik.values.is_publica}
									onChange={(event, checked) => formik.setFieldValue("is_publica", checked)}
									color="primary"
									size="small"
								/>
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}