import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import LicitacionProyecto from "../../../../components/licitacion_proyecto_detalle";
import ListadoCargos from "../../../../components/cargos_listado";
import TabSelector from "../../../../components/tab_selector";
import { TIPO_PROYECTO, CARGO_ESTADOS } from "../../../../constants/contexts";

export default function Page(props) {
	const {
		proyecto,
	} = props;

	const [TabIndex, SetTabIndex] = useState(0);

	/**
	 * Método encargado de cambiar las pestañas de estado de las asignaciones.
	 * @param {*} event Evento.
	 * @param {*} tabIndex Nuevo número de pestaña seleccionada.
	 */
	const handleTabChange = (event, tabIndex) => {
		SetTabIndex(tabIndex);
	}

	return (
		<Grid container spacing={2}>
			{/* DATOS DE LA LICITACiÓN */}
			<Grid item xs={12}>
				<LicitacionProyecto
					licitacion_proyecto={proyecto}
					tipo="proyectos"
				/>
			</Grid>
			{/* PESTAÑA CON ESTADOS DE LOS CARGOS */}
			<Grid item xs={12}>
				<TabSelector
					tab_list={["Activos", "Cerrados", "Detenidos", "Fallidos"]}
					tab_index={TabIndex}
					handle_change={handleTabChange}
				/>
			</Grid>
			{/* COLECCIÓN DE CARGOS */}
			<Grid item xs={12}>
				<ListadoCargos
					licitacion_proyecto={proyecto}
					mandante={proyecto.mandante}
					tipo={TIPO_PROYECTO}
					cargo_estado={Object.values(CARGO_ESTADOS)[TabIndex]}
				/>
			</Grid>
		</Grid>
	);
}