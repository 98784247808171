import { object, string } from "yup";
import { NIVELES_ESTUDIOS } from "../../../../constants/contexts";

export const FormikInitialValues = {
	valor: "",
	contexto: NIVELES_ESTUDIOS,
}

export const FormikValidationSchema = object().shape({
	valor: string()
		.min(3, ({ min }) => `El nivel de estudio debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nivel de estudio debe tener a lo más ${max} caracteres.`)
		.required("El nivek de estudio es requerido."),
});