import * as URLS from "../../constants/urls";
import * as ROUTES from "../../constants/routes";
import * as CONTEXTS from "../../constants/contexts";
import { VISITADOS } from "./local_db";

export const redirecURL = (destiny) => {
	const contrato = localStorage.getItem("contrato");
	const proyecto = localStorage.getItem("proyecto");
	var url = `${URLS.REDIRECT_BASE}/redirectToApp`;
	if (destiny) {
		url += `?redirect-url=${destiny}`;
	}
	if (contrato && proyecto && contrato !== "undefined" && proyecto !== "undefined") {
		url += `&contrato=${contrato}&proyecto=${proyecto}`;
	}
	console.log(url);
	return url;
}

/**
 * Método encargado de abrir el CV del postulante, en una nueva pestaña.
 * @param {*} curriculumURL URL del curriculum.
 */
export function RedirectPostulanteCV(curriculumURL) {
	//Se abre una nueva pestaña con la URL del CV.
	window.open(curriculumURL, "_blank");
}

/**
 * Método encargado de abrir la vista para editar información del postulante, en una nueva pestaña.
 * @param {*} postulanteID Datos del postulante.
 */
export function RedirectPostulanteEditar(postulanteID) {
	//Se incluye el ID en la base de datos de visitados.
	VISITADOS.Agregar(postulanteID);

	//URL del detalle de un postulante.
	let candidatoURL = ROUTES.CANDIDATOS_ID.replace(":postulante_id", postulanteID);
	//Se abre una nueva pestaña con la vista para editar un postulante.
	window.open(candidatoURL, "_blank");
}

/**
 * Método encargado de abrir la vista para editar información de un cargo, en una nueva pestaña.
 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
 * @param {*} cargoID ID del cargo.
 * @param {*} tipo Tipo licitación o proyecto.
 */
export function RedirectLicitacionProyectoCargo(licitacionProyectoID, cargoID, tipo) {
	//URL del detalle de una licitación.
	let licitacionURL = `${ROUTES.CARPETA_LICITACIONES}/${licitacionProyectoID}`;
	//URL del detalle de un proyecto.
	let proyectoURL = `${ROUTES.CARPETA_PROYECTOS}/${licitacionProyectoID}`;
	//URL del detalle de un cargo.
	let cargoURL = ROUTES.CARGO_LICITACION_PROYECTO.replace(":cargo_id", cargoID);

	if (tipo === CONTEXTS.TIPO_LICITACION) {
		//Se abre una nueva pestaña con la vista para editar un cargo en una licitación.
		window.open(`${licitacionURL}${cargoURL}`, "_blank");
	} else if (tipo === CONTEXTS.TIPO_PROYECTO) {
		//Se abre una nueva pestaña con la vista para editar un cargo en un proyecto.
		window.open(`${proyectoURL}${cargoURL}`, "_blank");
	} else {
		//Se abre una nueva pestaña con la vista principal.
		window.open(ROUTES.HOME, "_blank");
	}
}

/**
 * Método encargado de abrir la vista para editar información de un cargo, en una nueva pestaña.
 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
 * @param {*} cargoID ID del cargo.
 * @param {*} asignacionID ID de la asignación.
 * @param {*} tipo Tipo licitación o proyecto.
 */
export function RedirectPostulanteInteractuar(licitacionProyectoID, cargoID, asignacionID, tipo) {
	//URL del detalle de una licitación.
	let licitacionURL = `${ROUTES.CARPETA_LICITACIONES}/${licitacionProyectoID}`;
	//URL del detalle de un proyecto.
	let proyectoURL = `${ROUTES.CARPETA_PROYECTOS}/${licitacionProyectoID}`;
	//URL del detalle de un cargo.
	let cargoURL = ROUTES.CARGO_LICITACION_PROYECTO.replace(":cargo_id", cargoID);
	//URL del detalle de una interacción con un postulante.
	let interaccionURL = ROUTES.CARGO_ASIGNACION.replace(":asignacion_id", asignacionID);

	if (tipo === CONTEXTS.TIPO_LICITACION) {
		//Se abre una nueva pestaña con la vista para ver una interacción con un postulante de un cargo en una licitación.
		window.open(`${licitacionURL}${cargoURL}${interaccionURL}`, "_blank");
	} else if (tipo === CONTEXTS.TIPO_PROYECTO) {
		//Se abre una nueva pestaña con la vista para ver una interacción con un postulante de un cargo en un proyecto.
		window.open(`${proyectoURL}${cargoURL}${interaccionURL}`, "_blank");
	} else {
		//Se abre una nueva pestaña con la vista principal.
		window.open(ROUTES.HOME, "_blank");
	}
}