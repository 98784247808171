import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { AsignacionRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../App";

export default function Index(props) {
	const AsignacionesSWR = useSWR("asignaciones", (key) => AsignacionRequest.Obtener({ _reclutador_ref: MainCTX.usuarioSesion.ref }, "/_postulante_ref"), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (AsignacionesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de las asignaciones.", AsignacionesSWR.error);
		}
	}, [MainCTX, AsignacionesSWR.error]);

	return (
		<Page
			asignaciones={AsignacionesSWR.data}
			permisos={MainCTX.permisos}
			is_loading={AsignacionesSWR.isValidating}
		/>
	);
}