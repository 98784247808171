import React, { Fragment, useState } from "react";
import MaterialTable from "../../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";

export default function Page(props) {
	let {
		empresas,
		is_loading,
		mutate_empresas,
	} = props;

	const [IsOpen, SetIsOpen] = useState({});

	let columns = [
		{
			title: "RUT",
			field: "rut",
			width: 200,
		}, {
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Rubro",
			field: "rubro",
			width: 600,
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
		}, {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: row }),
		}
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	return (
		<Fragment>
			{/* LISTADO DE EMPRESAS */}
			<MaterialTable
				title="Empresas"
				is_loading={is_loading}
				data={empresas ? empresas.data : []}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					handle_close={handleCloseDialog}
					mutate_empresas={mutate_empresas}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_empresas={mutate_empresas}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_empresas={mutate_empresas}
				/>
			)}
		</Fragment>
	);
}