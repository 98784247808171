import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Fab, Grid, Tooltip, Typography } from "@material-ui/core";
import { ArrowBack, ExpandMore } from "@material-ui/icons";
import TooltipCustom from "../tooltip_custom";
import { FormatearFecha, MontoToMoneda, NombreAbreviado } from "../../services/utilities/formatUtils";
import { PreviousRoute } from "../../services/utilities/common";
import { TipoLicitacionProyecto } from "./utils";

/**
 * Componente para mostrar la información de un cargo, en un Accordion.
 * @param {*} cargo Datos del cargo.
 * @param {*} adjuntos Colección de adjuntos.
 * @returns Componente.
 * @link https://github.com/mui/material-ui/issues/9189#issuecomment-466814903
 */
export default function CargoInfo(props) {
	const {
		cargo,
		adjuntos,
	} = props;

	const [Tipo, SetTipo] = useState("");
	const [MontoMin, SetMontoMin] = useState(0);
	const [MontoMax, SetMontoMax] = useState(0);
	const [MontoObj, SetMontoObj] = useState(0);
	const [Avance, SetAvance] = useState(0);
	const history = useHistory();

	useState(() => {
		if (cargo) {
			const tipo = TipoLicitacionProyecto(cargo._licitacion_proyecto_ref.tipo);
			SetTipo(tipo);
			const montoMin = MontoToMoneda(cargo.renta_liquida_min);
			SetMontoMin(montoMin);
			const montoMax = MontoToMoneda(cargo.renta_liquida_max);
			SetMontoMax(montoMax);
			const montoObj = MontoToMoneda(cargo.renta_liquida_objetivo);
			SetMontoObj(montoObj);
			const porcentajeAvance = Number((cargo.cant_seleccionados / cargo.cant_vacantes) * 100).toFixed(1);
			SetAvance(porcentajeAvance);
		}
	}, [cargo]);

	/**
	 * Método encargado de volver a la vista anterior.
	 */
	const handleVolver = () => {
		//Se obtiene la ruta anterior.
		let previousRoute = PreviousRoute(history, 2);
		history.push(previousRoute);
	}

	return (
		<Accordion style={{ borderRadius: 25, backgroundColor: "#731f1f" }}>
			<AccordionSummary expandIcon={<ExpandMore style={{ color: "white" }} />}>
				<div style={{ width: "100%" }}>
					<Box display="flex" alignItems="center">
						{/* BOTÓN VOLVER */}
						<Tooltip title="Volver a cargos">
							<Fab onClick={handleVolver} color="default" size="small">
								<ArrowBack />
							</Fab>
						</Tooltip>
						{/* DATOS DEL CARGO */}
						<Box p={1} flexGrow={1}>
							{TituloContenido("Código", `[${cargo.codigo}] ${cargo.nombre} | ${cargo.turno} | ${cargo.modalidad}`)}
						</Box>
						<Box display={"flex"} p={1} pr={2}>
							{Titulo("Reclutador:")}
							<TooltipCustom tooltip={cargo._reclutador_ref.contacto.email} custom_color={true}>
								{NombreAbreviado(cargo._reclutador_ref)}
							</TooltipCustom>
						</Box>
						<Box p={1} pl={2}>
							{TituloContenido("Mandante", cargo.mandante)}
						</Box>
					</Box>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={1} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<Grid item xs={3}>
						{TituloContenido(Tipo, cargo._licitacion_proyecto_ref.nombre)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Renta líquida mínima", MontoMin)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Renta líquida objetivo", MontoObj)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Renta líquida máxima", MontoMax)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Ubicación", cargo.ubicacion)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Tipo contrato", cargo.tipo_contrato)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Cant. vacantes", cargo.cant_vacantes)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Cant. asignados", cargo.cant_asignados)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Cant. seleccionados", cargo.cant_seleccionados)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Porcentaje avance", `${Math.min(100, Avance)}%`)}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Fecha requerimiento", FormatearFecha(cargo.fecha_requerimiento))}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Fecha término", FormatearFecha(cargo.fecha_termino))}
					</Grid>
					<Grid item xs={3}>
						{TituloContenido("Fecha creación", FormatearFecha(cargo.fecha_creacion))}
					</Grid>
					<Grid item xs={12}>
						{Titulo("Títulos y Experiencias:")}
						{Array.from(cargo.titulos).map((titulo, index) => (
							<Chip
								label={`${titulo.nombre} (EG: ${titulo.exp_general}, EE: ${titulo.exp_especifica})`}
								size="small"
								style={{ marginLeft: 2, marginRight: 2 }}
								key={`chip_${index}`}
							/>
						))}
					</Grid>
					<Grid item xs={12}>
						{Titulo("Adjuntos:")}
						{Array.from(adjuntos).map((adjunto, index) => (
							<Chip
								label={adjunto.adjunto.nombre_original}
								onClick={() => window.open(adjunto.adjunto.url, "_blank")}
								size="small"
								style={{ marginLeft: 2, marginRight: 2 }}
								key={`chip_${index}`}
							/>
						))}
					</Grid>
					<Grid item xs={12}>
						<Box display={"flex"}>
							{Titulo("Descripción:")}
							<Typography style={{ color: "white", whiteSpace: "pre-line" }}>
								{cargo.descripcion}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * Método encargado de retornar un texto con título de sección.
 * @param {*} titulo Texto principal.
 * @returns Component.
 */
function Titulo(titulo) {
	return (
		<Typography style={{ color: "white", fontWeight: "bold", marginRight: 5 }}>
			{titulo}
		</Typography>
	);
}

/**
 * Método encargado de retornar un texto con título de sección y contenido.
 * @param {*} titulo Texto principal.
 * @param {*} contenido Texto adicional.
 * @returns Component.
 */
function TituloContenido(titulo, contenido) {
	return (
		<Box display={"flex"}>
			{Titulo(`${titulo}:`)}
			<Typography style={{ color: "white" }}>
				{contenido}
			</Typography>
		</Box>
	);
}