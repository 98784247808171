import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Método encargado de agregar una nueva empresa.
 * @param {*} empresa Datos de la empresa.
 * @returns Response.
 */
export async function Agregar(empresa) {
	try {
		let url = `${RECLUTAMIENTO}/empresas`;
		let response = await Axios.post(url, empresa);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una empresa.
 * @param {*} empresa Datos actualizados de la empresa.
 * @returns Response.
 */
export async function Actualizar(empresa) {
	try {
		let url = `${RECLUTAMIENTO}/empresas/${empresa._id}`;
		let response = await Axios.put(url, empresa);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una empresa.
 * @param {*} empresaID ID de la empresa.
 * @returns Response.
 */
export async function Eliminar(empresaID) {
	try {
		let url = `${RECLUTAMIENTO}/empresas/${empresaID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de empresas.
 * @returns Colección de empresas.
 */
export async function Obtener() {
	try {
		let url = `${RECLUTAMIENTO}/empresas`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una empresa, por su ID.
 * @param {*} empresaID ID de la empresa.
 * @returns Response.
 */
export async function ObtenerPorID(empresaID) {
	try {
		let url = `${RECLUTAMIENTO}/empresas/${empresaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}