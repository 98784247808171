import React, { useState } from "react";
import Page from "./page";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ObtenerJobApplicants } from "../../services/request/reclutamiento/trabajando_com";

export default function Index(props) {
	const [JobID, SetJobID] = useState("");
	const [Data, SetData] = useState([]);
	const [Open, SetOpen] = useState(null);
	const [LoadingJob, SetLoadingJob] = useState(false);
	
	const notistack = useSnackbar();

	/**
	 * Método encargado de cargar los datos de una postulación, por su ID.
	 */
	const LoadJob = async () => {
		try {
			if (!JobID || String(JobID).length !== 7) {
				throw new Error("Error, código inválido.");
			} else {
				SetLoadingJob(true);
				let data = await ObtenerJobApplicants(JobID);
				SetData(data);
			}
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar(error.message, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		} finally {
			SetLoadingJob(false);
		}
	}

	const handleChangeJobID = (jobID) => {
		SetJobID(String(jobID).replace("#", ""));
	}

	const handleCloseDialog = () => {
		SetOpen(null);
	}

	return (
		<Page
			job_id={JobID}
			handle_job_id={handleChangeJobID}
			data={Data}
			open={Open}
			set_open={SetOpen}
			loading_job={LoadingJob}
			load_job={LoadJob}
			handle_close_dialog={handleCloseDialog}
		/>
	);
}