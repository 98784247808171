import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import DialogTitleComponent from "../../../../components/dialog_title";
import { Eliminar } from "../../../../services/request/reclutamiento/formulariosPostulacion";
import { MainContext } from "../../../../App";

export default function DialogDelete(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_formularios_postulacion,
	} = props;

	const MainCTX = useContext(MainContext);

	const handleDelete = async () => {
		try {
			await Eliminar(data._id);
			MainCTX.ShowSnackbar("Formulario de postulaciones eliminado exitosamente.");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar eliminar el formulario de postulaciones.", error);
		} finally {
			mutate_formularios_postulacion();
			handle_close();
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Eliminar formulario de postulaciones</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Debe confirmar la eliminación del formulario de postulaciones "${data ? `${data.nombre}` : ""}".`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={handleDelete} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}