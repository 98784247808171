import React, { useContext } from "react";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import { PostulanteEditarContext } from "../index";
import AccordionComponent from "../../../../components/accordion";

export default function Contacto(props) {
	const {
		formik,
	} = useContext(PostulanteEditarContext);

	/**
	 * Método encargado de calcular la cantidad de errores.
	 * @returns Cantidad de errores.
	 */
	function CantErrores() {
		let errores = 0;
		if (formik && formik.errors) {
			formik.errors.contacto && formik.errors.contacto.email && errores++;
		}
		return errores;
	}

	return (
		<AccordionComponent title="Información de Contacto" errores={CantErrores()}>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<TextField
						name="contacto.telefono"
						label="Teléfono"
						value={formik.values.contacto.telefono}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.contacto && formik.errors.contacto.telefono}
						error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						name="contacto.telefono_alternativo"
						label="Teléfono Alternativo"
						value={formik.values.contacto.telefono_alternativo}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.contacto && formik.errors.contacto.telefono_alternativo}
						error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono_alternativo)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						name="contacto.telefono_fijo"
						label="Teléfono Fijo"
						value={formik.values.contacto.telefono_fijo}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.contacto && formik.errors.contacto.telefono_fijo}
						error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono_fijo)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Tooltip title="SOLO en caso de no tener Email, usar sincorreo@sincorreo.cl" placement="top">
						<TextField
							name="contacto.email"
							label="Email"
							value={formik.values.contacto.email}
							onChange={formik.handleChange}
							variant="outlined"
							size="small"
							fullWidth
							required
							helperText={formik.errors.contacto && formik.errors.contacto.email}
							error={Boolean(formik.errors.contacto && formik.errors.contacto.email)}
						/>
					</Tooltip>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="contacto.email_alternativo"
						label="Email Alternativo"
						value={formik.values.contacto.email_alternativo}
						onChange={formik.handleChange}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.contacto && formik.errors.contacto.email_alternativo}
						error={Boolean(formik.errors.contacto && formik.errors.contacto.email_alternativo)}
					/>
				</Grid>
			</Grid>
		</AccordionComponent>
	);
}