import React, { Fragment } from "react";
import Moment from "moment";
import { Box, Button, TextField, Tooltip } from "@material-ui/core";
import MaterialTable from "../../components/materialTable";
import DialogDetalle from "./dialog_detalle";
import { AñosDesde } from "../../services/utilities/formatUtils";

export default function Page(props) {
	const {
		job_id,
		handle_job_id,
		data,
		open,
		set_open,
		loading_job,
		load_job,
		handle_close_dialog,
	} = props;

	if (data) {
		Array.from(data).forEach((postulacion, index) => {
			let primerNombre = postulacion.applicant.personalInfo.firstName;
			let segundoNombre = postulacion.applicant.personalInfo.middleName;
			let primerApellido = postulacion.applicant.personalInfo.lastName;
			let segundoApellido = postulacion.applicant.personalInfo.maidenName;
			data[index]["applicant"]["personalInfo"]["fullname"] = `${primerNombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`;
			let edad = AñosDesde(Moment(postulacion.applicant.personalInfo.birthDate, "DD/MM/YYYY"));
			data[index]["applicant"]["personalInfo"]["edad"] = edad;
		});

		data.sort((a, b) => Moment(b.date, "DD/MM/YYYY HH:mm").diff(Moment(a.date, "DD/MM/YYYY HH:mm")));
	}

	let columns = [{
		title: "Fecha postulación",
		field: "date",
	}, {
		title: "Nombre",
		field: "applicant.personalInfo.fullname",
	}, {
		title: "Fecha de nacimiento",
		field: "applicant.personalInfo.birthDate",
		render: row => <Tooltip title={`${row.applicant.personalInfo.edad} años`}><label>{row.applicant.personalInfo.birthDate}</label></Tooltip>,
	}, {
		title: "RUN",
		field: "applicant.personalInfo.idNumberFormat",
	}, {
		title: "Nacionalidad",
		field: "applicant.personalInfo.nationality",
	}, {
		title: "Nivel de estudios",
		field: "applicant.personalInfo.studiesLevel",
	}, {
		title: "Situación actual",
		field: "applicant.personalInfo.currentSituationName",
	}, {
		title: "Años de experiencia",
		field: "applicant.personalInfo.experienceYears",
	}, {
		title: "Email",
		field: "applicant.personalInfo.emails.primaryEmail",
	}, {
		title: "Región",
		field: "applicant.personalInfo.region.description",
	}, {
		title: "Comuna",
		field: "applicant.personalInfo.commune.description",
	}, {
		title: "Ciudad",
		field: "applicant.personalInfo.city.description",
	}];

	let actions = [{
		tooltip: "Detalles",
		icon: "search",
		onClick: (event, row) => set_open(row),
	}];

	return (
		<Fragment>
			{/* INPUT Y BÚSQUEDA */}
			<Box display="flex" p={1}>
				<Box mr={2}>
					<TextField
						label="Job ID"
						value={job_id}
						onChange={(event) => handle_job_id(event.target.value)}
						variant="outlined"
						size="small"
					/>
				</Box>
				<Box ml={2}>
					<Button onClick={load_job} variant="contained" color="primary">Buscar</Button>
				</Box>
			</Box>

			{/* POSTULACIONES */}
			<MaterialTable
				title="Postulaciones Enviadas"
				is_loading={loading_job}
				data={data}
				columns={columns}
				actions={actions}
			/>

			{/* DIALOG DETALLE DE POSTULACIÓN */}
			{open && (
				<DialogDetalle
					postulante={open}
					handle_close_dialog={handle_close_dialog}
				/>
			)}
		</Fragment>
	);
}