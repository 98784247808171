import React, { Fragment } from "react";
import { Link, Typography } from "@material-ui/core";

export default function DetalleDocumentos(props) {
	const {
		documentos,
	} = props;

	return (
		<Fragment>
			{/* DOCUMENTOS */}
			{documentos[0] && Array.from(documentos[0]).map((documento, index) => (
				<Link href={documento.documentPath} target="_blank" rel="noreferrer" style={{ paddingLeft: 5, paddingRight: 5 }} key={`link_${index}`}>{documento.documentTypeName}</Link>
			))}

			{/* DOCUMENTOS MULTIPLES */}
			{documentos[1] && Array.from(documentos[1]).map((documento, index) => (
				<Link href={documento.documentPath} target="_blank" rel="noreferrer" style={{ paddingLeft: 5, paddingRight: 5 }} key={`link_${index}`}>{documento.documentTypeName}</Link>
			))}

			{/* MENSAJE SIN DOCUMENTOS */}
			{!documentos[0] && !documentos[1] && (
				<Typography>Sin documentos adjuntos</Typography>
			)}
		</Fragment>
	);
}