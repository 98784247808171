import React, { useContext, useEffect, useState } from "react";
import Page from "./page";
import useSWR from "swr";
import Moment from "moment";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { AgregarDatosPostulante, Constantes } from "./utils";
import { PostulanteRequest } from "../../../services/request/reclutamiento/postulantes";
import * as UsuariosPlataformaRequest from "../../../services/request/reclutamiento/usuariosPlataforma";
import * as RegistrosKPIRequest from "../../../services/request/reclutamiento/registro_kpi";
import { RedirectPostulanteCV, RedirectPostulanteEditar } from "../../../services/utilities/redirect";
import { CheckPermiso, CheckPermisosSome } from "../../../services/utilities/common";
import { VISITADOS } from "../../../services/utilities/local_db";
import { ToTitleCase } from "../../../services/utilities/formatUtils";
import { SendHTML } from "../../../services/utilities/email";
import { SolicitarCV } from "../../../services/utilities/email_template";
import * as Permissions from "../../../constants/permissions";
import { REGISTROS_KPI, LUCES_SEMAFORO, RELACION_CYD } from "../../../constants/contexts";
import { MainContext } from "../../../App";

export default function Postulantes(props) {
	const [Open, SetOpen] = useState();
	const [Postulantes, SetPostulantes] = useState([]);
	const [IsLoading, SetIsLoading] = useState(false);
	const [PalabrasClaves, SetPalabrasClave] = useState([]);
	const [Checked, SetChecked] = useState(false);
	const MainCTX = useContext(MainContext);
	const notistack = useSnackbar();

	const FavoritosSWR = useSWR("favoritos", (key) => UsuariosPlataformaRequest.ObtenerFavoritos(MainCTX.usuarioSesion.ref), { revalidateOnFocus: false });
	const OrigenesSWR = useSWR("origenes", PostulanteRequest.CantidadesOrigen, { revalidateOnFocus: false });
	const CantCVsSWR = useSWR("cant_cvs", PostulanteRequest.CantidadesCVs, { revalidateOnFocus: false });
	const CantIncompletosSWR = useSWR("cant_incompletos", PostulanteRequest.CantidadesIncompletos, { revalidateOnFocus: false });

	useEffect(() => {
		if (FavoritosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los favoritos.", FavoritosSWR.error);
		}
		if (OrigenesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener las fuentes de los candidatos.", OrigenesSWR.error);
		}
		if (CantCVsSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener la cantidad de candidatos con CVs.", CantCVsSWR.error);
		}
		if (CantIncompletosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener la cantidad de candidatos con porcentaje de completitud en 0.", CantIncompletosSWR.error);
		}
	}, [MainCTX, FavoritosSWR, OrigenesSWR, CantCVsSWR, CantIncompletosSWR]);

	/**
	 * Evento encargado de ejecutarse con CTRL + Enter.
	 * @link https://stackoverflow.com/questions/16006583/capturing-ctrlz-key-combination-in-javascript.
	 * @param {*} e Evento.
	 */
	document.onkeydown = function (e) {
		if (e.code === "Enter" && e.ctrlKey) {
			handleBuscar();
		}
	}

	/***************************************************************************************
	 * 																			STATES
	 ***************************************************************************************/

	/**
	 * Función para interactuar con el State.
	 * @param {*} open Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const OpenState = (open = undefined) => {
		if (open !== undefined) {
			SetOpen(open);
		} else {
			return Open;
		}
	}

	/**
	 * Función para interactuar con el State.
	 * @param {*} postulantes Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const PostulantesState = (postulantes = undefined) => {
		if (postulantes !== undefined) {
			SetPostulantes(postulantes);
		} else {
			return Postulantes;
		}
	}

	/**
	 * Función para interactuar con el State.
	 * @param {*} isLoading Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const IsLoadingState = (isLoading = undefined) => {
		if (isLoading !== undefined) {
			SetIsLoading(isLoading);
		} else {
			return IsLoading;
		}
	}

	/**
	 * Función para interactuar con el State.
	 * @param {*} palabrasClaves Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const PalabrasClavesState = (palabrasClaves = undefined) => {
		if (palabrasClaves !== undefined) {
			SetPalabrasClave(palabrasClaves);
		} else {
			return PalabrasClaves;
		}
	}

	/**
	 * Función para interactuar con el State.
	 * @param {*} checked Nuevo valor del State.
	 * @returns Valor del State.
	 */
	const CheckedState = (checked = undefined) => {
		if (checked !== undefined) {
			SetChecked(checked);
		} else {
			return Checked;
		}
	}

	/***************************************************************************************
	 * 																		HANDLERS
	 ***************************************************************************************/

	/**
	 * Método encargado de buscar los candidatos que cumplan con las palabras claves ingresadas.
	 */
	const handleBuscar = async () => {
		try {
			IsLoadingState(true);
			let keywords = PalabrasClavesState().map(pc => pc.value);
			let postulantes = [];
			if (keywords.length > 0) {
				postulantes = await PostulanteRequest.BusquedaAvanzada(keywords, Checked);
			} else {
				postulantes = await PostulanteRequest.Obtener();
			}

			let registroKPI = {
				_usuario_ref: MainCTX.usuarioSesion.ref,
				_gerencia_ref: MainCTX.usuarioSesion.gerencia._id,
				tag: REGISTROS_KPI.BUSQUEDA,
			}
			await RegistrosKPIRequest.Agregar(registroKPI);

			//Se mapea la colección de favoritos.
			let favoritos = Array.from(FavoritosSWR.data).map(f => f._postulante_ref);

			//Se agrega información adicional a los postulantes.
			let postulantesMap = Array.from(postulantes)
				.filter(p => p.curriculum_vitae)
				.filter(p => p.porcentaje > 0)
				.reverse()
				.map(p => ({
					...AgregarDatosPostulante(p),
					visitado: VISITADOS.VerificarVisitado(p._id),
					checked: false,
					is_favorito: favoritos.includes(p._id),
					semaforo: ColorSemaforo(p.fecha_actualizacion),
					disabledVerAdjunto: !p.curriculum_vitae || !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_ADJUNTO),
					disabledDetalles: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_DETALLE),
					disabledComentario: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_COMENTARIOS),
					hiddenIconRelacion: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_BLOQUEADOS),
					disabledMail: Boolean(p.is_notificado),
					hiddenMail: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_CORREO_SOLICITAR_CV),
				}));
			SetPostulantes(postulantesMap);
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			IsLoadingState(false);
		}
	}

	/**
	 * Método encargado de obtener el color de semáforo, según la fecha de actualización.
	 * @param {*} fecha Fecha de actualización.
	 * @returns Color de semáforo.
	 */
	const ColorSemaforo = (fecha) => {
		let antiguedad = Moment().diff(Moment(fecha), "months");
		if (antiguedad <= 6) {
			return LUCES_SEMAFORO.VERDE;
		} else if (antiguedad > 6 && antiguedad <= 12) {
			return LUCES_SEMAFORO.AMARILLO;
		} else {
			return LUCES_SEMAFORO.ROJO;
		}
	}

	/**
	 * Método encargado de cerrar el Dialog actualmente abierto.
	 */
	const handleCloseDialog = () => {
		let postulantes = Array.from(Postulantes);
		postulantes.forEach((postulante, index) => {
			postulantes[index].checked = false;
		});
		SetPostulantes(postulantes);
		OpenState(null);
	}

	/**
	 * Método encargado de abrir el Dialog para agregar una nueva evaluación.
	 */
	const handleAddEvaluacion = () => {
		let currentData = Object.assign({}, OpenState());
		handleCloseDialog();
		OpenState({ tipo: Constantes.EVALUACION_ADD, postulante: currentData.postulante });
	}

	/**
	 * Método encargado de abrir el Dialog para actualizar una evaluación.
	 * @param {*} evaluación Datos de la evaluación.
	 */
	const handleUpdateEvaluacion = (evaluacion) => {
		if (CheckPermisosSome(MainCTX.permisos, Permissions.CANDIDATOS_EDITAR_COMENTARIOS, Permissions.CANDIDATOS_ELIMINAR_COMENTARIOS)) {
			let currentData = Object.assign({}, OpenState());
			handleCloseDialog();
			OpenState({ tipo: Constantes.EVALUACION_UPDATE, postulante: currentData.postulante, evaluacion });
		} else {
			notistack.enqueueSnackbar("No tiene los permisos necesarios.", {
				variant: "warning",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
	}

	/**
	 * Método encargado de abrir el Dialog para agregar una nueva asignación.
	 */
	const handleAddAsignacion = () => {
		let currentData = Object.assign({}, OpenState());
		handleCloseDialog();
		OpenState({ tipo: Constantes.ASIGNACION_ADD, postulante: currentData.postulante });
	}

	/**
	 * Método encargado de agregar o quitar un postulante en favoritos.
	 * @param {*} postulanteID ID del postulante.
	 */
	const handleFavorito = async (postulanteID) => {
		try {
			//Se verifica si el postulante está o no en favoritos.
			let checkFavorito = Array.from(FavoritosSWR.data).find(f => f._postulante_ref === postulanteID);
			if (checkFavorito) {
				//Si el postulante ya está en favoritos, se elimina.
				await UsuariosPlataformaRequest.EliminarFavorito(MainCTX.usuarioSesion.ref, checkFavorito._id);
				MainCTX.ShowSnackbar("Favorito eliminado exitosamente.");
			} else {
				//Si el postulante no está en favoritos, se agrega.
				await UsuariosPlataformaRequest.AgregarFavorito(MainCTX.usuarioSesion.ref, postulanteID);
				MainCTX.ShowSnackbar("Favorito agregado exitosamente.");
			}

			let postulantes = Array.from(Postulantes);
			let postulanteIndex = postulantes.findIndex(p => p._id === postulanteID);
			//Se actualiza la información del postulante, localmente.
			postulantes[postulanteIndex].is_favorito = !Boolean(checkFavorito);
			SetPostulantes(postulantes);
			//Se recargan los datos de los favoritos.
			FavoritosSWR.mutate();
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar agregar el favorito.", error);
		}
	}

	/**
	 * Método encargado de actualizar localmente los datos de un postulante.
	 * @param {*} postulante Datos del postulante.
	 */
	const handleActualizarLocal = (postulante) => {
		let index = PostulantesState().findIndex(p => p._id === postulante._id);
		Postulantes[index] = AgregarDatosPostulante(postulante);
	}

	/**
	 * Método encargado de cambiar el estado de seleccionado de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} checked FLAG de seleccionado.
	 */
	const handleToggle = (postulanteID, checked) => {
		let postulantes = Array.from(Postulantes);
		let postulanteIndex = postulantes.findIndex(p => p._id === postulanteID);
		postulantes[postulanteIndex].checked = checked;
		SetPostulantes(postulantes);
	}

	/**
	 * Método encargado de ver al CV adjunto del postulante.
	 * Además, se aumenta la cantidad de visualizaciones del postulante.
	 * @param {*} postulante Datos del postulante.
	 */
	const handleVerAdjunto = async (postulante) => {
		try {
			RedirectPostulanteCV(postulante.curriculum_vitae.url);
			await PostulanteRequest.AumentarVisualizaciones(postulante._id);
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar aumentar la cantidad de visualizaciones.", error);
		}
	}

	/**
	 * Método encargado de abrir una nueva pestaña con el detalle del postulante.
	 * @param {*} postulante Datos del postulante.
	*/
	const handleVerDetalle = async (postulante) => {
		try {
			RedirectPostulanteEditar(postulante._id);
			await PostulanteRequest.AumentarVisualizaciones(postulante._id);
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar aumentar la cantidad de visualizaciones.", error);
		}
	}

	/**
	 * Método encargado de mandar un correo al postulante, para solicitar datos.
	 * @param {*} postulante Datos del postulante.
	 */
	const handleEmail = async (postulante) => {
		try {
			//Si el postulante no tiene email.
			if (!postulante.contacto?.email) {
				throw new TypeError("Postulante sin datos de contacto.");
			}
			//Si el postulante ya trabaja en CyD.
			if (postulante.detalle?.relacion_cyd === RELACION_CYD) {
				throw new TypeError("No se puede notificar, el postulante ya trabaja en CyD.");
			}
			//Si el postulante esta bloqueado.
			if (postulante.is_lista_negra) {
				throw new TypeError("No se puede notificar, el postulante está bloqueado.");
			}
			//Si el postulante tiene alguna asignacion.
			if (postulante.cant_asignaciones > 0) {
				throw new TypeError("No se puede notificar, el postulante está asignado a una vacante.");
			}
			//Si el postulante tiene los datos actualizados con al menos 6 meses de antiguedad.
			if (Moment(postulante.fecha_actualizacion).isAfter(Moment().add(-6, "month"))) {
				throw new TypeError("No se puede notificar, los datos del postulante son actuales.");
			}

			const datosActualizados = {
				fecha_notificacion: Moment(),
				is_notificado: true,
			}
			//Actualizacion local.
			let postulantes = Array.from(Postulantes);
			postulantes[postulante.tableData.id].fecha_notificacion = datosActualizados.fecha_notificacion;
			postulantes[postulante.tableData.id].is_notificado = datosActualizados.is_notificado;
			SetPostulantes(postulantes);
			//Actualizacion en base de datos.
			await PostulanteRequest.Actualizar(postulante._id, datosActualizados);

			const nombrePostulante = ToTitleCase(`${postulante.nombre} ${postulante.apellido_paterno} ${postulante.apellido_materno}`).trim();
			const correoPostulante = MainCTX.usuarioSesion.email; //postulante.contacto.email;
			const template = SolicitarCV(nombrePostulante);
			await SendHTML(correoPostulante, [], ["mlira@cydingenieria.cl"], "CyD Ingeniería - Trabaja con Nosotros", template);
			MainCTX.ShowSnackbar("Correo para solicitar CV enviado exitosamente.");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar mandar el correo para solicitar CV.", error);
		}
	}

	return (
		<Page
			open_state={OpenState}
			postulantes_state={PostulantesState}
			palabras_clave_state={PalabrasClavesState}
			checked_state={CheckedState}
			is_loading_state={IsLoadingState}
			origenes={OrigenesSWR}
			cant_cvs={CantCVsSWR}
			cant_incompletos={CantIncompletosSWR}
			handle_close_dialog={handleCloseDialog}
			handle_add_evaluacion={handleAddEvaluacion}
			handle_update_evaluacion={handleUpdateEvaluacion}
			handle_add_asignacion={handleAddAsignacion}
			handle_actualizar_local={handleActualizarLocal}
			handle_buscar={handleBuscar}
			handle_favorito={handleFavorito}
			handle_toggle={handleToggle}
			handle_ver_adjunto={handleVerAdjunto}
			handle_ver_detalle={handleVerDetalle}
			handle_email={handleEmail}
			is_disabled_asignar={!CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_AGREGAR_ASIGNACIONES) || Postulantes.filter(p => p.checked).length < 1}
		/>
	);
}