import { object, mixed, array, string } from "yup";

export const FormikInitialValues = {
	_reclutador_ref: null,
	_gerencia_ref: null,
	curriculum_vitae: null,
	ensenanza_media: null,
	hoja_vida_conductor: null,
	titulos: [],
	tags: [],
	origen: "",
}

export const FormikValidationSchema = object().shape({
	curriculum_vitae: mixed()
		.nullable()
		.required("El documento es requerido."),
	ensenanza_media: mixed()
		.nullable()
		.optional(),
	hoja_vida_conductor: mixed()
		.nullable()
		.optional(),
	titulos: array()
		.of(mixed())
		.optional(),
	tags: array()
		.of(string())
		.optional(),
	origen: string()
		.nullable()
		.required("El origen del documento es requerido."),
});

/**
 * Método encargado de obtener el listado de formatos permitidos.
 * @param {*} formatos Colección de formatos.
 * @returns Colección de formatos en texto formateado.
 */
export function FormatosText(formatos) {
	let formatosText = Array.from(formatos)
		.map(f => String(f).split("/")[1])
		.join(", ");
	return formatosText;
}