import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AccordionSummary, Collapse, Paper, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import AsignacionDialog from "./asignacion_dialog";
import { ActualizacionRequest } from "../../../../services/request/reclutamiento/postulantes";
import { AsignacionRequest } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { CARPETA_LICITACIONES, CARPETA_PROYECTOS, CARGO_LICITACION_PROYECTO, HOME } from "../../../../constants/routes";
import { TIPO_LICITACION, TIPO_PROYECTO } from "../../../../constants/contexts";
import { EstadoAsignacion, FormatearFecha, NombreAbreviado } from "../../../../services/utilities/formatUtils";
import { PostulanteEditarContext } from "../index";
import { MainContext } from "../../../../App";

export default function Asignaciones(props) {
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);

	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const { postulante_id } = useParams();

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	/**
	 * Método encargado de redireccionar a la página con detalle del cargo.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 * @param {*} cargoID ID del cargo.
	 * @param {*} tipo Tipo licitación o proyecto.
	 */
	const handleCargoRedirect = (licitacionProyectoID, cargoID, tipo) => {
		let ruta = "";
		if (tipo === TIPO_LICITACION) {
			ruta = `${CARPETA_LICITACIONES}/${licitacionProyectoID}${CARGO_LICITACION_PROYECTO.replace(":cargo_id", cargoID)}`;
		} else if (tipo === TIPO_PROYECTO) {
			ruta = `${CARPETA_PROYECTOS}/${licitacionProyectoID}${CARGO_LICITACION_PROYECTO.replace(":cargo_id", cargoID)}`;
		} else {
			ruta = HOME;
		}
		window.open(ruta, "_blank");
	}

	let columns = [
		{
			title: "Fecha",
			field: "fecha_creacion",
			render: (row) => FormatearFecha(row.fecha_creacion),
		}, {
			title: "Estado",
			field: "estado",
			render: (row) => EstadoAsignacion(row.estado, row.tipo),
		}, {
			title: "Vacante",
			field: "_cargo_ref.estado",
		}, {
			title: "ID cargo",
			field: "_cargo_ref.codigo",
			minWidth: 120,
		}, {
			title: "Cargo",
			field: "_cargo_ref.nombre",
		}, {
			title: "Asignado por",
			field: "_asignador_ref.nombre",
			render: (row) => NombreAbreviado(row._asignador_ref),
		}, {
			title: "Gerencia",
			field: "gerencia_sigla",
		}
	];

	let actions = [
		(row) => ({
			tooltip: "Editar",
			icon: "login",
			onClick: (event, row) => handleCargoRedirect(row._licitacion_proyecto_ref, row._cargo_ref._id, row.tipo),
		}),
		(row) => ({
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarAsignacion(row),
		}), {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar una asignación.
	 * @param {*} asignacion Datos de la asignación.
	 */
	const handleEliminarAsignacion = async (asignacion) => {
		try {
			//Se elimina la asignación y se agrega actualización.
			let responses = await Promise.all([
				AsignacionRequest.Eliminar(asignacion._licitacion_proyecto_ref, asignacion._cargo_ref._id, asignacion._id),
				ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref, `Asignación al cargo ${asignacion._cargo_ref.nombre} (${asignacion._cargo_ref.codigo}) eliminada`),
			]);

			//Nueva actualización.
			let actualizacion = responses[1];
			let asignaciones = Array.from(formik.values.asignaciones);
			//Se elimina la asignación.
			asignaciones.splice(asignacion.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("asignaciones", asignaciones);
			formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

			ShowSnackbar("Asignación del postulante eliminada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar eliminar la asignación del postulante.", error);
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE ASIGNACIONES */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Asignaciones"
					is_loading={false}
					data={formik.values.asignaciones}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Asignaciones (${formik.values.asignaciones.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG ASIGNACIÓN */}
			<AsignacionDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}