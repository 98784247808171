import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Grid, Paper, Tooltip, Typography } from "@material-ui/core";

/**
 * Componente gráfico de Dona con Highcharts.
 * @param {*} serie_name Nombre para el eje Y.
 * @param {*} data Datos para graficar.
 * @param {*} cant_cvs Cantidad de postulantes con CVs.
 * @param {*} cant_incompletos Cantidad de postulantes con datos incompletos.
 * @returns Componente gráfico.
 */
export default function BarChart(props) {
	const {
		serie_name = "Cantidad",
		data = [],
		cant_cvs,
		cant_incompletos,
	} = props;

	const Options = {
		chart: {
			height: 50,
			type: "bar",
			spacingTop: 0,
			spacingBottom: 0,
		},
		title: {
			text: null,
		},
		tooltip: {
			pointFormat: "{series.name}: {point.y} (<b>{point.percentage:.1f}%</b>)",
		},
		legend: false,
		xAxis: {
			categories: [serie_name]
		},
		yAxis: {
			min: 0,
			title: {
				text: null,
			},
			visible: false,
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		plotOptions: {
			series: {
				stacking: 'normal'
			}
		},
		series: Array.from(data).map(d => ({
			name: d[0],
			data: [d[1]]
		})),
	}

	return (
		<Paper style={{ borderRadius: 25 }}>
			<Grid container spacing={2} style={{ marginBottom: 10 }}>
				<Grid item xs={9}>
					<Box style={{ marginLeft: 10 }}>
						<HighchartsReact
							highcharts={Highcharts}
							options={Options}
						/>
					</Box>
				</Grid>
				<Grid item xs={3}>
					<Box display="flex" justifyContent="center" p={1} m={1}>
						<Tooltip title={`Incompletos: ${cant_incompletos}`}>
							<Typography>{`Total Registrados: ${Number(cant_cvs).toLocaleString("pt")} de ${ObtenerTotal(data)}`}</Typography>
						</Tooltip>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
}

/**
 * Método encargado de obtener el valot total de los datos.
 * @param {*} data Colección de datos.
 * @returns Valor total.
 */
function ObtenerTotal(data) {
	let total = Array.from(data)
		.map(d => d[1])
		.reduce((prev, curr) => (prev + curr));
	let totalFormat = Number(total).toLocaleString("es");
	return totalFormat;
}