import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Paper, Typography } from "@material-ui/core";
import { FindInPageOutlined, ContactPhoneOutlined, FolderShared } from "@material-ui/icons";
import MaterialTable from "../materialTable";
import TooltipIfLongerThan from "../tooltip_if_longer_than";
import { EdadFormato, EstadoAsignacion, LikeDislikeIcon, LikeDislikeTooltip, MontoToMoneda, NombreAbreviado, NombreCompleto, ToTitleCase } from "../../services/utilities/formatUtils";
import { RedirectPostulanteCV, RedirectPostulanteEditar } from "../../services/utilities/redirect";
import { CheckPermiso, CheckPermisosSome } from "../../services/utilities/common";
import { downloadFileFromURL, zipFiles } from "../../services/utilities/files";
import * as Permissions from "../../constants/permissions";
import { CARGO_ASIGNACION } from "../../constants/routes";
import DialogAsignacion from "./_dialog_asignacion";
import { MainContext } from "../../App";

/**
 * Componente para mostrar la colección de postulantes asignados.
 * @param {*} titulo Título del listado.
 * @param {*} cargo Datos del cargo.
 * @param {*} renta_max Renta líquida máxima.
 * @param {*} postulantes Colección de postulantes asignados.
 * @param {*} runs Colección de todos los RUNs.
 * @param {*} is_loading Estado de carga de los postulantes.
 * @param {*} handle_like_dislike Método encargado de cambiar el estado LIKE, DISLIKE y sin estado.
 * @returns Componente.
 */
export default function CargoPostulantes(props) {
	const {
		titulo = "Postulantes",
		cargo,
		renta_max,
		postulantes,
		runs,
		is_loading,
		handle_like_dislike,
		mutate_postulantes,
	} = props;

	const [IsOpen, SetIsOpen] = useState(false);
	const [Postulantes, SetPostulantes] = useState([]);
	const MainCTX = useContext(MainContext);
	const history = useHistory();

	useEffect(() => {
		if (postulantes) {
			Array.from(postulantes).forEach((asignacion, index) => {
				asignacion["cargo_codigo"] = cargo.codigo;
				asignacion["cargo_nombre"] = cargo.nombre;
				asignacion["cargo_turno"] = cargo.turno;
				asignacion._postulante_ref["nombre_abreviado"] = NombreAbreviado(asignacion._postulante_ref);
				asignacion._postulante_ref["nombre_completo"] = NombreCompleto(asignacion._postulante_ref);
				asignacion["estado"] = EstadoAsignacion(asignacion.estado, cargo._licitacion_proyecto_ref.tipo);
				asignacion._postulante_ref["correo"] = asignacion._postulante_ref.contacto?.email;
				asignacion._postulante_ref["edad"] = EdadFormato(asignacion._postulante_ref.fecha_nacimiento, "", "");
				asignacion._postulante_ref["telefono"] = asignacion._postulante_ref.contacto?.telefono;
				asignacion._postulante_ref["nacionalidad"] = ToTitleCase(asignacion._postulante_ref.nacionalidad);
				asignacion._postulante_ref["region"] = ToTitleCase(asignacion._postulante_ref.ubicacion?.region);
				asignacion._postulante_ref["comuna"] = ToTitleCase(asignacion._postulante_ref.ubicacion?.comuna);
				asignacion._postulante_ref["direccion"] = ToTitleCase(asignacion._postulante_ref.ubicacion?.direccion);
				asignacion._postulante_ref["licencias_conducir"] = asignacion._postulante_ref.detalle.licencias_conducir.join(", ");
				asignacion["comentarios_join"] = Array.from(asignacion.comentarios || []).reverse().map((c, index) => `Comentario ${index + 1}: ${c}`).join(", ");
				asignacion._postulante_ref["cv_link"] = asignacion._postulante_ref.curriculum_vitae?.url || "";
			});
			SetPostulantes(postulantes);
		}
	}, [postulantes, cargo]);

	/**
	 * Método encargado de formatear la pretensión de renta líquida. Si es mayor al máximo, se pinta color rojo.
	 * @param {*} row Datos del postulante asignado.
	 * @returns Componente con la pretensión de renta líquida.
	 */
	const RentaLiquida = (row) => {
		let rentaLiquidaMax = Number(renta_max);
		let rentaLiquidaPretension = Number(row.pretension_renta);
		let check = rentaLiquidaPretension > rentaLiquidaMax;
		return (
			<label style={{ color: check ? "red" : "inherit" }}>
				{MontoToMoneda(rentaLiquidaPretension)}
			</label>
		);
	}

	let columns = [
		{
			title: "Código",
			field: "cargo_codigo",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Cargo",
			field: "cargo_nombre",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Turno",
			field: "cargo_turno",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Nombre",
			field: "_postulante_ref.nombre_completo",
			width: 200,
		}, {
			title: "RUN",
			field: "_postulante_ref.run",
			width: 120,
		}, {
			title: "Estado",
			field: "estado",
			width: 100,
		}, {
			title: "Email",
			field: "_postulante_ref.correo",
			hidden: true,
		}, {
			title: "Edad",
			field: "_postulante_ref.edad",
			width: 50,
		}, {
			title: "Teléfono",
			field: "_postulante_ref.telefono",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "País",
			field: "_postulante_ref.nacionalidad",
			width: 50,
		}, {
			title: "Región",
			field: "_postulante_ref.region",
			width: 100,
			hidden: true,
		}, {
			title: "Comuna",
			field: "_postulante_ref.comuna",
			width: 100,
		}, {
			title: "Dirección",
			field: "_postulante_ref.direccion",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Lic. conducir",
			field: "_postulante_ref.licencias_conducir",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Fuente",
			field: "fuente",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Título",
			field: "titulo",
			width: 150,
			render: (row) => <TooltipIfLongerThan texto={ToTitleCase(row._postulante_ref.ultimo_titulo)} largo={25} />,
		}, {
			title: "Institución",
			field: "institucion",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Situación laboral",
			field: "situacion_laboral",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Experiencia general",
			field: "exp_general",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Experiencia específica",
			field: "exp_especifica",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Renta líquida",
			field: "pretension_renta",
			width: 100,
			render: (row) => RentaLiquida(row),
		}, {
			title: "Comentarios",
			field: "comentarios_join",
			hidden: true,
			hiddenByColumnsButton: true,
		}, {
			title: "Link CV",
			field: "_postulante_ref.cv_link",
			width: 100,
			render: (row) => row._postulante_ref.cv_link ? <a href={row._postulante_ref.cv_link} target="_blank" rel="noreferrer">Link</a> : null,
		}
	];

	let actions = [
		(row) => ({
			icon: () => <FindInPageOutlined />,
			tooltip: "Ver Adjunto",
			onClick: (event, row) => RedirectPostulanteCV(row._postulante_ref.curriculum_vitae.url),
			disabled: !row._postulante_ref.curriculum_vitae || !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_ADJUNTO),
		}), (row) => ({
			icon: () => <Badge badgeContent={Number(row._postulante_ref.cant_asignaciones || 0)} color="primary"><FolderShared /></Badge>,
			tooltip: "Detalles",
			onClick: (event, row) => RedirectPostulanteEditar(row._postulante_ref._id),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_DETALLE),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER_DETALLE),
		}), {
			icon: () => <ContactPhoneOutlined />,
			tooltip: "Interactuar",
			onClick: (event, row) => history.push(`${history.location.pathname}${CARGO_ASIGNACION.replace(":asignacion_id", row._id)}`),
			disabled: !CheckPermisosSome(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER, Permissions.CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER),
			hidden: !CheckPermisosSome(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER, Permissions.CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER),
		}, (row) => ({
			icon: () => LikeDislikeIcon(row.like_dislike),
			tooltip: LikeDislikeTooltip(row.like_dislike),
			onClick: (event, row) => handle_like_dislike(row._id, row.like_dislike),
			disabled: !CheckPermisosSome(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER, Permissions.CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER),
			hidden: !CheckPermisosSome(MainCTX.permisos, Permissions.CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER, Permissions.CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER),
		}), {
			icon: () => ButtonAsignar(),
			tooltip: "En este proceso",
			onClick: (event, row) => SetIsOpen(true),
			disabled: !CheckPermiso(MainCTX.permisos, Permissions.CARGAR_CV_INDIVIDUAL),
			hidden: !CheckPermiso(MainCTX.permisos, Permissions.CARGAR_CV_INDIVIDUAL),
			isFreeAction: true,
		}
	];

	const handleClose = () => {
		mutate_postulantes();
		SetIsOpen(false);
	}

	/**
	 * Método encargado de ejecutarse después de exportar los datos.
	 */
	const AfterExport = async () => {
		try {
			//Postulantes con CVs.
			let postulantesFilter = Postulantes.filter(a => a._postulante_ref.curriculum_vitae)
				.map(p => p._postulante_ref);

			//URLs y nombres de los CVs.
			let cvs = postulantesFilter.map(p => ({
				url: p.curriculum_vitae.url,
				nombre: FilenameCV(p)
			}));

			//Colección de promesas para descargar archivos.
			let promises = cvs.map(cv => downloadFileFromURL(cv.url));
			//Colección de archivos descargados.
			let responses = await Promise.allSettled(promises);

			//Arreglo para los archivos que se incluirán en el ZIP.
			let archivos = responses.map(r => ({
				contenido: r.value.data,
				nombre: cvs.find(cv => cv.url === r.value.config.url).nombre,
			}));

			await zipFiles(archivos, `CVs cargo ${cargo.nombre}`);
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar descargar CVs en ZIP.", error);
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE ASIGNADOS */}
			<MaterialTable
				title={titulo}
				is_loading={is_loading}
				data={Postulantes.reverse()}
				columns={columns}
				actions={actions}
				export_excel={true}
				after_export={AfterExport}
			/>

			{/* DIALOG NUEVA ASIGNACIÓN */}
			{IsOpen && (
				<DialogAsignacion
					is_open={IsOpen}
					cargo={cargo}
					runs={runs}
					handle_close={handleClose}
				/>
			)}
		</Fragment>
	);
}

/**
 * Método encargado de retornar el botón para buscar postulantes.
 * @returns Component.
 */
function ButtonAsignar() {
	return (
		<Paper style={{ backgroundColor: "#731f1f", borderRadius: 25, paddingLeft: 10, paddingRight: 10 }}>
			<Typography style={{ color: "white" }}>Asignar</Typography>
		</Paper>
	);
}

/**
 * Método encargado de formatear el nombre del CV descargado en ZIP.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre del archivo.
 */
function FilenameCV(postulante) {
	let inicialNombre = String(postulante.nombre).charAt(0).toUpperCase();
	let apellido = String(postulante.apellido_paterno).replace(/\s/, "_").toUpperCase() || "";
	let run = String(postulante.run).toUpperCase();
	let filename = `CV_${inicialNombre}${apellido}_${run}.pdf`;
	return filename;
}