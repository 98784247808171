import React, { createContext, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import Page from "./page";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { PostulanteRequest, EstudioRequest, ExperienciaRequest, ActualizacionRequest, ReferidoRequest, AdjuntoRequest } from "../../../services/request/reclutamiento/postulantes";
import { AsignacionRequest, LicitacionProyectoRequets } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import * as SeleccionableRequests from "../../../services/request/reclutamiento/seleccionables";
import * as EmpresaRequests from "../../../services/request/reclutamiento/empresas";
import * as CommonRequests from "../../../services/request/common";
import RegionesComunas from "../../../services/data/regiones_comunas.json";
import Generos from "../../../services/data/generos.json";
import { EdadFormato, MonedaToMonto, MontoToMoneda, PorcentajeCompletitud, ToTitleCase } from "../../../services/utilities/formatUtils";
import { MainContext } from "../../../App";

export const PostulanteEditarContext = createContext({
	discapacidades: { data: [], isValidating: Boolean() },
	estados_civiles: { data: [], isValidating: Boolean() },
	licencias_conducir: { data: [], isValidating: Boolean() },
	niveles_ingles: { data: [], isValidating: Boolean() },
	niveles_estudio: { data: [], isValidating: Boolean() },
	situaciones_laborales: { data: [], isValidating: Boolean() },
	instituciones: { data: [], isValidating: Boolean() },
	titulos: { data: [], isValidating: Boolean() },
	relaciones_cyd: { data: [], isValidating: Boolean() },
	empresas: { data: [], isValidating: Boolean() },
	licitaciones_proyectos: { data: [], isValidating: Boolean() },

	generos: [],
	regiones_comunas: [],
	paises: [],

	porcentaje: Number(),
	porcentajes: [],

	formik: null,
});

export default function Index(props) {
	const [IsLoading, SetIsLoading] = useState(false);
	const [Porcentaje, SetPorcentaje] = useState(0);
	const [Porcentajes, SetPorcentajes] = useState([]);
	const Paises = CommonRequests.Paises();
	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const { postulante_id } = useParams();
	const notistack = useSnackbar();

	const DiscapacidadesSWR = useSWR("discapacidades_detalles", SeleccionableRequests.ObtenerDiscapacidades, { revalidateOnFocus: false });
	const EstadosCivilesSWR = useSWR("estados_civiles_detalles", SeleccionableRequests.ObtenerEstadosCiviles, { revalidateOnFocus: false });
	const LicenciasConducirSWR = useSWR("licencias_conducir_detalles", SeleccionableRequests.ObtenerLicenciasConducir, { revalidateOnFocus: false });
	const NivelesInglesSWR = useSWR("niveles_ingles_detalles", SeleccionableRequests.ObtenerNivelesIngles, { revalidateOnFocus: false });
	const SituacionesLaboralesSWR = useSWR("situaciones_laborales_detalles", SeleccionableRequests.ObtenerSituacionesLaborales, { revalidateOnFocus: false });
	const InstitucionesSWR = useSWR("instituciones_detalles", SeleccionableRequests.ObtenerInstituciones, { revalidateOnFocus: false });
	const TitulosSWR = useSWR("titulos_detalles", SeleccionableRequests.ObtenerTitulos, { revalidateOnFocus: false });
	const RelacionesSWR = useSWR("relaciones_cyd_detalles", SeleccionableRequests.ObtenerRelacionesCyD, { revalidateOnFocus: false });
	const NivelesEstudioSWR = useSWR("niveles_estudio_detalles", SeleccionableRequests.ObtenerNivelesEstudio, { revalidateOnFocus: false });
	const EmpresasSWR = useSWR("empresas_detalles", EmpresaRequests.Obtener, { revalidateOnFocus: false });
	const LicitacionesProyectosSWR = useSWR("licitaciones_proyectos", LicitacionProyectoRequets.Obtener, { revalidateOnFocus: false });

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Actualizando el postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Se guardan en lower case.
				values.run = values.run.toLowerCase();
				values.nombre = values.nombre.toLowerCase();
				values.apellido_paterno = values.apellido_paterno.toLowerCase();
				values.apellido_materno = values.apellido_materno?.toLowerCase();
				values.contacto.email = values.contacto.email.toLowerCase();

				if (values.nacionalidad_object) {
					//Si se seleccionó una nacionalidad, se asigna.
					values.nacionalidad = values.nacionalidad_object.name;
				}
				if (values.estado_civil_object) {
					//Si se seleccionó un estado civil, se asigna.
					values.estado_civil = values.estado_civil_object.valor;
				}
				if (values.ubicacion.region_object) {
					//Si se seleccionó una región, se asigna.
					values.ubicacion.region = values.ubicacion.region_object.nombre;
				}
				if (values.detalle.licencias_conducir_objects.length > 0) {
					//Si se seleccionaron licencias de conducir, se asignan.
					values.detalle.licencias_conducir = values.detalle.licencias_conducir_objects.map(lc => lc.valor);
				}
				if (values.detalle.nivel_ingles_object) {
					//Si se seleccionó un nivel de inglés, se asigna.
					values.detalle.nivel_ingles = values.detalle.nivel_ingles_object.valor;
				}
				if (values.detalle.situacion_laboral_object) {
					//Si se seleccionó una situación laboral, se asigna.
					values.detalle.situacion_laboral = values.detalle.situacion_laboral_object.valor;
				}
				if (values.detalle.is_discapacitado_object) {
					//Si se seleccionó discapacidad, se asigna.
					values.detalle.is_discapacitado = values.detalle.is_discapacitado_object.valor;
				}
				if (values.detalle.relacion_cyd_object) {
					//Si se seleccionó relación con CyD, se asigna.
					values.detalle.relacion_cyd = values.detalle.relacion_cyd_object.valor;
				}
				if (values.detalle.zonas_trabajo_object.length > 0) {
					//Si se seleccionaron zonas de trabajo, se asigna.
					values.detalle.zonas_trabajo = values.detalle.zonas_trabajo_object;
				}
				if (values.detalle.sistema_turno_object) {
					//Si se seleccionó sistema de turnos, se asigna.
					values.detalle.sistema_turno = values.detalle.sistema_turno_object.valor;
				}
				if (values.detalle.pretencion_renta_formato) {
					//Si se ingresó una pretención de renta.
					values.detalle.pretencion_renta = MonedaToMonto(values.detalle.pretencion_renta_formato);
				}
				values.detalle._reclutador_ref = usuarioSesion.ref;

				//Se actualiza el postulante.
				await PostulanteRequest.Actualizar(postulante_id, values);
				//Se ingresa un nuevo registro de actualización del postulante.
				await ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref);

				ShowSnackbar("Postulante actualizado exitosamente.");
			} catch (error) {
				ShowSnackbar("Error al intentar actualizar el postulante.", error);
			}
		},
	});

	useEffect(() => {
		const LoadData = async () => {
			try {
				if (postulante_id && !DiscapacidadesSWR.isValidating && !EstadosCivilesSWR.isValidating && !LicenciasConducirSWR.isValidating && !NivelesInglesSWR.isValidating && !SituacionesLaboralesSWR.isValidating && !InstitucionesSWR.isValidating && !TitulosSWR.isValidating && !RelacionesSWR.isValidating && !EmpresasSWR.isValidating && !NivelesEstudioSWR.isValidating) {
					SetIsLoading(true);

					let responses = await Promise.all([
						PostulanteRequest.ObtenerPorID(postulante_id),
						EstudioRequest.Obtener(postulante_id),
						ExperienciaRequest.Obtener(postulante_id),
						ActualizacionRequest.Obtener(postulante_id),
						ReferidoRequest.Obtener(postulante_id),
						AdjuntoRequest.Obtener(postulante_id),
						AsignacionRequest.Obtener({ _postulante_ref: postulante_id }, "/_postulante_ref/_cargo_ref/_reclutador_ref/_asignador_ref"),
					]);

					let postulante = responses[0];
					let estudios = responses[1];
					let experiencias = responses[2];
					let actualizaciones = responses[3];
					let referidos = responses[4];
					let adjuntos = responses[5];
					let asignaciones = responses[6];

					formik.setValues({
						...formik.initialValues,
						run: postulante.run,
						nombre: ToTitleCase(postulante.nombre),
						apellido_paterno: ToTitleCase(postulante.apellido_paterno),
						apellido_materno: ToTitleCase(postulante.apellido_materno),
						fecha_nacimiento: postulante.fecha_nacimiento,
						edad: EdadFormato(postulante.fecha_nacimiento, " años", " meses"),
						nacionalidad: postulante.nacionalidad,
						nacionalidad_object: CommonRequests.PaisPorNombre(postulante.nacionalidad),
						genero: Generos.find(g => g === postulante.genero),
						origen: postulante.origen,

						ubicacion: {
							direccion: postulante.ubicacion?.direccion || "",
							ciudad: postulante.ubicacion?.ciudad || "",
							comuna: postulante.ubicacion?.comuna || "",
							region: postulante.ubicacion?.region || "",
							region_object: RegionesComunas.find(r => r.nombre === postulante.ubicacion?.region),
						},

						contacto: {
							telefono: postulante.contacto?.telefono || "",
							telefono_fijo: postulante.contacto?.telefono_fijo || "",
							telefono_alternativo: postulante.contacto?.telefono_alternativo || postulante.contacto?.telefono_fijo || "",
							email: postulante.contacto?.email || "",
							email_alternativo: postulante.contacto?.email_alternativo || "",
						},

						detalle: {
							licencias_conducir: postulante.detalle?.licencias_conducir || [],
							licencias_conducir_objects: Array.from(LicenciasConducirSWR.data.data).filter(lc => postulante.detalle && postulante.detalle.licencias_conducir.includes(lc.valor)),
							nivel_ingles: postulante.detalle?.nivel_ingles || "",
							nivel_ingles_object: Array.from(NivelesInglesSWR.data.data).find(ni => ni.valor === postulante.detalle?.nivel_ingles),
							is_discapacitado: postulante.detalle?.is_discapacitado || false,
							is_discapacitado_object: { label: postulante.detalle?.is_discapacitado ? "Si" : "No", valor: postulante.detalle?.is_discapacitado },
							relacion_cyd: postulante.detalle?.relacion_cyd || "",
							relacion_cyd_object: Array.from(RelacionesSWR.data.data).find(r => r.valor === postulante.detalle?.relacion_cyd),
							zonas_trabajo: postulante.detalle?.zonas_trabajo || [],
							zonas_trabajo_object: postulante.detalle?.zonas_trabajo || [],
							sistema_turno: postulante.detalle?.sistema_turno,
							sistema_turno_object: { label: postulante.detalle?.sistema_turno ? "Si" : "No", valor: postulante.detalle?.sistema_turno },
							pretencion_renta_formato: MontoToMoneda(postulante.detalle?.pretencion_renta) || "",
							pretencion_renta: postulante.detalle?.pretencion_renta || 0,
						},
						inicio_vida_laboral: postulante.inicio_vida_laboral,
						curriculum_vitae: postulante.curriculum_vitae,

						estudios: estudios,
						experiencias: experiencias,
						actualizaciones: actualizaciones,
						referidos: referidos,
						adjuntos: adjuntos,
						asignaciones: asignaciones,
					});

					SetPorcentaje(postulante.porcentaje);
					let porcentajes = PorcentajeCompletitud(postulante, 0, 0);
					SetPorcentajes(porcentajes);
				}
			} catch (error) {
				throw error;
			}
		}

		LoadData()
			.catch(error => ShowSnackbar("Error al intentar cargar los datos.", error))
			.finally(() => SetIsLoading(false));
	}, [ShowSnackbar, postulante_id, DiscapacidadesSWR.data, EstadosCivilesSWR.data, LicenciasConducirSWR.data, NivelesInglesSWR.data, NivelesEstudioSWR.data, SituacionesLaboralesSWR.data, InstitucionesSWR.data, TitulosSWR.data, RelacionesSWR.data, EmpresasSWR.data]);

	useEffect(() => {
		if (DiscapacidadesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las discapacidades.", DiscapacidadesSWR.error);
		}
		if (EstadosCivilesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de los estados civiles.", EstadosCivilesSWR.error);
		}
		if (LicenciasConducirSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las licencias de conducir.", LicenciasConducirSWR.error);
		}
		if (NivelesInglesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de los niveles de Inglés.", NivelesInglesSWR.error);
		}
		if (SituacionesLaboralesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las situaciones laborales.", SituacionesLaboralesSWR.error);
		}
		if (InstitucionesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las instituciones.", InstitucionesSWR.error);
		}
		if (TitulosSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de los títulos.", TitulosSWR.error);
		}
		if (EmpresasSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las empresas.", EmpresasSWR.error);
		}
		if (RelacionesSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las relaciones con CyD.", RelacionesSWR.error);
		}
		if (NivelesEstudioSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de los niveles de estudio.", NivelesEstudioSWR.error);
		}
		if (LicitacionesProyectosSWR.error) {
			ShowSnackbar("Error al intentar obtener datos de las licitaciones/proyectos.", LicitacionesProyectosSWR.error);
		}
	}, [ShowSnackbar, DiscapacidadesSWR, EstadosCivilesSWR, LicenciasConducirSWR, NivelesInglesSWR, NivelesEstudioSWR, SituacionesLaboralesSWR, InstitucionesSWR, TitulosSWR, RelacionesSWR, EmpresasSWR, LicitacionesProyectosSWR]);

	/**
	 * 
	 * @returns 
	 */
	const showBackdrop = () => {
		let check = formik.isSubmitting
			|| IsLoading
			|| DiscapacidadesSWR.isValidating
			|| EstadosCivilesSWR.isValidating
			|| LicenciasConducirSWR.isValidating
			|| NivelesInglesSWR.isValidating
			|| NivelesEstudioSWR.isValidating
			|| SituacionesLaboralesSWR.isValidating
			|| InstitucionesSWR.isValidating
			|| TitulosSWR.isValidating
			|| RelacionesSWR.isValidating
			|| EmpresasSWR.isValidating
			|| LicitacionesProyectosSWR.isValidating
		return check;
	}

	return (
		<PostulanteEditarContext.Provider
			value={{
				discapacidades: DiscapacidadesSWR,
				estados_civiles: EstadosCivilesSWR,
				licencias_conducir: LicenciasConducirSWR,
				niveles_ingles: NivelesInglesSWR,
				niveles_estudio: NivelesEstudioSWR,
				situaciones_laborales: SituacionesLaboralesSWR,
				instituciones: InstitucionesSWR,
				titulos: TitulosSWR,
				relaciones_cyd: RelacionesSWR,
				empresas: EmpresasSWR,
				licitaciones_proyectos: LicitacionesProyectosSWR,

				generos: Generos,
				regiones_comunas: RegionesComunas,
				paises: Paises,

				porcentaje: Porcentaje,
				porcentajes: Porcentajes,

				formik: formik,
			}}
		>
			<Page
				show_backdrop={showBackdrop()}
			/>
		</PostulanteEditarContext.Provider>
	);
}