import React, { Fragment } from "react";
import { Grid, Paper } from "@material-ui/core";

export default function DetalleExperiencias(props) {
	const {
		experiencias,
	} = props;

	return (
		<Fragment>
			{Array.from(experiencias).reverse().map((experiencia, index) => (
				<Fragment >
					<Paper elevation={5} style={{ padding: 10 }}>
						<Grid container spacing={2} key={`grid_${index}`}>
							<Grid item xs={4}>{`Rubro: ${experiencia.companyActivity.description || ""}`}</Grid>
							<Grid item xs={8}>{`Empresa: ${experiencia.companyName || ""}`}</Grid>
							<Grid item xs={4}>{`Cargo: ${experiencia.jobPosition || ""}`}</Grid>
							<Grid item xs={4}>{`Desde/Hasta: ${experiencia.fromDate} al ${experiencia.toDate}`}</Grid>
							<Grid item xs={4}>{`Salario: $${experiencia.salary || ""}`}</Grid>
							<Grid item xs={4}>{`Departamento: ${experiencia.department.description || ""}`}</Grid>
							<Grid item xs={4}>{`Posición: ${experiencia.position.description || ""}`}</Grid>
							<Grid item xs={4}>{`Región: ${experiencia.region.description || ""}`}</Grid>
							<Grid item xs={4}>{`Tipo de Trabajo: ${experiencia.jobType.description || ""}`}</Grid>
							<Grid item xs={12}>{`Logros: ${experiencia.achievements || ""}`}</Grid>
						</Grid>
					</Paper>
					<br />
				</Fragment>
			))}
		</Fragment>
	);
}