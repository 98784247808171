import React, { useState } from "react";
import { Button, Grid, ListItem, Paper, Typography } from "@material-ui/core";
import FuncionesDialog from "./funcionesDialog";
import AdjuntosDialog from "./adjuntosDialog";
import { FormatearFecha, MontoEnMillones, NombreAbreviado } from "../../../services/utilities/formatUtils";

/**
 * Método encargado de retornar un componente con la información del cargo.
 * @param {*} cargo Datos del cargo.
 * @param {*} licitacion_proyecto Datos de la licitación/proyecto.
 * @param {*} is_licitacion FLAG para saber si es licitación.
 * @param {*} is_proyecto FLAG para saber si es proyecto.
 * @param {*} responsable Datos del responsable.
 * @param {*} reclutador Datos del reclutador.
 * @param {*} asignador Datos del asignador.
 * @param {*} adjuntos Colección de documentos adjuntos.
 * @returns Component.
 */
export default function CargoInfo(props) {
	const {
		cargo,
		licitacion_proyecto,
		is_licitacion,
		is_proyecto,
		responsable,
		reclutador,
		asignador,
		adjuntos,
	} = props;

	const [IsOpenDescripcion, SetIsOpenDescripcion] = useState(false);
	const [IsOpenAdjuntos, SetIsOpenAdjuntos] = useState(false);

	return (
		<Paper style={{ padding: 20, borderRadius: 25 }}>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Paper style={{ backgroundColor: "#731f1f", padding: 10, borderRadius: 25 }}>
						<Typography style={{ color: "white" }}>Información del cargo</Typography>
					</Paper>
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Nombre del cargo", cargo.nombre)}
				</Grid>
				<Grid item xs={6}>
					{is_licitacion && (
						TituloContenido("Licitación", `[${licitacion_proyecto.codigo}] ${licitacion_proyecto.nombre}`)
					)}
					{is_proyecto && (
						TituloContenido("Proyecto", `[${licitacion_proyecto.codigo}] ${licitacion_proyecto.nombre}`)
					)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Títulos (EG, EE)", `${Array.from(cargo.titulos).map((titulo, index) => `${titulo.nombre} (${titulo.exp_general},${titulo.exp_especifica})`).join(", ")}`)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Rango renta liq. (min, objetivo, max)", `${MontoEnMillones(cargo.renta_liquida_min)}, ${MontoEnMillones(cargo.renta_liquida_objetivo)}, ${MontoEnMillones(cargo.renta_liquida_max)}`)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Vacantes", cargo.cant_vacantes)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Ubicación", cargo.ubicacion)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Fecha de inicio", FormatearFecha(cargo.fecha_requerimiento))}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Cliente", cargo.mandante)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Duración del cargo", cargo.duracion)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Responsable", NombreAbreviado(responsable))}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Turno", cargo.turno)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Reclutador", NombreAbreviado(reclutador))}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Modalidad", cargo.modalidad)}
				</Grid>
				<Grid item xs={6}>
					{TituloContenido("Asignado por", NombreAbreviado(asignador))}
				</Grid>
				<Grid item xs={6}>
					<Button onClick={() => SetIsOpenDescripcion(true)} variant="contained" color="secondary" size="small">Descripción funciones</Button>
				</Grid>
				<Grid item xs={6}>
					<Button onClick={() => SetIsOpenAdjuntos(true)} variant="contained" color="secondary" size="small">Anexos</Button>
				</Grid>
			</Grid>

			{/* DIALOG CON LA DESCRIPCIÓN DEL CARGO */}
			<FuncionesDialog
				is_open={IsOpenDescripcion}
				cargo_descripcion={cargo.descripcion}
				handle_close={() => SetIsOpenDescripcion(false)}
			/>

			{/* DIALOG CON LA DESCRIPCIÓN DEL CARGO */}
			<AdjuntosDialog
				is_open={IsOpenAdjuntos}
				adjuntos={adjuntos}
				handle_close={() => SetIsOpenAdjuntos(false)}
			/>
		</Paper>
	);
}

/**
 * Método encargado de retornar un texto como título de sección.
 * @param {*} text Texto para mostrar.
 * @returns Component.
 */
function TituloContenido(titulo, contenido) {
	return (
		<ListItem style={{ display: "list-item", paddingLeft: 0, paddingRight: 0 }}>
			<Typography style={{ display: "contents" }}>
				<b>{titulo}:</b> {contenido}
			</Typography>
		</ListItem>
	);
}