import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Requets de postulantes.
 */
export const PostulanteRequest = {
	Agregar: async (postulante) => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes`;
			let response = await Axios.post(url, postulante);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar los datos de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} datos Datos actualizados.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, datos) => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}`;
			let response = await Axios.put(url, datos);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la lista de postulantes.
	 * @returns Colección de postulantes.
	 */
	Obtener: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/run/nombre/apellido_paterno/apellido_materno/genero/fecha_nacimiento/fecha_actualizacion/nacionalidad/inicio_vida_laboral/ubicacion/contacto/detalle/ultimo_titulo/evaluacion_promedio/cant_evaluaciones/cant_asignaciones/cant_interacciones/cant_visualizaciones/is_lista_negra/origen/porcentaje/curriculum_vitae/fecha_notificacion/is_notificado`;
			let response = await Axios.get(url);
			return response.data.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la lista de todos los postulantes, por ID de reclutador.
	 * @param {*} reclutadorID ID del reclutador.
	 * @returns Colección de postulantes.
	 */
	ObtenerCargados: async (reclutadorID) => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes?detalle._reclutador_ref_eq=${reclutadorID}`;
			let response = await Axios.get(url);
			return response.data.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener un postulante, por su ID.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de postulantes.
	 */
	ObtenerPorID: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}`;
				let response = await Axios.get(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de verificar si un postulante existe, por su RUN.
	 * @param {*} postulanteRUN RUN del postulante.
	 * @returns Datos del postulante.
	 */
	ObtenerPorRUN: async function (postulanteRUN) {
		try {
			if (!postulanteRUN) {
				return null;
			}
			let url = `${RECLUTAMIENTO}/postulantes?run_contains=${postulanteRUN}`;
			let response = await Axios.get(url);
			if (response.data.data.length === 1) {
				return response.data.data[0];
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de buscar postulantes filtrados por palabras clave.
	 * @param {*} palabrasClave Colección de palabras clave.
	 * @param {*} todos FLAG para consulta con AND o OR.
	 * @returns Colección de postulantes filtrados.
	 */
	BusquedaAvanzada: async (palabrasClave, todos) => {
		try {
			if (palabrasClave && Array.from(palabrasClave).length > 0) {
				let url = `${RECLUTAMIENTO}/postulantes/busqueda-avanzada`;
				let response = await Axios.post(url, { keywords: palabrasClave, todos: todos });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de aumentar la cantidad de visualizaciones de un postulante, por su ID.
	 * @param {*} postulanteID ID del postulante.
	 */
	AumentarVisualizaciones: async function (postulanteID) {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/aumentar-visualizaciones`;
			await Axios.post(url);
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las cantidades de postulantes por fuente de origen.
	 * @returns Fuente y cantidad de postulantes.
	 */
	CantidadesOrigen: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/cantidad-origenes`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la cantidad de postulantes con CVs asignados.
	 * @returns Cantidad de postulantes.
	 */
	CantidadesCVs: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/cantidad-cvs`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la cantidad de postulantes con porcentaje de completitud en 0.
	 * @returns Cantidad de postulantes.
	 */
	CantidadesIncompletos: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/cantidad-incompletos`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la cantidad de postulantes con porcentaje de completitud entre 0, 42 y 90.
	 * @returns Cantidad de postulantes.
	 */
	CantidadesPendientes: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/cantidad-pendientes`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener la cantidad de postulantes contratados por CyD Ingeniería.
	 * @returns Cantidad de postulantes.
	 */
	CantidadesContratados: async () => {
		try {
			let url = `${RECLUTAMIENTO}/postulantes/cantidad-contratados`;
			let response = await Axios.get(url);
			return response.data;
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de comentarios de postulantes.
 */
export const ComentarioRequest = {
	/**
	 * Método encargado de actualizar un observación.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} comentario Comentario para el postulante.
	 * @returns Respuesta de comentario actualizado.
	 */
	Actualizar: async (postulanteID, comentario) => {
		try {
			if (postulanteID && comentario) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}`;
				let response = await Axios.put(url, { comentario });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener el comentario de un postulante, por su ID.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de postulantes.
	 */
	ObtenerPorPostulanteID: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/comentario`;
				let response = await Axios.get(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de contactos de postulantes.
 */
export const ContactoRequest = {
	/**
	 * Método encargado de agregar un nuevo contacto del reclutador con el postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} reclutadorID ID del reclutador.
	 * @param {*} comentario Comentario del contacto.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, reclutadorID, comentario) => {
		try {
			if (postulanteID && reclutadorID && comentario) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/contactos`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, _reclutador_ref: reclutadorID, comentario });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los contactos de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de contactos de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/contactos`;
				let response = await Axios.get(url);
				let contactos = Array.from(response.data);
				contactos.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
				return contactos;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de estudios de postulantes.
 */
export const EstudioRequest = {
	/**
	 * Método encargado de agregar un estudio a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} estudio Datos del estudio.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, estudio) => {
		try {
			if (postulanteID && estudio) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/estudios`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, ...estudio });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar un estudio de un postulante.
	 * @param {*} postulanteID ID el postulante.
	 * @param {*} estudio Datos del estudio.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, estudio) => {
		try {
			if (postulanteID && estudio) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/estudios/${estudio._id}`;
				let response = await Axios.put(url, estudio);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar un estudio de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} estudioID ID del estudio.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, estudioID) => {
		try {
			if (postulanteID && estudioID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/estudios/${estudioID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los estudios de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de estudios de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/estudios`;
				let response = await Axios.get(url);
				let estudios = Array.from(response.data);
				estudios.sort((a, b) => new Date(b.fecha_termino || b.fecha_inicio) - new Date(a.fecha_termino || a.fecha_inicio));
				return estudios;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de experiencias de postulantes.
 */
export const ExperienciaRequest = {
	/**
	 * Método encargado de agregar una experiencia a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} experiencia Datos de la experiencia.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, experiencia) => {
		try {
			if (postulanteID && experiencia) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/experiencias`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, ...experiencia });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una experiencia de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} experiencia Datos de la experiencia.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, experiencia) => {
		try {
			if (postulanteID && experiencia) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/experiencias/${experiencia._id}`;
				let response = await Axios.put(url, experiencia);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una experiencia de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} experienciaID ID de la experiencia.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, experienciaID) => {
		try {
			if (postulanteID && experienciaID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/experiencias/${experienciaID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las experiencias de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de experiencias de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/experiencias`;
				let response = await Axios.get(url);
				let experiencias = Array.from(response.data);
				experiencias.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
				return experiencias;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de actualizaciones de postulantes.
 */
export const ActualizacionRequest = {
	/**
	 * Método encargado de agregar una actualización a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} reclutadorID ID del reclutador.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, reclutadorID, motivo = "actualización") => {
		try {
			if (postulanteID && reclutadorID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/actualizaciones`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, _reclutador_ref: reclutadorID, motivo: motivo });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una actualización de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} actualizacion Datos de la actualización.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, actualizacion) => {
		try {
			if (postulanteID && actualizacion) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/actualizaciones/${actualizacion._id}`;
				let response = await Axios.put(url, actualizacion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una actualización de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} actualizacionID ID de la actualización.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, actualizacionID) => {
		try {
			if (postulanteID && actualizacionID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/actualizaciones/${actualizacionID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las actualizaciones de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de actualizaciones de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/actualizaciones`;
				let response = await Axios.get(url);
				let actualizaciones = Array.from(response.data);
				actualizaciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
				return actualizaciones;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de evaluaciones de postulantes.
 */
export const EvaluacionRequest = {
	/**
	 * Método encargado de agregar una evaluación a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} evaluacion Datos de la evaluación.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, evaluacion) => {
		try {
			if (postulanteID && evaluacion) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/evaluaciones`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, ...evaluacion });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar una evaluación de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} evaluacion Datos de la evaluación.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, evaluacion) => {
		try {
			if (postulanteID && evaluacion) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/evaluaciones/${evaluacion._id}`;
				let response = await Axios.put(url, evaluacion);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar una evaluación de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} evaluacionID ID de la evaluación.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, evaluacionID) => {
		try {
			if (postulanteID && evaluacionID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/evaluaciones/${evaluacionID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener las evaluaciones de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de evaluaciones de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/evaluaciones`;
				let response = await Axios.get(url);
				let evaluaciones = Array.from(response.data);
				evaluaciones.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
				return evaluaciones;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de referidos de postulantes.
 */
export const ReferidoRequest = {
	/**
	 * Método encargado de agregar un referido a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} reclutadorID ID del reclutador.
	 * @param {*} referido Datos del referido.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, reclutadorID, referido) => {
		try {
			if (postulanteID && referido) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/referidos`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, _reclutador_ref: reclutadorID, ...referido });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar un referido de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} referido Datos del referido.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, referido) => {
		try {
			if (postulanteID && referido) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/referidos/${referido._id}`;
				let response = await Axios.put(url, referido);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar un referido de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} referidoID ID del referido.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, referidoID) => {
		try {
			if (postulanteID && referidoID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/referidos/${referidoID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los referidos de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de referidos de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/referidos`;
				let response = await Axios.get(url);
				let referidos = Array.from(response.data);
				referidos.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
				return referidos;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}

/**
 * Request de adjuntos de postulantes.
 */
export const AdjuntoRequest = {
	/**
	 * Método encargado de agregar un adjnuto a un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} adjunto Datos del adjunto.
	 * @returns Response.
	 */
	Agregar: async (postulanteID, adjunto) => {
		try {
			if (postulanteID && adjunto) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/adjuntos`;
				let response = await Axios.post(url, { _postulante_ref: postulanteID, adjunto: adjunto });
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de actualizar un adjunto de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} adjunto Datos del adjunto.
	 * @returns Response.
	 */
	Actualizar: async (postulanteID, adjunto) => {
		try {
			if (postulanteID && adjunto) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/adjuntos/${adjunto._id}`;
				let response = await Axios.put(url, adjunto);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de eliminar un adjunto de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @param {*} adjuntoID ID del adjunto.
	 * @returns Response.
	 */
	Eliminar: async (postulanteID, adjuntoID) => {
		try {
			if (postulanteID && adjuntoID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/adjuntos/${adjuntoID}`;
				let response = await Axios.delete(url);
				return response.data;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * Método encargado de obtener los adjuntos de un postulante.
	 * @param {*} postulanteID ID del postulante.
	 * @returns Colección de adjuntos de un postulante.
	 */
	Obtener: async (postulanteID) => {
		try {
			if (postulanteID) {
				let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/adjuntos`;
				let response = await Axios.get(url);
				let adjuntos = Array.from(response.data);
				adjuntos.sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));
				return adjuntos;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	},
}