import { object, string } from "yup";

export const FormikInitialValues = {
	nombre: "",
	link_formulario: "",
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.required("El nombre es requerido."),
		link_formulario: string()
		.required("El link es requerido."),
});