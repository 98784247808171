import React, { Fragment, useEffect } from "react";
import { Badge, List, ListItem, ListItemText, Tooltip, Typography } from "@material-ui/core";
import { InsertComment } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Rating } from "@material-ui/lab";
import MaterialTable from "../../../components/materialTable";
import TooltipIfLongerThan from "../../../components/tooltip_if_longer_than";
import DialogEvaluaciones from "../../database/postulantes/evaluaciones/dialogEvaluaciones";
import DialogEvaluacion from "../../database/postulantes/evaluaciones/dialogEvaluacion";
import { AgregarDatosPostulante, Constantes } from "../../database/postulantes/utils";
import { BanderaPorNombre } from "../../../services/request/common";
import { IconRelacionCyD, TooltipRelacionCyD, ToTitleCase, } from "../../../services/utilities/formatUtils";
import { RedirectPostulanteCV, RedirectPostulanteEditar } from "../../../services/utilities/redirect";
import { CheckPermiso } from "../../../services/utilities/common";
import * as Permissions from "../../../constants/permissions";

export default function Page(props) {
	const {
		open_state,
		postulantes,
		permisos,
		is_loading,
		handle_close_dialog,
		handle_add_evaluacion,
		handle_update_evaluacion,
		handle_actualizar_local,
	} = props;

	useEffect(() => {
		if (postulantes) {
			for (let postulante of postulantes) {
				postulante = AgregarDatosPostulante(postulante);
			}
		}
	}, [postulantes]);

	let columns = [
		{
			title: "RUN",
			field: "run",
			hidden: false,
			maxWidth: 150,
		}, {
			title: "Nombre",
			field: "nombre_completo",
			hidden: false,
		}, {
			title: "Edad",
			field: "edad",
			hidden: false,
			grouping: false,
			render: row => <Tooltip title={row.fecha_nacimiento_formato}><label>{row.edad}</label></Tooltip>,
			maxWidth: 80,
		}, {
			title: "País",
			field: "nacionalidad",
			hidden: false,
			render: row => <img src={BanderaPorNombre(row.nacionalidad)} width={30} alt={row.nacionalidad} />,
			maxWidth: 80,
		}, {
			title: "Región",
			field: "ubicacion.region",
			hidden: false,
			render: row => row.ubicacion ? <TooltipIfLongerThan texto={row.ubicacion.region} largo={20} /> : null,
		}, {
			title: "Comuna",
			field: "ubicacion.comuna",
			hidden: false,
			render: row => row.ubicacion ? ToTitleCase(row.ubicacion.comuna) : null,
		}, {
			title: "Experiencia",
			field: "anos_experiencia",
			hidden: false,
			maxWidth: 90,
		}, {
			title: "Último título",
			field: "ultimo_titulo",
			hidden: false,
			minWidth: 250,
		}, {
			title: "Fecha nacimiento",
			field: "fecha_nacimiento_formato",
			hidden: true,
		}, {
			title: "Genero",
			field: "genero",
			hidden: true,
			render: row => ToTitleCase(row.genero),
		}, {
			title: "Teléfono",
			field: "contacto.telefono",
			hidden: true,
		}, {
			title: "Email",
			field: "contacto.email",
			hidden: true,
		}, {
			title: "Comentarios",
			field: "comentario",
			hidden: true,
			searchable: true,
			removable: false,
			hiddenByColumnsButton: true,
		}, {
			title: "estudios_arreglo",
			hidden: true,
			searchable: true,
			removable: false,
			hiddenByColumnsButton: true,
		}, {
			title: "Promedio evaluaciones",
			field: "evaluacion_promedio",
			hidden: true,
			render: (row) => EvaluacionComponent(row),
		}, {
			title: "% Completitud",
			field: "porcentaje",
			hidden: true,
		}, {
			title: "Fecha CV",
			field: "fecha_cv",
			hidden: true,
		}, {
			title: "Tags",
			field: "detalle.tags",
			hidden: true,
			render: (row) => TagsComponent(row),
		},
	];

	let actions = [(row) => ({
		icon: "find_in_page",
		tooltip: "Ver Adjunto",
		onClick: (event, row) => RedirectPostulanteCV(row.curriculum_vitae.url),
		disabled: !row.curriculum_vitae || !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_ADJUNTO),
	}), {
		icon: "folder_shared",
		tooltip: "Detalles",
		onClick: (event, row) => RedirectPostulanteEditar(row._id),
		disabled: !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_DETALLE),
	}, (row) => ({
		icon: () => <Badge badgeContent={Number(row.cant_evaluaciones || 0)} color="primary"><InsertComment /></Badge>,
		tooltip: "Comentario",
		onClick: (event, row) => open_state({ tipo: Constantes.EVALUACIONES, postulante: row }),
		disabled: !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_COMENTARIOS),
	}), (row) => ({
		icon: () => IconRelacionCyD(row),
		tooltip: TooltipRelacionCyD(row),
		hidden: !CheckPermiso(permisos, Permissions.CANDIDATOS_VER_BLOQUEADOS),
	})];

	return (
		<Fragment>
			{/* TABLA DE POSTULANTES */}
			<MaterialTable
				title="Mis cargas"
				is_loading={is_loading}
				data={postulantes}
				columns={columns}
				actions={actions}
			/>

			{/* DIALOGO DE EVALUACIONES */}
			{open_state() && open_state().tipo === Constantes.EVALUACIONES && (
				<DialogEvaluaciones
					postulante={open_state().postulante}
					evaluacion={open_state().evaluacion}
					handle_add={handle_add_evaluacion}
					handle_update={handle_update_evaluacion}
					handle_close={handle_close_dialog}
				/>
			)}

			{/* DIALOGO DE EVALUACION */}
			{open_state() && (open_state().tipo === Constantes.EVALUACION_ADD || open_state().tipo === Constantes.EVALUACION_UPDATE) && (
				<DialogEvaluacion
					postulante={open_state().postulante}
					evaluacion={open_state().evaluacion}
					handle_close={handle_close_dialog}
					actualizar_postulante_local={handle_actualizar_local}
				/>
			)}
		</Fragment>
	);
}

/**
 * Método encargado de retornar el componente para mostrar el promedio de evaluaciones.
 * @param {*} postulante Datos del postulante.
 * @returns Componente.
 */
function EvaluacionComponent(postulante) {
	return (
		<Rating name="valor" value={postulante.evaluacion_promedio || 0} precision={0.5} size="medium" readOnly />
	);
}

/**
 * Método encargado de retornar el componente para mostrar los Tags del postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Componente.
 */
function TagsComponent(postulante) {
	if (postulante.detalle && postulante.detalle.tags && postulante.detalle.tags.length > 0) {
		return (
			<HtmlTooltip
				title={
					<List dense component="nav">
						{postulante.detalle.tags.map((tag, index) => (
							<ListItem dense key={`list_item_${index}`}>
								<ListItemText primary={tag} />
							</ListItem>
						))}
					</List>
				}
			>
				<Typography>{`${postulante.detalle.tags[0]} ${postulante.detalle.tags.length > 1 ? `(+${postulante.detalle.tags.length - 1})` : ""}`}</Typography>
			</HtmlTooltip>
		);
	} else {
		return "Sin Tags";
	}
}

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);