import React, { useContext, useEffect } from "react";
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import useSWR from "swr";
import Moment from "moment";
import DialogTitleComponent from "../dialog_title";
import ListadoTitulos from "./_titulos";
import ListadoAdjuntos from "./_adjuntos";
import { AdjuntoRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import { ObtenerReclutadores } from "../../services/request/reclutamiento/usuariosPlataforma";
import { FormatearFecha, MontoToMoneda } from "../../services/utilities/formatUtils";
import Turnos from "../../services/data/turnos.json";
import Jornadas from "../../services/data/jornadas.json";
import Modalidades from "../../services/data/modalidades.json";
import TiposContratos from "../../services/data/tipos_contratos.json";
import { COMPROMISO_REMUNERACIONAL_TOOLTIP, CARGO_CERRAR_MENSAJE } from "./utils";
import { CARGO_ESTADOS } from "../../constants/contexts";
import { MainContext } from "../../App";

const filter = createFilterOptions();

/**
 * Dialog par aactualizar cargos.
 * @param {*} is_open TRUE: Dialog abierto | FALSE: Dialog cerrado.
 * @param {*} data Datos del cargo.
 * @param {*} formik Datos del formulario.
 * @param {*} handle_close Método encargado de cerrar el Dialog.
 * @returns Component.
 */
export default function DialogUpdateCargo(props) {
	const {
		is_open,
		data,
		formik,
		handle_close,
	} = props;

	const AdjuntosSWR = useSWR("adjuntos_cargo", (key) => AdjuntoRequest.Obtener(data._licitacion_proyecto_ref, data._id), { revalidateOnFocus: false });
	const ReclutadoresSWR = useSWR("reclutadores", (key) => ObtenerReclutadores(), { revalidateOnFocus: false });
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		if (data && AdjuntosSWR && AdjuntosSWR.data && ReclutadoresSWR && ReclutadoresSWR.data) {
			formik.setValues({
				_id: data._id,
				codigo: data.codigo,
				nombre: data.nombre,
				titulos: data.titulos,
				renta_liquida_min: data.renta_liquida_min,
				renta_liquida_min_formato: MontoToMoneda(data.renta_liquida_min),
				renta_liquida_max: data.renta_liquida_max,
				renta_liquida_max_formato: MontoToMoneda(data.renta_liquida_max),
				renta_liquida_objetivo: data.renta_liquida_objetivo,
				renta_liquida_objetivo_formato: MontoToMoneda(data.renta_liquida_objetivo),
				ubicacion: data.ubicacion,
				turno: data.turno,
				jornada: data.jornada,
				modalidad: data.modalidad,
				tipo_contrato: data.tipo_contrato,
				horario: data.horario,
				lugar_trabajo: data.lugar_trabajo,
				cant_vacantes: data.cant_vacantes,
				cant_asignados: data.cant_asignados,
				cant_seleccionados: data.cant_seleccionados,
				exp_especifica: data.exp_especifica,
				exp_general: data.exp_general,
				_licitacion_proyecto_ref: data._licitacion_proyecto_ref,
				fecha_requerimiento: data.fecha_requerimiento || null,
				fecha_termino: data.fecha_termino || null,
				duracion: data.duracion,
				mandante: data.mandante,
				descripcion: data.descripcion,
				adjuntos: AdjuntosSWR.data,
				_reclutador_ref: data._reclutador_ref._id,
				reclutador: data._reclutador_ref,
				fecha_creacion: data.fecha_creacion,
				estado: data.estado,
				fecha_ultimo_estado: data.fecha_ultimo_estado,
				fecha_penultimo_estado: data.fecha_penultimo_estado,
			});
		}
	}, [data, AdjuntosSWR, AdjuntosSWR.data, ReclutadoresSWR, ReclutadoresSWR.data]);

	useEffect(() => {
		if (AdjuntosSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los adjuntos.", AdjuntosSWR.error);
		}
		if (ReclutadoresSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los reclutadores.", ReclutadoresSWR.error);
		}
	}, [ShowSnackbar, AdjuntosSWR, ReclutadoresSWR]);

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	/**
	 * Método encargado de retornar el color para el botón.
	 * @param {*} estado_check Estado del cargo a verificar.
	 * @returns Color.
	 */
	const colorEstado = (estado_check) => {
		let estado = formik.values.estado;
		let check = String(estado).toUpperCase() === String(estado_check).toUpperCase();
		return check ? "secondary" : "default";
	}

	/**
	 * Método encargado de cambiar el estado del cargo. Se verifica la condición de cierre.
	 * @param {*} estado Nuevo estado del cargo.
	 */
	const cambioEstado = (estado) => {
		//Se verifica si las cantidades de vacantes y seleccionados son iguales.
		let checkCantidades = formik.values.cant_vacantes !== formik.values.cant_seleccionados;
		//Si el nuevo estado es cerrado y las cantidades son distintas.
		if (estado === CARGO_ESTADOS.CERRADO && checkCantidades) {
			ShowSnackbar(CARGO_CERRAR_MENSAJE, new Error("Error al intentar cerrar el cargo"));
		} else {
			formik.setFieldValue("estado", estado);
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" style={{ paddingTop: 50 }} PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Actualizar Cargo</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Ingrese la información requerida del cargo y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						{/* CÓDIGO */}
						<TextField
							name="codigo"
							label="Código (No puede ser modificado)"
							value={formik.values.codigo}
							variant="outlined"
							disabled
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid item xs={4}>
						{/* NOMBRE */}
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* CANTIDAD DE VACANTES */}
						<TextField
							name="cant_vacantes"
							label="Cantidad Vacantes"
							value={formik.values.cant_vacantes}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 1, max: 99 }}
							required
							fullWidth
							size="small"
							helperText={formik.errors.cant_vacantes}
							error={Boolean(formik.errors.cant_vacantes)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA MÍNIMA */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_min_formato"
								label="Renta líquida mínima (Pesos)"
								value={formik.values.renta_liquida_min_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_min_formato}
								error={Boolean(formik.errors.renta_liquida_min_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA OBJETIVO */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_objetivo_formato"
								label="Renta líquida objetivo (Pesos)"
								value={formik.values.renta_liquida_objetivo_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_objetivo_formato}
								error={Boolean(formik.errors.renta_liquida_objetivo_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* RENTA LÍQUIDA MÁXIMA */}
						<Tooltip title={COMPROMISO_REMUNERACIONAL_TOOLTIP} placement="top">
							<TextField
								name="renta_liquida_max_formato"
								label="Renta líquida máxima (Pesos)"
								value={formik.values.renta_liquida_max_formato}
								onChange={handleChangePretencionRenta}
								variant="outlined"
								size="small"
								fullWidth
								required
								helperText={formik.errors.renta_liquida_max_formato}
								error={Boolean(formik.errors.renta_liquida_max_formato)}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						{/* FECHA DE REQUERIMIENTO */}
						<DatePicker
							label="Fecha inicio"
							value={formik.values.fecha_requerimiento}
							onChange={date => formik.setFieldValue("fecha_requerimiento", date)}
							format="DD/MM/YYYY"
							inputVariant="outlined"
							size="small"
							fullWidth
							autoOk
							clearable
							okLabel="Aceptar"
							cancelLabel="Cancelar"
							clearLabel="Limpiar"
							helperText={formik.errors.fecha_requerimiento}
							error={Boolean(formik.errors.fecha_requerimiento)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* DURACIÓN */}
						<TextField
							name="duracion"
							label="Duración (Meses)"
							value={formik.values.duracion}
							onChange={(event) => {
								let duracion = Number(event.target.value);
								formik.setFieldValue("duracion", duracion);
								let fechaInicio = formik.values.fecha_requerimiento || Moment();
								formik.setFieldValue("fecha_termino", Moment(fechaInicio).add(duracion, "months"));
							}}
							variant="outlined"
							type="number"
							inputProps={{ min: 1, max: 999 }}
							required
							fullWidth
							size="small"
							helperText={formik.errors.duracion}
							error={Boolean(formik.errors.duracion)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* FECHA DE TÉRMINO */}
						<DatePicker
							label="Fecha Término"
							value={formik.values.fecha_termino}
							onChange={date => formik.setFieldValue("fecha_termino", date)}
							minDate={formik.values.fecha_requerimiento || undefined}
							format="DD/MM/YYYY"
							inputVariant="outlined"
							size="small"
							fullWidth
							autoOk
							clearable
							okLabel="Aceptar"
							cancelLabel="Cancelar"
							clearLabel="Limpiar"
							helperText={formik.errors.fecha_termino}
							error={Boolean(formik.errors.fecha_termino)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* UBICACIÓN */}
						<TextField
							name="ubicacion"
							label="Ubicación de la obra"
							value={formik.values.ubicacion}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.ubicacion}
							error={Boolean(formik.errors.ubicacion)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* MODALIDAD DE TRABAJO */}
						<Autocomplete
							value={formik.values.modalidad}
							options={Modalidades}
							getOptionLabel={(modalidad) => modalidad}
							onChange={(event, value) => formik.setFieldValue("modalidad", value)}
							loading={!Modalidades}
							noOptionsText="Sin opciones"
							disabled={!Modalidades}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Modalidad"
									helperText={formik.errors.modalidad}
									error={Boolean(formik.errors.modalidad)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* TIPO DE CONTRATO */}
						<Autocomplete
							value={formik.values.tipo_contrato}
							options={TiposContratos}
							getOptionLabel={(tipoContrato) => tipoContrato}
							onChange={(event, value) => formik.setFieldValue("tipo_contrato", value)}
							loading={!TiposContratos}
							noOptionsText="Sin opciones"
							disabled={!TiposContratos}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Tipo de contrato"
									helperText={formik.errors.tipo_contrato}
									error={Boolean(formik.errors.tipo_contrato)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* HORARIO */}
						<TextField
							name="horario"
							label="Horario (ej: De 8 a 18)"
							value={formik.values.horario}
							onChange={formik.handleChange}
							variant="outlined"
							// required
							fullWidth
							size="small"
							helperText={formik.errors.horario}
							error={Boolean(formik.errors.horario)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* LUGAR DE TRABAJO */}
						<TextField
							name="lugar_trabajo"
							label="Lugar de trabajo"
							value={formik.values.lugar_trabajo}
							onChange={formik.handleChange}
							variant="outlined"
							// required
							fullWidth
							size="small"
							helperText={formik.errors.lugar_trabajo}
							error={Boolean(formik.errors.lugar_trabajo)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* TURNO */}
						<Autocomplete
							value={formik.values.turno}
							options={Array.from(new Set([...Turnos, formik.values.turno])).filter(turno => turno)}
							loading={!Turnos}
							noOptionsText="Sin opciones"
							disabled={!Turnos}
							size="small"
							onChange={(event, value) => {
								let turno = String(value || "").replace("Agregar ", "");
								if (typeof value === "string") {
									formik.setFieldValue("turno", turno);
								} else if (value && value.inputValue) {
									formik.setFieldValue("turno", turno);
								} else {
									formik.setFieldValue("turno", turno);
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								if (params.inputValue !== "" && /\d{1,2}x\d{1,2}/.test(params.inputValue)) {
									filtered.push(`Agregar ${params.inputValue}`);
								}
								return filtered;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							getOptionLabel={(option) => {
								if (typeof option === "string") {
									return option;
								}
								if (option.inputValue) {
									return option.inputValue;
								}
								return option;
							}}
							renderOption={(option) => option}
							freeSolo
							renderInput={(params) => (
								<TextField
									label="Turno"
									helperText={formik.errors.turno}
									error={Boolean(formik.errors.turno)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* JORNADA */}
						<Autocomplete
							value={formik.values.jornada}
							options={Jornadas}
							getOptionLabel={(jornada) => jornada}
							onChange={(event, value) => formik.setFieldValue("jornada", value)}
							loading={!Jornadas}
							noOptionsText="Sin opciones"
							disabled={!Jornadas}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Jornada"
									helperText={formik.errors.jornada}
									error={Boolean(formik.errors.jornada)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* MANDANTE */}
						<TextField
							name="mandante"
							label="Mandante"
							value={formik.values.mandante}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.mandante}
							error={Boolean(formik.errors.mandante)}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* RECLUTADOR ENCARGADO */}
						<Autocomplete
							value={formik.values.reclutador}
							options={ReclutadoresSWR ? ReclutadoresSWR.data : []}
							getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
							onChange={(event, value) => formik.setFieldValue("reclutador", value)}
							loading={ReclutadoresSWR.isValidating}
							noOptionsText="Sin opciones"
							disabled={!ReclutadoresSWR}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Reclutador"
									helperText={formik.errors.reclutador}
									error={Boolean(formik.errors.reclutador)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* EXPERIENCIA GENERAL */}
						<TextField
							name="exp_general"
							label="E. general"
							value={formik.values.exp_general}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 99 }}
							fullWidth
							size="small"
							helperText={formik.errors.exp_general}
							error={Boolean(formik.errors.exp_general)}
						/>
					</Grid>
					<Grid item xs={2}>
						{/* EXPERIENCIA ESPECÍFICA */}
						<TextField
							name="exp_especifica"
							label="E. específica"
							value={formik.values.exp_especifica}
							onChange={formik.handleChange}
							variant="outlined"
							type="number"
							inputProps={{ min: 0, max: 99 }}
							fullWidth
							required
							size="small"
							helperText={formik.errors.exp_especifica}
							error={Boolean(formik.errors.exp_especifica)}
						/>
					</Grid>
					<Grid item xs={12} container spacing={1}>
						{/* LISTADO Y FORMULARIO DE TÍTULOS */}
						<Grid item xs={6}>
							<ListadoTitulos
								formik={formik}
							/>
						</Grid>
						{/* LISTADO Y FORMULARIO DE ADJUNTOS */}
						<Grid item xs={6}>
							<ListadoAdjuntos
								formik={formik}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{/* DESCRIPCIÓN */}
						<TextField
							name="descripcion"
							label="Descripción"
							value={formik.values.descripcion}
							onChange={formik.handleChange}
							variant="outlined"
							multiline
							rows={4}
							required
							fullWidth
							size="small"
							helperText={formik.errors.descripcion}
							error={Boolean(formik.errors.descripcion)}
						/>
					</Grid>
					<Grid item xs={12}>
						{formik.values.fecha_ultimo_estado && (
							<Typography variant="subtitle1" align="center">
								{`Últimas fechas de cambio de estado: ${FormatearFecha(formik.values.fecha_ultimo_estado)} y ${FormatearFecha(formik.values.fecha_penultimo_estado)}`}
							</Typography>
						)}
						{formik.values.estado !== CARGO_ESTADOS.ACTIVO && (
							<Typography variant="subtitle1" align="center">
								Ingrese comentario con la razón del cambio de estado en el recuadro de Descripción, para complementar información
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<ButtonGroup variant="contained">
								{/* BOTÓN ESTADO PENDIENTE */}
								<Button onClick={() => cambioEstado(CARGO_ESTADOS.ACTIVO)} color={colorEstado(CARGO_ESTADOS.ACTIVO)}>
									Activo
								</Button>
								{/* BOTÓN ESTADO SHORT LIST */}
								<Button onClick={() => cambioEstado(CARGO_ESTADOS.CERRADO)} color={colorEstado(CARGO_ESTADOS.CERRADO)}>
									Cerrado
								</Button>
								{/* BOTÓN ESTADO SELECCIONADO */}
								<Button onClick={() => cambioEstado(CARGO_ESTADOS.DETENIDO)} color={colorEstado(CARGO_ESTADOS.DETENIDO)}>
									Detenido
								</Button>
								{/* BOTÓN ESTADO FUERA DE PROCESO */}
								<Button onClick={() => cambioEstado(CARGO_ESTADOS.FALLIDO)} color={colorEstado(CARGO_ESTADOS.FALLIDO)}>
									Fallido
								</Button>
							</ButtonGroup>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}