import Axios from "axios";
import { PORTAFOLIO } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los contratos.
 * @returns Colección de contratos.
 */
export async function GetContratos() {
	try {
		let url = `${PORTAFOLIO}/contratos`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un contrato, por su ID.
 * @param {*} contratoID ID del contrato.
 * @returns Colección de contratos.
 */
export async function GetContrato(contratoID) {
	try {
		let url = `${PORTAFOLIO}/contratos/${contratoID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}