import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerNivelesIngles } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: nivelesIngles,
		isValidating: loadingNivelesIngles,
		mutate: mutateNivelesIngles,
		error: errorNivelesIngles,
	} = useSWR("niveles_ingles_seleccionables", ObtenerNivelesIngles, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorNivelesIngles) {
			ShowSnackbar("Error al intentar cargar los datos de los niveles de inglés.", errorNivelesIngles);
		}
	}, [ShowSnackbar, errorNivelesIngles]);

	return (
		<Page
			niveles_ingles={nivelesIngles}
			is_loading={loadingNivelesIngles}
			mutate_niveles_ingles={mutateNivelesIngles}
		/>
	);
}