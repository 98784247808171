import React, { Fragment, useContext, useState } from "react";
import MaterialTable from "../../../../components/materialTable";
import DialogAdd from "./dialogAdd";
import DialogUpdate from "./dialogUpdate";
import DialogDelete from "./dialogDelete";
import * as Permissions from "../../../../constants/permissions";
import { CheckPermiso } from "../../../../services/utilities/common";
import { MainContext } from "../../../../App";

export default function Page(props) {
	let {
		niveles_estudio,
		is_loading,
		mutate_niveles_estudio,
	} = props;

	const [IsOpen, SetIsOpen] = useState({});
	const MainCTX = useContext(MainContext);

	let columns = [
		{
			title: "Nombre",
			field: "valor"
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Actualizar",
			onClick: (event, row) => SetIsOpen({ tipo: "update", data: row }),
			hidden: !CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_EDITAR),
		}, {
			icon: "delete",
			tooltip: "Eliminar (Requiere confirmación)",
			onClick: (event, row) => SetIsOpen({ tipo: "delete", data: row }),
			hidden: !CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_ELIMINAR),
		}, {
			icon: "add",
			tooltip: "Agregar",
			isFreeAction: true,
			onClick: (event, row) => SetIsOpen({ tipo: "add", data: row }),
			hidden: !CheckPermiso(MainCTX.permisos,Permissions.ADMIN_SELECCIONABLE_NIVELES_ESTUDIO_AGREGAR),
		}
	];

	const handleCloseDialog = () => {
		SetIsOpen({});
	}

	return (
		<Fragment>
			{/* LISTADO DE NIVELES DE ESTUDIO */}
			<MaterialTable
				title="Niveles de Estudio"
				is_loading={is_loading}
				data={niveles_estudio ? niveles_estudio.data : []}
				columns={columns}
				actions={actions}
				export_excel={true}
				export_spreadsheet={true}
			/>

			{/* DIALOG PARA AGREGAR */}
			{IsOpen.tipo === "add" && (
				<DialogAdd
					is_open={IsOpen.tipo === "add"}
					handle_close={handleCloseDialog}
					mutate_niveles_estudio={mutate_niveles_estudio}
				/>
			)}

			{/* DIALOG PARA ACTUALIZAR */}
			{IsOpen.tipo === "update" && (
				<DialogUpdate
					is_open={IsOpen.tipo === "update"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_niveles_estudio={mutate_niveles_estudio}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{IsOpen.tipo === "delete" && (
				<DialogDelete
					is_open={IsOpen.tipo === "delete"}
					data={IsOpen.data}
					handle_close={handleCloseDialog}
					mutate_niveles_estudio={mutate_niveles_estudio}
				/>
			)}
		</Fragment>
	);
}