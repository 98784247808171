import Moment from "moment";
import Icon from "@mdi/react";
import { mdiFile, mdiFilePdfBox, mdiFileJpgBox, mdiFilePngBox } from "@mdi/js";
import { SvgIcon } from "@material-ui/core";
import { Lock, LockOpen, ThumbDown, ThumbUp, ThumbUpOutlined } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import * as Contexts from "../../constants/contexts";
import { ReactComponent as LogoCyD } from "../../img/logo_cyd.svg";
import CargoDificultad from "../data/cargo_dificultad.json";

/**
 * Método encargado de formatear una fecha.
 * @param {*} fecha Fecha que será formateada.
 * @param {*} formato Formato opcional. Valor predeterminado "DD/MM/YYYY".
 * @returns Fecha formateada.
 */
export function FormatearFecha(fecha, formato = "DD/MM/YYYY") {
	if (!fecha) {
		return FECHA_FORMATO_VACIO;
	}
	let fechaFormateada = Moment(fecha).format(formato);
	return fechaFormateada;
}

/**
 * Método encargado de obtener el rango desde hasta de un dato.
 * @param {*} data Dato con fecha de inicio y término.
 * @returns Rango de fecha desde.
 */
export function RangoFechas(data) {
	let fechaInicio = Moment(data.fecha_inicio);
	let fechaTermino = Moment(data.fecha_termino);
	if (fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")} Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (!fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (fechaInicio.isValid() && !fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")}`;
	} else {
		return `Desde ${FECHA_FORMATO_VACIO} Hasta ${FECHA_FORMATO_VACIO}`;
	}
}

/**
 * Método encargado de generar un texto con nombre completo del usuario.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre completo.
 */
export function NombreCompleto(postulante) {
	let nombrePostulante = "";
	if (postulante && postulante.nombre) {
		nombrePostulante += postulante.nombre;
	}
	if (postulante && postulante.apellido_paterno) {
		nombrePostulante += ` ${postulante.apellido_paterno}`;
	}
	if (postulante && postulante.apellido_materno) {
		nombrePostulante += ` ${postulante.apellido_materno}`;
	}
	return ToTitleCase(nombrePostulante);
}

/**
 * Método encargado de generar un texto con nombre abreviado del usuario.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre abreviado.
 */
export function NombreAbreviado(postulante) {
	let nombrePostulante = "";
	if (postulante && postulante.nombre) {
		let nombres = String(postulante.nombre).split(" ");
		let primerNombre = nombres[0];
		let segundoNombre = nombres[1] ? ` ${nombres[1].charAt(0)}.` : "";
		nombrePostulante += `${primerNombre}${segundoNombre}`;
	}
	if (postulante && postulante.apellido_paterno) {
		nombrePostulante += ` ${postulante.apellido_paterno}`;
	}
	if (postulante && postulante.apellido_materno) {
		nombrePostulante += ` ${postulante.apellido_materno[0]}.`;
	}
	return ToTitleCase(nombrePostulante);
}

/**
 * Método encargado de aplicar TitleCase a un texto.
 * @param {*} text Texto que será cambiado.
 * @returns Texto con formato TitleCase.
 */
export function ToTitleCase(text) {
	if (!text || String(text).trim() === "") {
		return "";
	}
	let texto = String(text).toLowerCase().trim().replace(/  +/g, " ");
	let result = texto.split(" ").map(m => `${m[0].toUpperCase()}${m.slice(1)}`).join(" ");
	return result;
}

/**
 * Método encargado de calcular la cantidad de años desde una fecha.
 * @param {*} fecha Fecha desde cuando realizar el cálculo.
 * @param {*} decimales Cantidad de decimales opcional. Valor predeterminado es 0.
 * @returns Cantidad de años.
 */
export function AñosDesde(fecha, decimales = 0) {
	let months = Moment().diff(Moment(fecha), "months") / 12;
	let years = months.toFixed(decimales);
	return years > 0 ? years : "";
}

/**
 * Método encargado de retornar los años y meses desde una fecha.
 * @param {*} fecha Fecha desde cuando se hará el cálculo.
 * @param {*} formatoYears Formato con el que se mostrarán los años.
 * @param {*} formatoMonths Formato con el que se mostrarán los meses.
 * @link https://stackoverflow.com/a/4512317
 * @returns Fecha en formato X años Y meses.
 */
export function EdadFormato(fecha, formatoYears = "y", formatoMonths = "m") {
	//Si la fecha no es válida.
	if (!fecha) {
		return "";
	}

	let yearsString = AñosDesde(fecha, 2);
	let years = Number(yearsString);
	let decimal = years % 1;
	let months = (decimal * 12).toFixed(0);

	if (years > 0 && months > 0) {
		return `${Math.floor(years)}${formatoYears}${formatoMonths && ` ${months}${formatoMonths}`}`;
	} else if (years > 0 && months === "0") {
		return `${Math.floor(years)}${formatoYears}`;
	} else {
		return `${months}${formatoMonths}`;
	}
}

/**
 * Método encargado de formatear el tamaño de un archivo.
 * @param {*} bytes Tamaño del archivo en bytes.
 * @returns Tamaño formateado.
 * @link https://stackoverflow.com/a/60988355
 */
export function BytesToSize(bytes) {
	const unit = Math.floor(Math.log(bytes) / Math.log(1024));
	const formatter = Intl.NumberFormat("es", { style: "unit", unit: units[unit], maximumFractionDigits: 1 });
	return formatter.format(bytes / 1024 ** unit);
}

/**
 * Método encargado de formatear el nombre de un CV.
 * @param {*} name Nombre del CV.
 * @returns Nombre del CV formateado.
 */
export function CurriculumNameFormat(name) {
	let nombre = String(name).split(/_/)
		.slice(0, 2)
		.join("")
		.split(/(?=[A-Z])/)
		.join(" ");
	return nombre;
}

/**
 * Método encargado de formatear un monto a formato moneda CLP.
 * @param {*} monto Monto sin formato dinero.
 * @returns Monto con formato dinero CLP.
 */
export function MontoToMoneda(monto) {
	let monedaNumber = String(monto).replace(/\D/g, "");
	let monedaFormato = Number(monedaNumber).toLocaleString("es-CL", {
		style: "currency",
		currency: "CLP",
	});
	return monedaFormato;
};

/**
 * Método encargado de obtener el monto de un monto de dinero.
 * @param {*} moneda Monto con formato dinero.
 * @returns Monto sin formato dinero CLP.
 */
export function MonedaToMonto(moneda) {
	let monto = String(moneda).replace(/\D/g, "");
	let montoNumber = Number(monto);
	return montoNumber;
};

/**
 * Método encargado de formatear una cantidad de dinero a millones
 * @param {*} monto Monto de dinero.
 * @returns Monto formateado en millones.
 * @link https://stackoverflow.com/a/60988355
 */
export function MontoEnMillones(monto) {
	const formatter = Intl.NumberFormat("es", { notation: "compact" });
	let montoFormat = formatter.format(monto);
	return `$${montoFormat}`.replace(/\s/g, "");
}

/**
 * Método encargado de retornar un componente Icon con el diseño del tipo de archivo.
 * @param {*} mime_type Tipo de archivo.
 * @returns Componente con el ícono del tipo de archivo.
 */
export function IconByMimeType(mime_type) {
	let icon = null;
	switch (mime_type) {
		case "application/pdf":
			icon = mdiFilePdfBox;
			break;
		case "image/jpeg":
			icon = mdiFileJpgBox;
			break;
		case "image/jpg":
			icon = mdiFileJpgBox;
			break;
		case "image/png":
			icon = mdiFilePngBox;
			break;
		default:
			icon = mdiFile;
			break;
	}

	return (
		<Icon path={icon} size={1} />
	);
}

/**
 * Método encargado de retornar el ícono correspondiente para el postulante, según relación con CyD y bloqueo.
 * @param {*} postulante Datos del postulante.
 * @returns Ícono correspondiente.
 */
export const IconRelacionCyD = (postulante) => {
	if (postulante && postulante.detalle && postulante.detalle.relacion_cyd === Contexts.RELACION_CYD) {
		return <SvgIcon component={LogoCyD} viewBox="-20 -100 350 350" />
	} else if (postulante && postulante.is_lista_negra) {
		return <Lock style={{ color: red["A700"] }} />
	} else {
		return <LockOpen />
	}
}

/**
 * Método encargado de retornar el texto para Tooltip correspondiente para ale postulante, según relación con CyD y bloqueo.
 * @param {*} postulante Datos del postulante.
 * @returns Texto correspondiente.
 */
export const TooltipRelacionCyD = (postulante) => {
	if (postulante && postulante.detalle && postulante.detalle.relacion_cyd === Contexts.RELACION_CYD) {
		return Contexts.RELACION_CYD_TRABAJADOR;
	} else if (postulante && postulante.is_lista_negra) {
		return Contexts.RELACION_CYD_BLOQUEADO;
	} else {
		return Contexts.RELACION_CYD_DESBLOQUEADO;
	}
}

/**
 * Método encargado de retornar el ícono LIKE/DISLIKE/SIN ESTADO, según su estado actual.
 * @param {*} like_dislike Datos de LIKE/DISLIKE.
 * @returns Ícono LIKE/DISLIKE/SIN ESTADO.
 */
export function LikeDislikeIcon(like_dislike) {
	if (like_dislike && like_dislike.estado) {
		//Actualmente tiene LIKE.
		return <ThumbUp />;
	} else if (like_dislike && !like_dislike.estado) {
		//Actualmente tiene DISLIKE.
		return <ThumbDown />;
	} else {
		//No tiene LIKE ni DISLIKE.
		return <ThumbUpOutlined />;
	}
}

/**
 * Método encargado de retornar el nombre del usuario, según su estado actual.
 * @param {*} like_dislike Datos de LIKE/DISLIKE.
 * @returns Texto LIKE/DISLIKE/SIN ESTADO.
 */
export function LikeDislikeTooltip(like_dislike) {
	if (like_dislike) {
		//Actualmente tiene LIKE o DISLIKE.
		return like_dislike.usuario_nombre;
	} else {
		//No tiene LIKE ni DISLIKE.
		return "Sin estado";
	}
}

/**
 * Método encargado de obtener el detalle del porcentaje de completitud de información.
 * @param {*} postulante Datos del postulante.
 * @param {*} cant_estudios Cantidad de estudios del postulante.
 * @param {*} cant_experiencias Cantidad de experiencias del postulante.
 * @param {*} as_number TRUE: Porcentaje como detalle | FALSE: Porcentaje como número.
 * @returns Colección con el detalle de los porcentajes.
 */
export function PorcentajeCompletitud(postulante, cant_estudios, cant_experiencias, as_number = false) {
	let porcentajePersonal = INFORMACION_PERSONAL_TOTAL / INFORMACION_PERSONAL;
	let porcentajePersonalTotal = 0;
	porcentajePersonalTotal += postulante.run ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.nombre ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.apellido_paterno ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.apellido_materno ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.fecha_nacimiento ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.genero ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.nacionalidad ? porcentajePersonal : 0;

	let porcentajeContacto = INFORMACION_CONTACTO_TOTAL / INFORMACION_CONTACTO;
	let porcentajeContactoTotal = 0;
	if (postulante.contacto) {
		porcentajeContactoTotal += postulante.contacto.telefono ? porcentajeContacto : 0;
		porcentajeContactoTotal += postulante.contacto.email ? porcentajeContacto : 0;
	}

	let porcentajeUbicacion = INFORMACION_UBICACION_TOTAL / INFORMACION_UBICACION;
	let porcentajeUbicacionTotal = 0;
	if (postulante.ubicacion) {
		porcentajeUbicacionTotal += postulante.ubicacion.direccion ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.ciudad ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.comuna ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.region ? porcentajeUbicacion : 0;
	}

	let porcentajeAdicional = INFORMACION_ADICIONAL_TOTAL / INFORMACION_ADICIONAL;
	let porcentajeAdicionalTotal = 0;
	if (postulante.detalle) {
		porcentajeAdicionalTotal += postulante.detalle.nivel_ingles ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.licencias_conducir?.length > 0 ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.is_discapacitado ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.relacion_cyd ? porcentajeAdicional : 0;
	}

	let porcentajeEstudiosTotal = Boolean(cant_estudios <= 3) ? (ESTUDIOS * cant_estudios) : 15;
	let porcentajeExperienciasTotal = Boolean(cant_experiencias <= 3) ? (EXPERIENCIAS * cant_experiencias) : 30;

	let porcentajeTotal = porcentajePersonalTotal + porcentajeContactoTotal + porcentajeUbicacionTotal + porcentajeAdicionalTotal + porcentajeExperienciasTotal;

	if (as_number) {
		//Si el FLAG as_number es TRUE, se retorna solo el porcentaje total.
		return porcentajeTotal;
	} else {
		//Si el FLAG as_number es FALSE, se retorna el porcentaje como detalle.
		let porcentajes = [
			`Personal: ${porcentajePersonalTotal.toFixed(1)}%`,
			`Contacto: ${porcentajeContactoTotal.toFixed(1)}%`,
			`Ubicación: ${porcentajeUbicacionTotal.toFixed(1)}%`,
			`Adicional: ${porcentajeAdicionalTotal.toFixed(1)}%`,
			`Estudios: ${porcentajeEstudiosTotal.toFixed(1)}%`,
			`Experiencias: ${porcentajeExperienciasTotal.toFixed(1)}%`,
			`Total: ${porcentajeTotal.toFixed(1)}%`,
		];
		return porcentajes;
	}
}

/**
 * Método encargado de obtener el puntaje de dificultad de un cargo.
 * @param {*} cargo Datos del cargo.
 * @returns Puntaje de dificultad.
 */
export function ObtenerDificultadPuntaje(cargo) {
	const { exp_especifica, turno, modalidad, jornada } = cargo;
	const datoExp = CargoDificultad.experiencias.find(e => exp_especifica > e.desde && exp_especifica <= e.hasta);
	const datoTurno = CargoDificultad.turnos.find(t => t.turno.toLocaleLowerCase() === String(turno).toLocaleLowerCase());
	const datoModalidad = CargoDificultad.modalidades.find(m => m.modalidad.toLocaleLowerCase() === String(modalidad).toLocaleLowerCase());
	const datoJornada = CargoDificultad.jornadas.find(j => j.jornada.toLocaleLowerCase() === String(jornada).toLocaleLowerCase());

	let puntaje = datoExp?.puntaje + datoTurno?.puntaje + datoModalidad?.puntaje + datoJornada?.puntaje;
	//Si no existen datos, se asigna puntaje máximo.
	if (isNaN(puntaje)) {
		puntaje = CargoDificultad.puntaje_maximo;
	}
	return puntaje;
}

/**
 * Método encargado de obtener la categoría de dificultad de un cargo.
 * @param {*} cargo Datos del cargo.
 * @returns Categoría de dificultad.
 */
export function ObtenerDificultadCategoria(cargo) {
	//Puntaje asociado al cargo.
	let puntaje = ObtenerDificultadPuntaje(cargo);
	const datoCategoria = CargoDificultad.categorias.find(c => puntaje > c.desde && puntaje <= c.hasta);
	return datoCategoria.categoria;
}

/**
 * Método encargado de retornar el texto para usuario.
 * @param {*} estado Estado de la asignación como constante.
 * @param {*} tipo Tipo licitacion o proyecto como constante.
 * @returns Texto transformado para el usuario.
 */
export function EstadoAsignacion(estado, tipo) {
	switch (estado) {
		case Contexts.ASIGNACION_ESTADOS.FUERA_PROCESO:
			return "Fuera de Proceso";
		case Contexts.ASIGNACION_ESTADOS.PENDIENTE:
			return "Pendiente";
		case Contexts.ASIGNACION_ESTADOS.SHORT_LIST:
			return "Short List";
		case Contexts.ASIGNACION_ESTADOS.SELECCIONADO:
			if (tipo === Contexts.TIPO_PROYECTO) {
				return "Seleccionado";
			} else if (tipo === Contexts.TIPO_LICITACION) {
				return "Presentado";
			} else {
				return "";
			}
		default:
			return estado;
	}
}

/**********************************************************************************
 * 																CONSTANTES
 **********************************************************************************/
export const FECHA_FORMATO_VACIO = "--/--/--";
const INFORMACION_PERSONAL = 7;
const INFORMACION_PERSONAL_TOTAL = 20;
const INFORMACION_CONTACTO = 2;
const INFORMACION_CONTACTO_TOTAL = 20;
const INFORMACION_UBICACION = 4;
const INFORMACION_UBICACION_TOTAL = 10;
const INFORMACION_ADICIONAL = 4;
const INFORMACION_ADICIONAL_TOTAL = 5;
const ESTUDIOS = 5;
const EXPERIENCIAS = 10;
const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"];