import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Ruta en la API para formularios de postulación.
 */
const RUTA = "formularios-postulacion";

/**
 * Método encargado de agregar un nuevo registro de formulario de postulación.
 * @param {*} formularioPostulacion Datos del formulario de postulación.
 * @returns Response.
 */
export async function Agregar(formularioPostulacion) {
	try {
		let url = `${RECLUTAMIENTO}/${RUTA}`;
		let response = await Axios.post(url, formularioPostulacion);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una registro de formulario de postulación.
 * @param {*} formularioPostulacion Datos actualizados del formulario de postulación.
 * @returns Response.
 */
export async function Actualizar(formularioPostulacion) {
	try {
		let url = `${RECLUTAMIENTO}/${RUTA}/${formularioPostulacion._id}`;
		let response = await Axios.put(url, formularioPostulacion);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un formulario de postulación.
 * @param {*} formularioPostulacionID ID del registro de formulario de postulación.
 * @returns Response.
 */
export async function Eliminar(formularioPostulacionID) {
	try {
		let url = `${RECLUTAMIENTO}/${RUTA}/${formularioPostulacionID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de formularios de postulación.
 * @returns Colección de formularios de postulación.
 */
export async function Obtener() {
	try {
		let url = `${RECLUTAMIENTO}/${RUTA}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un registro de formulario de postulación, por su ID.
 * @param {*} formularioPostulacionID ID del registro de formulario de postulación.
 * @returns Response.
 */
export async function ObtenerPorID(formularioPostulacionID) {
	try {
		let url = `${RECLUTAMIENTO}/${RUTA}/${formularioPostulacionID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}