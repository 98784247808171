import { object, string } from "yup";
import { TITULOS } from "../../../../constants/contexts";

export const FormikInitialValues = {
	valor: "",
	contexto: TITULOS,
}

export const FormikValidationSchema = object().shape({
	valor: string()
		.min(3, ({ min }) => `El título debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El título debe tener a lo más ${max} caracteres.`)
		.required("El título es requerido."),
});