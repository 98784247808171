import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerTitulos } from "../../../../services/request/reclutamiento/seleccionables";

export default function Index(props) {
	const { ShowSnackbar } = useContext(MainContext);

	const {
		data: titulos,
		isValidating: loadingTitulos,
		mutate: mutateTitulos,
		error: errorTitulos,
	} = useSWR("titulos_seleccionables", ObtenerTitulos, { revalidateOnFocus: false });

	useEffect(() => {
		if (errorTitulos) {
			ShowSnackbar("Error al intentar cargar los datos de los títulos.", errorTitulos);
		}
	}, [ShowSnackbar, errorTitulos]);

	return (
		<Page
			titulos={titulos}
			is_loading={loadingTitulos}
			mutate_titulos={mutateTitulos}
		/>
	);
}