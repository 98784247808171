import React, { useState, createContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Container, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import * as PERMISSIONS from "./constants/permissions";
import * as ROUTES from "./constants/routes";
import * as URLS from "./constants/urls";
import AppBar from "./components/appBar";
import Loading from "./components/page_loading"
import { IniciarSession, GetPersona } from "./services/request/usuarios/personas";
import { ObtenerUsuarioPlataformaByPersonaID } from "./services/request/reclutamiento/usuariosPlataforma";
import * as RegistrosKPIRequests from "./services/request/reclutamiento/registro_kpi";
import { GetGerencia } from "./services/request/portafolio/gerencias";
import { auth, onAuthStateChange } from "./services/utilities/firebase";
import { redirectToAccount } from "./services/utilities/auth";
import { VISITADOS } from "./services/utilities/local_db";

import Error401 from "./container/401";
/**
 * BASES DE DATOS
 */
import PostulantesContainer from "./container/database/postulantes";
import PostulanteEditarContainer from "./container/database/postulante_editar";

/**
 * CARGA DE CVs
 */
import CargaIndividualContainer from "./container/cargas_curriculums/individual";
import CargaMasivaContainer from "./container/cargas_curriculums/masiva";

/**
 * CARPETAS
 */
import CarpetaLicitaciones from "./container/carpetas/licitaciones/listado";
import LicitacionDetalle from "./container/carpetas/licitaciones/detalle";
import CarpetaProyectos from "./container/carpetas/proyectos/listado";
import ProyectoDetalle from "./container/carpetas/proyectos/detalle";
import CarpetaFavoritos from "./container/carpetas/favoritos";
import CarpetaCartasOferta from "./container/carpetas/cartas_oferta";
import CarpetaCVsCargados from "./container/carpetas/cvs_cargados";
import CarpetaAsignados from "./container/carpetas/asignados";
import CarpetaSeleccionados from "./container/carpetas/seleccionados";
import CargoDetalle from "./components/cargo_detalle";
import PostulanteInteractuar from "./components/postulante_interactuar";

/**
 * DASHBOARD
*/
import DashboardContainer from "./container/dashboard";

/**
 * AJUSTES
 */
import AjustesContainer from "./container/administracion/ajustes";
import SeleccionablesContainer from "./container/administracion/seleccionables";
import TrabajandoComContainer from "./container/trabajando_com";

const login = async () => {
	let currenToken = getQueryVariable("csrfToken");
	if (!currenToken) {
		currenToken = localStorage.getItem("csrfToken");
	}
	localStorage.setItem("csrfToken", currenToken);
	if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
		localStorage.setItem("contrato", "");
		localStorage.setItem("proyecto", "");
		let response = await axios.post(`${URLS.REDIRECT_BASE}/verifySessionCookie`, {}, {
			withCredentials: true,
			crossDomain: true,
			params: {
				_csrf: currenToken,
			},
		}).catch((error) => {
			console.error(error);
			redirectToAccount();
		});

		if (response.data.token) {
			auth.signInWithCustomToken(response.data.token);
		}
	} else {
		redirectToAccount()
	}
};

//TODO ver react-router-dom los hooks
const getQueryVariable = function (variable) {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return false;
};

export const MainContext = createContext({
	usuarioSesion: { photoUrl: "", nombre: "", email: "", ref: "", uid: "", gerencia: { _id: "", nombre: "", sigla: "" } },
	accessError: false,
	setAccessError: () => { },
	permisos: [],
	setPermisos: () => { },
	ShowSnackbar: (message, error = false) => { },
});

export default function App() {
	const classes = useStyles();
	const notistack = useSnackbar();
	const { pathname } = useLocation();

	const [usuarioSesion, setUsuarioSesion] = useState(null);
	const [permisos, setPermisos] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [accessError, setAccessError] = useState(true);

	VISITADOS.EliminarAntiguos();

	const checkSession = (isLogged) => {
		try {
			if (isLogged && auth.currentUser) {
				const user = auth.currentUser;
				ObtenerDatosUsuario(user);
			} else {
				login();
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Método encargado de cargar la información del usuario, TAGs, gerencia.
	 * @param {*} user 
	 */
	async function ObtenerDatosUsuario(user) {
		try {
			let usuario = await IniciarSession();
			let usuarioPlataforma = await ObtenerUsuarioPlataformaByPersonaID(usuario.ref);
			let persona = await GetPersona(usuario.ref);
			let gerencia = await GetGerencia(persona.gerencia_ref);

			let data = {
				photoUrl: user.photoURL,
				nombre: user.displayName,
				email: user.email,
				ref: usuario.ref,
				uid: persona.auth_id,
				gerencia: {
					_id: gerencia._id,
					nombre: gerencia.nombre,
					sigla: gerencia.sigla,
				},
			}
			setUsuarioSesion(data);
			if (usuarioPlataforma && usuarioPlataforma.permisos) {
				setPermisos(usuarioPlataforma.permisos);
			} else {
				setPermisos([]);
			}
			setIsLoading(false);
			setAccessError(false);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChange(checkSession);
		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (pathname && pathname !== "/" && usuarioSesion) {
			let registroKPI = {
				_usuario_ref: usuarioSesion.ref,
				_gerencia_ref: usuarioSesion.gerencia._id,
				tag: pathname.replace(/\/[0-9a-fA-F]{24}/g, ""),
			}
			RegistrosKPIRequests.Agregar(registroKPI)
				.catch(error => console.error(`Error al intentar agregar Registro KPI con ruta ${pathname}.`, error));
		}
	}, [pathname]);

	const privateRoute = ({
		component: Component,
		permission,
		path,
		...rest
	}) => {
		return (
			<Route
				path={path}
				{...rest}
				render={(props) => {
					if (accessError) {
						return <Redirect to="/accessError" />;
					}
					if (!accessError && path === ROUTES.ACCESS_ERROR) {
						return <Redirect to="/" />;
					}
					if (permission && usuarioSesion && !permisos.includes(permission)) {
						return <Redirect to="/" />;
					}
					return (
						<Component {...props} />
					);
				}}
			/>
		);
	};

	/**
	 * Método encargado de mostrar un mensaje al usuario.
	 * Si el error es de tipo TypeError, solamente es un mensaje de error para el usuario.
	 * @param {*} error Datos del error.
	 * @param {*} message Mensaje de error.
	 */
	function ShowSnackbar(message, error = undefined) {
		//FLAG para verificar si solamente es un mensaje de error para el usuario.
		let checkErrorUsuario = Boolean(error && error.name === "TypeError");
		//Si existe un error, se imprime en consola y se manda a Sentry.
		if (error && !checkErrorUsuario) {
			console.error(error);
			Sentry.captureException(error);
		}
		notistack.enqueueSnackbar(checkErrorUsuario ? error.message : message, {
			variant: error ? "error" : "success",
			autoHideDuration: 3000,
			persist: false,
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><CloseIcon /></IconButton>
		});
	}

	const mainAppNew = (
		<React.Fragment>
			<MainContext.Provider
				value={{ usuarioSesion, accessError, setAccessError, permisos, setPermisos, ShowSnackbar }}
			>
				<div style={{ display: "flex", paddingTop: "80px", backgroundColor: "#E9E9E9", overflow: "hidden" }} className={classes.root}>
					<AppBar />
					<Container disableGutters maxWidth="xl" style={{ paddingBottom: 20, paddingLeft: 70, paddingRight: 10 }}>
						<Switch>
							<Route
								exact
								path="/"
								render={() => <Redirect to={permisos.includes(PERMISSIONS.CANDIDATOS_VER.value) ? ROUTES.CANDIDATOS : "/accessError"} />}
							/>
							{/* SECCIÓN POSTULANTES */}
							{privateRoute({
								component: PostulantesContainer,
								permission: PERMISSIONS.CANDIDATOS_VER.value,
								exact: true,
								path: ROUTES.CANDIDATOS
							})}
							{privateRoute({
								component: PostulanteEditarContainer,
								permission: PERMISSIONS.CANDIDATOS_VER_DETALLE.value,
								exact: true,
								path: ROUTES.CANDIDATOS_ID
							})}

							{/* SECCIÓN CARGA CVs */}
							{privateRoute({
								component: CargaIndividualContainer,
								permission: PERMISSIONS.CARGAR_CV_INDIVIDUAL.value,
								exact: true,
								path: ROUTES.CARGA_INDIVIDUAL
							})}
							{privateRoute({
								component: CargaMasivaContainer,
								permission: PERMISSIONS.CARGAR_CV_MASIVA.value,
								exact: true,
								path: ROUTES.CARGA_MASIVA
							})}

							{/* SECCIÓN CARPETAS */}
							{privateRoute({
								component: CarpetaLicitaciones,
								permission: PERMISSIONS.CARPETA_LICITACIONES_VER.value,
								exact: true,
								path: ROUTES.CARPETA_LICITACIONES
							})}
							{privateRoute({
								component: LicitacionDetalle,
								permission: PERMISSIONS.CARPETA_LICITACIONES_DETALLE.value,
								exact: true,
								path: ROUTES.DETALLE_LICITACION
							})}
							{privateRoute({
								component: CargoDetalle,
								permission: PERMISSIONS.CARPETA_LICITACIONES_CARGO_VER.value,
								exact: true,
								path: `${ROUTES.DETALLE_LICITACION}${ROUTES.CARGO_LICITACION_PROYECTO}`
							})}
							{privateRoute({
								component: PostulanteInteractuar,
								permission: PERMISSIONS.CARPETA_LICITACIONES_CARGO_INTERACTUAR_VER.value,
								exact: true,
								path: `${ROUTES.DETALLE_LICITACION}${ROUTES.CARGO_LICITACION_PROYECTO}${ROUTES.CARGO_ASIGNACION}`
							})}
							{privateRoute({
								component: CarpetaProyectos,
								permission: PERMISSIONS.CARPETA_PROYECTOS_VER.value,
								exact: true,
								path: ROUTES.CARPETA_PROYECTOS
							})}
							{privateRoute({
								component: ProyectoDetalle,
								permission: PERMISSIONS.CARPETA_PROYECTOS_DETALLE.value,
								exact: true,
								path: ROUTES.DETALLE_PROYECTO
							})}
							{privateRoute({
								component: CargoDetalle,
								permission: PERMISSIONS.CARPETA_PROYECTOS_CARGO_VER.value,
								exact: true,
								path: `${ROUTES.DETALLE_PROYECTO}${ROUTES.CARGO_LICITACION_PROYECTO}`
							})}
							{privateRoute({
								component: PostulanteInteractuar,
								permission: PERMISSIONS.CARPETA_PROYECTOS_CARGO_INTERACTUAR_VER.value,
								exact: true,
								path: `${ROUTES.DETALLE_PROYECTO}${ROUTES.CARGO_LICITACION_PROYECTO}${ROUTES.CARGO_ASIGNACION}`
							})}
							{privateRoute({
								component: CarpetaFavoritos,
								permission: PERMISSIONS.CARPETA_FAVORITOS_VER.value,
								exact: true,
								path: ROUTES.CARPETA_FAVORITOS
							})}
							{privateRoute({
								component: CarpetaCartasOferta,
								permission: PERMISSIONS.CARPETA_CARTAS_OFERTA_VER.value,
								exact: true,
								path: ROUTES.CARPETA_CARTAS_OFERTA
							})}
							{privateRoute({
								component: CarpetaCVsCargados,
								permission: PERMISSIONS.CARPETA_CVS_CARGADOS_VER.value,
								exact: true,
								path: ROUTES.CARPETA_CVS_CARGADOS
							})}
							{privateRoute({
								component: CarpetaAsignados,
								permission: PERMISSIONS.CARPETA_ASIGNADOS_VER.value,
								exact: true,
								path: ROUTES.CARPETA_ASIGNADOS
							})}
							{privateRoute({
								component: CarpetaSeleccionados,
								permission: PERMISSIONS.CARPETA_SELECCIONADOS_VER.value,
								exact: true,
								path: ROUTES.CARPETA_SELECCIONADOS
							})}

							{/* SECCIÓN DASHBOARD */}
							{privateRoute({
								component: DashboardContainer,
								permission: PERMISSIONS.DASHBOARD_VER.value,
								exact: true,
								path: ROUTES.DASHBOARD
							})}

							{/* SECCIÓN TRABAJANDO.COM */}
							{privateRoute({
								component: TrabajandoComContainer,
								permission: PERMISSIONS.CARGAR_CV_TRABAJANDO_COM.value,
								exact: true,
								path: ROUTES.TRABAJANDO_COM
							})}

							{/* SECCIÓN AJUSTES */}
							{privateRoute({
								component: AjustesContainer,
								permission: PERMISSIONS.ADMIN_AJUSTES_VER.value,
								exact: true,
								path: ROUTES.AJUSTES
							})}
							{privateRoute({
								component: SeleccionablesContainer,
								permission: PERMISSIONS.ADMIN_SELECCIONABLE_VER.value,
								exact: true,
								path: ROUTES.SELECCIONABLES
							})}
							<Route
								exact={true}
								path={ROUTES.ACCESS_ERROR}
								component={() => <Error401 />}
							/>
						</Switch>
					</Container>
				</div>
			</MainContext.Provider>
		</React.Fragment>
	);

	if (usuarioSesion && permisos && !isLoading && !accessError) {
		return mainAppNew;
	} else {
		return <Loading />
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	paper: {
		padding: theme.spacing(),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	footer: {
		marginTop: "1rem",
		padding: "1rem",
		position: "fixed",
		color: theme.palette.text.secondary,
		bottom: 0,
		left: 0,
		width: "100%",
	},
	appBar: {
		top: "auto",
		bottom: 0,
	},
}));