import React from "react";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";

/**
 * Componente título para Dialogs, con X para cerrar popup.
 * @param {*} onClose Función encargada de 
 * @returns Componente custom de título.
 */
export default function DialogTitleComponent(props) {
	const {
		children,
		onClose,
	} = props;
	return (
		<DialogTitle onClose={onClose}>
			{children}
		</DialogTitle>
	)
}

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});