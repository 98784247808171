export const HOME = "/";

export const DASHBOARD = "/dashboard";
export const VACANTES = "/vacantes";

export const CANDIDATOS = "/candidatos";
export const CANDIDATOS_ID = "/candidatos/:postulante_id";
export const CURRICULUMS = "/curriculums";

export const CARGA_INDIVIDUAL = "/carga-individual";
export const CARGA_MASIVA = "/carga-masiva";

export const CARPETA_LICITACIONES = "/carpeta-licitaciones";
export const DETALLE_LICITACION = "/carpeta-licitaciones/:licitacion_id";
export const CARPETA_PROYECTOS = "/carpeta-proyectos";
export const DETALLE_PROYECTO = "/carpeta-proyectos/:proyecto_id";
export const CARGO_LICITACION_PROYECTO = "/cargos/:cargo_id";
export const CARGO_ASIGNACION = "/asignacion/:asignacion_id";
export const CARPETA_FAVORITOS = "/carpeta-favoritos";
export const CARPETA_CARTAS_OFERTA = "/carpeta-cartas-oferta";
export const CARPETA_CVS_CARGADOS = "/carpeta-cvs-cargados";
export const CARPETA_ASIGNADOS = "/carpeta-asignados";
export const CARPETA_SELECCIONADOS = "/carpeta-seleccionados";

export const AJUSTES = "/ajustes";
export const SELECCIONABLES = "/seleccionables";

export const ACCESS_ERROR = "/accessError";

export const TRABAJANDO_COM = "/trabajando-com";
export const LINKED_IN = "/linked-in";