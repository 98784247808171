import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import DialogTitleComponent from "../../../../components/dialog_title";
import { Actualizar } from "../../../../services/request/reclutamiento/formulariosPostulacion";
import { VerificarAccesoArchivo } from "../../../../services/utilities/google_apps_scripts";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../../../App";

export default function DialogUpdate(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_formularios_postulacion,
	} = props;

	const MainCTX = useContext(MainContext);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				values["_id"] = data._id;
				//Se verifica acceso al archivo por parte de cydocs@cydingenieria.cl.
				let check = await VerificarAccesoArchivo(values.link_formulario);
				if (!check) {
					//Error, sin acceso al archivo.
					MainCTX.ShowSnackbar("CyDocs no tiene acceso al archivo.", new Error("Archivo sin acceso."));
				} else {
					//Se guardan los datos.
					await Actualizar(values);
					MainCTX.ShowSnackbar("Formulario de postulaciones actualizado exitosamente.");
				}
			} catch (error) {
				MainCTX.ShowSnackbar("Error al intentar actualizar el formulario de postulaciones.", error);
			} finally {
				helper.resetForm();
				mutate_formularios_postulacion();
				handle_close();
			}
		}
	});

	useEffect(() => {
		if (data) {
			formik.setValues({
				nombre: data.nombre,
				link_formulario: data.link_formulario,
			});
		}
	}, [data]);

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Actualizar formulario de postulaciones</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Actualice la información necesaria del formulario de postulaciones y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="link_formulario"
							label="Link template"
							value={formik.values.link_formulario}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							helperText={formik.errors.link_formulario}
							error={Boolean(formik.errors.link_formulario)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}