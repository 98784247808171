import Moment from "moment";

/**
 * Método encargado de verificar si una URL tiene el formato válido.
 * @param {*} url URL que será verificada.
 * @returns TRUE|FALSE si la URL tiene el formato correspondiente.	
 */
export function CheckURL(url) {
	let check = String(url).includes("https://");
	return check;
}

/**
 * Método encargado de verificar si el usuario incluye un permiso.
 * @param {*} permisos Colección de permisos del usuario.
 * @param {*} permisosVerificar Permiso que será verificado.
 * @returns TRUE|FALSE Si la colección de permisos incluye los permisos a verificar.
 */
export const CheckPermiso = (permisos, permisosVerificar) => {
	let check = Array.from(permisos).includes(permisosVerificar.value);
	return check;
}

/**
 * Método encargado de verificar que el usuario tenga todos los permisos.
 * @param {*} permisos Colección de permisos del usuario.
 * @param  {...any} permisosVerificar Colección de permisos para verificar.
 * @returns TRUE|FALSE Si la colección de permisos incluye los permisos a verificar.
 * @link https://www.geeksforgeeks.org/what-is-the-difference-between-every-and-some-methods-in-javascript/
 */
export const CheckPermisosEvery = (permisos, ...permisosVerificar) => {
	let check = permisosVerificar.every(permisoVerificar => Array.from(permisos).includes(permisoVerificar.value));
	return check;
}

/**
 * Método encargado de verificar que el usuario tenga alguno de los permisos.
 * @param {*} permisos Colección de permisos del usuario.
 * @param  {...any} permisosVerificar Colección de permisos para verificar.
 * @returns TRUE|FALSE Si la colección de permisos incluye los permisos a verificar.
 * @link https://www.geeksforgeeks.org/what-is-the-difference-between-every-and-some-methods-in-javascript/
 */
export const CheckPermisosSome = (permisos, ...permisosVerificar) => {
	let check = permisosVerificar.some(permisoVerificar => Array.from(permisos).includes(permisoVerificar.value));
	return check;
}

/**
 * Método encargado de verificar si la fecha es hoy.
 * @param {*} fecha Fecha que será verificada.
 * @returns TRUE: Fecha es hoy | FALSE: Fecha no es hoy.
 */
export function IsToday(fecha) {
	let fechaDate = Moment(fecha).date();
	let fechaMonth = Moment(fecha).month();
	let fechaYear = Moment(fecha).year();

	let todayDate = Moment().date();
	let todayMonth = Moment().month();
	let todayYear = Moment().year();

	let check = todayDate === fechaDate && todayMonth === fechaMonth && todayYear === fechaYear;
	return check;
}

/**
 * Método encargado de obtener una ruta anterior, desde la ruta actual.
 * @param {*} history History con los datos de la ruta.
 * @param {*} cantidad Cantidad de retrocesos.
 * @returns Ruta anterior.
 */
export function PreviousRoute(history, cantidad = 1) {
	let locationCurrent = String(history.location.pathname);
	let locationSplit = locationCurrent.split("/").slice(0, -cantidad);
	let locationJoin = locationSplit.join("/");
	return locationJoin;
}