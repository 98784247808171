import { object, string, date, boolean } from "yup";

export const FormikInitialValues = {
	empresa_object: null,
	empresa: "",
	lugar_trabajo: "",
	cargo: "",
	experiencia_especifica: "",
	fecha_inicio: null,
	fecha_termino: null,
	is_trabajando_object: null,
	is_trabajando: false,
	comentario: "",
	certificado: null,
	file: null,
}

export const FormikValidationSchema = object().shape({
	empresa_object: object()
		.nullable()
		.required("La empresa es requerida."),
	empresa: string()
		.optional(),
	lugar_trabajo: string()
		.min(3, ({ min }) => `El área de trabajo debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El área de trabajo debe tener a lo más ${max} caracteres.`)
		.optional(),
	cargo: string()
		.min(3, ({ min }) => `El cargo debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El cargo debe tener a lo más ${max} caracteres.`)
		.required("El cargo es requerido."),
	experiencia_especifica: string()
		.min(3, ({ min }) => `La experiencia específica debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La experiencia específica debe tener a lo más ${max} caracteres.`)
		.optional(),
	fecha_inicio: date()
		.nullable()
		.required("La fecha de inicio es requerida."),
	fecha_termino: date()
		.nullable()
		.when("is_trabajando_object", {
			is: (is_trabajando_object) => is_trabajando_object?.valor === true,
			then: (schema) => schema.optional(),
			otherwise: (schema) => schema.required("La fecha de término es requerida."),
		}),
	is_trabajando_object: object()
		.nullable()
		.optional(),
	is_trabajando: boolean()
		.optional(),
	comentario: string()
		.min(3, ({ min }) => `La descripción de funciones debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `La descripción de funciones debe tener a lo más ${max} caracteres.`)
		.optional(),
	certificado: object()
		.nullable()
		.optional(),
	file: object()
		.nullable()
		.optional(),
});