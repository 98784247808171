import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los usuarios plataforma.
 * @returns Colección de usuarios plataforma.
 */
export async function ObtenerUsuariosPlataforma() {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/_usuario_ref.contacto/_usuario_ref.nombre/_usuario_ref.apellido_paterno/_usuario_ref.apellido_materno/permisos`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todas las personas que son reclutadores.
 * @returns Colección de reclutadores.
 */
export async function ObtenerReclutadores() {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/_usuario_ref.contacto/_usuario_ref.nombre/_usuario_ref.apellido_paterno/_usuario_ref.apellido_materno`;
		let response = await Axios.get(url);
		let reclutadores = Array.from(response.data.data).map(r => r._usuario_ref);
		return reclutadores;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un usuario plataforma, por su ID.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @returns Datos del usuario plataforma.
 */
export async function ObtenerUsuarioPlataforma(usuarioPlataformaID) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataformaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los favoritos de un usuario, por su ID.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @param {*} populates Colección de datos a popular.
 * @returns Colección de favoritos.
 */
export async function ObtenerFavoritos(usuarioPlataformaID, populates = "") {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataformaID}/favoritos${populates}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un usuario plataforma, por el ID de persona.
 * @param {*} personaID ID de la persona.
 * @returns Datos del usuario plataforma.
 */
export async function ObtenerUsuarioPlataformaByPersonaID(personaID) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma?_usuario_ref_eq=${personaID}`;
		let response = await Axios.get(url);
		return response.data && response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 * @returns Dato agregado.
 */
export async function AgregarUsuarioPlataforma(usuarioPlataforma) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma`;
		let response = await Axios.post(url, usuarioPlataforma);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un favorito.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @param {*} postulanteID ID del postulante.
 * @returns Dato agregado.
 */
export async function AgregarFavorito(usuarioPlataformaID, postulanteID) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataformaID}/favoritos`;
		let favorito = {
			_usuario_ref: usuarioPlataformaID,
			_postulante_ref: postulanteID,
		}
		let response = await Axios.post(url, favorito);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 * @returns Dato actualizado.
 */
export async function ActualizarUsuarioPlataforma(usuarioPlataforma) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataforma._id}`;
		let response = await Axios.put(url, usuarioPlataforma);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un usuario plataforma.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @returns Respuesta de la eliminación.
 */
export async function EliminarUsuarioPlataforma(usuarioPlataformaID) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataformaID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un favorito de un usuario plataforma.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @param {*} favoritoID ID del favorito.
 * @returns Respuesta de la eliminación.
 */
export async function EliminarFavorito(usuarioPlataformaID, favoritoID) {
	try {
		let url = `${RECLUTAMIENTO}/usuarios-plataforma/${usuarioPlataformaID}/favoritos/${favoritoID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}