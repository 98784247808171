import { auth, realTime } from './firebase.js';
import axios from 'axios';
import fetch from 'node-fetch';
import * as URLS from '../../constants/urls.js';

const getQueryVariable = function (variable) {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		if (pair[0] === variable) { return pair[1]; }
	}
	return (false);
}

export const redirectToAccount = () => {
	let scopes = "&scope=https://www.googleapis.com/auth/gmail.send,https://www.googleapis.com/auth/gmail.compose,https://www.googleapis.com/auth/gmail.readonly,https://www.googleapis.com/auth/gmail.metadata,https://www.googleapis.com/auth/gmail.addons.current.message.metadata,https://www.googleapis.com/auth/gmail.addons.current.message.readonly,https://www.googleapis.com/auth/gmail.addons.current.message.action,https://www.googleapis.com/auth/gmail.modify,https://www.googleapis.com/auth/gmail.addons.current.action.compose,https://mail.google.com/,https://www.googleapis.com/auth/calendar.events,https://www.googleapis.com/auth/calendar.settings.readonly,https://www.googleapis.com/auth/calendar"
	let forceOutSession = "&forceSesion=true"
	window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]}${scopes}${forceOutSession}`;
}

export const loginUsuario = async (email, password) => {
	var token = "";
	return auth.signInWithEmailAndPassword(email, password)
		.then((userCredential) => {
			return auth.currentUser.getIdToken(true)
		})
		.then((id_token) => {
			token = id_token;
			return fetch(`${URLS.REDIRECT_BASE}/getCSRFToken`, {
				method: 'get',
				credentials: 'include',
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});
		})
		.then(res => res.json())
		.then((res) => {
			return axios.post(`${URLS.REDIRECT_BASE}/sessionLogin`, {
				withCredentials: true,
				crossDomain: true,
				xsrfCookieName: 'csrfToken',
				xsrfHeaderName: 'CSRF-Token',
				headers: {
					'Authorization': `Bearer ${token}`,
					'CSRF-Token': res.csrfToken
				}
			})
		});
}

export const checkSession = async () => {
	const csrfToken = getQueryVariable("csrfToken") || localStorage.getItem("csrfToken") || "";
	localStorage.setItem("csrfToken", csrfToken);
	return fetch(`${URLS.REDIRECT_BASE}/verifySessionCookie`, {
		method: 'get',
		credentials: 'include',
		headers: {
			'Authorization': `Bearer ${csrfToken}`
		},
	})
		.then(res => {
			if (res.status === 200) {
				return res.json()
			} else {
				setTimeout(() => {
					window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]}`;
				}, 10000);

			}
		})
		.then((res) => {
			if (res) {
				return auth.signInWithCustomToken(res.token);
			} else {
				return null;
			}
		});
}

export const getCurrentUser = async () => {
	return auth.currentUser.getIdTokenResult()
}

export const getCanPermisos = async (rol) => {
	var permiso = await realTime.ref("/permisos/" + rol).once("value").then();
	if (permiso.val() !== null) {
		if (typeof permiso.val().portafolio_contratos === "undefined") {
			return {};
		} else {
			return permiso.val().portafolio_contratos.permisos;
		}
	}
}

export const getCanPermisosGeneral = async (rol) => {
	var permiso = await realTime.ref("/permisos/" + rol).once("value").then();
	if (permiso.val() !== null) {
		if (typeof permiso.val().portafolio_contratos === "undefined") {
			return false;
		} else {
			return permiso.val().portafolio_contratos.acceso;
		}
	} else {
		return false;
	}
}

export const redirecTo = async (destiny) => {
	const contrato = localStorage.getItem("contrato");
	const proyecto = localStorage.getItem("proyecto");
	var url = `${URLS.REDIRECT_BASE}/redirectToApp`;
	if (destiny) {
		url += `?redirect-url=${destiny}`;
	}
	if (contrato && proyecto && contrato !== "undefined" && proyecto !== "undefined") {
		url += `&contrato=${contrato}&proyecto=${proyecto}`;
	}
	window.location.href = url;
}

export const cerrarSesion = async () => {
	var token = "";
	localStorage.setItem("csrfToken", "");
	localStorage.setItem("contrato", "");
	localStorage.setItem("proyecto", "");

	if (auth.currentUser) {
		return auth.currentUser.getIdToken(true)
			.then((id_token) => {
				token = id_token;
				return fetch(`${URLS.REDIRECT_BASE}/getCSRFToken`, {
					method: 'get',
					credentials: 'include',
					headers: {
						'Authorization': `Bearer ${token}`
					},
				});
			})
			.then(res => res.json())
			.then((res) => {
				return fetch(`${URLS.REDIRECT_BASE}/sessionLogout?_csrf=${res.csrfToken}`, {
					method: 'post',
					credentials: 'include',
				});
			})
			.then((res) => auth.signOut());
	} else {
		return null;
	}
}