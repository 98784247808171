import Axios from "axios";
import Moment from "moment";
import { FormatearFecha, ToTitleCase } from "./formatUtils";

/**
 * Método encargado de generar una carta de oferta, para un postulante.
 * @param {*} template_id ID del template seleccionado.
 * @param {*} postulante Datos del postulante.
 * @param {*} proyecto Datos del proyecto.
 * @param {*} cargo Datos del cargo.
 * @param {*} responsable Datos del responsable.
 * @returns URL del Google Doc.
 * @link URL en Apps Scripts https://script.google.com/home/projects/1KcPgzqur-chJQJQJ0JUpVxgkEt_4L4jioFkOHuBViWrzq29ybL32cvx1/edit
 * @link Objecto to Query String https://howchoo.com/javascript/how-to-turn-an-object-into-query-string-parameters-in-javascript
 */
export async function GenerarCartaOferta(template_id, postulante, proyecto, cargo, responsable) {
	try {
		//Nombre completo del postulante.
		let nombrePostulante = `${postulante.nombre} ${postulante.apellido_paterno} ${postulante.apellido_materno}`;
		//Nombre completo del responsable.
		let nombreResponsable = `${responsable.nombre} ${responsable.apellido_paterno} ${responsable.apellido_materno}`;
		//Fecha actual para el nombre del archivo generado.
		let fecha = Moment().format("DD-MM-YYYY HH:mm");

		//Datos para generar la carta de oferta.
		let datos = {
			template_id: template_id,
			postulante_nombre: ToTitleCase(nombrePostulante),
			postulante_direccion: ToTitleCase(postulante.ubicacion.direccion) || "DIRECCIÓN NO INGRESADA",
			postulante_comuna: ToTitleCase(postulante.ubicacion.comuna) || "COMUNA NO INGRESADA",
			postulante_region: ToTitleCase(postulante.ubicacion.region) || "REGIÓN NO INGRESADA",
			proyecto_nombre: ToTitleCase(proyecto.nombre),
			proyecto_ubicacion: ToTitleCase(cargo.ubicacion) || "UBICACIÓN NO INGRESADA",
			cargo_nombre: ToTitleCase(cargo.nombre),
			cargo_modalidad: ToTitleCase(cargo.modalidad) || "MODALIDAD NO INGRESADA",
			cargo_turno: ToTitleCase(cargo.turno) || "TURNO NO INGRESADO",
			cargo_jornada: ToTitleCase(cargo.jornada) || "JORNADA NO INGRESADA",
			cargo_horario: ToTitleCase(cargo.horario) || "HORARIO NO INGRESADO",
			cargo_ubicacion: ToTitleCase(cargo.lugar_trabajo) || "UBICACIÓN NO INGRESADA",
			cargo_tipo_contrato: ToTitleCase(cargo.tipo_contrato) || "TIPO DE CONTRATO NO INGRESADO",
			cargo_fecha_inicio: FormatearFecha(cargo.fecha_requerimiento),
			responsable_nombre: ToTitleCase(nombreResponsable),
			nombre_archivo: `Carta Oferta ${ToTitleCase(nombrePostulante)} ${proyecto.nombre} ${fecha}`,
		}

		//Se mapea el objeto con los datos a formato Query String.
		let urlParams = Object.keys(datos).map(key => `${key}=${datos[key]}`).join('&');
		//Se codifica la Query String para evitar errores con caracteres especiales.
		let encodedParams = encodeURIComponent(urlParams);
		let response = await Axios.get(`${APPS_SCRIPT_URLS.CARTA_OFERTA}?${encodedParams}`);

		if (response.data === "ERROR") {
			throw new Error("Error en Apps Script");
		}
		return response.data.url;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de verificar si se tiene acceso a un archivo en Google Drive, por su URL.
 * @param {*} fileURL URL del archivo en Google Drive.
 * @returns TRUE|FALSE Flag para saber si se tiene acceso.
 */
export async function VerificarAccesoArchivo(fileURL) {
	try {
		let urlParams = `file_url=${fileURL}`;
		//Se codifica la Query String para evitar errores con caracteres especiales.
		let encodedParams = encodeURIComponent(urlParams);
		console.log(encodedParams);
		let response = await Axios.get(`${APPS_SCRIPT_URLS.VERIFICAR_ACCESO_POR_URL}?${encodedParams}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/*************************************************************************************
 * 																		CONSTANTES
 ************************************************************************************/

/**
 * Colección de URLs de los distintos Google Apps Scripts.
 */
const APPS_SCRIPT_URLS = {
	/**
	 * @link https://script.google.com/home/projects/1KcPgzqur-chJQJQJ0JUpVxgkEt_4L4jioFkOHuBViWrzq29ybL32cvx1/edit
	 */
	CARTA_OFERTA: "https://script.google.com/macros/s/AKfycbxTfhndAg2IgVjDbo1sMh5UoQLZF2q0bIdIjvRg5P-Clu2NSv_NlsvUPBMuu9HQYCLB/exec",
	/**
	 * @link https://script.google.com/home/projects/1gvocBZUVEmwZozZe_D72wkUuSo9pAov6a3MsiqhitrA9YYwLCveegv0Z/edit
	 */
	VERIFICAR_ACCESO_POR_URL: "https://script.google.com/macros/s/AKfycbzkKSkLAL-GKYUfXCZiymLx6vsHJ-ZlubFDGhznJhD9N488egpQFYayZ5wxBnSafQ6iVg/exec",
}