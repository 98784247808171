import React, { Fragment, useContext, useState } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { AccordionSummary, Collapse, Paper, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../materialTable";
import ComentarioDialog from "./comentarioDialog";
import { InteraccionRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { FormatearFecha, MontoToMoneda } from "../../../services/utilities/formatUtils";
import { MainContext } from "../../../App";

/**
 * Método encargado de retornar un componente con el listado de comentarios.
 * @param {*} postulante Datos del postulante.
 * @returns Component.
 */
export default function Comentarios(props) {
	const {
		postulante,
	} = props;
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);
	const { licitacion_id, proyecto_id, cargo_id, asignacion_id } = useParams();
	const LicitacionProyectoID = licitacion_id || proyecto_id;
	const InteraccionesSWR = useSWR(`interacciones_${asignacion_id}`, (key) => InteraccionRequest.Obtener({ _asignacion_ref: asignacion_id }), { revalidateOnFocus: false });
	const { ShowSnackbar } = useContext(MainContext);

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	let columns = [
		{
			title: "Comentario",
			field: "comentario",
		}, {
			title: "Pretensión",
			field: "pretension_renta",
			render: (row) => row.pretension_renta ? MontoToMoneda(row.pretension_renta) : "",
			width: 120,
		}, {
			title: "Fecha",
			field: "fecha_creacion",
			render: (row) => FormatearFecha(row.fecha_creacion),
			width: 120,
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarInteraccion(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} interaccion Datos de la interacción.
	 */
	const handleEliminarInteraccion = async (interaccion) => {
		try {
			await InteraccionRequest.Eliminar(LicitacionProyectoID, cargo_id, asignacion_id, interaccion._id);
			InteraccionesSWR.mutate();
			ShowSnackbar("Interacción con el postulante eliminada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar eliminar la interacción con el postulante.", error);
		}
	}

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClose = () => {
		SetIsOpen(false);
		InteraccionesSWR.mutate();
	}

	return (
		// <Fragment>

		// </Fragment>

		<Fragment>
			{/* LISTADO DE COMENTARIOS */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Apuntes entrevista / Comentarios"
					is_loading={InteraccionesSWR.isValidating}
					data={InteraccionesSWR.data}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
					page_size={5}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Apuntes Entrevista / Comentarios (${InteraccionesSWR.data?.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG COMENTARIO */}
			{IsOpen && (
				<ComentarioDialog
					is_open={IsOpen}
					licitacion_proyecto_id={LicitacionProyectoID}
					cargo_id={cargo_id}
					asignacion_id={asignacion_id}
					postulante={postulante}
					handle_close={handleClose}
				/>
			)}
		</Fragment>
	);
}