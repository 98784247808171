import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ToTitleCase } from "../../../services/utilities/formatUtils";

/**
 * Componente gráfico de Dona con Highcharts.
 * @param {*} serie_name Nombre para el eje Y.
 * @param {*} data Datos para graficar.
 * @returns Componente gráfico.
 */
export default function StackedColumnChart(props) {
	const {
		data,
		meses,
	} = props;

	const Options = {
		chart: {
			type: "column",
		},
		title: {
			text: `Últimos ${Array.from(meses).length} meses`,
			align: "center",
			style: {
				fontSize: 12,
			}
		},
		xAxis: {
			categories: meses.map(m => ToTitleCase(m.format("MMMM"))),
		},
		yAxis: {
			min: 0,
			title: {
				text: "Cantidad"
			},
			stackLabels: {
				enabled: true
			}
		},
		credits: {
			enabled: false
		},
		legend: {
			align: "left",
			x: 70,
			verticalAlign: "top",
			floating: false,
			backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
			borderColor: "#CCC",
			borderWidth: 1,
			shadow: false
		},
		tooltip: {
			headerFormat: "<b>{point.x}</b><br/>",
			pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
		},
		plotOptions: {
			column: {
				stacking: "normal",
				dataLabels: {
					enabled: true
				}
			},
		},
		series: data,
	}

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={Options}
		/>
	);
}