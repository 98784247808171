import Moment from "moment";

const VISITADOS_DB = "visitados";

export const VISITADOS = {
	/**
	 * Método encargado de agregar un registro de postulante visitado.
	 * @param {*} postulanteID ID del postulante.
	 */
	Agregar: function (postulanteID) {
		let visitados = this.Obtener();
		//Se verifica si el dato ya existe.
		let indexCheck = visitados.findIndex(v => v.id === postulanteID);
		if (indexCheck === -1) {
			//Si el dato no existe, se agrega.
			visitados.push({
				id: postulanteID,
				fecha: Moment(),
			});
		} else {
			//Si el dato ya existe, se actualiza su fecha.
			visitados[indexCheck].fecha = Moment();
		}
		let visitadosJSON = JSON.stringify(visitados);
		localStorage.setItem(VISITADOS_DB, visitadosJSON);
	},

	/**
	 * Método encargado de obtener la colección de postulantes visitados.
	 * @returns Colección de visitados.
	 */
	Obtener: function () {
		let data = localStorage.getItem(VISITADOS_DB);
		if (!data) {
			return [];
		}
		let visitadosJSON = JSON.parse(data);
		let visitados = Array.from(visitadosJSON);
		return visitados;
	},

	/**
	 * Método encargado de verificar si un postulante fue visitado hoy.
	 * @param {*} postulanteID ID del postulante que se verificará.
	 * @returns TRUE: Postulante visitado | FALSE: Postulante no visitado.
	 */
	VerificarVisitado: function (postulanteID) {
		let visitados = this.Obtener();
		let indexCheck = visitados.findIndex(v => v.id === postulanteID);
		return indexCheck !== -1;
	},

	/**
	 * Método encargado de eliminar las visitas más antiguas.
	 * @param {*} days Cantidad de días hacia atrás para eliminar (OPCIONAL).
	 */
	EliminarAntiguos: function (days = 30) {
		let visitados = this.Obtener();
		let hoy = Moment();
		let visitadosFilter = visitados.filter(visitado => Moment(visitado.fecha).diff(hoy, "days") <= days);
		let visitadosJSON = JSON.stringify(visitadosFilter);
		localStorage.setItem(VISITADOS_DB, visitadosJSON);
	},

	/**
	 * Método encargado de limpiar el registro de visitados.
	 */
	EliminarTodo: function () {
		localStorage.removeItem(VISITADOS_DB);
	},
}