import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { useSnackbar } from "notistack";
import DialogTitleComponent from "../../../../components/dialog_title";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../../../App";

export default function DialogDeleteProyecto(props) {
	const {
		is_open,
		data,
		handle_close,
		mutate_proyectos,
	} = props;

	const { ShowSnackbar } = useContext(MainContext);
	const notistack = useSnackbar();

	const handleDelete = async () => {
		try {
			notistack.enqueueSnackbar("Eliminando el proyecto...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			await LicitacionProyectoRequets.Eliminar(data._id);
			notistack.closeSnackbar();
			ShowSnackbar("Proyecto eliminado exitosamente.");
		} catch (error) {
			console.error(error);
			ShowSnackbar("Error al intentar eliminar el proyecto.", error);
		} finally {
			mutate_proyectos();
			handle_close();
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Eliminar Proyecto</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{`Debe confirmar la eliminación del proyecto ${data ? `${data.nombre}, de la gerencia ${data.gerencia}, cuyo responsable es ${data.responsable}` : ""}.`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={handleDelete} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}