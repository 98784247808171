import React, { Fragment } from "react";
import { Paper, Typography } from "@material-ui/core";

export default function DetalleEstudios(props) {
	const {
		estudios,
	} = props;

	return (
		<Fragment>
			{/* ESTUDIOS SUPERIORES */}
			{Array.from(estudios.higherEducation).map((estudio, index) => (
				<Fragment key={`fragment_${index}`}>
					<Paper elevation={5} style={{ padding: 10 }}>
						<Typography>{` ${estudio.career.nameAlias || estudio.career.name || ""} en ${estudio.institution.name || ""} desde ${estudio.entryYear} al ${estudio.graduationyear}, en estado ${estudio.statusName} y modalidad ${estudio.studyMode?.description || ""}`}</Typography>
					</Paper>
					<br />
				</Fragment>
			))}

			{/* CURSOS */}
			{Array.from(estudios.degrees).map(estudio => String(estudio.degree).split("\r\n").filter(d => d)).flat().map((estudio, index) => (
				<Fragment key={`fragment_${index}`}>
					<Paper elevation={5} style={{ padding: 10 }}>
						<Typography>{estudio}</Typography>
					</Paper>
					<br />
				</Fragment>
			))}

			{/* BÁSICA Y MEDIA */}
			{estudios.primaryEducation.school && (
				<Fragment>
					<Paper elevation={5} style={{ padding: 10 }}>
						{!estudios.primaryEducation.specialty && (
							<Typography>{`${estudios.primaryEducation.school || ""} en ${estudios.primaryEducation.region || ""} el ${estudios.primaryEducation.promoteYear || ""}`}</Typography>
						)}
						{estudios.primaryEducation.specialty && (
							<Typography>{`${estudios.primaryEducation.school || ""} con especialidad en ${estudios.primaryEducation.specialty} en ${estudios.primaryEducation.region || ""} el ${estudios.primaryEducation.promoteYear || ""}`}</Typography>
						)}
					</Paper>
					<br />
				</Fragment>
			)}
		</Fragment>
	);
}