import React, { Fragment, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { AccordionSummary, Collapse, IconButton, Paper, Typography } from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import EstudioDialog from "./estudio_dialog";
import { ActualizacionRequest, EstudioRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function Estudios(props) {
	const [IsOpen, SetIsOpen] = useState(false);
	const [Expanded, SetExpanded] = useState(false);

	const { usuarioSesion } = useContext(MainContext);
	const { formik } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	/**
	 * Método encargado de expandir o colapsar la tabla.
	 */
	const handleClick = () => {
		SetExpanded(!Expanded);
	}

	let columns = [
		{
			title: "Institución",
			field: "institucion",
		}, {
			title: "Título",
			field: "titulo",
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarEstudio(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}, {
			icon: "expand_less",
			onClick: handleClick,
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} estudio Datos del estudio.
	 */
	const handleEliminarEstudio = async (estudio) => {
		try {
			//Se elimina el estudio y se agrega actualización.
			let responses = await Promise.all([
				EstudioRequest.Eliminar(estudio._postulante_ref, estudio._id),
				ActualizacionRequest.Agregar(estudio._postulante_ref, usuarioSesion.ref, "Estudio eliminado"),
			]);

			//Nueva actualización.
			let actualizacion = responses[1];
			let estudios = Array.from(formik.values.estudios);
			//Se elimina el estudio.
			estudios.splice(estudio.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("estudios", estudios);
			formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

			notistack.enqueueSnackbar("Estudio del postulante eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el estudio del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			{/* LISTADO DE ESTUDIOS */}
			<Collapse in={Expanded && !IsOpen}>
				<MaterialTable
					title="Estudios"
					is_loading={false}
					data={formik.values.estudios}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			</Collapse>

			{/* LISTADO COLAPSADO */}
			{!Expanded && (
				<Paper style={{ borderRadius: 25 }}>
					<AccordionSummary onClick={handleClick} expandIcon={<ExpandMore />}>
						<Typography>{`Estudios (${formik.values.estudios.length})`}</Typography>
					</AccordionSummary>
				</Paper>
			)}

			{/* DIALOG ESTUDIO */}
			<EstudioDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}