import React, { useContext } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, Collapse, Grid, Paper, TextField } from "@material-ui/core";
import { FormikInitialValues, FormikValidationSchema } from "./comentarioUtils";
import { InteraccionRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { MonedaToMonto, MontoToMoneda } from "../../../services/utilities/formatUtils";
import { PostulanteRequest } from "../../../services/request/reclutamiento/postulantes";
import { MainContext } from "../../../App";

/**
 * Componente para agregar o actualizar una evaluación.
 * @param {*} licitacion_proyecto_id ID de la licitación/proyecto.
 * @param {*} cargo_id ID del cargo.
 * @param {*} asignacion_id ID de la asignación.
 * @param {*} postulante Datos del postulante.
 * @param {*} handle_close Método encargado de cerrar el popup.
 * @returns Component.
 */
export default function ComentarioDialog(props) {
	const {
		is_open,
		licitacion_proyecto_id,
		cargo_id,
		asignacion_id,
		postulante,
		handle_close,
	} = props;

	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const notistack = useSnackbar();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nuevo comentario del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				values["_asignacion_ref"] = asignacion_id;
				values["_reclutador_ref"] = usuarioSesion.ref;
				values["_postulante_ref"] = postulante._id;
				values["pretension_renta"] = MonedaToMonto(values.pretension_renta_formato);

				//Se agrega una nueva asignación.
				await InteraccionRequest.Agregar(licitacion_proyecto_id, cargo_id, asignacion_id, values);
				//Si el postulante no tiene pretensión de renta líquida y se incluye pretensión de renta en el comentario.
				if (postulante.detalle.pretencion_renta === 0 && values.pretension_renta > 0) {
					let actualizacion = {
						detalle: {
							...postulante.detalle,
							pretencion_renta: values.pretension_renta,
						},
					}
					//Se actualiza la pretención de renta líquida del postulante.
					await PostulanteRequest.Actualizar(postulante._id, actualizacion);
				}

				ShowSnackbar("Comentario agregado exitosamente");
			} catch (error) {
				ShowSnackbar("Error al intentar agregar el comentario", error);
			} finally {
				handle_close();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formik.isSubmitting ||
			!formik.values.comentario ||
			!formik.isValid ||
			Object.keys(formik.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name="comentario"
							label="Comentario"
							value={formik.values.comentario}
							onChange={formik.handleChange}
							variant="outlined"
							size="small"
							multiline
							fullWidth
							required
							helperText={formik.errors.comentario}
							error={Boolean(formik.errors.comentario)}
						/>
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={6}>
						<TextField
							name="pretension_renta_formato"
							label="Renta líquida en pesos (opcional)"
							value={formik.values.pretension_renta_formato}
							onChange={handleChangePretencionRenta}
							variant="outlined"
							size="small"
							fullWidth
							helperText={formik.errors.pretension_renta_formato}
							error={Boolean(formik.errors.pretension_renta_formato)}
						/>
					</Grid>
					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box mr={1}>
								<Button variant="contained" onClick={formik.submitForm} disabled={isDisabled()} color="primary">
									Asignar
								</Button>
							</Box>
							<Box ml={1}>
								<Button variant="contained" onClick={handle_close} disabled={formik.isSubmitting} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}