import React, { Fragment, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Backdrop, Badge, Box, Button, CircularProgress, Divider, Grid, makeStyles, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Icon from "@mdi/react";
import { mdiCloudUploadOutline } from "@mdi/js";
import Origenes from "../../../services/data/origenes.json";

const filter = createFilterOptions();

export default function Page(props) {
	const {
		uploader,
		rejecter,
		is_loading,
		loading_status,
		limite_archivos,
	} = props;

	const [Tags, SetTags] = useState([]);
	const [Options, SetOptions] = useState([]);
	const [Origen, SetOrigen] = useState("");
	const classes = useStyles();

	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
	} = useDropzone({ maxFiles: limite_archivos, accept: ["application/pdf"], multiple: true, onDropRejected: rejecter, minSize: 1 });
	// } = useDropzone({ maxFiles: limite_archivos, accept: ["application/pdf", ".doc", ".docx"], multiple: true, onDropRejected: rejecter, minSize: 1 });

	/**
	 * Evento encargado de ejecutarse con CTRL + Enter.
	 * @link https://stackoverflow.com/questions/16006583/capturing-ctrlz-key-combination-in-javascript.
	 * @param {*} e Evento.
	 */
	document.onkeydown = function (e) {
		if (acceptedFiles && acceptedFiles.length > 0 && e.code === "Enter" && e.ctrlKey && Origen) {
			uploader(acceptedFiles, Tags);
		}
	}

	return (
		<Fragment>
			<Paper style={{ margin: 5, marginLeft: 50, marginRight: 100 }}>
				<Grid container spacing={2} style={{ paddingLeft: "10%", paddingRight: "10%" }}>

					{/* ICONO DE NUBE CYD */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center" style={{ marginLeft: "25%", marginRight: "25%" }} {...getRootProps()}>
							<input {...getInputProps()} />
							<Icon path={mdiCloudUploadOutline} color="#731f1f" size={12} />
						</Box>
					</Grid>

					{/* MENSAJES CON INSTRUCCIONES Y COMENTARIOS */}
					<Grid item xs={12}>
						<Box>
							<Typography align="center" variant="h5">Arrastre los documentos en PDF, o puede hacer click para buscarlos</Typography>
							<Typography align="center" variant="h6">{`El límite es de ${limite_archivos} archivos`}</Typography>
						</Box>
					</Grid>

					{/* SEPADADOR DE SECCIÓN */}
					<Grid item xs={12}>
						<Divider />
					</Grid>

					{/* CAMPO PARA INGRESAR TAGS */}
					<Grid item xs={9}>
						<Box>
							<Autocomplete
								value={Tags}
								onChange={(event, value) => {
									let newValues = [];

									//Por cada valor ingresado como palabra clave.
									value.forEach((valor, index) => {
										if (typeof valor === "string") {
											//Si es de tipo texto.
											newValues.push({ value: valor, is_new: false });
										}
										if (typeof valor === "object") {
											//Si es de tipo objeto.
											newValues.push({ ...valor, is_new: false });
										}
									});

									SetTags(newValues);
									//Se combierten en texto, para buscar repetidos.
									let values = [...Options, ...newValues].map(v => JSON.stringify(v));
									//Se filtran los valores repetidos.
									SetOptions(Array.from(new Set(values)).map(v => JSON.parse(v)));
								}}
								filterOptions={(options, params) => {
									options = options.filter(o => !Tags.find(v => v.value === o.value));
									const filtered = filter(options, params);
									// Suggest the creation of a new value
									if (params.inputValue !== '') {
										filtered.push({
											value: params.inputValue,
											is_new: true,
										});
									}
									return filtered;
								}}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								options={Options}
								getOptionLabel={(option) => option.value}
								renderOption={(option) => option.is_new ? `Incluir "${option.value}"` : option.value}
								ChipProps={{ color: "primary" }}
								freeSolo
								multiple
								fullWidth
								size="small"
								disabled={is_loading}
								// style={{ paddingLeft: 50, paddingRight: 50 }}
								noOptionsText="Sin opciones"
								renderInput={(params) => (
									<TextField
										label="Tags (Opcional)"
										variant="outlined"
										fullWidth
										{...params}
									/>
								)}
							/>
							<Typography align="center" variant="h6">Puede incluir TAGs para clasificar y realizar búsquedas futuras</Typography>
						</Box>
					</Grid>

					{/* CAMPO PARA EL ORIGEN DEL DOCUMENTO */}
					<Grid item xs={3}>
						<Box display="flex" justifyContent="center">
							<Autocomplete
								name="origen"
								options={Origenes || []}
								value={Origen}
								onChange={(event, value) => SetOrigen(value)}
								getOptionLabel={(origen) => origen}
								size="small"
								fullWidth
								noOptionsText="Sin opciones"
								renderInput={(params) => (
									<TextField
										label="Origen"
										variant="outlined"
										required
										style={{ backgroundColor: "white" }}
										// helperText="El origen de los documentos es requerido."
										error={!Boolean(Origen)}
										{...params}
									/>
								)}
							/>
						</Box>
					</Grid>

					{/* SEPADADOR DE SECCIÓN */}
					<Grid item xs={12}>
						<Divider />
					</Grid>

					{/* BOTÓN PARA CARGAR LOS ARCHIVOS SELECCIONADOS */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<Tooltip title="CTRL + Enter" style={{ margin: 20 }}>
								<Badge badgeContent={acceptedFiles.length ? `${acceptedFiles.length} CVs` : null} color="secondary">
									<Button onClick={() => uploader(acceptedFiles, Tags, Origen)} disabled={!acceptedFiles || acceptedFiles.length === 0 || !Origen} variant="contained" color="primary" size="large">
										Cargar Documentos
									</Button>
								</Badge>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
			</Paper>

			{/* COMPONENTE DE CARGA */}
			<Backdrop open={is_loading} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography align="center" variant="h5">{`Cargando datos... (${Number(loading_status).toFixed(2)}%)`}</Typography>
			</Backdrop>
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 100,
		color: "#fff",
	},
}));