import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { MainContext } from "../../../../App";
import { ObtenerUsuariosPlataforma } from "../../../../services/request/reclutamiento/usuariosPlataforma";

export default function Index(props) {
	const MainCTX = useContext(MainContext);
	const UsuariosPlataformaSWR = useSWR("usuarios_plataforma_ajustes", ObtenerUsuariosPlataforma, { revalidateOnFocus: false });

	useEffect(() => {
		if (UsuariosPlataformaSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de los usuarios.", UsuariosPlataformaSWR.error);
		}
	}, [MainCTX, UsuariosPlataformaSWR.error]);

	return (
		<Page
			usuarios={UsuariosPlataformaSWR.data}
			permisos={MainCTX.permisos}
			is_loading={UsuariosPlataformaSWR.isValidating}
			mutate_usuarios={UsuariosPlataformaSWR.mutate}
		/>
	);
}