import { mixed, object } from "yup";
import { ASIGNACION_ESTADOS } from "../../constants/contexts";

export const FormikInitialValues = {
	licitacion_proyecto: null,
	_licitacion_proyecto_ref: "",
	tipo: "",
	cargo: null,
	_cargo_ref: "",
	cargo_nombre: "",
	cargo_codigo: "",
	licitacion_proyecto_codigo: "",
	_responsable_ref: "",
	_reclutador_ref: "",
	_gerencia_ref: "",
	gerencia_sigla: "",
	_postulante_ref: "",
	estado: ASIGNACION_ESTADOS.PENDIENTE,
}

export const FormikValidationSchema = object().shape({
	licitacion_proyecto: mixed()
		.nullable()
		.required("La licitación/proyecto es requida."),
	cargo: mixed()
		.nullable()
		.required("El cargo es requerido."),
});