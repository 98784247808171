import React, { useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import DialogTitleComponent from "../../../../components/dialog_title";
import { LicitacionProyectoRequets } from "../../../../services/request/reclutamiento/licitaciones_proyectos";
import { ObtenerReclutadores } from "../../../../services/request/reclutamiento/usuariosPlataforma";
import { GetPersonas } from "../../../../services/request/usuarios/personas";
import { GetContratos } from "../../../../services/request/portafolio/contratos";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../../../App";

export default function DialogAddProyecto(props) {
	const {
		is_open,
		handle_close,
		mutate_proyectos,
	} = props;

	const { ShowSnackbar } = useContext(MainContext);
	const notistack = useSnackbar();
	const ReclutadoresSWR = useSWR("reclutadores", (key) => ObtenerReclutadores(), { revalidateOnFocus: false });
	const PersonasSWR = useSWR("personas", (key) => GetPersonas({ is_gsuite_talana: true }), { revalidateOnFocus: false });
	const ContratosSWR = useSWR("contratos", (key) => GetContratos(), { revalidateOnFocus: false });

	useEffect(() => {
		if (ReclutadoresSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los reclutadores.", ReclutadoresSWR.error);
		}
		if (PersonasSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de las personas.", PersonasSWR.error);
		}
		if (ContratosSWR.error) {
			ShowSnackbar("Error al intentar cargar los datos de los contratos.", ContratosSWR.error);
		}
	}, [ShowSnackbar, ReclutadoresSWR, PersonasSWR, ContratosSWR]);

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				notistack.enqueueSnackbar("Agregando el nuevo proyecto...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});
				delete values._id;
				values._gerencia_ref = values.gerencia._id;
				values._contrato_ref = values.contrato._id;
				values._responsable_ref = values.responsable._id;
				values._reclutador_ref = values.reclutador._id;

				await LicitacionProyectoRequets.Agregar(values);
				notistack.closeSnackbar();
				ShowSnackbar("Proyecto agregado exitosamente.");
			} catch (error) {
				console.error(error);
				ShowSnackbar("Error al intentar agregar el proyecto.", error);
			} finally {
				helper.resetForm();
				mutate_proyectos();
				handle_close();
			}
		}
	});

	return (
		<Dialog open={is_open} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>Agregar Nuevo Proyecto</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					{"Ingrese la información requerida del proyecto y luego guarde los cambios."}
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Autocomplete
							options={ContratosSWR.data || []}
							getOptionLabel={(contrato) => `[${contrato.codigo}] ${contrato.nombre}`}
							onChange={(event, value) => {
								formik.setFieldValue("contrato", value);
								formik.setFieldValue("gerencia", value.gerencia_ref);
								formik.setFieldValue("codigo", value.codigo);
								formik.setFieldValue("nombre", value.nombre);
								formik.setFieldValue("mandante", value.mandante_ref.nombre);
								let responsable = Array.from(PersonasSWR.data).find(p => p.usuario_id === value.responsable_ref.persona.usuario_id);
								formik.setFieldValue("responsable", responsable);
							}}
							loading={ContratosSWR.isValidating}
							noOptionsText="Sin opciones"
							disabled={!ContratosSWR}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Contrato"
									helperText={formik.errors.contrato}
									error={Boolean(formik.errors.contrato)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							options={ReclutadoresSWR.data || []}
							getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
							onChange={(event, value) => formik.setFieldValue("reclutador", value)}
							loading={ReclutadoresSWR.isValidating}
							noOptionsText="Sin opciones"
							disabled={!ReclutadoresSWR}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Reclutador"
									helperText={formik.errors.reclutador}
									error={Boolean(formik.errors.reclutador)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							disabled
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="codigo"
							label="Código"
							value={formik.values.codigo}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							disabled
							helperText={formik.errors.codigo}
							error={Boolean(formik.errors.codigo)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Autocomplete
							value={formik.values.responsable}
							options={PersonasSWR.data || []}
							getOptionLabel={(persona) => `${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno} [${persona.contacto.email}]`}
							onChange={(event, value) => formik.setFieldValue("responsable", value)}
							loading={PersonasSWR.isValidating}
							noOptionsText="Sin opciones"
							disabled
							size="small"
							renderInput={(params) => (
								<TextField
									label="Responsable"
									helperText={formik.errors.responsable}
									error={Boolean(formik.errors.responsable)}
									variant="outlined"
									required
									{...params}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="mandante"
							label="Mandante"
							value={formik.values.mandante}
							onChange={formik.handleChange}
							variant="outlined"
							required
							fullWidth
							size="small"
							disabled
							helperText={formik.errors.mandante}
							error={Boolean(formik.errors.mandante)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="descripcion"
							label="Descripción"
							value={formik.values.descripcion}
							onChange={formik.handleChange}
							variant="outlined"
							multiline
							rows={4}
							required
							fullWidth
							size="small"
							helperText={formik.errors.descripcion}
							error={Boolean(formik.errors.descripcion)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} color="primary">Cancelar</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting} variant="contained" color="primary">Aceptar</Button>
			</DialogActions>
		</Dialog>
	);
}