import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Método encargado de obtener la colección de respuestas de un JOB en Trabajando.com.
 * @returns Colección de respuestas de una postulación.
 */
export async function ObtenerJobPorID(jobID) {
	try {
		let url = `${RECLUTAMIENTO}/trabajando-com/jobs/${jobID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los datos de un postulante, por su ID.
 * @param {*} applicantID ID del postulante.
 * @returns Datos de un postulante.
 */
export async function ObtenerApplicantPorID(applicantID) {
	try {
		let url = `${RECLUTAMIENTO}/trabajando-com/applicants/${applicantID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los datos de un postulante, por su ID.
 * @param {*} applicantID ID del postulante.
 * @returns Datos de un postulante.
 */
export async function ObtenerJobApplicants(jobID) {
	try {
		let url = `${RECLUTAMIENTO}/trabajando-com/jobs-applicants/${jobID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}