import React, { useContext } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { Box, Button, Collapse, Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { MainContext } from "../../../../App";
import { PostulanteEditarContext } from "../index";
import { FormikInitialValues, FormikValidationSchema, POSTGRADO } from "./estudio_utils";
import FileInput from "../../../../components/fileInput";
import { uploadFileToCloudStorage } from "../../../../services/utilities/files";
import { ActualizacionRequest, EstudioRequest } from "../../../../services/request/reclutamiento/postulantes";

export default function EstudioDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { postulante_id } = useParams();
	const { usuarioSesion } = useContext(MainContext);
	const { formik, instituciones, titulos, niveles_estudio } = useContext(PostulanteEditarContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nuevo estudio del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				values.institucion = values.institucion_object.valor;
				values.nivel_estudio = values.nivel_estudio_object.valor;
				values.titulo = values.titulo_object.valor;

				//Si existe un archivo de certificado.
				if (values.file) {
					notistack.enqueueSnackbar("Subiendo certificado a la nube...", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});
					let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
					const archivo = await uploadFileToCloudStorage(values.file, "certificados-estudio-test", `${values.file.name}_${ahora}`);
					values.certificado = archivo;
				}

				//Se agregan estudio y actualización del postulante.
				let responses = await Promise.all([
					EstudioRequest.Agregar(postulante_id, values),
					ActualizacionRequest.Agregar(postulante_id, usuarioSesion.ref, "Nuevo estudio"),
				]);

				//Nuevo estudio al postulante.
				let estudio = responses[0];
				//Nuevo registro de actualización del postulante.
				let actualizacion = responses[1];

				//Se actualizan los datos del postulante.
				formik.setFieldValue("estudios", [...formik.values.estudios, estudio]);
				formik.setFieldValue("actualizaciones", [actualizacion, ...formik.values.actualizaciones]);

				notistack.enqueueSnackbar("Estudio del postulante agregado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar agregar el estudio del postulante.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handleCloseDialog();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCloseDialog = () => {
		set_is_open(false);
		formikDialog.resetForm();
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formikDialog.isSubmitting ||
			!formikDialog.values.institucion_object ||
			!formikDialog.values.titulo_object ||
			!formikDialog.values.nivel_estudio_object ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					{/* CAMPO INSTITUCIÓN */}
					<Grid item xs={8}>
						<Autocomplete
							name="institucion_object"
							options={instituciones.data?.data || []}
							value={formikDialog.values.institucion_object}
							getOptionLabel={(institucion) => institucion.valor}
							onChange={(event, value) => formikDialog.setFieldValue("institucion_object", value)}
							loading={instituciones.isValidating}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Institución"
									variant="outlined"
									helperText={formikDialog.errors.institucion_object}
									error={Boolean(formikDialog.errors.institucion_object)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO NIVEL DE ESTUDIO */}
					<Grid item xs={4}>
						<Autocomplete
							name="nivel_estudio_object"
							options={niveles_estudio.data?.data || []}
							value={formikDialog.values.nivel_estudio_object}
							getOptionLabel={(nivelEstudio) => nivelEstudio.valor}
							onChange={(event, value) => formikDialog.setFieldValue("nivel_estudio_object", value)}
							loading={niveles_estudio.isValidating}
							size="small"
							renderInput={(params) => (
								<TextField
									label="Nivel de estudio"
									variant="outlined"
									helperText={formikDialog.errors.nivel_estudio_object}
									error={Boolean(formikDialog.errors.nivel_estudio_object)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO TÍTULO, CUANDO NO ES POSTGRADO */}
					{formikDialog.values.nivel_estudio_object?.valor !== POSTGRADO && (
						<Grid item xs={6}>
							<Autocomplete
								name="titulo_object"
								options={titulos.data?.data || []}
								value={formikDialog.values.titulo_object}
								getOptionLabel={(titulo) => titulo.valor}
								onChange={(event, value) => formikDialog.setFieldValue("titulo_object", value)}
								loading={titulos.isValidating}
								size="small"
								renderInput={(params) => (
									<TextField
										label="Título"
										variant="outlined"
										helperText={formikDialog.errors.titulo_object}
										error={Boolean(formikDialog.errors.titulo_object)}
										{...params}
									/>
								)}
							/>
						</Grid>
					)}

					{/* CAMPO TÍTULO, CUANDO ES POSTGRADO */}
					{formikDialog.values.nivel_estudio_object?.valor === POSTGRADO && (
						<Grid item xs={6}>
							<TextField
								name="titulo_object"
								label="Título"
								value={formikDialog.values.titulo_object?.valor}
								onChange={(event) => {
									let valor = event.target.value;
									formikDialog.setFieldValue("titulo", valor);
									formikDialog.setFieldValue("titulo_object", { valor });
								}}
								variant="outlined"
								fullWidth
								size="small"
								helperText={formikDialog.errors.titulo_object}
								error={Boolean(formikDialog.errors.titulo_object)}
							/>
						</Grid>
					)}

					{/* CAMPO CERTIFICADO */}
					<Grid item xs={6}>
						<FileInput
							inputName="file"
							label="Certificado"
							handleOnChange={(e) => formikDialog.setFieldValue("file", e.target.files[0])}
							meta={{ touched: formikDialog.touched.file, error: formikDialog.errors.file }}
							accept="image/png, image/jpg, image/jpeg, application/pdf"
							buttonColor="primary"
							buttonVariant="outlined"
							value={formikDialog.values.certificado}
							textVariant="outlined"
							size="small"
						/>
					</Grid>

					{/* CAMPO FECHA DE TÉRMINO */}
					<Grid item xs={6}>
						<DatePicker
							label="Fecha de titulación"
							value={formikDialog.values.fecha_termino}
							onChange={date => formikDialog.setFieldValue("fecha_termino", date)}
							openTo="year"
							views={["year", "month"]}
							format="MMMM[ de ]YYYY"
							minDate={Moment().add(-80, "years").year().toString()}
							maxDate={Moment().add(1, "years").year().toString()}
							variant="inline"
							inputVariant="outlined"
							fullWidth
							autoOk
							size="small"
							helperText={formikDialog.errors.fecha_termino}
							error={Boolean(formikDialog.errors.fecha_termino)}
						/>
					</Grid>

					{/* COMENTARIO NUEVAS INSTITUCIONES Y TÍTULOS */}
					<Grid item xs={12}>
						<Typography variant="caption">Puede agregar nuevas instituciones o títulos en la sección seleccionables del menú de Administración.</Typography>
					</Grid>

					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box pr={1}>
								<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
									Agregar estudio
								</Button>
							</Box>
							<Box pl={1}>
								<Button variant="contained" onClick={handleCloseDialog} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}