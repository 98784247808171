import React, { Fragment, useContext, useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import * as Permissions from "../../../constants/permissions";
import TabPanelComponent from "../../../components/tab_panel";
import AccessDeniedComponent from "../../../components/access_denied";
import UsuariosPlataforma from "./usuariosPlataforma";
import CartasOferta from "./cartasOferta";
import FormulariosPostulacion from "./formulariosPostulacion";
import { CheckPermiso } from "../../../services/utilities/common";
import { MainContext } from "../../../App";

export default function Page(props) {
	const [TabIndex, SetTabIndex] = useState(0);
	const MainCTX = useContext(MainContext);

	const handleChange = (event, value) => {
		SetTabIndex(value);
	};

	return (
		<Fragment>
			<AppBar position="static" color="inherit">
				<Tabs value={TabIndex} onChange={handleChange} indicatorColor="primary" variant="fullWidth">
					<Tab label="Usuarios plataforma" />
					<Tab label="Cartas de oferta" />
					<Tab label="Formularios postulación" />
				</Tabs>
			</AppBar>

			{/* PESTAÑA USUARIOS PLATAFORMA */}
			<TabPanelComponent value={TabIndex} index={0}>
				{CheckPermiso(MainCTX.permisos, Permissions.ADMIN_AJUSTES_USUARIOS_PLATAFORMA_VER) ? <UsuariosPlataforma /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA CARTAS OFERTA */}
			<TabPanelComponent value={TabIndex} index={1}>
				{CheckPermiso(MainCTX.permisos, Permissions.ADMIN_AJUSTES_CARTAS_OFERTA_VER) ? <CartasOferta /> : <AccessDeniedComponent />}
			</TabPanelComponent>

			{/* PESTAÑA FORMULARIOS POSTULACIÓN */}
			<TabPanelComponent value={TabIndex} index={2}>
				{CheckPermiso(MainCTX.permisos, Permissions.ADMIN_AJUSTES_FORMULARIOS_POSTULACIONES_VER) ? <FormulariosPostulacion /> : <AccessDeniedComponent />}
			</TabPanelComponent>
		</Fragment>
	);
}