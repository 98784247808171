import { object, string } from "yup";

export const FormikInitialValues = {
	nombre: "",
	link_template: "",
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.required("El nombre es requerido."),
	link_template: string()
		.required("El link es requerido."),
});