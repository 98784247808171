import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { ToTitleCase } from "../../services/utilities/formatUtils";

/**
 * Método encargado de retornar un componente Tooltip si el texto es muy largo.
 * @param {*} texto Texto original.
 * @param {*} largo Largo máximo permitido.
 */
export default function TooltipIfLongerThan(props) {
	const {
		texto,
		largo,
	} = props;

	const textoTitleCase = ToTitleCase(texto);

	if (String(textoTitleCase).length > largo) {
		return (
			<Tooltip title={textoTitleCase}>
				<Typography>{`${String(textoTitleCase).slice(0, largo)}...`}</Typography>
			</Tooltip>
		);
	} else {
		return (
			<Typography>{textoTitleCase}</Typography>
		);
	}
}