import React, { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import { Box, Collapse, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { Search, ExpandLess, ExpandMore, Tune, FolderShared, More, Dashboard } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@mdi/react";
import { mdiFileUpload, mdiCrane, mdiBookOpenVariant, mdiMicrosoftExcel } from "@mdi/js";
import * as Routes from "../../../constants/routes";
import * as Permissions from "../../../constants/permissions";
import { CheckPermiso, CheckPermisosSome } from "../../../services/utilities/common";
import { DescargarAsignados, DescargarVacantes } from "../../../services/request/reclutamiento/descargasExcel";
import { MainContext } from "../../../App";

const VERIFICAR_RUN_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSfFRXUBBNhWXRIZYg824SDAlFD7erqsohO5VDtJkpezMPyyuw/viewform";
const FEEDBACK_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSdgk1LLcU9vDdMB1c_Rx8AmNAf4uA4Yc-NwAVfjGbOS3lr0ww/viewform";
const SITE_AYUDA_URL = "https://sites.google.com/cydingenieria.cl/gestordereclutamiento";

/**
 * Método encargado de retornar el listado con los módulos.
 * @param {*} open TRUE: Drawer abierto | FALSE: Drawer cerrado.
 * @param {*} set_open Método encargado de setear el state open.
 * @returns Component.
 */
export default function MenuLateral(props) {
	const {
		open,
		set_open,
	} = props;

	const [openCargarCVs, setOpenCargarCVs] = useState(false);
	const [openDescargas, setOpenDescargas] = useState(false);
	const [openCarpetas, setOpenCarpetas] = useState(false);
	const [openAccesosAdministracion, setOpenAccesosAdministracion] = useState(false);
	const location = useLocation();
	const MainCTX = useContext(MainContext);
	const [rect1, ref1] = useClientRect();
	const [rect2, ref2] = useClientRect();
	const classes = useStyles();

	useEffect(() => {
		//Si el Drawer cambia a cerrado, se cierran todos los componentes internos.
		if (open === false) {
			setOpenCargarCVs(false);
			setOpenDescargas(false);
			setOpenCarpetas(false);
			setOpenAccesosAdministracion(false);
		}
	}, [open]);

	/**
	 * Método encargado de abrir módulo de CVs.
	 */
	const abrirModuloCVs = () => {
		set_open(true);
		setOpenCargarCVs(!openCargarCVs);
		setOpenDescargas(false);
		setOpenCarpetas(false);
		setOpenAccesosAdministracion(false);
	}

	/**
	 * Método encargado de abrir módulo de CVs.
	 */
	const abrirModuloDescargas = () => {
		set_open(true);
		setOpenCargarCVs(false);
		setOpenDescargas(!openDescargas);
		setOpenCarpetas(false);
		setOpenAccesosAdministracion(false);
	}

	/**
	 * Método encargado de abrir módulo de Carpetas.
	 */
	const abrirModuloCarpetas = () => {
		set_open(true);
		setOpenCargarCVs(false);
		setOpenDescargas(false);
		setOpenCarpetas(!openCarpetas);
		setOpenAccesosAdministracion(false);
	}

	/**
	 * Método encargado de abrir módulo de Administración.
	 */
	const abrirModuloAdministracion = () => {
		set_open(true);
		setOpenCargarCVs(false);
		setOpenDescargas(false);
		setOpenCarpetas(false);
		setOpenAccesosAdministracion(!openAccesosAdministracion);
	}

	/**
	 * Método encargado de descargar el Excel de vacantes.
	 */
	const handleDescargarVacantes = async () => {
		try {
			await DescargarVacantes(MainCTX.usuarioSesion.gerencia._id);
			MainCTX.ShowSnackbar("Excel de vacantes descargado exitosamente.");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar descargar el Excel de vacantes.", error);
		}
	}

	/**
	 * Método encargado de descargar el Excel de asignados.
	 */
	const handleDescargarAsignados = async () => {
		try {
			await DescargarAsignados(MainCTX.usuarioSesion.gerencia._id)
			MainCTX.ShowSnackbar("Excel de asignados descargado exitosamente.");
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar descargar el Excel de asignados.", error);
		}
	}

	/**
	 * Método encargado de redireccionar a una página externa.
	 * @param {*} url URL de la página.
	 */
	const RedirecTo = (url) => {
		window.open(url, "_blank");
		set_open(false);
	}

	/**
	 * Método encargado de verificar si un módulo está seleccionado.
	 * @param {*} route Ruta del módulo.
	 * @returns TRUE|FALSE Flag de módulo seleccionado.
	 */
	const isSelected = (route) => {
		let check = location.pathname.includes(route);
		return check;
	}

	return (
    <Drawer
      variant={open ? "temporary" : "permanent"}
      open={open}
      onClose={() => set_open(false)}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Box height={"88%"} style={{ marginTop: 55 }} ref={ref1}>
        {/* ELEMENTOS INICIO */}
        <Box
          display="block"
          alignItems="flex-start"
          style={{
            height:
              rect1 && rect2
                ? Math.max(440, rect1?.height - rect2?.height)
                : 500,
          }}
        >
          <List style={{ backgroundColor: "#FFFFFF" }}>
            {/* MÓDULO CANDIDATOS */}
            <ListItem
              button
              component={Link}
              to={Routes.CANDIDATOS}
              onClick={() => set_open(false)}
              disabled={
                !CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_VER)
              }
              style={{
                backgroundColor: isSelected(Routes.CANDIDATOS)
                  ? "#e0e0e0"
                  : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Candidatos" placement="right">
                  <Search color="primary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>Candidatos</ListItemText>
            </ListItem>

            <Divider />

            {/* LICITACIONES */}
            <ListItem
              button
              component={Link}
              to={Routes.CARPETA_LICITACIONES}
              onClick={() => set_open(false)}
              disabled={
                !CheckPermiso(
                  MainCTX.permisos,
                  Permissions.CARPETA_LICITACIONES_VER
                )
              }
              style={{
                backgroundColor: isSelected(Routes.CARPETA_LICITACIONES)
                  ? "#e0e0e0"
                  : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Licitaciones" placement="right">
                  <Icon path={mdiBookOpenVariant} color="#731f1f" size={1} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>
                Licitaciones
              </ListItemText>
            </ListItem>

            {/* PROYECTOS */}
            <ListItem
              button
              component={Link}
              to={Routes.CARPETA_PROYECTOS}
              onClick={() => set_open(false)}
              disabled={
                !CheckPermiso(
                  MainCTX.permisos,
                  Permissions.CARPETA_PROYECTOS_VER
                )
              }
              style={{
                backgroundColor: isSelected(Routes.CARPETA_PROYECTOS)
                  ? "#e0e0e0"
                  : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Proyectos" placement="right">
                  <Icon path={mdiCrane} color="#731f1f" size={1} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>Proyectos</ListItemText>
            </ListItem>

            {/* HISTORIAL ASIGNADOS */}
            <ListItem
              button
              component={Link}
              to={Routes.CARPETA_SELECCIONADOS}
              onClick={() => set_open(false)}
              disabled={
                !CheckPermiso(
                  MainCTX.permisos,
                  Permissions.CARPETA_SELECCIONADOS_VER
                )
              }
              style={{
                backgroundColor: isSelected(Routes.CARPETA_SELECCIONADOS)
                  ? "#e0e0e0"
                  : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Seleccionados" placement="right">
                  <FolderShared color="primary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>
                Seleccionados
              </ListItemText>
            </ListItem>

            <Divider />

            {/* MÓDULO CARGAR CVs */}
            <ListItem
              button
              onClick={abrirModuloCVs}
              disabled={
                !CheckPermisosSome(
                  MainCTX.permisos,
                  Permissions.CARGAR_CV_INDIVIDUAL,
                  Permissions.CARGAR_CV_MASIVA,
                  Permissions.CARGAR_CV_TRABAJANDO_COM
                )
              }
              style={{
                backgroundColor:
                  isSelected(Routes.CARGA_INDIVIDUAL) ||
                  isSelected(Routes.CARGA_MASIVA) ||
                  isSelected(Routes.TRABAJANDO_COM)
                    ? "#e0e0e0"
                    : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="CV" placement="right">
                  <Icon path={mdiFileUpload} color="#731f1f" size={1} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>CV</ListItemText>
              {openCargarCVs ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </ListItem>
            <Collapse in={openCargarCVs} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* INDIVIDUAL */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARGA_INDIVIDUAL}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARGAR_CV_INDIVIDUAL
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Carga individual
                  </ListItemText>
                </ListItem>

                {/* MASIVA */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARGA_MASIVA}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARGAR_CV_MASIVA
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Carga masiva
                  </ListItemText>
                </ListItem>

                {/* TRABAJANDO.COM */}
                {/* Editado por PReyes el 2023.12.22 a Solicitud de Carmen Valenzuela, en ticket de soporte N°: 12279  */}
                {/* <ListItem dense button component={Link} to={Routes.TRABAJANDO_COM} onClick={() => set_open(false)} disabled={!CheckPermiso(MainCTX.permisos, Permissions.CARGAR_CV_TRABAJANDO_COM)} style={{ paddingLeft: 40 }}>
									<ListItemText style={{ color: "black" }}>
										Trabajando.com
									</ListItemText>
								</ListItem> */}
              </List>
            </Collapse>

            <Divider />

            {/* DASHBOARD */}
            <ListItem
              button
              component={Link}
              to={Routes.DASHBOARD}
              onClick={() => set_open(false)}
              disabled={
                !CheckPermiso(MainCTX.permisos, Permissions.DASHBOARD_VER)
              }
              style={{
                backgroundColor: isSelected(Routes.DASHBOARD)
                  ? "#e0e0e0"
                  : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="KPI" placement="right">
                  <Dashboard color="primary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>KPI</ListItemText>
            </ListItem>

            <Divider />

            {/* MÓDULO DESCARGAS */}
            <ListItem
              button
              onClick={abrirModuloDescargas}
              disabled={
                !CheckPermisosSome(
                  MainCTX.permisos,
                  Permissions.DESCARGAR_VACANTES,
                  Permissions.DESCARGAR_ASIGNADOS
                )
              }
              style={{
                backgroundColor: isSelected(openDescargas) ? "#e0e0e0" : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Descargas" placement="right">
                  <Icon path={mdiMicrosoftExcel} color="#731f1f" size={1} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>Descargas</ListItemText>
              {openDescargas ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </ListItem>
            <Collapse in={openDescargas} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* INDIVIDUAL */}
                <ListItem
                  dense
                  button
                  onClick={handleDescargarVacantes}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.DESCARGAR_VACANTES
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Vacantes
                  </ListItemText>
                </ListItem>

                {/* MASIVA */}
                <ListItem
                  dense
                  button
                  onClick={handleDescargarAsignados}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.DESCARGAR_ASIGNADOS
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Asignados
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>

            <Divider />
          </List>
        </Box>

        {/* ELEMENTOS FINAL */}
        <Box alignItems="flex-end" ref={ref2} width={1}>
          <List style={{ backgroundColor: "#FFFFFF" }}>
            {/* MÓDULO CARPETAS */}
            <ListItem
              button
              onClick={abrirModuloCarpetas}
              disabled={
                !CheckPermiso(MainCTX.permisos, Permissions.CARPETAS_VER)
              }
              style={{
                backgroundColor:
                  isSelected(Routes.CARPETA_CVS_CARGADOS) ||
                  isSelected(Routes.CARPETA_FAVORITOS) ||
                  isSelected(Routes.CARPETA_CARTAS_OFERTA)
                    ? "#e0e0e0"
                    : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Adicional" placement="right">
                  <More color="primary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>Adicional</ListItemText>
              {openCarpetas ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </ListItem>
            <Collapse in={openCarpetas} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* CVS SELECCIONADOS */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARPETA_ASIGNADOS}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARPETA_ASIGNADOS_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Asignados
                  </ListItemText>
                </ListItem>

                {/* CVS CARGADOS */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARPETA_CVS_CARGADOS}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARPETA_CVS_CARGADOS_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Mis cargas
                  </ListItemText>
                </ListItem>

                {/* FAVORITOS */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARPETA_FAVORITOS}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARPETA_FAVORITOS_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Mis favoritos
                  </ListItemText>
                </ListItem>

                {/* CARTAS DE OFERTA */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.CARPETA_CARTAS_OFERTA}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.CARPETA_CARTAS_OFERTA_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Mis cartas de oferta
                  </ListItemText>
                </ListItem>

                {/* FORMULARIO VERIFICAR RUNs */}
                <ListItem
                  dense
                  button
                  onClick={() => RedirecTo(VERIFICAR_RUN_FORM_URL)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.FORMULARIO_VERIFICAR_RUN
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Verificar RUN
                  </ListItemText>
                </ListItem>

                {/* FORMULARIO DE OBSERVACIONES/FEEDBACK */}
                <ListItem
                  dense
                  button
                  onClick={() => RedirecTo(FEEDBACK_FORM_URL)}
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Observaciones
                  </ListItemText>
                </ListItem>

                {/* SITE DE AYUDA PARA USUARIOS */}
                <ListItem
                  dense
                  button
                  onClick={() => RedirecTo(SITE_AYUDA_URL)}
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Ayuda Gestor de Reclutamiento
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>

            <Divider />

            {/* MÓDULO ADMINISTRACIÓN */}
            <ListItem
              button
              onClick={abrirModuloAdministracion}
              disabled={
                !CheckPermisosSome(
                  MainCTX.permisos,
                  Permissions.ADMIN_AJUSTES_VER,
                  Permissions.ADMIN_SELECCIONABLE_VER
                )
              }
              style={{
                backgroundColor:
                  isSelected(Routes.AJUSTES) ||
                  isSelected(Routes.SELECCIONABLES)
                    ? "#e0e0e0"
                    : null,
              }}
            >
              <ListItemIcon>
                <Tooltip title="Administración" placement="right">
                  <Tune color="primary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText style={{ color: "black" }}>
                Administración
              </ListItemText>
              {openAccesosAdministracion ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </ListItem>
            <Collapse
              in={openAccesosAdministracion}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* AJUSTES */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.AJUSTES}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.ADMIN_AJUSTES_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Ajustes
                  </ListItemText>
                </ListItem>

                {/* SELECCIONABLES */}
                <ListItem
                  dense
                  button
                  component={Link}
                  to={Routes.SELECCIONABLES}
                  onClick={() => set_open(false)}
                  disabled={
                    !CheckPermiso(
                      MainCTX.permisos,
                      Permissions.ADMIN_AJUSTES_VER
                    )
                  }
                  style={{ paddingLeft: 40 }}
                >
                  <ListItemText style={{ color: "black" }}>
                    Seleccionables
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}

/**
 * Método encargado de retornar una referencia a un componente.
 * @returns Referencia.
 */
function useClientRect() {
	const [rect, setRect] = useState(null);
	const ref = useCallback(node => {
		if (node !== null) {
			setRect(node.getBoundingClientRect());
		}
	}, []);
	return [rect, ref];
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		position: "absolute"
	},
	drawerOpen: {
		backgroundColor: "#FFFFFF",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: "#FFFFFF",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: isMobile ? 0 : theme.spacing(7) + 1,
	},
}));