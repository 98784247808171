import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { AsignacionRequest } from "../../../services/request/reclutamiento/licitaciones_proyectos";
import { ASIGNACION_ESTADOS, GERENCIAS_SUPERIORES } from "../../../constants/contexts";
import { MainContext } from "../../../App";

export default function Index(props) {
	const SeleccionadosSWR = useSWR("seleccionados", (key) => AsignacionRequest.Obtener({ estado: ASIGNACION_ESTADOS.SELECCIONADO, _gerencia_ref: FiltroPorGerencia() }, "/_postulante_ref"), { revalidateOnFocus: false });
	const MainCTX = useContext(MainContext);

	/**
	 * Método encargado de verificar si se tiene acceso a todas las gerencias o no.
	 * @returns Filtro de la consulta.
	 */
	const FiltroPorGerencia = () => {
		if (GERENCIAS_SUPERIORES.includes(MainCTX.usuarioSesion.gerencia._id)) {
			return undefined;
		} else {
			return MainCTX.usuarioSesion.gerencia._id;
		}
	}

	useEffect(() => {
		if (SeleccionadosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar cargar los datos de los seleccionados.", SeleccionadosSWR.error);
		}
	}, [MainCTX, SeleccionadosSWR.error]);

	return (
		<Page
			seleccionados={SeleccionadosSWR.data}
			permisos={MainCTX.permisos}
			is_loading={SeleccionadosSWR.isValidating}
		/>
	);
}