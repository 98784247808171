/**
 * Método encargado de obtener el template de email, por postulación exitosa.
 * @param {*} nombrePostulante Nombre del postulante.
 * @returns Template formateado.
 * @link https://material.io/develop/web/getting-started
 */
export function SolicitarCV(nombrePostulante) {
	let template = `
		<html>
			<head>
				<style>
					div.root {
						align-items: center;
						justify-content: center;
						margin: auto;
						width: 50%;
						padding: 10px;
					}
					div.rounded {
						border: 2px solid #d4d2d2;
						border-radius: 25px;
						padding: 25px;
					}
					img.center {
						display: block;
						margin-left: auto;
						margin-right: auto;
						width: 100;
					}
				</style>
			</head>
			<body>
				<div class="root">
					<div class="rounded">
						<p style="text-align:center">Estimado/a ${nombrePostulante},</p>

						<p align="justify" style="text-align:center">
							Junto con saludar y esperando te encuentres bien, te invitamos a actualizar tus antecedentes profesionales en la página Postulaciones CyD, 
							puedes acceder por este <a href="${POSTULACIONES_URL}">link</a> y de esta manera nuestros reclutadores podrán revisar tu perfil laboral y considerarte en sus búsquedas activas.
						</p>
						
						<p style="text-align:center">
							CyD Ingeniería es una empresa chilena con más de 45 años de experiencia en gerenciamiento de proyectos, 
							inspección técnica de obras, servicios de ingeniería, validación técnica financiera y 
							asesoría en diferentes rubros como minería, energía, vialidad, edificación, aeropuertos-puertos, hidráulica-sanitaria, industrial y transporte 
							tanto en empresas públicas como privadas.
						</p>
						
						<p align="justify" style="text-align:center">
							Nuestros equipos de profesionales están presentes en los más destacados proyectos a lo largo del país y en el extranjero, 
							por lo que buscamos personas que quieran dejar huella y contribuir con sus conocimientos y experiencia en los desafíos que día a día se nos presentan.
						</p>

						<p style="text-align:center">
							¡Te invitamos a dejar tu huella!
						</p>

						<p style="text-align:center">
							<b>POR FAVOR NO RESPONDER ESTE CORREO</b>
						</p>

						<img src="${LOGO_CYD}" alt="Logo CyD" class="center">
						
						<p style="text-align:center">
							<a href="${CYD_INGENIERIA_URL}">cydingenieria.cl</a>
						</p>
					</div>
				</div>
			</body>
		</html>
	`;
	return template;
}

const LOGO_CYD = "https://cydingenieria.cl/wp-content/uploads/2019/12/logo-color.png";
const CYD_INGENIERIA_URL = "https://www.cydingenieria.cl";
const POSTULACIONES_URL = "https://postulaciones.cydocs.cl/";