import React, { Fragment, useContext } from "react";
import { Badge, Dialog, DialogContent, Divider, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@material-ui/core";
import { CheckBoxOutlineBlank, Comment, FindInPage, FolderShared, Lock, LockOpen, Mail, ThumbUpAltOutlined } from "@material-ui/icons";
import DialogTitleComponent from "../../../../components/dialog_title";
import { ReactComponent as LogoCyD } from "../../../../img/logo_cyd.svg";
import { CANDIDATOS_CORREO_SOLICITAR_CV, CANDIDATOS_VER_ADJUNTO, CANDIDATOS_VER_BLOQUEADOS, CANDIDATOS_VER_COMENTARIOS, CANDIDATOS_VER_DETALLE } from "../../../../constants/permissions";
import { LUCES_SEMAFORO } from "../../../../constants/contexts";
import { CheckPermiso } from "../../../../services/utilities/common";
import { MainContext } from "../../../../App";

export default function DialogInfo(props) {
	const {
		handle_close,
	} = props;

	const MainCTX = useContext(MainContext);

	return (
		<Dialog open={true} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent onClose={handle_close}>
				Significado de las acciones
			</DialogTitleComponent>
			<DialogContent>
				<List dense style={{ height: 500 }}>
					<Divider />

					{CheckPermiso(MainCTX.permisos, CANDIDATOS_VER_ADJUNTO) && (
						<Fragment>
							<ListItem>
								<ListItemIcon>
									<FindInPage />
								</ListItemIcon>
								<ListItemText primary="Ver CV adjunto" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<FindInPage style={{ color: LUCES_SEMAFORO.VERDE }} />
								</ListItemIcon>
								<ListItemText primary="El CV ha sido actualizado recientemente (menos de 6 meses)" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<FindInPage style={{ color: LUCES_SEMAFORO.AMARILLO }} />
								</ListItemIcon>
								<ListItemText primary="El CV ha sido actualizado en un tiempo mayor a 6 meses pero menor a un año" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<FindInPage style={{ color: LUCES_SEMAFORO.ROJO }} />
								</ListItemIcon>
								<ListItemText primary="El CV no ha sido recientemente actualizado, lleva más de un año sin actualización" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Badge badgeContent={4} color="primary">
										<FindInPage />
									</Badge>
								</ListItemIcon>
								<ListItemText primary="El CV ha sido visto por reclutadores las cantidades de veces que indica el número" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<FindInPage style={{ color: "lightgray" }} />
								</ListItemIcon>
								<ListItemText primary="CV no disponible" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Badge badgeContent={4} color="primary">
										<FindInPage style={{ color: "lightgray" }} />
									</Badge>
								</ListItemIcon>
								<ListItemText primary="CV no disponible, pero ha sido visto en el detalle" />
							</ListItem>

							<Divider />
						</Fragment>
					)}

					{CheckPermiso(MainCTX.permisos, CANDIDATOS_VER_DETALLE) && (
						<Fragment>
							<ListItem>
								<ListItemIcon>
									<FolderShared />
								</ListItemIcon>
								<ListItemText primary="Detalle del candidato, contiene el CV, permite editar la información personal y profesional " />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Badge badgeContent={4} color="primary">
										<FolderShared />
									</Badge>
								</ListItemIcon>
								<ListItemText primary="Número de asignaciones de un candidato a proyectos o licitaciones. Verificar si se encuentra disponible" />
							</ListItem>

							<Divider />
						</Fragment>
					)}


					{CheckPermiso(MainCTX.permisos, CANDIDATOS_VER_COMENTARIOS) && (
						<Fragment>
							<ListItem>
								<ListItemIcon>
									<Comment />
								</ListItemIcon>
								<ListItemText primary="Persona sin comentarios emitidos por reclutadores y usuarios de la plataforma" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Badge badgeContent={4} color="primary">
										<Comment />
									</Badge>
								</ListItemIcon>
								<ListItemText primary="Persona con comentarios emitidos por reclutadores y usuarios de la plataforma" />
							</ListItem>

							<Divider />
						</Fragment>
					)}

					{CheckPermiso(MainCTX.permisos, CANDIDATOS_VER_BLOQUEADOS) && (
						<Fragment>
							<ListItem>
								<ListItemIcon>
									<LockOpen />
								</ListItemIcon>
								<ListItemText primary="Candidato disponible/desbloqueado para contratar o para que participe de un proceso" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<SvgIcon component={LogoCyD} viewBox="-20 -100 350 350" />
								</ListItemIcon>
								<ListItemText primary="Colaborador actualmente contratado en CyD." />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Lock style={{ color: "red" }} />
								</ListItemIcon>
								<ListItemText primary="Candidato bloqueado. No disponible para contratar. No se recomienda que participe en un proceso" />
							</ListItem>

							<Divider />
						</Fragment>
					)}

					<ListItem>
						<ListItemIcon>
							<ThumbUpAltOutlined />
						</ListItemIcon>
						<ListItemText primary="Te permite agregar a un candidato a la carpeta de favoritos personal de tu usuario" />
					</ListItem>

					<Divider />

					{CheckPermiso(MainCTX.permisos, CANDIDATOS_CORREO_SOLICITAR_CV) && (
						<Fragment>
							<ListItem>
								<ListItemIcon>
									<Mail />
								</ListItemIcon>
								<ListItemText primary="Envía un correo de solicitud a la persona para que actualice su CV por medio del trabaja con nosotros" />
							</ListItem>

							<ListItem>
								<ListItemIcon>
									<Mail style={{ color: "green" }} />
								</ListItemIcon>
								<ListItemText primary="El correo de solicitud ya fue enviado" />
							</ListItem>

							<Divider />
						</Fragment>
					)}

					<ListItem>
						<ListItemIcon>
							<CheckBoxOutlineBlank />
						</ListItemIcon>
						<ListItemText primary="Botón disponible para que selecciones y asignes al candidato a una licitación y/o proyecto multiples veces" />
					</ListItem>

					<Divider />
				</List>
			</DialogContent>
		</Dialog>
	);
}