import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

/**
 * Método encargado de agregar un nuevo registro de curriculum.
 * @param {*} curriculum Datos del curriculum.
 * @returns Response.
 */
export async function Agregar(curriculum) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums`;
		let response = await Axios.post(url, curriculum);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una colección de nuevos registros de curriculum.
 * @param {*} curriculums Colección de curriculums.
 * @returns Response.
 */
export async function AgregarBulk(curriculums) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/bulk`;
		let response = await Axios.post(url, curriculums);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un curriculum.
 * @param {*} curriculum Datos actualizados del curriculum.
 * @returns Response.
 */
export async function Actualizar(curriculumID, datos) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/${curriculumID}`;
		let response = await Axios.put(url, datos);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un curriculum.
 * @param {*} curriculumID ID del curriculum.
 * @returns Response.
 */
export async function Eliminar(curriculumID) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/${curriculumID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de curriculum.
 * @returns Colección de curriculums.
 */
export async function Obtener() {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/nombre/linkedin_link/curriculum_vitae/ensenanza_media/hoja_vida_conductor/titulos/tags/_postulante_ref/fecha_creacion`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la cantidad de curriculum.
 * @returns Cantidad de curriculums.
 */
export async function ObtenerCantidad() {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/cantidad`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de curriculum, filtrados por palabra clave.
 * @param {*} keywords Colección de palabras claves.
 * @param {*} todos Flag para determinar si debe cumplir todos los criterios o solo 1.
 * @returns Colección de curriculums que contienen las palabras claves y los que no.
 */
export async function ObtenerFiltrado(keywords, todos) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/search-pdfs`;
		let response = await Axios.post(url, { keywords: keywords, todos: todos });
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los datos KPI de CVs cargados.
 * @returns .
 */
export async function ObtenerKPI() {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/kpi`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un curriculum, por su ID.
 * @param {*} curriculumID ID del curriculum.
 * @returns Response.
 */
export async function ObtenerPorID(curriculumID) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums/${curriculumID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}