import React from "react";
import { Grid } from "@material-ui/core";
import TraficoKPI from "./components/trafico";
import CalidadKPI from "./components/calidad";
import DesempeñoKPI from "./components/desempeno";

export default function Page(props) {

	return (
		<Grid container spacing={5}>
			<Grid item xs={12}>
				<CalidadKPI />
			</Grid>

			<Grid item xs={12}>
				<DesempeñoKPI />
			</Grid>

			<Grid item xs={12}>
				<TraficoKPI />
			</Grid>
		</Grid>
	);
}