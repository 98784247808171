import React, { useContext } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PostulanteEditarContext } from "../index";
import AccordionComponent from "../../../../components/accordion";
import { MontoToMoneda } from "../../../../services/utilities/formatUtils";
import ZonasTrabajo from "../../../../services/data/zonas_trabajo.json";

export default function PersonalDetalle(props) {
	const {
		licencias_conducir,
		niveles_ingles,
		relaciones_cyd,
		formik,
	} = useContext(PostulanteEditarContext);

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	return (
		<AccordionComponent title="Información Adicional">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.nivel_ingles_object"
						options={niveles_ingles.data?.data || []}
						getOptionLabel={(nivelIngles) => nivelIngles.valor}
						value={formik.values.detalle.nivel_ingles_object}
						onChange={(event, value) => formik.setFieldValue("detalle.nivel_ingles_object", value)}
						loading={niveles_ingles.isValidating}
						size="small"
						fullWidth
						renderInput={(params) => (
							<TextField
								label="Nivel Inglés"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.nivel_ingles_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.nivel_ingles_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.licencias_conducir_objects"
						options={licencias_conducir.data?.data || []}
						getOptionLabel={(licenciaConducir) => licenciaConducir.valor}
						value={formik.values.detalle.licencias_conducir_objects}
						onChange={(event, value) => formik.setFieldValue("detalle.licencias_conducir_objects", value)}
						loading={licencias_conducir.isValidating}
						multiple
						size="small"
						fullWidth
						renderInput={(params) => (
							<TextField
								label="Licencias de Conducir"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.licencias_conducir_objects}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.licencias_conducir_objects)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.is_discapacitado_object"
						options={[{ label: "Si", valor: true }, { label: "No", valor: false }]}
						getOptionLabel={(option) => option.label}
						value={formik.values.detalle.is_discapacitado_object}
						onChange={(event, value) => formik.setFieldValue("detalle.is_discapacitado_object", value)}
						size="small"
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Discapacidad"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.is_discapacitado_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.is_discapacitado_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.relacion_cyd_object"
						options={relaciones_cyd.data?.data || []}
						getOptionLabel={(relacion) => relacion.valor}
						value={formik.values.detalle.relacion_cyd_object}
						onChange={(event, value) => formik.setFieldValue("detalle.relacion_cyd_object", value)}
						size="small"
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Relación con CyD"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.relacion_cyd_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.relacion_cyd_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.zonas_trabajo_object"
						options={ZonasTrabajo}
						getOptionLabel={(option) => option}
						value={formik.values.detalle.zonas_trabajo_object}
						onChange={(event, value) => formik.setFieldValue("detalle.zonas_trabajo_object", value)}
						size="small"
						fullWidth
						multiple
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Estaría dispuesto a trabajar en"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.zonas_trabajo_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.zonas_trabajo_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						name="detalle.sistema_turno_object"
						options={[{ label: "Si", valor: true }, { label: "No", valor: false }]}
						getOptionLabel={(option) => option.label}
						value={formik.values.detalle.sistema_turno_object}
						onChange={(event, value) => formik.setFieldValue("detalle.sistema_turno_object", value)}
						size="small"
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="¿Trabajaría por turnos?"
								variant="outlined"
								helperText={formik.errors.detalle && formik.errors.detalle.sistema_turno_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.sistema_turno_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="detalle.pretencion_renta_formato"
						label="Pretenciones de renta líquida"
						value={formik.values.detalle.pretencion_renta_formato}
						onChange={handleChangePretencionRenta}
						variant="outlined"
						size="small"
						fullWidth
						helperText={formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato}
						error={Boolean(formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato)}
					/>
				</Grid>
			</Grid>
		</AccordionComponent>
	);
}