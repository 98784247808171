import { object, string } from "yup";
import { NIVELES_INGLES } from "../../../../constants/contexts";

export const FormikInitialValues = {
	valor: "",
	contexto: NIVELES_INGLES,
}

export const FormikValidationSchema = object().shape({
	valor: string()
		.min(3, ({ min }) => `El nivel de inglés debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nivel de inglés debe tener a lo más ${max} caracteres.`)
		.required("El nivel de inglés es requerido."),
});