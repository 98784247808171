import { object, string, date, boolean, array } from "yup";

const REGEX_RUN = /^(\d{7}|\d{8})-[0-9kK]$/;
const REGEX_TELEFONO = /^((\+56)?(9))?\d{8}$/;
const REGEX_TELEFONO_ALTERNATIVO = /^((\+56)?)?\d{9}$/;

export const FormikInitialValues = {
	run: "",
	nombre: "",
	apellido_paterno: "",
	apellido_materno: "",
	fecha_nacimiento: null,
	edad: "",
	nacionalidad: "",
	nacionalidad_object: null,
	estado_civil: "",
	estado_civil_object: null,
	genero: "",
	ubicacion: {
		direccion: "",
		ciudad: "",
		comuna: "",
		region: "",
		region_object: null,
	},
	contacto: {
		telefono: "",
		telefono_alternativo: "",
		telefono_fijo: "",
		email: "",
		email_alternativo: "",
	},
	detalle: {
		licencias_conducir: [],
		licencias_conducir_objects: [],
		nivel_ingles: "",
		nivel_ingles_object: null,
		discapacidades: [],
		discapacidades_objects: [],
		is_discapacitado: false,
		is_discapacitado_object: null,
		situacion_laboral: "",
		situacion_laboral_object: null,
		is_ex_cyd: false,
		relacion_cyd: "",
		relacion_cyd_object: null,
		desempeno: "",
		is_contratable: false,
		zonas_trabajo: [],
		zonas_trabajo_object: [],
		sistema_turno: false,
		sistema_turno_object: null,
		pretencion_renta_formato: "",
		pretencion_renta: 0,
		_reclutador_ref: null,
	},
	inicio_vida_laboral: null,
	origen: "",
	curriculum_vitae: null,

	estudios: [],
	experiencias: [],
	referidos: [],
	adjuntos: [],
	actualizaciones: [],
	asignaciones: [],
}

export const FormikValidationSchema = object().shape({
	run: string()
		.nullable()
		.min(9, ({ min }) => `El RUN debe tener al menos ${min} caracteres.`)
		.max(10, ({ max }) => `El RUN debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_RUN, { message: "El RUN debe tener el formato \"12345678-9\"." })
		.required("El RUN es requerido."),
	nombre: string()
		.nullable()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	apellido_paterno: string()
		.nullable()
		.min(3, ({ min }) => `El primer apellido debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El primer apellido debe tener a lo más ${max} caracteres.`)
		.required("El primer apellido es requerido."),
	apellido_materno: string()
		.nullable()
		.min(3, ({ min }) => `El segundo apellido debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El segundo apellido debe tener a lo más ${max} caracteres.`)
		.optional(),
	fecha_nacimiento: date()
		.max(new Date(), "La fecha de nacimiento no puede ser superior a la fecha actual.")
		.nullable()
		.optional(),
	edad: string()
		.optional(),
	nacionalidad: string()
		.optional(),
	nacionalidad_object: object()
		.nullable()
		.optional(),
	estado_civil_object: object()
		.nullable()
		.optional(),
	genero: string()
		.nullable()
		.optional(),
	ubicacion: object()
		.shape({
			direccion: string()
				.min(2, ({ min }) => `La dirección debe tener al menos ${min} caracteres.`)
				.max(2000, ({ max }) => `La dirección debe tener a lo más ${max} caracteres.`)
				.optional(),
			ciudad: string()
				.min(2, ({ min }) => `La ciudad debe tener al menos ${min} caracteres.`)
				.max(2000, ({ max }) => `La ciudad debe tener a lo más ${max} caracteres.`)
				.optional(),
			comuna: string()
				.nullable()
				.optional(),
			region_object: object()
				.nullable()
				.optional(),
		})
		.optional(),
	contacto: object()
		.shape({
			telefono: string()
				.matches(REGEX_TELEFONO, { message: "El teléfono debe tener el formato \"[+56][9]87654321\"." })
				.optional(),
			telefono_alternativo: string()
				.matches(REGEX_TELEFONO_ALTERNATIVO, { message: "El teléfono alternativo debe tener el formato \"[+56]987654321\"." })
				.optional(),
			telefono_fijo: string()
				.matches(REGEX_TELEFONO_ALTERNATIVO, { message: "El teléfono alternativo debe tener el formato \"[+56]987654321\"." })
				.optional(),
			email: string()
				.email("El correo debe tener el formato \"usuario@dominio\".")
				.required("El correo es requerido."),
			email_alternativo: string()
				.email("El correo alternativo debe tener el formato \"usuario@dominio\".")
				.optional(),
		})
		.required("La información de contacto es requerida."),
	detalle: object()
		.shape({
			licencias_conducir_objects: array()
				.of(object())
				.optional(),
			nivel_ingles_object: object()
				.nullable()
				.optional(),
			discapacidades_objects: array()
				.of(object())
				.optional(),
			is_discapacitado_object: object()
				.nullable()
				.optional(),
			situacion_laboral_object: object()
				.nullable()
				.optional(),
			is_ex_cyd: boolean()
				.optional(),
			relacion_cyd_object: object()
				.nullable()
				.optional(),
			desempeno: string()
				.optional(),
			is_contratable: boolean()
				.optional(),
			zonas_trabajo_object: object()
				.nullable()
				.optional(),
			sistema_turno_object: object()
				.nullable()
				.optional(),
			pretencion_renta_formato: string()
				.nullable()
				.optional(),
		})
		.optional(),
	inicio_vida_laboral: date()
		.max(new Date(), "La fecha de inicio de vida laboral no puede ser superior a la fecha actual.")
		.nullable()
		.optional(),
	estudios: array()
		.of(object())
		.optional(),
	experiencias: array()
		.of(object())
		.optional(),
	origen: string()
		.required("El origen del postulante es requerido."),
});