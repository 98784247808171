import React, { useEffect } from "react";
import MaterialTable from "../../../components/materialTable";
import { FormatearFecha } from "../../../services/utilities/formatUtils";
import { AgregarDatosPostulante } from "../../database/postulantes/utils";

export default function Page(props) {
	const {
		cartas_oferta,
		is_loading,
	} = props;

	useEffect(() => {
		if (cartas_oferta) {
			for (const cartaOferta of cartas_oferta) {
				cartaOferta["fecha_format"] = FormatearFecha(cartaOferta.fecha_creacion);
				cartaOferta["_postulante_ref"] = AgregarDatosPostulante(cartaOferta._postulante_ref);
			}
		}
	}, [cartas_oferta]);

	let columns = [
		{
			title: "Nombre",
			field: "_postulante_ref.nombre_completo",
			maxWidth: 350,
		}, {
			title: "Template",
			field: "_carta_oferta_template_ref.nombre",
			render: row => <a href={row._carta_oferta_template_ref.link_template} target="_blank" rel="noreferrer">{row._carta_oferta_template_ref.nombre}</a>,
		}, {
			title: "Proyecto",
			field: "_licitacion_proyecto_cargo_asignacion_ref.licitacion_proyecto_codigo",
			maxWidth: 100,
		}, {
			title: "Cargo",
			field: "_licitacion_proyecto_cargo_asignacion_ref.cargo_codigo",
			maxWidth: 100,
		}, {
			title: "Carta oferta",
			field: "carta_oferta_url",
			render: (row) => <a href={row.carta_oferta_url} target="_blank" rel="noreferrer">Link</a>,
			maxWidth: 120,
		}, {
			title: "Fecha",
			field: "fecha_format",
			maxWidth: 120,
		},
	];

	return (
		<MaterialTable
			title="Cartas de oferta"
			is_loading={is_loading}
			data={cartas_oferta}
			columns={columns}
		/>
	);
}