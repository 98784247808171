import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

/**
 * Componente Accordion custom.
 * @param {*} title Título del accordion.
 * @param {*} errores Cantidad de errores.
 * @param {*} expanded FLAG para mostrar accordion colapsado o abierto. Valor colapsado predeterminado.
 * @returns 
 */
export default function AccordionComponent(params) {
	const {
		children,
		title,
		errores = 0,
		expanded = false,
	} = params;

	const [IsExpanded, SetisExpanded] = useState(expanded);

	/**
	 * Método encargado de actualizar el estado expandido del Accordion.
	 * @param {*} event Datos del evento.
	 * @param {*} expanded Estado expandido o no.
	 */
	const handleChange = (event, expanded) => {
		SetisExpanded(expanded);
	}

	return (
		<Accordion defaultExpanded={expanded} onChange={handleChange} style={{ borderRadius: 25 }}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Badge variant={IsExpanded ? "standard" : "dot"} badgeContent={errores} color="primary">
					<Typography>{title}</Typography>
				</Badge>
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion>
	);
}