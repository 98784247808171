import { array, mixed, number, object, string } from "yup";
import { CARGO_ESTADOS } from "../../constants/contexts";

export const TIPO_ADD = "add";
export const TIPO_UPDATE = "update";
export const TIPO_DELETE = "delete";

export const CARGO_CERRAR_MENSAJE = "No puede cerrar el cargo con cantidades distintas de vacantes y seleccionados.";
export const COMPROMISO_REMUNERACIONAL_TOOLTIP = "En caso de compromiso remuneracional, ingresar el mismo valor en los 3 campos de renta.";

export const FormikInitialValues = {
	_id: "",
	nombre: "",
	titulos: [],
	renta_liquida_min_formato: "",
	renta_liquida_min: 0,
	renta_liquida_max_formato: "",
	renta_liquida_max: 0,
	renta_liquida_objetivo_formato: "",
	renta_liquida_objetivo: 0,
	ubicacion: "",
	turno: "",
	jornada: "",
	modalidad: "",
	horario: "",
	lugar_trabajo: "",
	tipo_contrato: "",
	cant_vacantes: 0,
	_licitacion_proyecto_ref: "",
	_gerencia_ref: "",
	fecha_requerimiento: null,
	fecha_termino: null,
	duracion: undefined,
	mandante: "",
	descripcion: "",
	adjuntos: [],
	reclutador: null,
	_reclutador_ref: "",
	exp_general: 0,
	exp_especifica: 0,
	estado: CARGO_ESTADOS.ACTIVO,
	fecha_ultimo_estado: null,
	fecha_penultimo_estado: null,
	fecha_creacion: null,
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	titulos: array()
		.required("El título es requerido."),
	renta_liquida_min_formato: string()
		.required("La renta mínima es requerida."),
	renta_liquida_max_formato: string()
		.required("La renta máxima es requerida."),
	renta_liquida_objetivo_formato: string()
		.optional(),
	ubicacion: string()
		.required("La ubicación es requerida."),
	turno: string()
		.nullable()
		.optional(),
	jornada: string()
		.nullable()
		.optional(),
	modalidad: string()
		.nullable()
		.required("La modalidad es requerida."),
	tipo_contrato: string()
		.nullable()
		.required("El tipo de contrato es requerido."),
	horario: string()
		.nullable()
		.optional(),
	lugar_trabajo: string()
		.nullable()
		.optional(),
	cant_vacantes: number()
		.min(1, ({ min }) => `La cantidad de vacantes debe ser mayor a ${min}.`)
		.max(99, ({ max }) => `La cantidad de vacantes debe ser menor a ${max + 1}.`)
		.required("La cantidad de vacantes de vacantes es requerida."),
	exp_general: number()
		.min(0, ({ min }) => `La EG debe ser mayor o igual a ${min}.`)
		.max(99, ({ max }) => `La EG debe ser menor a ${max + 1}.`)
		.optional(),
	exp_especifica: number()
		.min(0, ({ min }) => `La EE debe ser mayor o igual a ${min}.`)
		.max(99, ({ max }) => `La EE debe ser menor a ${max + 1}.`)
		.required("La EE es requerida."),
	fecha_requerimiento: mixed()
		.nullable()
		.optional(),
	fecha_termino: mixed()
		.nullable()
		.optional(),
	duracion: number()
		.min(1, ({ min }) => `La duración debe ser mayor a ${min}.`)
		.max(999, ({ max }) => `La duración debe ser menor a ${max + 1}.`)
		.optional(),
	mandante: string()
		.required("El mandante es requerido."),
	descripcion: string()
		.required("La descripción es requerida."),
	reclutador: object()
		.nullable()
		.required("El reclutador es requerido."),
});

/**
 * Método encargado de retornar el texto para usuario.
 * @param {*} estado Estado del cargo como constante.
 * @returns Texto transformado para el usuario.
 */
export function EstadoCargo(estado) {
	switch (estado) {
		case CARGO_ESTADOS.ACTIVO:
			return "Activo";
		case CARGO_ESTADOS.CERRADO:
			return "Cerrado";
		case CARGO_ESTADOS.DETENIDO:
			return "Detenido";
		case CARGO_ESTADOS.FALLIDO:
			return "Fallido";
		default:
			return estado;
	}
}