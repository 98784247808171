import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { ActualizacionRequest } from "../../services/request/reclutamiento/postulantes";
import { CargoRequest, AsignacionRequest } from "../../services/request/reclutamiento/licitaciones_proyectos";
import { MainContext } from "../../App";

/**
 * Componente para la asignación de un postulante a un cargo de una licitación/proyecto.
 * @param {*} licitaciones_proyectos_swr Datos de las licitaciones/proyectos.
 * @param {*} asignaciones Asignaciones actuales de un postulante.
 * @param {*} postulantes_ids Colección de IDs de postulantes, si solo es 1, es asignación simple, si son varios, es asignación múltiple.
 * @param {*} handle_asignado Método OPCIONAL ejecutado en caso de asignación.
 * @param {*} handle_cancelado Método OPCIONAL ejecutado en caso de cancelación.
 * @returns Componente.
 */
export default function PostulanteAsignar(props) {
	const {
		licitaciones_proyectos_swr,
		asignaciones,
		postulantes_ids,
		handle_asignado,
		handle_cancelado,
	} = props;

	const [Cargos, SetCargos] = useState([]);
	const IsMultiple = Array.from(postulantes_ids).length > 1;
	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				let mensaje1 = `Agregando ${IsMultiple ? "nuevas asignaciones a los postulantes" : "nueva asignación del postulante"}...`;
				notistack.enqueueSnackbar(mensaje1, {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				let promises = [];

				for (const postulanteID of postulantes_ids) {
					let licitacionProyectoID = values.licitacion_proyecto._id;
					values._licitacion_proyecto_ref = licitacionProyectoID;
					values.tipo = values.licitacion_proyecto.tipo;
					let cargoID = values.cargo._id;
					values._cargo_ref = cargoID;
					values.cargo_nombre = values.cargo.nombre;
					values.cargo_codigo = values.cargo.codigo;
					values.licitacion_proyecto_codigo = values.licitacion_proyecto.codigo;
					values._gerencia_ref = values.licitacion_proyecto._gerencia_ref._id;
					values.gerencia_sigla = values.licitacion_proyecto._gerencia_ref.sigla;
					values._postulante_ref = postulanteID;
					values._responsable_ref = values.licitacion_proyecto._responsable_ref;
					values._reclutador_ref = values.licitacion_proyecto._reclutador_ref;
					values._asignador_ref = usuarioSesion.ref;

					//Se verifica si ya existe una asignación al postulante.
					let checkAsignaciones = await AsignacionRequest.Obtener({ _licitacion_proyecto_ref: licitacionProyectoID, _cargo_ref: cargoID, _postulante_ref: postulanteID });
					if (checkAsignaciones.length === 0) {
						//Si no existe una postulación.
						promises.push(AsignacionRequest.Agregar(licitacionProyectoID, cargoID, values));
						promises.push(ActualizacionRequest.Agregar(postulanteID, usuarioSesion.ref, `Nueva asignación al cargo ${values.cargo.nombre} (${values.cargo.codigo})`));
					}
				}

				let responses = await Promise.allSettled(promises);

				//Si existe una función para ejecutar después de todo.
				if (handle_asignado) {
					//https://futurestud.io/tutorials/detect-if-value-is-a-promise-in-node-js-and-javascript
					if (typeof handle_asignado.then === "function") {
						//Si la función es de tipo Promise.
						await handle_asignado();
					} else {
						//Si la función no es de tipo Promise.
						handle_asignado();
					}
				}

				if (IsMultiple) {
					ShowSnackbar(`${Number(responses.length / 2).toFixed(0)} asignaciones de los postulantes agregdas exitosamente.`);
				} else {
					ShowSnackbar("Asignación del postulante agregada exitosamente.");
				}
			} catch (error) {
				if (IsMultiple) {
					ShowSnackbar("Error al intentar agregar las asignaciones de los postulantes.", error);
				} else {
					ShowSnackbar("Error al intentar agregar la asignación del postulante.", error);
				}
			} finally {
				helper.resetForm();
				handleCancelar();
			}
		}
	});

	/**
	 * Método encargado de encargado de verificar si ya existe una asignación similar.
	 * @returns Texto con mensaje o vacío.
	 */
	function CheckAsignacionRepetida() {
		//Si aún no se selecciona una licitación/proyecto o cargo.
		if (!formikDialog.values.licitacion_proyecto || !formikDialog.values.cargo) {
			return "";
		}
		//ID de la licitación/proyecto seleccionado.
		let licitacionProyectoID = formikDialog.values.licitacion_proyecto._id;
		//ID del cargo seleccionado.
		let cargoAsignadoID = formikDialog.values.cargo._id;

		if (!IsMultiple) {
			//Se verifica si ya existe una asignación similar.
			let check = Array.from(asignaciones)
				.find(a => a._licitacion_proyecto_ref === licitacionProyectoID && a._cargo_ref === cargoAsignadoID);

			if (check) {
				return "La asignación ya existe.";
			} else {
				return "";
			}
		}
		return "";
	}

	/**
	 * Método encargado de cargar los cargos de una licitación/proyecto.
	 * @param {*} licitacionProyectoID ID de la licitación/proyecto.
	 */
	async function LoadCargos(licitacionProyectoID) {
		try {
			let cargos = await CargoRequest.ObtenerPorLicitacionProyecto(licitacionProyectoID);
			SetCargos(cargos);
		} catch (error) {
			ShowSnackbar("Error al intentar cargar los datos de los cargos.", error);
		}
	}

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCancelar = () => {
		formikDialog.resetForm();
		if (handle_cancelado) {
			handle_cancelado();
		}
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			CheckAsignacionRepetida() ||
			formikDialog.isSubmitting ||
			!formikDialog.values.licitacion_proyecto ||
			!formikDialog.values.cargo ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Grid container spacing={2}>
			{/* NOMBRE DEL ADJUNTO */}
			<Grid item xs={12}>
				<Autocomplete
					name="licitacion_proyecto"
					options={licitaciones_proyectos_swr.data || []}
					value={formikDialog.values.licitacion_proyecto}
					getOptionLabel={(licitacionProyecto) => `[${licitacionProyecto.codigo}] ${licitacionProyecto.nombre} (${licitacionProyecto.tipo})`}
					onChange={(event, value) => {
						formikDialog.setFieldValue("licitacion_proyecto", value);
						formikDialog.setFieldValue("cargo", null);
						value ? LoadCargos(value._id) : SetCargos([]);
					}}
					loading={licitaciones_proyectos_swr.isValidating}
					size="small"
					renderInput={(params) => (
						<TextField
							label="Licitación/Proyecto"
							variant="outlined"
							helperText={formikDialog.errors.licitacion_proyecto}
							error={Boolean(formikDialog.errors.licitacion_proyecto)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Autocomplete
					name="cargo"
					options={Cargos}
					value={formikDialog.values.cargo}
					getOptionLabel={(cargo) => `[${cargo.codigo}] ${cargo.nombre}`}
					onChange={(event, value) => formikDialog.setFieldValue("cargo", value)}
					loading={Cargos.length < 1}
					disabled={Cargos.length < 1}
					size="small"
					renderInput={(params) => (
						<TextField
							label="Cargo"
							variant="outlined"
							helperText={formikDialog.errors.cargo}
							error={Boolean(formikDialog.errors.cargo)}
							{...params}
						/>
					)}
				/>
			</Grid>

			{/* BOTONES AGREGAR/CANCELAR */}
			<Grid item xs={12}>
				<Box display="flex">
					<Box p={1} flexGrow={1}>
						<Typography>{CheckAsignacionRepetida()}</Typography>
					</Box>
					<Box mr={1}>
						<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
							Asignar
						</Button>
					</Box>
					<Box ml={1}>
						<Button variant="contained" onClick={handleCancelar} disabled={formikDialog.isSubmitting} color="secondary">
							Cerrar
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}