import React from "react";
import { Grid, ListItem, Paper, Typography } from "@material-ui/core";
import { NombreCompleto } from "../../../services/utilities/formatUtils";

/**
 * Método encargado de retornar un componente con la información del postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Component.
 */
export default function PostulanteInfo(props) {
	const {
		postulante,
	} = props;

	return (
		<Paper style={{ padding: 20, borderRadius: 25 }}>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Paper style={{ backgroundColor: "#731f1f", padding: 10, borderRadius: 25 }}>
						<Typography style={{color:"white"}}>Información del postulante</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					{TituloContenido("Nombre", NombreCompleto(postulante))}
				</Grid>
				<Grid item xs={12}>
					{TituloContenido("RUN", postulante.run)}
				</Grid>
				<Grid item xs={12}>
					{TituloContenido("Teléfono", postulante.contacto.telefono)}
				</Grid>
				<Grid item xs={12}>
					{TituloContenido("Email", postulante.contacto.email)}
				</Grid>
			</Grid>
		</Paper>
	);
}

/**
 * Método encargado de retornar un texto como título de sección.
 * @param {*} text Texto para mostrar.
 * @returns Component.
 */
function TituloContenido(titulo, contenido) {
	return (
		<ListItem style={{ display: "list-item", paddingLeft: 0, paddingRight: 0 }}>
			<Typography style={{ display: "contents" }}>
				<b>{titulo}:</b> {contenido}
			</Typography>
		</ListItem>
	);
}