import Axios from "axios";
import { RECLUTAMIENTO } from "../../../constants/urls";

const REGISTROS_KPI_ROUTE = "registros-kpi";

/**
 * Método encargado de agregar un registro de KPI.
 * @param {*} registroKPI Datos del registro KPI.
 * @returns Response.
 */
export async function Agregar(registroKPI) {
	try {
		let url = `${RECLUTAMIENTO}/${REGISTROS_KPI_ROUTE}`;
		let response = await Axios.post(url, registroKPI);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la colección de registros de KPI.
 * @returns Colección de registros KPI.
 */
export async function Obtener() {
	try {
		let url = `${RECLUTAMIENTO}/${REGISTROS_KPI_ROUTE}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los registros de KPI de inicion de sesión.
 * @returns Colección de registros KPI.
 */
export async function ObtenerSesionKPI() {
	try {
		let url = `${RECLUTAMIENTO}/${REGISTROS_KPI_ROUTE}/sesion`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los registros de KPI de búsqueda.
 * @returns Colección de registros KPI.
 */
export async function ObtenerBusquedaKPI() {
	try {
		let url = `${RECLUTAMIENTO}/${REGISTROS_KPI_ROUTE}/busqueda`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}