import PaisesJson from "../data/paises.json";

/**
 * Método encargado de obtener el listado de paises.
 * @returns Colección de paises, y más información.
 */
export function Paises() {
	try {
		let paises = Array.from(PaisesJson);
		let indexChile = paises.findIndex(p => LocaleCompare(p.name, "chile"));
		paises.unshift(paises[indexChile]);
		return paises;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un país, por su nombre.
 * @param {*} nombrePais Nombre del país.
 * @returns Datos del país.
 */
export function PaisPorNombre(nombrePais) {
	try {
		let pais = Paises().find(p => LocaleCompare(p.name, nombrePais));
		return pais;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la bandera de un país, por su nombre.
 * @param {*} nombrePais Nombre del país.
 * @returns URL de la bandera del país.
 */
export function BanderaPorNombre(nombrePais) {
	try {
		let bandera = Paises().find(p => LocaleCompare(p.name, nombrePais))?.image;
		return bandera;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/***************************************************************************************
 * 																	FUNCIONES
 ***************************************************************************************/

/**
 * Método encargado de hacer una comparación de textos.
 * @param {*} text1 Texto original.
 * @param {*} text2 Texto para comparar.
 * @returns Boolean con el resultado de la comparación.
 */
function LocaleCompare(text1, text2) {
	let check = String(text1).toLowerCase().localeCompare(String(text2).toLowerCase(), "es", { sensitivity: "base" }) === 0;
	return check;
}