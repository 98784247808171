import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import SemiCircleChart from "./semiCircleChart";
import { PostulanteRequest } from "../../../services/request/reclutamiento/postulantes";
import { MainContext } from "../../../App";

export default function Calidad(props) {
	const IncompletosSWR = useSWR("incompletos", (key) => PostulanteRequest.CantidadesIncompletos());
	const PendientesSWR = useSWR("pendientes", (key) => PostulanteRequest.CantidadesPendientes());
	const ContratadosSWR = useSWR("contratados", (key) => PostulanteRequest.CantidadesContratados());
	const MainCTX = useContext(MainContext);

	useEffect(() => {
		if (IncompletosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los registros KPI de incompletos.", IncompletosSWR.error);
		}
		if (PendientesSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los registros KPI de pendientes.", PendientesSWR.error);
		}
		if (ContratadosSWR.error) {
			MainCTX.ShowSnackbar("Error al intentar obtener los registros KPI de contratados.", ContratadosSWR.error);
		}
	}, [MainCTX, IncompletosSWR.error, PendientesSWR.error, ContratadosSWR.error]);

	return (
		<Fragment>
			<Typography variant="h4">KPI Calidad</Typography>
			<Grid container spacing={2} style={{ padding: 10 }}>
				{/* GRÁFICO DE SESIONES */}
				<Grid item xs={4}>
					<Paper elevation={10} style={{ height: 225, borderRadius: 25, paddingBottom: 20 }}>
						<Typography variant="h6" align="center">Postulantes Sin CV</Typography>
						<Box display="flex" justifyContent="center" alignItems="center" style={{ height: 200 }}>
							<Typography variant="h1" align="center">{Number(IncompletosSWR.data || 0).toLocaleString()}</Typography>
						</Box>
					</Paper>
				</Grid>

				{/* GRÁFICO DE ASIGNACIONES */}
				<Grid item xs={4}>
					<Paper elevation={10} style={{ height: 225, borderRadius: 25, paddingBottom: 20 }}>
						<Typography variant="h6" align="center">Postulantes con Información Incompleta</Typography>
						<Typography variant="subtitle1" align="center">(menor a 42%)</Typography>
						<SemiCircleChart
							data={PendientesSWR.data}
						/>
					</Paper>
				</Grid>

				{/* GRÁFICO DE CVS CARGADOS */}
				<Grid item xs={4}>
					<Paper elevation={10} style={{ height: 225, borderRadius: 25, paddingBottom: 20 }}>
						<Typography variant="h6" align="center">Postulantes Contratados</Typography>
						<Box display="flex" justifyContent="center" alignItems="center" style={{ height: 200 }}>
							<Typography variant="h1" align="center">{Number(ContratadosSWR.data || 0).toLocaleString()}</Typography>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Fragment>
	);
}