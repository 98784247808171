import React from "react";
import { AppBar, Button, Dialog, DialogActions, DialogContent, Tab, Tabs } from "@material-ui/core";
import DialogTitleComponent from "../../components/dialog_title";
import TabPanelComponent from "../../components/tab_panel";
import DetalleRespuestas from "./detalles/detalle_respuestas";
import DetalleExperiencias from "./detalles/detalle_experiencias";
import DetalleEstudios from "./detalles/detalle_estudios";
import DetalleIdiomas from "./detalles/detalle_idiomas";
import DetalleHabilidades from "./detalles/detalle_habilidades";
import DetalleDocumentos from "./detalles/detalle_documentos";

/**
 * Componente para mostrar el detalle de un postulante.
 * @param {*} postulante Datos del postulante.
 * @param {*} handle_close_dialog Método encargado de cerrar el Dialog.
 */
export default function DialogDetalle(props) {
	const {
		postulante,
		handle_close_dialog,
	} = props;

	const [TabIndex, SetTabIndex] = React.useState(0);

	const handleTabIndex = (event, value) => {
		SetTabIndex(value);
	};

	return (
		<Dialog open={true} onClose={handle_close_dialog} maxWidth="md" PaperProps={{ style: { borderRadius: 20, height: 500 } }}>
			<DialogTitleComponent onClose={handle_close_dialog}>Detalles del Postulante</DialogTitleComponent>
			<DialogContent>
				<div>
					<AppBar position="static" color="default">
						<Tabs
							value={TabIndex}
							onChange={handleTabIndex}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab label="Respuestas" />
							<Tab label="Experiencias" />
							<Tab label="Estudios" />
							<Tab label="Idiomas" />
							<Tab label="Habilidades" />
							<Tab label="Documentos" />
						</Tabs>
					</AppBar>

					{/* PESTAÑA DETALLE RESPUESTAS */}
					<TabPanelComponent value={TabIndex} index={0}>
						<DetalleRespuestas
							respuestas={postulante.questions}
						/>
					</TabPanelComponent>

					{/* PESTAÑA DETALLE EXPERIENCIAS */}
					<TabPanelComponent value={TabIndex} index={1}>
						<DetalleExperiencias
							experiencias={postulante.applicant.workExperience}
						/>
					</TabPanelComponent>

					{/* PESTAÑA DETALLE ESTUDIOS */}
					<TabPanelComponent value={TabIndex} index={2}>
						<DetalleEstudios
							estudios={postulante.applicant.studies}
						/>
					</TabPanelComponent>

					{/* PESTAÑA DETALLE IDIOMAS */}
					<TabPanelComponent value={TabIndex} index={3}>
						<DetalleIdiomas
							idiomas={postulante.applicant.languages}
						/>
					</TabPanelComponent>

					{/* PESTAÑA DETALLE HABILIDADES */}
					<TabPanelComponent value={TabIndex} index={4}>
						<DetalleHabilidades
							habilidades={postulante.applicant.skills}
						/>
					</TabPanelComponent>

					{/* PESTAÑA DETALLE DOCUMENTOS */}
					<TabPanelComponent value={TabIndex} index={5}>
						<DetalleDocumentos
							documentos={[postulante.applicant.documents.dataDocument, postulante.applicant.multipleDocuments.dataDocument]}
						/>
					</TabPanelComponent>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close_dialog} variant="outlined" color="primary">
					Salir
				</Button>
			</DialogActions>
		</Dialog>
	);
}