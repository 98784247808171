import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

/**
 * Componente gráfico de Dona con Highcharts.
 * @param {*} data Datos para graficar.
 * @returns Componente gráfico.
 */
export default function SemiCircleChart(props) {
	const {
		data,
	} = props;

	const Options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			height: 165,
		},
		title: {
			text: data ? `${data.cant_lt_42.toLocaleString()} de ${data.cant.toLocaleString()}` : null,
			align: "center",
			verticalAlign: "middle",
			y: 90
		},
		tooltip: {
			pointFormat: "{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>"
		},
		accessibility: {
			point: {
				valueSuffix: "%"
			}
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			pie: {
				dataLabels: {
					enabled: true,
					distance: -50,
					style: {
						fontWeight: "bold",
						color: "white"
					}
				},
				startAngle: -90,
				endAngle: 90,
				center: ["50%", "90%"],
				size: "170%"
			}
		},
		series: [{
			type: "pie",
			name: "Completitud",
			innerSize: "50%",
			data: [
				["< 42%", data?.cant_lt_42 || 0],
				["42% ≤ X ≤ 90%", data?.cant_lt_90 || 0],
				["90% <", data?.cant_gt_90 || 0],
			]
		}]
	}

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={Options}
		/>
	);
}