import React, { useContext } from "react";
import { Grid, TextField } from "@material-ui/core";
import AccordionComponent from "../../accordion";
import { MainContext } from "../../../App";

/**
 * Método encargado de retornar un componente con formulario de entrevista.
 * @param {*} formik Datos del formulario.
 * @returns Component.
 */
export default function VistaEntrevista(props) {
	const {
		formik,
	} = props;

	const MainCTX = useContext(MainContext);

	/**
	 * Método encargado de verificar y asignar la nota del postulante.
	 * @param {*} event Datos del evento.
	 */
	const handleEvaluacion = (event) => {
		try {
			let evaluacion = Number(event.target.value);
			if (isNaN(evaluacion) || evaluacion < 0 || evaluacion > 7) {
				formik.setFieldValue("evaluacion", 0);
				throw new Error("Nota inválida.");
			} else {
				formik.setFieldValue("evaluacion", evaluacion);
			}
		} catch (error) {
			MainCTX.ShowSnackbar("Error al asignar la nota.", error);
		}
	}

	return (
		<AccordionComponent title="Vista entrevista">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TextField
						name="fuente"
						label="Fuente"
						value={formik.values.fuente}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.fuente}
						error={Boolean(formik.errors.fuente)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="situacion_laboral"
						label="Situación laboral y disponibilidad"
						value={formik.values.situacion_laboral}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.situacion_laboral}
						error={Boolean(formik.errors.situacion_laboral)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="titulo"
						label="Título"
						value={formik.values.titulo}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.titulo}
						error={Boolean(formik.errors.titulo)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="institucion"
						label="Institución"
						value={formik.values.institucion}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.institucion}
						error={Boolean(formik.errors.institucion)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						name="exp_general"
						label="Exp. general"
						value={formik.values.exp_general}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.exp_general}
						error={Boolean(formik.errors.exp_general)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						name="exp_especifica"
						label="Exp. específica"
						value={formik.values.exp_especifica}
						onChange={formik.handleChange}
						variant="outlined"
						fullWidth
						size="small"
						helperText={formik.errors.exp_especifica}
						error={Boolean(formik.errors.exp_especifica)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						name="evaluacion"
						label="Nota (de 0 a 7)"
						value={formik.values.evaluacion}
						onChange={handleEvaluacion}
						variant="outlined"
						type="number"
						inputProps={{ min: 0, max: 7, step: 0.1 }}
						fullWidth
						size="small"
						helperText={formik.errors.evaluacion}
						error={Boolean(formik.errors.evaluacion)}
					/>
				</Grid>
			</Grid>
		</AccordionComponent>
	);
}