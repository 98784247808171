import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import DialogTitleComponent from "../../../../components/dialog_title";
import { MainContext } from "../../../../App";
import { FormikInitialValues, FormikValidationSchema } from "./utilsEvaluaciones";
import { EvaluacionRequest, PostulanteRequest } from "../../../../services/request/reclutamiento/postulantes";
import * as Permissions from "../../../../constants/permissions";
import { CheckPermiso } from "../../../../services/utilities/common";

/**
 * Componente para agregar o actualizar una evaluación.
 * @param {*} postulante Datos del postulante.
 * @param {*} evaluacion Datos de la evaluación.
 * @param {*} handle_close Método encargado de cerrar el popup.
 * @param {*} actualizar_postulante_local Método encargado de actualizar los datos localmente.
 * @returns Component.
 */
export default function DialogEvaluacion(props) {
	const {
		postulante,
		evaluacion,
		handle_close,
		actualizar_postulante_local,
	} = props;

	const MainCTX = useContext(MainContext);
	const notistack = useSnackbar();

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar(`${evaluacion ? "Actualizando el" : "Agregando nuevo"} comentario del postulante...`, {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				values["_postulante_ref"] = postulante._id;
				values["_reclutador_ref"] = MainCTX.usuarioSesion.ref;

				if (evaluacion) {
					await EvaluacionRequest.Actualizar(postulante._id, values);
				} else {
					await EvaluacionRequest.Agregar(postulante._id, values);
				}
				let postulanteUpdate = await PostulanteRequest.ObtenerPorID(postulante._id);
				actualizar_postulante_local(postulanteUpdate);

				notistack.enqueueSnackbar(`Comentario del postulante ${evaluacion ? "actualizado" : "agregado"} exitosamente.`, {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				notistack.enqueueSnackbar(`Error al intentar ${evaluacion ? "actualizar" : "agregar"} el comentario del postulante.`, {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handle_close();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de eliminar una evaluación del postulante.
	 */
	const handleEliminar = async () => {
		try {
			notistack.enqueueSnackbar("Eliminando la evaluación del postulante...", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
			});
			await EvaluacionRequest.Eliminar(postulante._id, evaluacion._id);
			let postulanteUpdate = await PostulanteRequest.ObtenerPorID(postulante._id);
			actualizar_postulante_local(postulanteUpdate);

			notistack.enqueueSnackbar("Evaluación del postulante eliminada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar eliminar la evaluación del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handle_close();
			formik.resetForm();
		}
	}

	useEffect(() => {
		if (evaluacion) {
			formik.setValues({
				...evaluacion,
			});
		}
	}, [evaluacion]);

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			!postulante ||
			formik.isSubmitting ||
			!formik.values.valor ||
			!CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_EDITAR_COMENTARIOS),
			Object.keys(formik.errors).length > 0
		);
		return check;
	}

	return (
		<Dialog open={true} onClose={handle_close} maxWidth="md" PaperProps={{ style: { borderRadius: 20 } }} fullWidth>
			<DialogTitleComponent>Comentario</DialogTitleComponent>
			<DialogContent>
				<DialogContentText>
					Ingresar toda la información del nuevo comentario para el candidato.
				</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={9}>
						<TextField
							name="comentario"
							label="Comentario"
							value={formik.values.comentario}
							onChange={formik.handleChange}
							variant="outlined"
							fullWidth
							helperText={formik.errors.comentario}
							error={Boolean(formik.errors.comentario)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Rating
							name="valor"
							value={formik.values.valor}
							size="large"
							onChange={(event, value) => formik.setFieldValue("valor", value || 0)}
						/>
						{formik && formik.errors && formik.errors.valor && (
							<Box display="flex">
								<Typography variant="caption" color="error" style={{ marginLeft: 5 }}>
									{formik.errors.valor}
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={formik.submitForm} disabled={isDisabled()} color="primary">
					{evaluacion ? "Actualizar" : "Agregar"}
				</Button>
				{evaluacion && (
					<Button variant="contained" onClick={handleEliminar} disabled={!CheckPermiso(MainCTX.permisos, Permissions.CANDIDATOS_ELIMINAR_COMENTARIOS)} color="primary">
						Eliminar
					</Button>
				)}
				<Button variant="contained" onClick={handle_close} color="secondary">
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
}